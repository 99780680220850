import React, { useState } from "react";
import { FaEnvelope, FaLock, FaGoogle, FaFacebook } from "react-icons/fa";
import "./user.css";
import Google from "../../Assets/Images/Google.png";
import facebook from "../../Assets/Images/facebook.png";
import { signin, signup } from "../../action/auth";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { useSearchParams } from "react-router-dom";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const[showerror,SetShowerror]=useState('')
  const redirect_Url = searchParams.get("redirect_url");
console.log(redirect_Url);
  // export const signUp = (email,password,name) => API.post('/register', {email,password,name});
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {  
    setPassword(e.target.value);
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    SetShowerror('')
    //login logic
    // const API = axios.create({ baseURL: 'http://localhost:8080' });
  
    // API.interceptors.request.use((req) => {
    //   if (localStorage.getItem('profile')) {
    //     req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`;
    //   }
    //   return req;
    // });
    try{
      var response= await  axios.post(`https://us-central1-askmeidentity-website.cloudfunctions.net/as/login`, {
        email, password});
        localStorage.setItem("profile", JSON.stringify(response.data));
        if(redirect_Url==''||redirect_Url==null||redirect_Url==undefined){
          window.location.replace('/user-overview')
        }else{
          window.location.replace(`/${redirect_Url}`)

        }
       //console.log(response.data.access_token);
    } catch (error) {
      SetShowerror(error.response?.data.general)
    //  console.log(error.response.data.general);
    }

  //  dispatch(signin(email, password, navigate));
  };

  return (
    <div className="login-form">
      <h2 style={{ marginTop: "25%", marginBottom: "20px" ,fontFamily:"Inter"}}>Log in</h2>
      <form onSubmit={handleSubmit}>
        <div className="input-group">
          <div className="icon">
            <FaEnvelope />
          </div>
          <input
            type="email"
            placeholder="Email"
            style={{heigth:'20px',fontSize:"18px",padding:"0px",fontFamily:"Inter"}}
            value={email}
            onChange={handleEmailChange}
            required
          />
        </div>

        <div className="input-group">
          <div className="icon">
            <FaLock />
          </div>
          <input
            type="password"
            placeholder="Password"
            value={password}
            style={{heigth:'20px',fontSize:"18px",padding:"0px",fontFamily:"Inter"}}
            onChange={handlePasswordChange}
            required
          />
        </div>
       <p style={{color:"red",marginLeft:"10px",fontWeight:"500"}} className="forgot-password">{showerror}</p>
        <button
          type="submit"
          style={{ backgroundColor: "#1A53DD", color: "white",fontSize:"17px" ,height:"40px",fontFamily:"Inter"}}
        >
          Login
        </button>
      </form>
    <a href="https://mylogin.askmeidentity.com/signin/forgot-password"  style={{textDecoration:"none"}}> <p className="forgot-password" style={{fontSize:"16px",fontFamily:"Inter" }}  >Forgot password?</p></a>
     

      {/* <div style={{ display: "flex" }}>
        <button
          style={{ border: "1px solid #E0E2E9", marginRight: "10px",fontSize:"14px" }}
          className="google-btn"
        >
          <img src={Google} className="icon google-icon"  />
          Google
        </button>
        <button
          style={{ border: "1px solid #E0E2E9",fontSize:"14px",fontFamily:"Inter" }}
          className="facebook-btn"
        >
          <img src={facebook} className="icon facebook-icon" />
          Facebook
        </button>
      </div> */}
      <br />

      <p style={{ marginLeft: "30px",fontSize:"16px",fontFamily:"Inter" }}>
        Don't have an account? <a href={`/register?redirect_url=${redirect_Url}`}>Sign up</a>
      </p>
    </div>
  );
}

export default Login;
