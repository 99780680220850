import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Logo from "../../Assets/Images/Logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";
import "./nav.css";
function MainNavbar() {
  const token=JSON.parse(localStorage.getItem('profile'));

  return (
    <Navbar expand="lg" className="bg-body-tertiary sticky-top custom-navbar" style={{display:"flex",justifyContent:"center"}}>
      <Container fluid  style={{display:"flex",justifyContent:"center",width:"70%"}} >
        <Navbar.Brand href="/askmeidentity-training" style={{ marginLeft: "5%" }}>
          <img style={{ width: "180px" }} src={Logo} alt="" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll"style={{backgroundColor:"white"}}>
          <Nav
            className=" my-2 my-lg-2 custom-nav"
            style={{ maxHeight: "100px", margin: "0px 20px"}}
            navbarScroll
          >
            <NavDropdown style={{fontSize:"18px"}} title="Categories" id="navbarScrollingDropdown" >
              <NavLink style={{textDecoration:"none",color:"black",textAlign:"center",marginLeft:"15px",fontSize:"14px",backgroundColor:"white",fontFamily:"Inter"}}  to='/all-training?name=IAM'>IAM</NavLink>
              <NavDropdown.Item href="/all-training?name=web-dev">
                <NavLink style={{textDecoration:"none",color:"black",fontSize:"14px",fontFamily:"Inter"}} reloadDocument to='/all-training?name=web-dev'>Web Development</NavLink>
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>

          <Form className="d-flex">
            <div className="position-relative">
              <Form.Control
                type="search"
                placeholder="  Search"
                className="me-2"
                aria-label="Search"
                style={{
                  borderRadius: "20px",
                //   width: "600px",
                  border:"1px solid black",
                  paddingLeft: "15px",
                  height:"39px",
                  paddingTop:"2px",
                  fontSize:"15px"
                }}
              />
              <FontAwesomeIcon
                icon={faSearch}
                style={{ marginLeft: "15px" }}
                className="position-absolute top-50 start-0 translate-middle-y"
              />
            </div>
          </Form>

          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: "100px" }}
            navbarScroll
          >
            <Nav.Link href="https://askmeidentity.com/" style={{fontSize:"18px",fontFamily:"Inter"}}>Askmeidentity</Nav.Link>
            {
               (token==null || token==''  || token==undefined)?(
                <>
                 <NavLink to="/login">
              {" "}
              <Button
                className="custom-button"
                style={{
                  border: "1px solid black",
                  backgroundColor: "white",
                  color: "black",
                  fontSize:"15px",fontFamily:"Inter"
                }}
              >
                Log in
              </Button>
            </NavLink>

            <NavLink to="/register">
              {" "}
              <Button className="custom-button" style={{fontSize:"15px",fontFamily:"Inter"}}>Sign up</Button>
            </NavLink>
                </>
               ):(  <NavLink to="/user-overview">
               {" "}
               <Button className="custom-button" style={{marginLeft:"50px",fontSize:"15px",fontFamily:"Inter"}}>My Learning</Button>
             </NavLink>)
            }
           
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default MainNavbar;
