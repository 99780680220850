import React, { useState,useEffect } from "react";
import firebase from "firebase/compat/app";
import Nav from "../Components/Nav/Navbar";
import Footer from "../src/Component/Fotter/Fotter";
import { NavLink } from "react-router-dom";
import "./conact.css";
import { Helmet } from "react-helmet";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import Rectangle from "../Components/Images/Rectangle.png";
import Navbar from "../Components/header/Header";
function Contact() {
  const [data, setdata] = useState("");
  const [name, setname] = useState("");
  const [ename, setename] = useState("");
  const [query, setquery] = useState("");
  const [equery, setequery] = useState("");
  const [email, setemail] = useState("");
  const [coustmer, setcoustmer] = useState("");
  const [ecoustmer, setecoustmer] = useState("");
  const [message, setmessage] = useState("");
  const [emessage, setemessage] = useState("");
  const [efailed, setefailed] = useState("");
  const token = JSON.parse(localStorage.getItem("data"));
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  let navigate = useNavigate();

  const createDoc = async (e) => {
    e.preventDefault();

    if (name == "") {
      setename("required*");
    } else {
      setename("");
    }
    if (query == "") {
      setequery("required*");
    } else {
      setequery("");
    }
    if (coustmer == "") {
      setecoustmer("required*");
    } else {
      setecoustmer("");
    }
    if (message == "") {
      setemessage("required*");
    } else {
      setemessage("");
    }

    if (name == "" || query == "" || message == "" || coustmer == "") {
      return;
    }

    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (String(email).toLowerCase().match(re)) {
      setefailed("");
      console.log("work");
    } else {
      setefailed("Invalid email");

      return;
      console.log("cond");
    }

    await Axios.post(`${process.env.REACT_APP_BASE_URL}/contact`, {
      name: name,
      email: email,
      query: query,
      message: message,
      coustmer: coustmer,
    })
      .then((response) => {
        console.log(response.data);
        setdata("jhkj");
        // localStorage.setItem('data', JSON.stringify(user._tokenResponse))
      })
      .catch((err) => {
        console.error(err);
        //  setfailed('Invalid email or password')
      });
  };

  const main = {};
  const keywords = ["IAM Training", "IAM Solutions", "IAM Services", "Managed IT Solutions", "Web Development","Consultant Services"]
  
  const keywordString = keywords.join(', ');
  return (
    <>
       <Helmet>
            <title> Get in Touch with Askmeidentity Today| Best IAM Service Provider
      </title>
            <meta name="description" content="Connect with Askmeidentity - Your Trusted IAM Experts. Reach out to us for secure, tailored identity and access management solutions." />
            <meta name="keywords" content={keywordString} />
          </Helmet>
      {/* <Nav/> */}
      <Navbar />
      <div
        className="maindiv"
        style={{
          backgroundImage: `url(${Rectangle})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="text">
          <p className="textl" style={{ fontFamily: "Inter" }}>
            WE'RE HERE TO HELP YOU
          </p>
          <p className="texts" style={{ fontFamily: "Inter" }}>
            Reach out today to receive more information about any of our
            services, trainings, to receive a demo of our IAM solutions, or if
            you have questions about any of our IAM vendor partners’ products.
            <br />
            <br />
            For Support: support@askmeidentity.com
          </p>
        </div>

        {data ? (
          <div className="contactform">
            <form className="" action="">
              {/* <input className='input' style={{padding: "6px 15px",fontFamily:"Inter",fontSize:'18px'}}  type="text" name="" id="name1" onChange={(e)=>setname(e.target.value)} placeholder=' Your Name'/>
            <input className='input' style={{padding: "6px 15px",fontFamily:"Inter",fontSize:'18px'}}  type="Email" name="" id="email1" onChange={(e)=>setemail(e.target.value)} placeholder='Your Email'/>
            <input className='input' style={{padding: "6px 15px",fontFamily:"Inter",fontSize:'18px'}}  type="text" name="" id="query1" onChange={(e)=>setquery(e.target.value)} placeholder='Your Query'/>
            <input className='input' style={{padding: "6px 15px",fontFamily:"Inter",fontSize:'18px'}}  type="text" name="" id="coustmer1" onChange={(e)=>setcoustmer(e.target.value)} placeholder='Existing Customer'/>
            <input className='Minput' style={{padding: "6px 15px",fontFamily:"Inter",fontSize:'18px'}}  type="text" name="" id="message1" onChange={(e)=>setmessage(e.target.value)} placeholder='Message'/>
           <h2 style={{marginLeft:"40px",marginTop:"20px",fontSize:"15px"}}> <input type="checkbox" style={{height:"15px",width:"15px",border:"1px solid #000000",borderRadius:"2px"}} /> I Agree Term & Conditions <span style={{color:"rgba(37,214,225,1)"}}>      <NavLink to="/term" style={{color:"rgba(37,214,225,1)"}}> askmeidentity</NavLink></span></h2> */}

              <p
                style={{
                  fontSize: "20px",
                  fontFamily: "Inter",
                  justifyContent: "center",
                  textAlign: "center",
                  justifyItems: "center",
                  marginLeft: "30px",
                  marginRight: "30px",
                  marginTop: "200px",
                }}
              >
                Thankyou for submitting your request. Please expect a response
                from the team within 48 hours. For immediate support please
                write to us at <br />
                <span
                  style={{
                    color: "rgba(37,214,225,1)",
                    fontSize: "23px",
                    marginTop: "20px",
                  }}
                >
                  {" "}
                  support@askmeidentity.com
                </span>{" "}
              </p>

              {/* <button className='contactbtnn' onClick={(e) => createDoc(e)} >Send</button> */}
            </form>
          </div>
        ) : (
          <div className="contactform">
            <form className="" action="">
              <input
                className="input"
                style={{
                  padding: "6px 15px",
                  fontFamily: "Inter",
                  fontSize: "18px",
                }}
                type="text"
                name=""
                id="name1"
                onChange={(e) => setname(e.target.value)}
                placeholder=" Your Name"
                required
              />
              {ename ? (
                <p
                  style={{
                    fontSize: "15px",
                    opacity: "0.8",
                    marginTop: "10px",
                    float: "right",
                  }}
                >
                  <p style={{ color: "red" }}>{ename}</p>
                </p>
              ) : (
                ""
              )}
              <input
                className="input"
                style={{
                  padding: "6px 15px",
                  fontFamily: "Inter",
                  fontSize: "18px",
                }}
                type="Email"
                name=""
                id="email1"
                onChange={(e) => setemail(e.target.value)}
                placeholder="Your Email"
              />
              <br />
              {efailed ? (
                <p
                  style={{
                    fontSize: "15px",
                    opacity: "0.8",
                    marginTop: "10px",
                    float: "right",
                  }}
                >
                  <p style={{ color: "red" }}>{efailed}</p>
                </p>
              ) : (
                ""
              )}
              <input
                className="input"
                style={{
                  padding: "6px 15px",
                  fontFamily: "Inter",
                  fontSize: "18px",
                }}
                type="text"
                name=""
                id="query1"
                onChange={(e) => setquery(e.target.value)}
                placeholder="Your Query"
              />
              {equery ? (
                <p
                  style={{
                    fontSize: "15px",
                    opacity: "0.8",
                    marginTop: "10px",
                    float: "right",
                  }}
                >
                  <p style={{ color: "red" }}>{equery}</p>
                </p>
              ) : (
                ""
              )}
              <input
                className="input"
                style={{
                  padding: "6px 15px",
                  fontFamily: "Inter",
                  fontSize: "18px",
                }}
                type="text"
                name=""
                id="coustmer1"
                onChange={(e) => setcoustmer(e.target.value)}
                placeholder="Existing Customer"
              />
              {ecoustmer ? (
                <p
                  style={{
                    fontSize: "15px",
                    opacity: "0.8",
                    marginTop: "10px",
                    float: "right",
                  }}
                >
                  <p style={{ color: "red" }}>{ecoustmer}</p>
                </p>
              ) : (
                ""
              )}
              <input
                className="Minput"
                style={{
                  padding: "6px 15px",
                  fontFamily: "Inter",
                  fontSize: "18px",
                }}
                type="text"
                name=""
                id="message1"
                onChange={(e) => setmessage(e.target.value)}
                placeholder="Message"
              />
              {emessage ? (
                <p
                  style={{
                    fontSize: "15px",
                    opacity: "0.8",
                    marginTop: "10px",
                    float: "right",
                  }}
                >
                  <p style={{ color: "red" }}>{emessage}</p>
                </p>
              ) : (
                ""
              )}
              <h2
                style={{
                  marginLeft: "40px",
                  marginTop: "20px",
                  fontSize: "15px",
                  fontFamily: "Inter",
                }}
              >
                {" "}
                <input
                  type="checkbox"
                  style={{
                    height: "15px",
                    width: "15px",
                    border: "1px solid #000000",
                    borderRadius: "2px",
                  }}
                />{" "}
                I Agree Term & Conditions{" "}
                <span style={{ color: "rgba(37,214,225,1)" }}>
                  {" "}
                  <NavLink to="/term" style={{ color: "rgba(37,214,225,1)" }}>
                    {" "}
                    askmeidentity
                  </NavLink>
                </span>
              </h2>
              <button className="contactbtnn" onClick={(e) => createDoc(e)}>
                Send
              </button>
            </form>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
}

export default Contact;
