import * as api from '../api/index';

export const getcourses = () => async (dispatch) => {
  try {
  //console.log(start,end);
    const { data } = await api.getcourses();
    
    dispatch({ type: 'FETCH_ALL', payload: {data}});

  } catch (error) {
    console.log(error);
  }
};
export const getcoursesDetail = (ID) => async (dispatch) => {
    try {
    //console.log(start,end);
      const { data } = await api.getDetail(ID);
      
      dispatch({ type: 'FETCH_Detail', payload: data});
  
    } catch (error) {
      console.log(error);
    }
  };