import axios from 'axios';
const API = axios.create({ baseURL: 'https://us-central1-askmeidentity-website.cloudfunctions.net/as' });

API.interceptors.request.use((req) => {
  if (localStorage.getItem('profile')) {
    req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`;
  }
  return req;
});

export const getcourses = () => API.get(`/course`);
export const getDetail=(ID)=>API.get(`/training?id=${ID}`)

export const signIn = (email,password) => API.post('/login', {email,password});
export const signUp = (email,password,name) => API.post('/register', {email,password,name});