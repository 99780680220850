import React from "react";
import "./fotter.css";
import { NavLink } from "react-router-dom";
import globe from '../../Assets/Images/globe.png'
import logo from '../../Assets/Images/Logo 2.png'
import f from './img/fa.png'
import I from './img/insta.png'
import Y from './img/you.png'
import Wa from '../Fotter/img/whatsapp.png'
import In from './img/in.png'
function Fotter() {
  return (
    <>
      <div className="Footer_main_cmponenet">
        <div className="footer_container_content">
        <div className="footer_link_containr" style={{width:"250px"}}>
           <p className="fotter_link" >Askmeidentity is a registered trademark of Modern Identity Private Limited, Bengaluru, India.</p>
           <br />
           <a href="https://www.facebook.com/askmeidentity"><img style={{width:"35px"}} src={f} alt="" /></a>
           <a href="https://www.linkedin.com/company/modern-identity-private-limited/mycompany/"><img style={{width:"35px"}} src={In} alt="" /></a>
           <a href="https://www.instagram.com/askmeidentity/"><img style={{width:"35px"}} src={I} alt="" /></a>
           <a href="https://www.youtube.com/@askmeidentity"><img style={{width:"35px"}} src={Y} alt="" /></a>
           <a href="https://whatsapp.com/channel/0029VaC7tgWDTkJxJosRqK2G"><img style={{width:"30px"}} src={Wa} alt="" /></a>

         <br />
          </div>
          <div className="footer_link_containr">
            <NavLink className="fotter_link" to="/idam?sid=1">
              Askmeidentity Services
            </NavLink>
            <br />
            <NavLink className="fotter_link" to="/maintraining">
              Training
            </NavLink>
            
            <br />
            <NavLink className="fotter_link" to="/contact">
              Contact us
            </NavLink>
            <br />
            <NavLink className="fotter_link" to="/about">
              About us
            </NavLink>
            <NavLink className="fotter_link" to="/all-training?name=IAM">
              Career
            </NavLink>
            <br />
            <NavLink className="fotter_link" to="/blog">
             blogs
            </NavLink>
          
            <br />
          </div>

         

          <div className="footer_link_containr">
            <NavLink className="fotter_link" to="/term">
              Term
            </NavLink>
            <br />
            <NavLink className="fotter_link" to="/contact">
            Support
            </NavLink>
            <br />
            <NavLink className="fotter_link" to="/refund">
             Returns and Refund 
            </NavLink>
            <br />
            <NavLink className="fotter_link" to="/privacy">
             Privacy policy
            </NavLink>

            <br />

            {/* <NavLink className="fotter_link" to="">
              Sitemap
            </NavLink>
            <br />
            <NavLink className="fotter_link" to="">
            Accessibility statement
            </NavLink> */}
          </div>
          <div className="footer_link_containr">
           
          <p className="fotter_link" style={{width:"250px"}} >Sri Jagannath Nivas,<br />
8-1-164/345/A/1,
Pragati Colony, Mailardevpally,
Nawab Saheb Kunta, Hyd, 500005</p>

         </div>
          <div className="mobile_fotter_link" style={{position:"absolute",bottom:"10px",right:"10px"}}>
            <p className="fotter_link" >© 2023 askmeidentity, Inc.</p>
          </div>
          <div style={{position:"absolute",top:"10px",right:"10px",border:"1px solid white",borderRadius:"30px"}}>
            <p className="fotter_link" style={{padding:"0px 20px",paddingTop:"4px"}}><img src={globe} alt="" style={{padding:"0px 5px"}} /> English</p>
          </div>
        </div>
     
      <img className="fotterlogo_place"  src={logo} alt="" style={{position:"absolute",bottom:"10px",left:"14%",width:"150px",marginBottom:"20px"}} />

      </div>
      <div style={{height:"30px",backgroundColor:" #e1f7d4",textAlign:"center"}}>
      <p style={{ fontSize: "15px", fontFamily: "Inter",color:"",paddingTop:"5px"}}>
            Copyright @ 2023 askmeidentity. All Rights Reserved
          </p>
      </div>
    </>
  );
}

export default Fotter;
