import React, { useState, useEffect } from "react";
import axios from "axios";
import NewCourse from "./NewCourse";
import ViewEditCourse from "./ViewEditCourse";

import loading from "../../Images/admin-loading.gif";
import "./styles.scss";

const Courses = () => {
    const nodeURL = process.env.REACT_APP_BASE_URL;
    const tokenn = JSON.parse(localStorage.getItem("data"));

    const [buttonStatus, setButtonStatus] = useState(false);
    const [courses, setCourses] = useState([]);

    const fetchAllCourses = async () => {
        await axios.get(`${nodeURL}/api/courses`).then((response) => {
            console.log(response);
            setCourses(response.data);
        }).catch((error) => {
            console.log(error);
        }).finally(() => { });
    };

    useEffect(() => {
        fetchAllCourses();
    }, []);

    const [newCourse, setNewCourse] = useState(false);
    const showNewCourseForm = async (event) => {
        event.preventDefault();
        console.log("add data");
        setNewCourse(current => !current);
    };

    const [viewEditCourseId, setViewEditCourseId] = useState("");
    const viewEditCourse = async (event) => {
        const docId = event.currentTarget.className;
        let obj = courses.find((o, i) => o.docId === docId);
        // console.log(obj, docId);
        setViewEditCourseId(obj);
    };

    const deleteCourse = async (event) => {
        const docId = event.currentTarget.className;
        if (!window.confirm("This will delete course with docId: " + docId)) {
            return;
        }
        console.log(docId);
        const url = `${nodeURL}/api/course-delete/${docId}`;
        setButtonStatus(true);
        await axios.post(url,{token:tokenn}).then((response) => {
            alert("course deleted");
            fetchAllCourses();
        }).catch((error) => {
            console.log("delete error");
            console.error(error);
        }).finally(() => {
            setButtonStatus(false);
        });
    };


    return (
        <div className="coursesContainer">
            <h3>Total {courses.length} courses</h3>
            <div className="allCoursesContainer">
                {
                    courses.length === 0 ? <img src={loading} alt="loading" style={{ marginLeft: "calc(50% - 90px)", width: "180px", height: "180px" }} /> : <>
                        <table className="coursesList" border="0">
                            <thead>
                                <tr><th className="Admin_blog_Index">Index</th><th className="Admin_blog_Index">Category</th><th className="Admin_blog_Index">course id</th><th className="Admin_blog_Index">name</th><th className="Admin_blog_Index">View / Edit</th><th className="Admin_blog_Index">Delete</th></tr>
                            </thead>
                            <tbody>
                                {courses.map((item, index) => (
                                    <tr key={index}>
                                        <td className="Admin_blog_Present">{index + 1}.</td>
                                        <td className="Admin_blog_Present">{item.course_category}</td>
                                        <td className="Admin_blog_Present">{item.course_id}</td>
                                        <td className="Admin_blog_Present">{item.course_name}</td>
                                        <td className="Admin_blog_Present"><input type="button"  style={{fontFamily:"Inter",fontSize:"18px"}} className={item.docId} value="View / Edit" onClick={viewEditCourse} disabled={buttonStatus} /></td>
                                        <td className="Admin_blog_Present"><input type="button"  style={{fontFamily:"Inter",fontSize:"18px"}} className={item.docId} value="Delete" onClick={deleteCourse} disabled={buttonStatus} /></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </>
                }
            </div>
            <hr />
            <div className="viewEditCourseContainer" style={{backgroundColor: "#eee", padding: "15px", "marginTop": "15px"}}>
                {
                    viewEditCourseId && (
                        <>
                            <h3 style={{marginTop: "20px"}}>Edit course with document id: {JSON.stringify(viewEditCourseId.docId)}</h3>
                            <ViewEditCourse course={viewEditCourseId} />
                        </>
                    )
                }
            </div>
            <div style={{backgroundColor: "#eee", padding: "20px", "marginTop": "15px"}}>
                <input type="button" className="addNewCourse" value="Add New Course [Reset]" onClick={showNewCourseForm} />
                <div className="newCourseContainer">
                    {
                        newCourse && <>
                            <NewCourse reloadDataFunc={fetchAllCourses} />
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default Courses;
