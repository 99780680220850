import React from 'react'

function Mission(props) {
  console.log(props.data)
  const section=props.data
  return (
      <>
      {/* <p  style={{marginTop:"20px",fontFamily:"Inter",fontSize:"20px",fontWeight:"500",opacity:"0.9",color:"rgba(37,214,225,1)"}}> Our {props.title}</p> */}

    {
    (section)?( section.map(name => (  
      <li> 
         
      <p className='About_info' style={{marginTop:"20px",opacity:"0.6"}}>  {name}  </p>  
      </li>  
    ))):('')
        }  

   
    </>
  )
}

export default Mission