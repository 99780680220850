import React from 'react'
import Course_cart from './course_cart'
import { useSelector } from "react-redux";
function Second_course() {
  const courses = useSelector((state) => state.courses);
   const limitedCourses = courses.slice(0,4);
  return (
<>
<div style={{display:"flex",justifyContent:"center",margin:"50px 0px"}}>
    <div style={{maxWidth:"1100px"}}>
      <h3 className='heading_css' style={{fontSize:"30px",fontFamily:"Inter"}}>Students are viewing</h3>
      <br />
       <div style={{display:"flex",flexWrap:"wrap",justifyContent:"center"}}>
        
         {
      (courses=='undefined'||courses=='')?(''):(
        
        
        limitedCourses.map((course, index) => (
        
            <Course_cart name={course.course_name} url={course.url} instructor={course.course_instructor} fee={course.course_fee} img={course.image_url} cid={course.course_id}/>
           
        )
          ))
         
          }
          
       </div>
    </div>
</div>
</>
  )
}

export default Second_course