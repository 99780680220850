import React, { useState, useEffect } from "react";
import Nav from "../Nav/Navbar";
import Cloud from "../Images/Cloud.gif";
import Mortarboard from "../Images/Mortarboard.gif";
import Idea from "../Images/Idea.gif";
import SA from "../Images/Database.gif";

import Computer from "../Images/Computer.gif";
import Rectangle7 from "../Images/Rectangle7.png";
import Rectangle9 from "../Images/Rectangle9.png";
import Rectangle11 from "../Images/Rectangle11.png";
import Rectangle12 from "../Images/Rectangle12.png";
import Rectangle13 from "../Images/Rectangle13.png";
import { NavLink } from "react-router-dom";
import Group5 from "../Images/Group5.png";
import "./itservice.css";
import Back from "../Images/Back.png";
import { useSearchParams } from "react-router-dom";
import Footer from "../../src/Component/Fotter/Fotter";
import db from "./../../firebase";
import Axios from "axios";
import Servicesdescription from "./Servicesdescription";
import Navbar from '../header/Header'
function Idam() {
  const [Active, setActive] = useState("IDAM");
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setdata] = useState([]);
  const [service, setservice] = useState([]);
  const sid = searchParams.get("sid");
  console.log(sid);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const fetchService = async () => {

    if(sid==1 ){
      setdata(detail[0])

    }else if(sid==2){
      setdata(detail[1])

    }else if(sid==3){
      setdata(detail[2])

    }else if(sid==4){
      setdata(detail[3])

    }else if(sid==5){
      setdata(detail[4])
    }
    return ;
    await Axios.get(
      `https://us-central1-askmeidentity-website-ppe.cloudfunctions.net/as/service?id=${sid}`,
      {}
    )
      .then((response) => {
        console.log(response.data);

        setdata(response.data);
      })
      .catch((err) => {
        console.error(err);
      });

    await Axios.get(`${process.env.REACT_APP_BASE_URL}/All_service`)
      .then((response) => {
        console.log(response.data);

        setservice(response.data);
      })
      .catch((err) => {
        console.error(err);
      });

    //  db.collection('services').get().then((querySnapshot) => {
    //       querySnapshot.forEach(element => {
    //           var data = element.data();

    //           setservice(arr => [...arr, data]);

    //       })
    //   })

    //   })

    // })
  };
  console.log(data);
  console.log(service);

  useEffect(fetchService, []);

  const callService = async (get) => {
    if(get==1 ){
      setdata(detail[0])

    }else if(get==2){
      setdata(detail[1])

    }else if(get==3){
      setdata(detail[2])

    }else if(get==4){
      setdata(detail[3])

    }
    else if(get==5){
      setdata(detail[4])
    }
    console.log(data);
    return;
    await Axios.get(`${process.env.REACT_APP_BASE_URL}/service?id=${get}`, {})
      .then((response) => {
        console.log(response.data);

        setdata(response.data);
      })
      .catch((err) => {
        console.error(err);
      });


    
    // const Rel = db.collection('services');
    // Rel.where('service_id', '==',get).get().then((snapshot) => {
    //   if (snapshot.empty) {
    //     console.log('No matching documents.');
    //     return;
    //   }
    //   snapshot.forEach(element => {
    //     var course = element.data();
    //     console.log(course)
    // setdata(course)

    //     console.log(course)

    //   })
    // })
  };
  var  detail=[
    {
      detail:"Identity transformations don’t happen overnight. But they do happen a lot faster and they run smoother when you have seasoned experts at the helm guiding your implementation. The team at Askmeidentity has performed thousands of identity implementations across every industry. There aren’t many identity challenges that we haven’t seen and successfully tackled.",
      title:"IDAM Solutions",
      Img:"https://firebasestorage.googleapis.com/v0/b/askmeidentity-website.appspot.com/o/website_data%2FRectangle11.png?alt=media&token=27da92c4-f554-4b06-81c2-46610a794d72"
    }
    ,{
      detail:"Managed IT services are a way to keep your IT infrastructure and applications stable, cost-effective, and relevant to the evolving business needs. Askmeidentity, is a reliable provider of remote monitoring, management and evolution services for on-premises and cloud infrastructures and help desk services.",
      title:"Managed IT Services",
      Img:"https://firebasestorage.googleapis.com/v0/b/askmeidentity-website.appspot.com/o/website_data%2FRectangle9.png?alt=media&token=1f2431c3-751e-4657-8e79-e64716522736"
    },{
      detail:"Application development is the process of designing, building, and implementing software applications to meet the customer's requirements. We have expert developers with years of experience in Web, Desktop, Mobile and customised application developement.",
      title:"Application Development",
      Img:"https://firebasestorage.googleapis.com/v0/b/askmeidentity-website.appspot.com/o/website_data%2FRectangle13.png?alt=media&token=cf495cb7-960f-40d3-a1b4-d95f7cf99cbc"
    }
    ,{
      detail:"Askmeidentity is one of the top Identity and Access Management training provider. Our training programs include Okta, Ping Identity, Onelogin, Forgerock, Sailpoint, Savyint, Web Technologies and security related courses. We are recognised individual and corporate training provider.",
      title:"Training",
      Img:"https://firebasestorage.googleapis.com/v0/b/askmeidentity-website.appspot.com/o/website_data%2FRectangle12.png?alt=media&token=453ad086-909b-41a2-bdd2-816273c4acd8"
    },{
      detail:"At Askmeidentity, we specialize in staff augmentation solutions that empower your organization with the right talent to meet your business objectives. Our experienced professionals are handpicked to seamlessly integrate with your team, extending your capabilities and filling skill gaps. Whether you require temporary support or long-term partnerships, we offer flexible staffing solutions tailored to your needs. Trust Askmeidentity to enhance your workforce and drive success in an ever-evolving business landscape.",
      Img:"https://firebasestorage.googleapis.com/v0/b/askmeidentity-website.appspot.com/o/website_data%2Fpexels-fauxels-3184398.jpg?alt=media&token=5e46f7b8-7119-4d97-add1-18017b2ad238",
      title:"Staff Augmentation"
    }
   ]
  return (
    <>
      {/* <Nav /> */}
      <Navbar/>
      <div
        style={{
          height: "200px",
          marginTop:"80px",
          display: "flex",
          backgroundImage: `url(${Back})`,
        }}
      >
        <p
          style={{
            marginLeft: "20%",
            marginTop: "80px",
            fontSize: "40px",
            fontWeight: "700",
            color: "#3C3C3B",
            fontFamily: "Inter",
          }}
        >
          Services
        </p>
      </div>
      <div className="Service_maincomp">
        <div className="Lcomp">
          
            <NavLink style={{textDecoration:"none"}} to="" onClick={() => callService(1)}>
              <div
                className="Sprev"
                style={{
                  display: "flex",
                  marginTop: "30px",
                  textAlign: "center",
                  boxShadow: "0px 10px 100px 0px rgba(0, 0, 0, 0.05)",
                }}
              >
                <img
                  className="Simg"
                  style={{
                    width: "60px",
                    height: "60px",
                    marginTop: "20px",
                    marginLeft: "30px",
                  }}
                  src={Cloud}
                  alt=""
                />
                <div className="servicecont" style={{textDecoration:"none"}}>
                  <h1
                    className=""
                    style={{
                      fontFamily: "Inter",
                      fontSize: "20px",
                      fontWeight: "600",
                      marginLeft: "20px",
                      marginTop: "20px",
                      color: "black",
                    }}
                  >
                  IDAM Solutions
                  </h1>
                </div>
              </div>
            </NavLink>
            <NavLink style={{textDecoration:"none"}}  to="" onClick={() => callService(5)}>
              <div
                className="Sprev"
                style={{
                  display: "flex",
                  marginTop: "30px",
                  textAlign: "center",
                  boxShadow: "0px 10px 100px 0px rgba(0, 0, 0, 0.05)",
                }}
              >
                <img
                  className="Simg"
                  style={{
                    width: "60px",
                    height: "60px",
                    marginTop: "20px",
                    marginLeft: "30px",
                  }}
                  src={SA}
                  alt=""
                />
                <div className="servicecont">
                  <h1
                    className=""
                    style={{
                      fontFamily: "Inter",
                      fontSize: "20px",
                      fontWeight: "600",
                      marginLeft: "20px",
                      marginTop: "20px",
                      color: "black",
                    }}
                  >
                  Staff Augmentation
                  </h1>
                </div>
              </div>
            </NavLink>

            <NavLink style={{textDecoration:"none"}}  to="" onClick={() => callService(2)}>
              <div
                className="Sprev"
                style={{
                  display: "flex",
                  marginTop: "30px",
                  textAlign: "center",
                  boxShadow: "0px 10px 100px 0px rgba(0, 0, 0, 0.05)",
                }}
              >
                <img
                  className="Simg"
                  style={{
                    width: "60px",
                    height: "60px",
                    marginTop: "20px",
                    marginLeft: "30px",
                  }}
                  src={Idea}
                  alt=""
                />
                <div className="servicecont">
                  <h1
                    className=""
                    style={{
                      fontFamily: "Inter",
                      fontSize: "20px",
                      fontWeight: "600",
                      marginLeft: "20px",
                      marginTop: "20px",
                      color: "black",
                    }}
                  >
                  Managed IT Solutions
                  </h1>
                </div>
              </div>
            </NavLink>
            <NavLink  style={{textDecoration:"none"}} to="" onClick={() => callService(3)}>
              <div
                className="Sprev"
                style={{
                  display: "flex",
                  marginTop: "30px",
                  textAlign: "center",
                  boxShadow: "0px 10px 100px 0px rgba(0, 0, 0, 0.05)",
                }}
              >
                <img
                  className="Simg"
                  style={{
                    width: "60px",
                    height: "60px",
                    marginTop: "20px",
                    marginLeft: "30px",
                  }}
                  src={Computer}
                  alt=""
                />
                <div className="servicecont">
                  <h1
                    className=""
                    style={{
                      fontFamily: "Inter",
                      fontSize: "20px",
                      fontWeight: "600",
                      marginLeft: "20px",
                      marginTop: "20px",
                      color: "black",
                    }}
                  >
                  Application Development
                  </h1>
                </div>
              </div>
            </NavLink>
            <NavLink style={{textDecoration:"none"}}  to="" onClick={() => callService(4)}>
              <div
                className="Sprev"
                style={{
                  display: "flex",
                  marginTop: "30px",
                  textAlign: "center",
                  boxShadow: "0px 10px 100px 0px rgba(0, 0, 0, 0.05)",
                }}
              >
                <img
                  className="Simg"
                  style={{
                    width: "60px",
                    height: "60px",
                    marginTop: "20px",
                    marginLeft: "30px",
                  }}
                  src={Mortarboard}
                  alt=""
                />
                <div className="servicecont">
                  <h1
                    className=""
                    style={{
                      fontFamily: "Inter",
                      fontSize: "20px",
                      fontWeight: "600",
                      marginLeft: "20px",
                      marginTop: "20px",
                      color: "black",
                    }}
                  >
                  Training
                  </h1>
                </div>
              </div>
            </NavLink>

          <div
            className="Scontact"
            style={{
              backgroundImage: `url(${Rectangle7})`,
              backgroundRepeat: "no-repeat",
              height: "802px",
              marginTop: "20px",
              border: "1px solid white",
            }}
          >
            <div>
              <NavLink style={{textDecoration:"none"}}  to="/contact">
                {" "}
                <p
                  style={{
                    fontSize: "18px",
                    marginLeft: "90px",
                    marginTop: "180px",
                    color: "rgba(37,214,225,1)",
                    fontFamily: "Inter",
                  }}
                >
                  CONTACT US NOW
                </p>
              </NavLink>
              <p
                style={{
                  fontSize: "36px",
                  color: "White",
                  marginLeft: "55px",
                  marginTop: "18px",
                  fontFamily: "Inter",
                }}
              >
                For Business and{" "}
              </p>
              <p
                style={{
                  fontSize: "36px",
                  color: "White",
                  marginLeft: "75px",
                  marginTop: "10px",
                  fontFamily: "Inter",
                }}
              >
                {" "}
                Opportunities
              </p>
              <hr
                style={{
                  marginLeft: "40px",
                  marginTop: "40px",
                  width: "300px",
                  color: "#25D6E1",
                }}
              />
              <h1
                style={{
                  fontSize: "40px",
                  color: "White",
                  marginLeft: "30px",
                  marginTop: "18px",
                  fontFamily: "Inter",
                }}
              >
                +91 70037 52368
              </h1>
             
              <p
                style={{
                  fontSize: "16px",
                  color: "White",
                  marginLeft: "90px",
                  marginTop: "28px",
                  fontFamily: "Inter",
                }}
              >
                Or Go To Contact Form:
              </p>
              <NavLink to="/contact">
                <img style={{ margin: "30px 60px" }} src={Group5} alt="" />
              </NavLink>
            </div>
          </div>
        </div>
        <div className="Rcomp">
          {/* {Active ==="IDAM" && <Servicesdescription title="IDAM Solutions" img={Rectangle11}  />}
     {Active ==="IT" && <Servicesdescription title="Managed IT Services"  img={Rectangle9}  />}
  {Active ==="Training" && <Servicesdescription title="Training"  img={Rectangle12}  />}
  {Active ==="Application" && <Servicesdescription title="Application Development"  img={Rectangle13}  />} */}

          <Servicesdescription
            title={data.title}
            img={data.Img}
            Servicesdescription={data.detail}
          />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Idam;
