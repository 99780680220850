import React, { useEffect, useState } from "react";
import All from "./All.css";
import icon1 from "../Images/icon.png";
import icon2 from "../Images/icon1.png";
import icon3 from "../Images/icon2.png";
import icon12 from "../Images/icon12.png";
import icon11 from "../Images/icon11.png";
import icon10 from "../Images/icon10.png";
import icon9 from "../Images/icon9.png";
import icon8 from "../Images/icon8.png";
import icon7 from "../Images/icon7.png";
import icon6 from "../Images/icon6.png";
import icon5 from "../Images/icon5.png";
import icon4 from "../Images/icon4.png";
import ci from '../Images/icon3.png'
import Rectangle10 from "../..//Images/Rectangle10.png";
import Rectangle from "../Images/Rectangle.png";
import { NavLink } from "react-router-dom";
import Contact_banner from './Contact_baner'

function IDAM(props) {
  const [text, setText] = useState("");
  const [data, setdata] = useState(Idam);
  const [fullText, setFullText] = useState("We Here to help You !");
  const [index, setIndex] = useState(0);
  console.log(props.id)
  useEffect(()=>{
if(props.id==1){
    setdata(T)
}else if(props.id==2){
setdata(AD)
}else if(props.id==3){
    setdata(SA)

}else if(props.id==4){
    setdata(ITM)

}else{
    setdata(Idam)
}
 },[])

  return (
    <>
    <div className="Service_main_Container">
      <div className="Service_inner_Container">
        <div className="SI_section1">
          <div className="SI_Left">
            <h2 className="Service_Heading" style={{ fontWeight: "600" }}>
              {data.section1.title}
            </h2>
            <p
              style={{
                fontwight: "400",
                fontFamily: "Inter",
                marginTop: "10px",
                fontSize: "17px",
              }}
            >
              {data.section1.text}
            </p>
          </div>
          <div className="SI_right">
            <img
              style={{ width: "100%", borderRadius: "5px 5px 100px 5px" }}
              src={data.section1.img}
              alt=""
            />
          </div>
        </div>

        <br />
        <div className="SI_section1">
          <div>
            <h2
              className="Service_Heading"
              style={{ textAlign: "center", fontWeight: "600" }}
            >
              {data.section2.title}
            </h2>
            <div className="Service_Points" style={{ marginTop: "25px",flexWrap:"wrap",gap:"10px",justifyContent:"center",marginLeft:"0px" }}>
              {data.section2.text.map((el) => (
                <div
                  className="Service_Pointsinside"
                  style={{
                    background: "black",
                    height: "150px",
                    borderRadius: "7px",
                    marginLeft:"0px"
                  }}
                >
                  <p style={{ marginTop: "3px", display: "flex" }}>
                    <img
                      className="Simgtt"
                      style={{ color: "blue" }}
                      src={el.icon}
                      alt=""
                    />{" "}
                    <span
                      style={{
                        marginLeft: "85px",
                        fontSize: "20px",
                        fontWeight: "500",
                        color: "#54B426",
                        marginTop: "30px",
                        fontFamily: "Inter",
                      }}
                    >
                      {el.S_title}
                      <span
                        style={{ fontSize: "15px", fontWeight: "normal" }}
                      ></span>{" "}
                      <p
                        style={{
                          fontSize: "15px",
                          marginTop: "10px",
                          color: "white",
                          fontFamily: "Inter",
                          marginRight: "10px",
                        }}
                      >
                        {el.text}
                      </p>
                    </span>
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <br />
        <br />

        <div style={{ marginTop: "50px" }}>
          <h2
            className="Service_Heading"
            style={{ textAlign: "center", fontWeight: "600" }}
          >
            {data.section3.title}
          </h2>
          <br />
          <br />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "20px",
              flexWrap: "wrap",
            }}
          >
            {data.section3.challenges.map((element) => (
              <div className="challenges_box">
                <h4
                  className="Service_text"
                  style={{ color: "#1A53DD", fontWeight: "600" }}
                >
                  {element.head}
                </h4>
                <p style={{ fontFamily: "Inter", fontSize: "16px" }}>
                  {element.text}
                </p>
              </div>
            ))}
          </div>
        </div>
        <br />
        <br />
        <div className="SI_section1">
          <div className="SI_right">
            <img style={{ width: "100%" }} src={Rectangle10} alt="" />
          </div>
          <div
            className="SI_Left"
            style={{
              marginTop: "0px",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div>
              <p className="Service_Heading">Our Portfolio</p>
              <p
                style={{
                  fontSize: "16px",
                  fontwight: "400",
                  fontFamily: "Inter",
                  marginTop: "10px",
                }}
              >
                At Askmeidentity, we offer a comprehensive suite of technology
                solutions designed to empower your organization in today's
                dynamic digital landscape. From cutting-edge Digital Workspace
                Solutions that enhance collaboration and productivity to Cloud
                and Internet Security Solutions that safeguard your data from
                evolving cyber threats, we've got you covered. Our System
                Integration expertise ensures that your operations run
                seamlessly, and our Consulting services provide strategic
                guidance to navigate the complex world of technology and
                security. Trust Askmeidentity as your partner in achieving
                efficiency, security, and success in the digital age.
              </p>
            </div>
          </div>
        </div>
        <br />

          
         
       
      </div>
    </div>
     <Contact_banner/>
     </>

  );
}

export default IDAM;

var Idam = {
  
  section1: {
    title: "IDAM Solutions",
    text: "Stay ahead of the curve and secure your organization's digital future with our state-of-the-art IDAM solution. Elevate your security posture, streamline user access, and ensure compliance effortlessly. Contact us today to learn how we can empower your organization to thrive in a rapidly evolving digital landscape. Your security is our priority, and together, we can keep you one step ahead of the competition..",
    img: "https://firebasestorage.googleapis.com/v0/b/askmeidentity-website.appspot.com/o/website_data%2FRectangle11.png?alt=media&token=27da92c4-f554-4b06-81c2-46610a794d72",
  },
  section2: {
    title: "Why Askmeidentity for IDAM",
    text: [
      {
        S_title: "Certified Consultants",
        icon: icon2,
        text: "Our team of certified consultants with years of industry experience.",
      },
      {
        S_title: "Customer Satisfaction ",
        icon: icon1,
        text: "Customer satisfaction is our top priority, driving our every action.",
      },
      {
        S_title: "On Time Delivery",
        icon: icon3,
        text: "On-Time SLA based delivery of solutions makes us the top choice among our clients.",
      },
    ],
  },
  section3: {
    title: "Overcoming Identity and Access Management Challenges",
    challenges: [
      {
        head: "Security Threats",
        text: "IDAM enhances security by controlling user access, reducing unauthorized access risks.",
      },
      {
        head: "Password Management",
        text: "IDAM simplifies passwords via SSO and MFA, reducing password management overhead.  ",
      },
      {
        head: "Access Control",
        text: "IDAM enforces access policies based on roles, bolstering control.",
      },
      {
        head: "User Experience",
        text: "IDAM balances security with user-friendly access.",
      },
      {
        head: "Shadow IT",
        text: "IDAM manages and mitigates unauthorized application use. ",
      },
      {
        head: "Third-Party Access",
        text: "IDAM extends control to external partners, enhancing security.",
      },
    ],
  },
};

var ITM = {
    section1: {
      title: "Managed IT Services",
      text: "Empower Your IT with Askmeidentity's Managed Services. Keep your IT infrastructure and applications stable, cost-effective, and future-ready with Askmeidentity. We're your go-to partner for remote monitoring, management, and help desk services, both on-premises and in the cloud. Elevate your IT with Askmeidentity and stay ahead in the digital age.",
      img: "https://firebasestorage.googleapis.com/v0/b/askmeidentity-website.appspot.com/o/website_data%2FRectangle9.png?alt=media&token=1f2431c3-751e-4657-8e79-e64716522736",
    },
    section2: {
        title: "Why Choose Askmeidentity for IT Managed Services",
        text: [
          {
            S_title: "Cybersecurity Expertise",
            icon: icon5,
            text: "Protect your data and assets with our top-notch cybersecurity solutions.",
          },
          {
            S_title: "Best Service Provider.",
            icon: icon10,
            text: "Recognized as the best service provider in the industry.",
          },
          {
            S_title: "Strategic IT Planning",
            icon: icon7,
            text: "Align your IT strategy with your business goals for growth and efficiency.",
          },
          // Add more key points specific to IT Managed Services here.
        ],
      },
    section3: {
        title: "Elevate Your Business with Managed IT Services",
        challenges: [
          {
            head: "Proactive IT Management",
            text: "Our Managed IT Services provide proactive monitoring and maintenance, ensuring your IT infrastructure runs smoothly and minimizing downtime.",
          },
          {
            head: "Cost-Effective Solutions",
            text: "Save on operational costs with our scalable solutions, allowing you to pay for only the services you need while avoiding hefty upfront investments.",
          },
          {
            head: "24/7 Support",
            text: "Access round-the-clock IT support from our team of experts, guaranteeing timely resolution of issues and continuous business operation.",
          },
          {
            head: "Security and Compliance",
            text: "Protect your sensitive data with our robust security measures and ensure compliance with industry regulations and standards.",
          },
          {
            head: "Strategic IT Planning",
            text: "Benefit from our strategic IT planning, aligning your technology with your business goals and staying ahead of industry trends.",
          },
          {
            head: "Scalability",
            text: "Scale your IT resources up or down as your business evolves, ensuring flexibility and agility in a rapidly changing environment.",
          },
        ],
      },
      
  };
  
var AD = {
    section1: {
      title: "Application Development ",
      text: "Unlock Your Potential for Innovation with Askmeidentity’s Application Development Expertise. At Askmeidentity, we are the architects of digital transformation, crafting software applications that not only meet but exceed your unique requirements. Our seasoned developers bring years of experience to the table, specializing in crafting cutting-edge solutions for Web, Desktop, Mobile, and custom applications. With Askmeidentity as your trusted partner, embark on a journey of innovation and seamless digital experiences.",
      img: "https://firebasestorage.googleapis.com/v0/b/askmeidentity-website.appspot.com/o/website_data%2FRectangle13.png?alt=media&token=cf495cb7-960f-40d3-a1b4-d95f7cf99cbc",
    },
    section2:{
        title: "Why Choose Askmeidentity for Application Development",
        text: [
          {
            S_title: "Expert team",
            icon: icon11,
            text: "Our experienced app development team delivers tailored solutions to drive your business forward.",
          },
          {
            S_title: "Scalability",
            icon: icon8,
            text: "Applications designed to scale with your business.",
          },
          {
            S_title: "Customer Satisfaction  ",
            icon: icon1,
            text: "On-Time SLA based delivery of solutions makes us the top choice among our clients.",
          },
        
          // Add more key points specific to Application Development here.
        ],
      },
    section3: {
        title: "Unlocking Possibilities with Application Development",
        challenges: [
          {
            head: "Innovation at Its Core",
            text: "Our application development team is at the forefront of innovation, crafting solutions that push boundaries and drive your business forward.",
          },
          {
            head: "Seamless User Experiences",
            text: "We prioritize user experience in every application we build, striking the perfect balance between functionality and ease of use.",
          },
          {
            head: "Scalability",
            text: "Our applications are designed to grow with your business, ensuring they can handle increased demands and evolving requirements.",
          },
          {
            head: "Security by Design",
            text: "Security is at the heart of our development process, with robust measures in place to safeguard your applications and data.",
          },
          {
            head: "Rapid Deployment",
            text: "We understand the need for speed. Our agile development methodologies ensure your applications are delivered promptly.",
          },
          {
            head: "Integration Expertise",
            text: "We excel in seamlessly integrating applications with your existing systems and third-party services for enhanced functionality.",
          },
        ],
      },
      
  };
  
var T = {
    section1: {
      title: "Training",
      text: "Elevate Your Expertise with Askmeidentity Identity and Access Management Training. Discover excellence in identity and access management with Askmeidentity, a premier training provider. Our comprehensive training programs cover industry-leading platforms such as Okta, Ping Identity, OneLogin, ForgeRock, SailPoint, and Saviynt, as well as essential web technologies and security courses. We are your trusted source for both individual and corporate training solutions. Join us on a journey of knowledge and skill enhancement, and unlock the potential of identity and access management in the ever-evolving cybersecurity landscape.",
      img: "https://firebasestorage.googleapis.com/v0/b/askmeidentity-website.appspot.com/o/website_data%2FRectangle12.png?alt=media&token=453ad086-909b-41a2-bdd2-816273c4acd8",
    },
    section2: {
        title: "Why Choose Askmeidentity for Training",
        text: [
          {
            S_title: "Value for Money.",
            icon: icon6,
            text: "Get exceptional training at competitive prices, maximizing your investment.",
          },
          {
            S_title: "Comprehensive Courses",
            icon: ci,
            text: "Access a wide range of comprehensive training courses.",
          },
          {
            S_title: "Hands-On Practical Training",
            icon: icon12,
            text: "Gain practical skills through hands-on training exercises.",
          },
          // Add more key points specific to Training here.
        ],
      },
    section3: {
        title: "Empower Your Team through Training and Development",
        challenges: [
          {
            head: "Skill Enhancement",
            text: "Our training programs are designed to enhance the skills of your workforce, keeping them up-to-date with the latest industry trends and technologies.",
          },
          {
            head: "Customized Learning Paths",
            text: "We create tailored learning paths to address your organization's specific needs and goals, ensuring maximum impact.",
          },
          {
            head: "Expert Instructors",
            text: "Learn from seasoned industry experts who bring real-world experience and insights to the training room.",
          },
          {
            head: "Flexible Training Options",
            text: "Choose from a variety of training formats, including in-person, virtual, and self-paced courses, to fit your team's schedule and preferences.",
          },
          {
            head: "Certification Preparation",
            text: "Prepare your team for industry-recognized certifications, bolstering their credentials and increasing your organization's competitiveness.",
          },
          {
            head: "Measurable Results",
            text: "Track progress and measure the impact of our training programs through comprehensive assessments and performance metrics.",
          },
        ],
      },
      
  };
  
var SA = {
    section1: {
      title: "Staff Augmentation",
      text: "Elevate Your Team with Askmeidentity Staff Augmentation Solutions. At Askmeidentity, we specialize in empowering your organization with the right talent to achieve your business objectives. Our handpicked professionals seamlessly integrate with your team, filling skill gaps and extending your capabilities. Whether you need temporary support or long-term partnerships, our flexible staffing solutions are tailored to your needs. Trust Askmeidentity to enhance your workforce and drive success in today's dynamic business landscape.",
      img: "https://firebasestorage.googleapis.com/v0/b/askmeidentity-website.appspot.com/o/website_data%2Fpexels-fauxels-3184398.jpg?alt=media&token=5e46f7b8-7119-4d97-add1-18017b2ad238",
    },
    section2:{
        title: "Why Choose Askmeidentity for Staff Augmentation",
        text: [
          {
            S_title: "Skilled Augmentation",
            icon: icon4,
            text: "Access skilled professionals whenever you need them.",
          },
          {
            S_title: "Best Service Provider",
            icon: icon10,
            text: "Recognized as the best service provider in the industry.",
          },
          {
            S_title: "Industry-Specific Talent",
            icon: icon9,
            text: "Tap into specialized industry talent to address skill gaps.",
          },
        ],
      },
    section3: {
        title: "Empowering Your Team with Staff Augmentation",
        challenges: [
          {
            head: "Expertise On-Demand",
            text: "Access a pool of skilled professionals to augment your team's capabilities whenever you need them.",
          },
          {
            head: "Flexibility and Scalability",
            text: "Scale your team up or down based on project requirements, ensuring cost-efficiency and agility.",
          },
          {
            head: "Domain-Specific Talent",
            text: "Tap into our network of experts in various domains to fill skill gaps and meet specific project needs.",
          },
          {
            head: "Efficiency and Productivity",
            text: "Boost project efficiency and productivity with experienced professionals who seamlessly integrate into your team.",
          },
          {
            head: "Cost Control",
            text: "Enjoy cost savings by avoiding the overhead of hiring full-time employees while benefiting from top-tier talent.",
          },
          {
            head: "Quick Onboarding",
            text: "Our professionals are ready to hit the ground running, minimizing onboarding time and maximizing project momentum.",
          },
        ],
      },
      
  };
  