import React, { useState, useEffect } from "react";
import Group6 from "../Images/Group6.png";
import Nav from "../../Components/Nav/Navbar";
import Footer from "../../src/Component/Fotter/Fotter";
import correct from "../Images/correct.png";
import "./about.css";
import { NavLink } from "react-router-dom";
import Mission from "./Mission";
// import Modal from 'react-bootstrap/Modal';
// import button from 'react-bootstrap';
import Axios from "axios";
import Navbar from "../header/Header";
import { Helmet } from "react-helmet";

function About() {
  const [Active, setActive] = useState("Vission");
  const [data, setdata] = useState([]);
  const [modalShow, setModalShow] = React.useState(false);
  const fetchinfo = async () => {
    // console.log('dchcvvv')
    await Axios.post(`${process.env.REACT_APP_BASE_URL}/about`, {
      // token:token,
    })
      .then((response) => {
        console.log(response.data);

        setdata(response.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(fetchinfo, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const keywords = [
    "Best IT service Company",
    "Leading IAM services and Training Provider",
    "Best Managed service providers",
    "Best IDAM Solutions",
    "IAM Services",
  ];

  const keywordString = keywords.join(", ");
  return (
    <>
      <Helmet>
        <title>Best Managed Sevice Provider| Expert IAM Consultant</title>
        <meta
          name="description"
          content="Enhance your business's identity access management with Askmeidentity trusted solutions and experienced consultants  protect your data, and ensure your business stays secure."
        />
        <meta name="keywords" content={keywordString} />
      </Helmet>
      <Navbar />
      {/* <div style={{height:"200px",display:"flex",backgroundImage: `url(${Back})`}}>
      <p style={{marginLeft: "20%",
            marginTop: "80px",
            fontSize: "40px",
            fontWeight: "700",
            color: "#3C3C3B",
            fontFamily:'Inter'}}>About Us</p>
    </div> */}
      <div className="about" style={{ background: "white", display: "flex" }}>
        <div className="About_comp">
          <div className="About_lcomp">
            <p
              style={{
                fontFamilly: "Inter",
                fontSize: "25px",
                fontWeight: "650",
                color: "#4BB543",
                fontFamily: "Inter",
              }}
            >
              ABOUT US
            </p>
            <nav className="About_nav">
              <NavLink
                to=""
                className="About_navcomp"
                onClick={() => setActive("Vission")}
              >
                Our Vision
              </NavLink>
              <NavLink
                to=""
                className="About_navcomp"
                style={{ marginLeft: "50px" }}
                onClick={() => setActive("Mission")}
              >
                Our Mission
              </NavLink>

              <NavLink
                to=""
                className="About_navcomp"
                style={{ marginLeft: "50px" }}
                onClick={() => setActive("Values")}
              >
                Our Values
              </NavLink>
            </nav>
            <hr className="About_hr" style={{}} />
            <div>
              {Active === "Mission" && (
                <Mission title="Mission" data={data.Mission} />
              )}
              {Active === "Vission" && (
                <Mission title="Vission" data={data.Vission} />
              )}
              {Active === "Values" && (
                <Mission title="Values" data={data.Values} />
              )}
            </div>
            <p className="About_head" style={{ fontFamily: "Inter" }}>
              Chose the best <br />
              <span
                style={{ color: "#1A53DD", fontFamily: "Inter" }}
              >
                IT Services Company
              </span>
            </p>
            <p className="About_info">
              The Askmeidentity Consulting Practice is comprised of our IAM
              subject matter experts that have the expertise to align your
              organization’s core competencies and its information security
              requirements with best practice IAM processes and procedures.
            </p>
            <p className="About_info">
              Our Managed Services team manages, maintains, services, and
              supports your IAM or Digital Workspace solution and provides 24/7
              monitoring for real-time threat detection and alerts.
            </p>
            <p className="About_info">
              Our team is comprised of implementation experts in vendor, legacy
              and custom Identity & Access management applications. We have
              dedicated integration teams for each of the leading IAM technology
              vendor solutions. Each team includes IAM professionals in project
              and program management, architecture, engineering and development,
              analysis and support engineering.
            </p>
           
          </div>
          <div className="About_rcomp">
            <img className="About_Img" src={Group6} alt="" />
          </div>
        </div>
      </div>
      {/* <div className='Compony' style={{background:"rgb(246,254,254)",marginTop:"00px"}}> 
  <div className='Services'>
  <div className=''>
  <h1 className='shead' style={{fontSize:"40px",marginTop:"100px"}}>30+ Companies Uses Our Training </h1>
  <p className='phome' style={{marginLeft:"100px"}}>Lorem ipsum dolor sit amet consectetur adipisicing</p>
  <p className='p2home' style={{marginLeft:"150px"}}>Lorem ipsum dolor sit amet ipsum.</p>
  </div>
 
  </div>
  <p style={{fontSize:"20px" ,display:"flex",justifyContent:"center",marginTop:"200px"}}>
  Read Customer Stories
  </p>
  </div> */}

      <Footer />
    </>
  );
}

export default About;

// function MyVerticallyCenteredModal(props) {
//   return (
//     <Modal
//       {...props}
//       size="sm"
//     //  width="100px"
//       aria-labelledby="contained-modal-title-vcenter"
//       centered
//     >

//           <div style={{justifyContent:"center",textAlign:"center"}}>
//             <p style={{color:"#4BB543",fontSize:"25px",fontFamily:"Inter"}}>Success!</p>

//           </div>

//       <Modal.Body>
//         <div style={{justifyContent:"center",textAlign:"center"}}>
//           <p style={{fontSize:"22px",fontFamily:"Inter",marginTop:"10px"}}>You have Successfully Erolled to the Course</p>
//            <img src={correct} style={{width:"100px",height:"100px",marginTop:"40px"}} alt="" /></div>

//       </Modal.Body>

//         <NavLink className='dashboard_button' to="/dashboard">Dashboard</NavLink>
//         {/* <button onClick={props.onHide}>Close</button> */}

//     </Modal>
//   );
// }
