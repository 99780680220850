import React, { useEffect, useRef, useState } from 'react';
import styles from "./header.module.scss";

import websiteLogo from "./Images/Logo.png";

import Services from './services/Services';
import Solutions from './solutions/Solutions';
import Capabilities from './capabilities/Capabilities';
import Companies from './companies/Companies';
import Resources from './resources/Resources';
import { NavLink } from 'react-router-dom';

const Header = () => {
    const responsiveContainer = useRef(null);
    const handleContactClick = () => {
        window.scrollTo(0, document.body.scrollHeight);
    };

    const [responsiveOpen, setResponsiveOpen] = useState(false);
    const toggleResponsive = () => {
        setResponsiveOpen(responsiveOpen => !responsiveOpen);
    };
    useEffect(() => {
        if (!responsiveOpen) {
            responsiveContainer.current.style.height = 0;
            document.querySelector(".line1").style.transform = "translateY(-8px) rotate(0)";
            document.querySelector(".line2").style.opacity = 1;
            document.querySelector(".line3").style.transform = "translateY(8px) rotate(0)";
        } else {
            responsiveContainer.current.style.height = "300px";
            document.querySelector(".line1").style.transform = "translateY(-8px) rotate(35deg)";
            document.querySelector(".line2").style.opacity = 0;
            document.querySelector(".line3").style.transform = "translateY(8px) rotate(-35deg)";
        }
        
    }, [responsiveOpen]);

    const [accordionClick, setAccordionClick] = useState({
        services: false,
        solutions: false,
        company: false,
        resources: false,
    });

    const handleAccordionClick = (name) => {
        if (name === "services") {
            setAccordionClick({ ...accordionClick, services: !accordionClick.services });
        } else if (name === "solutions") {
            setAccordionClick({ ...accordionClick, solutions: !accordionClick.solutions });
        } else if (name === "company") {
            setAccordionClick({ ...accordionClick, company: !accordionClick.company });
        } else if (name === "resources") {
            setAccordionClick({ ...accordionClick, resources: !accordionClick.resources });
        }
        
    };

    useEffect(() => {
        // console.log(accordionClick);
        if (accordionClick.services === true) {
            document.querySelector(".servicesAccordionSign").style.transform = "rotateZ(45deg)";
            document.querySelector(".servicesNavLinksContainer").style.height = "128px";
        } else {
            document.querySelector(".servicesAccordionSign").style.transform = "rotateZ(0)";
            document.querySelector(".servicesNavLinksContainer").style.height = "0";
        }

        if (accordionClick.solutions === true) {
            document.querySelector(".solutionsAccordionSign").style.transform = "rotateZ(45deg)";
            document.querySelector(".solutionsNavLinksContainer").style.height = "108px";
        } else {
            document.querySelector(".solutionsAccordionSign").style.transform = "rotateZ(0)";
            document.querySelector(".solutionsNavLinksContainer").style.height = "0";
        }

        if (accordionClick.company === true) {
            document.querySelector(".companyAccordionSign").style.transform = "rotateZ(45deg)";
            document.querySelector(".companyNavLinksContainer").style.height = "135px";
        } else {
            document.querySelector(".companyAccordionSign").style.transform = "rotateZ(0)";
            document.querySelector(".companyNavLinksContainer").style.height = "0";
        }

        if (accordionClick.resources === true) {
            document.querySelector(".resourcesAccordionSign").style.transform = "rotateZ(45deg)";
            document.querySelector(".resourcesNavLinksContainer").style.height = "80px";
        } else {
            document.querySelector(".resourcesAccordionSign").style.transform = "rotateZ(0)";
            document.querySelector(".resourcesNavLinksContainer").style.height = "0";
        }
    }, [accordionClick]);

    return (
        <div  className={styles.container}>
            <div className={styles.row1}>
                <NavLink className={styles.headerImageContainer}  to="/home">
                    <img className={styles.headerImage} src={websiteLogo}  style={{width:"190px"}}  alt="Askmeidentity" />
                </NavLink>
                <div className={styles.navContainer}>
                    <NavLink to='/services?sid=identity-access-management' style={{textDecoration:"none"}}> <div style={{color:"black",textDecoration:"none"}} className={`${styles.pageLinkContainer}`}>
                        
                        Services
                        {/* <div className={`${styles.dropDownContainer}`}>
                            <Services />
                        </div> */}
                    </div></NavLink>
                    <a href="https://learn.askmeidentity.com/" style={{textDecoration:"none"}}> <div style={{color:"black",textDecoration:"none"}} className={`${styles.pageLinkContainer}`}>
                        
                        Training
                        {/* <div className={`${styles.dropDownContainer}`}>
                            <Services />
                        </div> */}
                    </div></a>
                    <NavLink to='/iam-consultant' style={{textDecoration:"none"}}> <div style={{color:"black",textDecoration:"none"}} className={`${styles.pageLinkContainer}`}>
                        
                        Consultant
                        {/* <div className={`${styles.dropDownContainer}`}>
                            <Services />
                        </div> */}
                    </div></NavLink>
                    <NavLink to='/mainblog' style={{textDecoration:"none"}}> <div style={{color:"black",textDecoration:"none"}} className={`${styles.pageLinkContainer}`}>
                        
                        Blog
                        {/* <div className={`${styles.dropDownContainer}`}>
                            <Services />
                        </div> */}
                    </div></NavLink>
                    <NavLink to='/about' style={{textDecoration:"none"}}> <div style={{color:"black",textDecoration:"none"}} className={`${styles.pageLinkContainer}`}>
                        
                        About
                        {/* <div className={`${styles.dropDownContainer}`}>
                            <Services />
                        </div> */}
                    </div></NavLink>
                 

                    <NavLink  to="/contact" className={`${styles.pageLinkContainer} ${styles.contact}`}>Contact</NavLink>

                    <div onClick={toggleResponsive} className={styles.dropDownIconContainer}>
                        <div className={`${styles.line} ${styles.line1} line1`}></div>
                        <div className={`${styles.line} ${styles.line2} line2`}></div>
                        <div className={`${styles.line} ${styles.line3} line3`}></div>
                    </div>
                </div>
            </div>
            <div ref={responsiveContainer} className={styles.responsiveContainer}>
                <div className={styles.responsiveInnerContainer}>
                    <div onClick={() => handleAccordionClick("services")} className={`${styles.servicesLink} servicesLink`}>
                     <NavLink to='/services?sid=identity-access-management'  style={{textDecoration:"none"}}> <div style={{fontFamily:"Inter"}} className={styles.servicesText}>Services</div></NavLink>  
                        <div className={`${styles.servicesAccordionSign} servicesAccordionSign`}></div>
                    </div>
                    <div className={`${styles.servicesNavLinks} ${styles.navLinksContainer} servicesNavLinksContainer`}>
                    {/* <NavLink  onClick={toggleResponsive}  to="/services/iam-modernization-services" className={styles.subNavLink}>IAM Modernization Services</NavLink>
                        <NavLink  onClick={toggleResponsive}  to="/services/iam-professional-services" className={styles.subNavLink}>Professional Services</NavLink>
                        <NavLink onClick={toggleResponsive}   to="/services/iam-advisory-and-assessment-services" className={styles.subNavLink}>Advisory and Assessment</NavLink>
                        <NavLink onClick={toggleResponsive}   to="/services/iam-managed-services" className={styles.subNavLink}>Managed Services</NavLink>
                        <NavLink onClick={toggleResponsive}   to="/services/staff-augementation" className={styles.subNavLink}>Staff Augmentation</NavLink> */}
                    </div>

                    <div onClick={() => handleAccordionClick("solutions")} className={`${styles.solutionsLink} solutionsLink`}>
                      <a href='https://learn.askmeidentity.com/'  style={{textDecoration:"none"}}> <div style={{fontFamily:"Inter"}} className={styles.solutionsText}>Training</div></a>  
                        <div className={`${styles.solutionsAccordionSign} solutionsAccordionSign`}></div>
                    </div>
                    <div onClick={() => handleAccordionClick("company")} className={`${styles.companyLink} companyLink`}>
                     <NavLink to='/iam-consultant' style={{textDecoration:"none"}}><div  style={{fontFamily:"Inter"}} className={styles.companyText}>Consultant</div></NavLink>   
                        <div   className={`${styles.companyAccordionSign} companyAccordionSign`}></div>
                    </div>
                    <div className={`${styles.solutionsNavLinks} ${styles.navLinksContainer} solutionsNavLinksContainer`}>
                        {/* <NavLink onClick={toggleResponsive}   to="/solutions/identity-governance-and-administration" className={styles.subNavLink}>Identity Governance and Administration</NavLink>
                        <NavLink onClick={toggleResponsive}   to="/solutions/access-management" className={styles.subNavLink}>Enterprise Access Management</NavLink>

                        <NavLink onClick={toggleResponsive}   to="/solutions/privileged-access-management" className={styles.subNavLink}>Privileged Access Management</NavLink>
                        <NavLink onClick={toggleResponsive}   to="/solutions/customer-identity-and-access-management" className={styles.subNavLink}>Customer Identity and Access Management</NavLink> */}
                    </div>

                    {/* <NavLink onClick={toggleResponsive}   to="/capabilities" className={styles.capabilitiesLink}>
                        Capabilities
                    </NavLink> */}

                    <div onClick={() => handleAccordionClick("company")} className={`${styles.companyLink} companyLink`}>
                     <NavLink to='/mainblog'  style={{textDecoration:"none"}}><div style={{fontFamily:"Inter"}}  className={styles.companyText}>Blog</div></NavLink>   
                        <div   className={`${styles.companyAccordionSign} companyAccordionSign`}></div>
                    </div>
                    <div className={`${styles.companyNavLinks} ${styles.navLinksContainer} companyNavLinksContainer`}>
                        {/* <NavLink onClick={toggleResponsive}   to="/company/about-us" className={styles.subNavLink}>About us</NavLink>
                        <NavLink  onClick={toggleResponsive}  to="/company/why-sharpits" className={styles.subNavLink}>Why Sharp ITS</NavLink>

                        <NavLink  onClick={toggleResponsive} to="/company/our-partners" className={styles.subNavLink}>Partners</NavLink>
                        <NavLink  onClick={toggleResponsive}  to="/company/careers" className={styles.subNavLink}>Careers</NavLink>

                        <NavLink  onClick={toggleResponsive}  to="/company/contact-us" className={styles.subNavLink}>Contact Us</NavLink> */}
                    </div>

                    <div onClick={() => handleAccordionClick("resources")} className={`${styles.resourcesLink} resourcesLink`}>
                       <NavLink to='/about'  style={{textDecoration:"none"}}><div style={{fontFamily:"Inter"}} className={styles.resourcesText}>Aboutus</div></NavLink> 
                        <div className={`${styles.resourcesAccordionSign} resourcesAccordionSign`}></div>
                    </div>
                    <div className={`${styles.resourcesNavLinks} ${styles.navLinksContainer} resourcesNavLinksContainer`}>
                        {/* <NavLink onClick={toggleResponsive}   to="/resources/whitepaper" className={styles.subNavLink}>Whitepapers</NavLink>
                        <NavLink onClick={toggleResponsive}   to="/resources/case-studies" className={styles.subNavLink}>Case Studies</NavLink>
                        <NavLink  onClick={toggleResponsive}  to="/resources/blogs" className={styles.subNavLink}>Blogs</NavLink> */}
                    </div>

                    <NavLink  onClick={toggleResponsive}  style={{textDecoration:"none",fontFamily:"Inter"}}  to="/contact-us" className={styles.contactLink}>
                        Contact
                    </NavLink>
                </div>
            </div>
        </div>
    )
}

export default Header
