// import React, { useState, useEffect } from "react";
// import { NavLink } from "react-router-dom";
// import { useLocation } from "react-router-dom";
// import Group8 from "../Images/Group8.png";
// import Group9 from "../Images/Group9.png";
// import Group10 from "../Images/Group10.png";
// import Group12 from "../Images/Group12.png";
// import Bar2 from "../Images/bar2.png";
// import Ping from "../Images/Ping.png";
// import Calender from "../Images/Calendar.png";
// import home from "../Images/home.png";
// import file from "../Images/file.png";
// import correct from "../Images/correct.png"
// import Swal from 'sweetalert2'
// import hat from "../Images/hat.png";
// import Clock from "../Images/Clock.png";
// import globe from "../Images/globe.png";
// import loadinggif from '../Images/Spinner.gif'
// import Auth0 from "../Images/Auth0.png";
// import firebase from "firebase/compat/app";
// import Forgerock from "../Images/Forgerock1.png";
// import Onelogin from "../Images/Onelogin.png";
// import Okta from "../Images/Okta.png";
// import "./ping.css";

// import Axios from "axios";
// import Training from "./Training";
// import Back from "../Images/Back.png";
// import Right from "../Images/ChRight.png";
// import Nav from "../../Components/Nav/Navbar";
// import Footer from "../Footer";
// import { useSearchParams } from "react-router-dom";
// import db from "./../../firebase";
// import {
//   collection,
//   onSnapshot,
//   where,
//   query,
//   orderBy,
//   limit,
// } from "@firebase/firestore";

// function Pingfederate(props) {
//   const [Active, setActive] = useState("Ping");
//   const [searchParams, setSearchParams] = useSearchParams();
//   const [data, setdata] = useState([]);
//   const [ref_data, setref_data] = useState([]);
//   const [wishlisttr, setwishlisttr] = useState("");
//   const [cname, setcname] = useState("");
//   const [session_id,setsession_id]=useState('')
//   const [modalShow, setModalShow] = React.useState(false);
//   const [loading,setloading]=useState(false)
//   const [wloading,setwloading]=useState(false)
//   const [state, setState] = useState({
//     ip: "",
//     countryName: "",
//     countryCode: "",
//     city: "",
//     timezone: ""
// });

//   const resp = searchParams.get("cid");
//   console.log('fething url');
// console.log(searchParams.get("session_id"));
// var idd=searchParams.get("session_id")
// console.log(window.location.href);

//   console.log(resp);
//   const tokenn = JSON.parse(localStorage.getItem("data"));
//   const fetchSingleCourse = async () => {
//     var cnamee;
//     var rel;

//     await Axios.get(`${process.env.REACT_APP_BASE_URL}/training?id=${resp}`, {})
//       .then((response) => {
//         console.log(response.data.course_category);
//         rel = response.data.course_category;

//         setdata(response.data);
//         // setcname(response.data.course_name)
//         cnamee = response.data.course_name;
//       })
//       .catch((err) => {
//         console.error(err);
//       });

//     // var rel='Identity and Access Management';

//     await Axios.post(`${process.env.REACT_APP_BASE_URL}/getinfo`, {
//       token: tokenn,
//     })
//       .then((response) => {
//         console.log(response.data);
//       })
//       .catch((err) => {
//         console.error(err.response.data.err.code);
//         if (err.response.data.err.code === "auth/id-token-expired") {
//           alert("your session is expired please login again");
//           localStorage.removeItem("data");
//           window.location.replace("/login");
//         }
//       });

//     await Axios.get(`${process.env.REACT_APP_BASE_URL}/Related_training?rel_course=${rel}`)
//       .then((response) => {
//         console.log(response.data);
//         setref_data(response.data);
//       })
//       .catch((err) => {
//         console.error(err);
//       });
//     await Axios.post(`${process.env.REACT_APP_BASE_URL}/added`, {
//       token: tokenn,
//     })
//       .then((response) => {
//         console.log(response.data);
//         console.log(cnamee);
//         var get = response.data.some((item) => cnamee === item);
//         if (get) {
//           setwishlisttr("stop");
//         }
//         //  if(response.data.indexOf(data.course_name) > -1){
//         //   setwishlisttr('stop');
//         //   console.log(response.data+ 'getting')
//         //  }else{
//         //   console.log('not getting')
//         //  }
//       })
//       .catch((err) => {
//         console.error(err);
//       });

//     console.log(searchParams.get("status"))
//   };

//   // const fetchSingleCourse = () => {

//   //   const Ref = db.collection('courses');
//   // Ref.where('course_id', '==', res).get().then((snapshot) => {
//   //   if (snapshot.empty) {
//   //     console.log('No matching documents.');
//   //     return;
//   //   }
//   //   snapshot.forEach(element => {
//   //     var course = element.data();
//   //     setdata(course)

//   //   console.log(course)

//   //   var rel=course.course_category;
//   //   console.log(rel)
//   //   const Rel = db.collection('courses');
//   //   Rel.where('course_category', '==',rel).get().then((snapshot) => {
//   //     if (snapshot.empty) {
//   //       console.log('No matching documents.');
//   //       return;
//   //     }
//   //     snapshot.forEach(element => {
//   //       var course = element.data();

//   //        setref_data(arr => [...arr, course]);
//   //       console.log(course)

//   //     })
//   //   })
//   //   }
//   //   )

//   // })

//   // }
//   console.log(ref_data);

//   useEffect(fetchSingleCourse, []);

//   useEffect(() => {
//     Axios
//     .get("https://ipapi.co/json/")
//     .then((response) => {
//         let data = response.data;
//         setState({
//             ...state,
//             ip: data.ip,
//             countryName: data.country_name,
//             countryCode: data.country_calling_code,
//             city: data.city,
//             timezone: data.timezone
//         });
//     })
//     .catch((error) => {
//         console.log(error);
//     });
//   }, []);

//  // const [count,setCount]=useState(0)
//   useEffect(()=>{
//     if(idd==null){
//       return
//     }else{
//       Swal.fire({  
//         title: 'Thanks!',  
//         text: 'We have taken your payment request we will send you email after payment verify',
//         icon: 'success',
//        showCancelButton:true,
        
//       })
//     }
   

   

//   },[])

// console.log(state);
//   const fetchcourse = async (get) => {
//     window.location.replace(`/training-detail?cid=${get}`)
//     // await Axios.get(`http://localhost:3002/training?id=${get}`, {})
//     //   .then((response) => {
//     //     setdata(response.data);
//     //   })
//     //   .catch((err) => {
//     //     console.error(err);
//     //   });

//     // const Rel = db.collection('courses');
//     // Rel.where('course_name', '==',get).get().then((snapshot) => {
//     //   if (snapshot.empty) {
//     //     console.log('No matching documents.');
//     //     return;
//     //   }
//     //   snapshot.forEach(element => {
//     //     var course = element.data();
//     //     console.log(course)
//     // setdata(course)

//     //     console.log(course)

//     //   })
//     // })
//   };

//   const loadScript = (src) => {
   
//     return new Promise((resolve) => {
//       const script = document.createElement("script");
//       script.src = src;
//       script.onload = () => {
//         resolve(true);
//         setTimeout(() => {
//           setloading(false);

//         }, 2000);
        

//       };
//       script.onerror = () => {
//         resolve(false);
//       };
//       document.body.appendChild(script);
//     });
//   };
// console.log(process.env.REACT_APP_RAZORPAY_TEST_KEY)
//   const token = JSON.parse(localStorage.getItem("data"));
// console.log(loading)

//   const paymentStripe =async()=>{
//    const value=data;
//     console.log('cklikk')
//     if (token == null || token == "" || token == undefined) {
//       console.log("not");

//       window.location.replace("/login?redirect_url="+window.location.pathname+`?cid=`+value.course_id);
//       return false;
//     } else {
     
//       console.log(loading);
//     //  await Axios.post('http://localhost:3002/create-checkout-session',{
//     //   token:token
//     //  })
//    await fetch(`${process.env.REACT_APP_BASE_URL}/create-checkout-session` , {
//   method: "POST",
//   headers: {
//     'Content-type': 'application/json',
    
//   },
//   body:JSON.stringify({token,
//     items: value.course_id
//   }),
// }).then(res => {
 
//   if (res.ok) return res.json()
//   return res.json().then(json => Promise.reject(json))
// })
// .then(({ url }) => {
//   window.location = url
  
// }).then(
//   console.log(window.location.pathname)
// // Axios.get(`http://localhost:3002/verifystripe?id=${idd}`,{

// // }).then((res)=>{
// //   console.log(res)
// // })
// )
// .catch(e => {
//   console.error(e.error)
// })
//     }
//   }

//   const payment = async() => {
//     console.log(data)
//     const value=data;
//     if (token == null || token == "" || token == undefined) {
//       console.log("not");

//       window.location.replace("/login?redirect_url="+window.location.pathname+`?cid=`+value.course_id);
//       return false;
//     } else {
//       console.log(value)
//       setloading(true);
//       console.log("payment clicked");
//       console.log(JSON.stringify(localStorage));
//       // const course_name =
//       // return;
//       const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
//       if (!res) {
//           alert ("Razor SDK failed to load");
//           setloading(true);

          

//           return;
//       }
//       console.log(`razorpay script loaded:: ${res}`);

//      // return;
//       // const localStorageData = localStorage.getItem("email");
//      // const email_id = localStorage.getItem("user-email");
//     // console.log(data)
//       const course_id = value.course_id
//       const course_name = value.course_name
//       const razorpay_test_key = process.env.REACT_APP_RAZORPAY_TEST_KEY;
//       const razorpay_data = {
//         token:token,
//         //  emailid: email_id,
//           courseid: course_id,
//           coursename: course_name,
//           razorpaytestkey: razorpay_test_key
//       };
//       console.log(razorpay_data);

//       // return;
//       const data = await fetch(`${process.env.REACT_APP_BASE_URL}/init-razorpay`, {
//           headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
//           method: 'POST',
//           body: JSON.stringify(razorpay_data)
//       })
//       .then((razorpay_return) => razorpay_return.json());
//       console.log(data);
//       const options = {
//           key: razorpay_data.razorpaytestkey,
//           amount: data.amount.toString(),
//           currency: data.currency,
//           name: data.coursename,
//           description: data.coursename,
//           image: "https://askmeidentity.com/",
//           order_id: data.id,
//           handler: async response=> {
//               // alert (response.razorpay_payment_id);
//               // alert (response.razorpay_order_id);
//               // alert (response.razorpay_signature);
//              // return;

//               console.log(`client:: ${JSON.stringify(response)}`);
//               let courseamount=(value.course_fee.INR).split(' ')[1]

//               const test_point = await Axios.post(`${process.env.REACT_APP_BASE_URL}/verify-payment`, {
//                   razorpay_payment_id: response.razorpay_payment_id,
//                   razorpay_order_id: response.razorpay_order_id,
//                   razorpay_signature: response.razorpay_signature,
//                  // email: razorpay_data.emailid,
                 
//                  token:token,
//                   amount: courseamount,
//                   courseid: razorpay_data.courseid,
//                   course_name: value.course_name
//               })
//               .then(function(response) {
//                   console.log(response);
//                   console.log(response.data.message);
//                   if (response.data.message!="success") {
//                       console.log ("payment not verified");
//                      // alert ("payment not verified");
//                      setloading(true);
//                      Swal.fire({  
//                       title: 'Oops...',  
//                       text: 'Something went wrong please try again',
//                       icon: 'error',
//                      showCancelButton:true,
//                       confirmButtonText:'Tryagain'
//                     })
//                   }
//                   else {
//                       console.log ("payment verified");
//                       // setModalShow(true)
//                       setloading(false);

//                       Swal.fire({  
//                         title: 'Success!',  
//                         text: 'You have Successfully Enrolled to the Course',
//                         icon: 'success',
//                        showCancelButton:true,
//                         confirmButtonText:'Dashboard'
//                       }).then((show)=>{
//                         if(show.isConfirmed){
//                          window.location.replace('/dashboard')
//                         }
          
//                       }); 
//                   }
//               })
//               .catch((error)=>{
//                   console.log(error);
//                   Swal.fire({  
//                     title: 'Oops...',  
//                     text: 'Something went wrong please try again',
//                     icon: 'error',
//                    showCancelButton:true,
//                     confirmButtonText:'Tryagain'
//                   })
                   
//               })
//               .then(()=>{
//                   // always executed
//               });
//           }
//       };
//       const paymentObject = new window.Razorpay(options);
//       paymentObject.open();
//      // return;


//      // window.location.replace(data.payment_url);
//     }
//   };

//   const wishlist = () => {
//     if (token == null || token == "" || token == undefined) {
//       console.log("not");

//       window.location.replace("/login?redirect_url="+window.location.pathname+`?cid=`+data.course_id);      return false;
//     } else {
//       console.log(token);
//       setwloading(true)

//       Axios.post(`${process.env.REACT_APP_BASE_URL}/addwishlist`, {
//         course: data.course_name,
//         token: token,
//       })
//         .then((response) => {
//           console.log(response.data);
//           setwishlisttr("stop");
//           setwloading(false)

//         })
//         .catch((err) => {
//           console.log(err);
//         });

//       //      if(token.localId==null||token.localId==undefined||token.localId==''){

//       //       console.log(token.uid);
//       //       const db=firebase.firestore();
//       //       db.collection("user").doc(token.uid).
//       //       update({user_id:token.uid,

//       //         array: firebase.firestore.FieldValue.arrayUnion(data.course_name)});
//       //       setwishlisttr('stop');
//       //      }

//       //      else{
//       //      console.log(token.localId);
//       //      const db=firebase.firestore();
//       //      db.collection("user").doc(token.localId).update({

//       //       user_id:token.localId,
//       //       array: firebase.firestore.FieldValue.arrayUnion(data.course_name)

//       //     });

//       // setwishlisttr('stop');
//       //      }
//     }
//   };
//   return (
//     <>
//       <Nav />
//       {/* <div
//         style={{
//           height: "00px",
//           display: "flex",
//           backgroundImage: `url(${Back})`,
//         }}
//       >
//         <p
//           style={{
//             marginLeft: "20%",
//             marginTop: "80px",
//             fontSize: "40px",
//             fontWeight: "700",
//             color: "#3C3C3B",
//             fontFamily: "Inter",
//           }}
//         >
//           Training Details
//         </p>
//       </div> */}
//       <div className="maincomp">
//         <Training
//           content={data.course_content}
//           title={data.course_name}
//           discription={data.course_description}
//           img={data.image_url}
//         />

//         <div className="traininginfo">
//           <div className="about-training">
//             <p
//               style={{
//                 fontSize: "25px",
//                 fontWeight: "bold",
//                 fontFamily: "Inter",
//               }}
//             >
//               {" "}
//               <img src={Bar2} alt="" /> Get the Training
//             </p>

//             <p
//               style={{
//                 fontSize: "20px",
//                 marginTop: "25px",
//                 fontFamily: "Inter",
//               }}
//             >
//               {" "}
//               <img src={home} alt="" /> Instructor :
//               <span style={{ opacity: "0.5", fontSize: "10",fontFamily:"Inter" }}>
//                 {data.course_instructor}
//               </span>{" "}
//             </p>
//             <p
//               style={{
//                 fontSize: "20px",
//                 marginTop: "25px",
//                 fontFamily: "Inter",
//               }}
//             >
//               {" "}
//               <img src={file} alt="" /> Source :{" "}
//               <span style={{ opacity: "0.5", fontSize: "10",fontFamily:"Inter" }}>
//                 {data.course_source}
//               </span>{" "}
//             </p>
//             <p
//               style={{
//                 fontSize: "20px",
//                 marginTop: "25px",
//                 fontFamily: "Inter",
//               }}
//             >
//               {" "}
//               <img src={Calender} alt="" /> Duration :{" "}
//               <span style={{ opacity: "0.5", fontSize: "10",fontFamily:"Inter" }}>
//                 {data.course_duration}
//               </span>
//             </p>
//             <p
//               style={{
//                 fontSize: "20px",
//                 marginTop: "25px",
//                 fontFamily: "Inter",
//               }}
//             >
//               {" "}
//               <img src={hat} alt="" /> Enrolled:{" "}
//               <span style={{ opacity: "0.5", fontSize: "10",fontFamily:"Inter" }}>
//                 {data.enrolled_customers}
//               </span>
//             </p>
//             <p
//               style={{
//                 fontSize: "20px",
//                 marginTop: "25px",
//                 fontFamily: "Inter",
//               }}
//             >
//               {" "}
//               <img src={globe} alt="" /> Language:{" "}
//               <span style={{ opacity: "0.5", fontSize: "10",fontFamily:"Inter" }}>
//                 {" "}
//                 {data.course_language}
//               </span>
//             </p>
//             <hr style={{ width: "250px", marginTop: "30px" }} />
//             <p
//               style={{
//                 fontSize: "40px",
//                 fontWeight: "bold",
//                 marginTop: "20px",
//               }}
//             >
//               {data.course_fee
//                 ? Object.keys(data.course_fee).map(function (key) {
//                     return (
//                       <div
//                         style={{
//                           fontSize: "25px",
//                           fontFamily: "Inter",
//                           fontWeight: "600",
//                           marginTop: "10px",
//                         }}
//                       >
//                         {data.course_fee[key]}
//                       </div>
//                     );
//                   })
//                 : ""}
//             </p>

//             {
//               (state.countryName=='India')?( <button
//                 className="contactbtn"
//                 style={{ borderRadius: "10px", fontFamily: "Inter" }}
//                 onClick={() => payment()}
//               >
//                  {
//                     (loading)?(
//                       <img style={{width:"30px",height:"30px",marginBottom:"30px",}} src={loadinggif} alt="" />
//                     ):('Start Learninng Now')
//                   }
               
//               </button>):(
//               <button onClick={() => paymentStripe()}
//               className="contactbtn"
//                 style={{ borderRadius: "10px", fontFamily: "Inter" }}>
//                    {
//                     (loading)?(
//                       <img style={{width:"30px",height:"30px",marginBottom:"30px",}} src={loadinggif} alt="" />
//                     ):('Start Learninng Now')
//                   }
                
//               </button>

//             )
//             }
           
//               {/* <button onClick={() => paymentStripe()}
//               className="contactbtn"
//                 style={{ borderRadius: "10px", style: "Inter" }}>
//                 Start Learninng Now
//               </button> */}
//              {/* <button onClick={() => paymentStripe()}
//               className="contactbtn"
//                 style={{ borderRadius: "10px", fontFamily: "Inter" }}>
//                 Start Learninng Now
//               </button> */}

//             {wishlisttr ? (
//               <button
//                 className="contactbtn"
//                 style={{ borderRadius: "10px", fontFamily: "Inter" }}
//               >
//                 Added{" "}
//               </button>
//             ) : (
//               <button
//                 className="contactbtn"
//                 style={{ borderRadius: "10px", fontFamily: "Inter" }}
//                 onClick={() => wishlist()}
//               >
//                 {
//                   (wloading)?( <img style={{width:"30px",height:"30px",marginBottom:"30px",}} src={loadinggif} alt="" />):('Add to Wishlist')
//                 }
                
//               </button>
//             )}
//           </div>
//           <div className="about-training" style={{ marginBottom: "50px" }}>
//             <p
//               style={{
//                 fontSize: "25px",
//                 fontWeight: "bold",
//                 fontFamily: "Inter",
//               }}
//             >
//               {" "}
//               <img src={Bar2} alt="" /> Related Training
//             </p>

//             {ref_data.map((course) => (
//               <NavLink to="" onClick={() => fetchcourse(course.course_id)}>
//                 <p className="training_names">
//                   <img
//                     className="imgt"
//                     style={{ height: "80px",marginTop:"20px" }}
//                     src={course.image_url}
//                     alt=""
//                   />{" "}
//                   <span
//                     style={{
//                       margin: "10px 20px",
//                       fontSize: "18px",
//                       color: "black",
//                       fontFamily: "Inter",
//                     }}
//                   >
//                     {" "}
//                     {course.course_name}
//                     <span
//                       style={{
//                         fontSize: "25px",
//                         fontWeight: "bold",
//                         color: "black",
//                         fontFamily: "Inter",
//                       }}
//                     >
//                       {" "}
//                       <br />{(course.course_fee)?
//       (  Object.keys(course.course_fee).map(function(key) {
//        return <div style={{fontSize:"20px",display:"flex",marginLeft:'0px',marginTop:"0px",fontFamily:"Inter",fontWeight:"650"}}>{course.course_fee[key]}</div>
      
//   })):('')
//     }
//                     </span>{" "}
//                   </span>
//                 </p>
//               </NavLink>
//             ))}
//           </div>
//         </div>
//       </div>

//       {/* <button variant="primary" onClick={() => setModalShow(true)}>
//         Launch vertically 
//       </button> */}

//       {/* <MyVerticallyCenteredModal
//         show={modalShow}
//         onHide={() => setModalShow(false)}
//       /> */}
//       <Footer />
//     </>
//   );
// }

// export default Pingfederate;




// // function MyVerticallyCenteredModal(props) {
// //   return (
// //     <Modal
// //       {...props}
// //       size="sm"
// //     //  width="100px"
// //       aria-labelledby="contained-modal-title-vcenter"
// //       centered
// //     >

      
       
// //           <div style={{justifyContent:"center",textAlign:"center"}}>
// //             <p style={{color:"#4BB543",fontSize:"25px",fontFamily:"Inter"}}>Success!</p>

// //           </div>
          
      
// //       <Modal.Body>
// //         <div style={{justifyContent:"center",textAlign:"center"}}>
// //           <p style={{fontSize:"22px",fontFamily:"Inter",marginTop:"10px"}}>You have Successfully Erolled to the Course</p>
// //            <img src={correct} style={{width:"100px",height:"100px",marginTop:"40px"}} alt="" /></div>
       
// //       </Modal.Body>
      

// //         <NavLink className='dashboard_button' to="/dashboard">Dashboard</NavLink>
// //         {/* <button onClick={props.onHide}>Close</button> */}
     
// //     </Modal>
// //   );
// // }


import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Group8 from "../Images/Group8.png";
import Group9 from "../Images/Group9.png";
import Group10 from "../Images/Group10.png";
import Group12 from "../Images/Group12.png";
import Bar2 from "../Images/bar2.png";
import Ping from "../Images/Ping.png";
import Calender from "../Images/Calendar.png";
import home from "../Images/home.png";
import file from "../Images/file.png";
import correct from "../Images/correct.png"
import Swal from 'sweetalert2'
import hat from "../Images/hat.png";
import Clock from "../Images/Clock.png";
import globe from "../Images/globe.png";
import loadinggif from '../Images/Spinner.gif'
import Auth0 from "../Images/Auth0.png";
import firebase from "firebase/compat/app";
import Forgerock from "../Images/Forgerock1.png";
import Onelogin from "../Images/Onelogin.png";
import Okta from "../Images/Okta.png";
import "./ping.css";

import Axios from "axios";
import Training from "./Training";
import Back from "../Images/Back.png";
import Right from "../Images/ChRight.png";
import Nav from "../../Components/Nav/Navbar";
import Footer from "../Footer";
import { useSearchParams } from "react-router-dom";
import db from "./../../firebase";
import {
  collection,
  onSnapshot,
  where,
  query,
  orderBy,
  limit,
} from "@firebase/firestore";

function Pingfederate(props) {
  const [Active, setActive] = useState("Ping");
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setdata] = useState([]);
  const [ref_data, setref_data] = useState([]);
  const [wishlisttr, setwishlisttr] = useState("");
  const [cname, setcname] = useState("");
  const [session_id,setsession_id]=useState('')
  const [modalShow, setModalShow] = React.useState(false);
  const [loading,setloading]=useState(false)
  const [wloading,setwloading]=useState(false)
  const [state, setState] = useState({
    ip: "",
    countryName: "",
    countryCode: "",
    city: "",
    timezone: ""
});

  const resp = searchParams.get("cid");
  console.log('fething url');
console.log(searchParams.get("session_id"));
var idd=searchParams.get("session_id")
console.log(window.location.href);

  console.log(resp);
  const tokenn = JSON.parse(localStorage.getItem("data"));
  const fetchSingleCourse = async () => {
    var cnamee;
    var rel;

    await Axios.get(`${process.env.REACT_APP_BASE_URL}/training?id=${resp}`, {})
      .then((response) => {
        console.log(response.data.course_category);
        rel = response.data.course_category;

        setdata(response.data);
        // setcname(response.data.course_name)
        cnamee = response.data.course_name;
      })
      .catch((err) => {
        console.error(err);
      });

    // var rel='Identity and Access Management';

    await Axios.get(`${process.env.REACT_APP_BASE_URL}/Related_training?rel_course=${rel}`)
      .then((response) => {
        console.log(response.data);
        setref_data(response.data);
      })
      .catch((err) => {
        console.error(err);
      });
    await Axios.post(`${process.env.REACT_APP_BASE_URL}/added`, {
      token: tokenn,
    })
      .then((response) => {
        console.log(response.data);
        console.log(cnamee);
        var get = response.data.some((item) => cnamee === item);
        if (get) {
          setwishlisttr("stop");
        }
        //  if(response.data.indexOf(data.course_name) > -1){
        //   setwishlisttr('stop');
        //   console.log(response.data+ 'getting')
        //  }else{
        //   console.log('not getting')
        //  }
      })
      .catch((err) => {
        console.error(err);
      });

    console.log(searchParams.get("status"))
  };

  // const fetchSingleCourse = () => {

  //   const Ref = db.collection('courses');
  // Ref.where('course_id', '==', res).get().then((snapshot) => {
  //   if (snapshot.empty) {
  //     console.log('No matching documents.');
  //     return;
  //   }
  //   snapshot.forEach(element => {
  //     var course = element.data();
  //     setdata(course)

  //   console.log(course)

  //   var rel=course.course_category;
  //   console.log(rel)
  //   const Rel = db.collection('courses');
  //   Rel.where('course_category', '==',rel).get().then((snapshot) => {
  //     if (snapshot.empty) {
  //       console.log('No matching documents.');
  //       return;
  //     }
  //     snapshot.forEach(element => {
  //       var course = element.data();

  //        setref_data(arr => [...arr, course]);
  //       console.log(course)

  //     })
  //   })
  //   }
  //   )

  // })

  // }
  console.log(ref_data);

  useEffect(fetchSingleCourse, []);

  useEffect(() => {
    Axios
    .get("https://ipapi.co/json/")
    .then((response) => {
        let data = response.data;
        setState({
            ...state,
            ip: data.ip,
            countryName: data.country_name,
            countryCode: data.country_calling_code,
            city: data.city,
            timezone: data.timezone
        });
    })
    .catch((error) => {
        console.log(error);
    });
  }, []);

 // const [count,setCount]=useState(0)
  useEffect(()=>{
    if(idd==null){
      return
    }else{
      Swal.fire({  
        title: 'Thanks!',  
        text: 'We have taken your payment request we will send you email after payment verify',
        icon: 'success',
       showCancelButton:true,
        
      })
    }
   

   

  },[])

console.log(state);
  const fetchcourse = async (get) => {
    window.location.replace(`/pingfederate?cid=${get}`)
    // await Axios.get(`http://localhost:3002/training?id=${get}`, {})
    //   .then((response) => {
    //     setdata(response.data);
    //   })
    //   .catch((err) => {
    //     console.error(err);
    //   });

    // const Rel = db.collection('courses');
    // Rel.where('course_name', '==',get).get().then((snapshot) => {
    //   if (snapshot.empty) {
    //     console.log('No matching documents.');
    //     return;
    //   }
    //   snapshot.forEach(element => {
    //     var course = element.data();
    //     console.log(course)
    // setdata(course)

    //     console.log(course)

    //   })
    // })
  };

  const loadScript = (src) => {
   
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
        setTimeout(() => {
          setloading(false);

        }, 2000);
        

      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };
console.log(process.env.REACT_APP_RAZORPAY_TEST_KEY)
  const token = JSON.parse(localStorage.getItem("data"));
console.log(loading)

  const paymentStripe =async()=>{
   const value=data;
    console.log('cklikk')
    if (token == null || token == "" || token == undefined) {
      console.log("not");

    //   window.location.replace("/login?redirect_url="+window.location.pathname+`?cid=`+value.course_id);
    //   return false;

    console.log(loading);
    //  await Axios.post('http://localhost:3002/create-checkout-session',{
    //   token:token
    //  })
   await fetch(`${process.env.REACT_APP_BASE_URL}/unauth/create-checkout-session` , {
  method: "POST",
  headers: {
    'Content-type': 'application/json',    
  },
  body:JSON.stringify({items: value.course_id}),
}).then(res => {
 
  if (res.ok) return res.json()
  return res.json().then(json => Promise.reject(json))
})
.then(({ url }) => {
  window.location = url
  
}).then(
  console.log(window.location.pathname)
// Axios.get(`http://localhost:3002/verifystripe?id=${idd}`,{

// }).then((res)=>{
//   console.log(res)
// })
)
.catch(e => {
  console.error(e.error)
})
    } else {
     
      console.log(loading);
    //  await Axios.post('http://localhost:3002/create-checkout-session',{
    //   token:token
    //  })
   await fetch(`${process.env.REACT_APP_BASE_URL}/create-checkout-session` , {
  method: "POST",
  headers: {
    'Content-type': 'application/json',
    
  },
  body:JSON.stringify({token,
    items: value.course_id
  }),
}).then(res => {
 
  if (res.ok) return res.json()
  return res.json().then(json => Promise.reject(json))
})
.then(({ url }) => {
  window.location = url
  
}).then(
  console.log(window.location.pathname)
// Axios.get(`http://localhost:3002/verifystripe?id=${idd}`,{

// }).then((res)=>{
//   console.log(res)
// })
)
.catch(e => {
  console.error(e.error)
})
    }
  }

/*
login or not - payment
while payment - catch details
check email id registered or not
email if registered send invoice to email
else register createuserwithemailandpassword + login
email and information 
*/ 

const paymentFunction = async (value) => {
    console.log(value)
      setloading(true);
      console.log("payment clicked");
      console.log(JSON.stringify(localStorage));
      // const course_name =
      // return;
      const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
      if (!res) {
          alert ("Razor SDK failed to load");
          setloading(true);

          

          return;
      }
      console.log(`razorpay script loaded:: ${res}`);

     // return;
      // const localStorageData = localStorage.getItem("email");
     // const email_id = localStorage.getItem("user-email");
        // console.log(data)
      const course_id = value.course_id
      const course_name = value.course_name
      const razorpay_test_key = process.env.REACT_APP_RAZORPAY_TEST_KEY;
      const razorpay_data = {
        token:token,
        //  emailid: email_id,
          courseid: course_id,
          coursename: course_name,
          razorpaytestkey: razorpay_test_key
      };
      console.log(razorpay_data);

      // return;
      const data = await fetch(`${process.env.REACT_APP_BASE_URL}/init-razorpay`, {
          headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
          method: 'POST',
          body: JSON.stringify(razorpay_data)
      })
      .then((razorpay_return) => razorpay_return.json());
      console.log(data);
      const options = {
          key: razorpay_data.razorpaytestkey,
          amount: data.amount.toString(),
          currency: data.currency,
          name: data.coursename,
          description: data.coursename,
          image: "https://askmeidentity.com/",
          order_id: data.id,
          handler: async response=> {
              // alert (response.razorpay_payment_id);
              // alert (response.razorpay_order_id);
              // alert (response.razorpay_signature);
             // return;

              console.log(`client:: ${JSON.stringify(response)}`);
              // let courseamount = 0;
              // if (razorpay_data.courseid==="1") {
              //     courseamount = 35000;
              // }
              // else if (razorpay_data.courseid==="2") {
              //     courseamount = 700;
              // }

             let courseamount=(value.course_fee.INR).split(' ')[1]
              const test_point = await Axios.post(`${process.env.REACT_APP_BASE_URL}/verify-payment`, {
                  razorpay_payment_id: response.razorpay_payment_id,
                  razorpay_order_id: response.razorpay_order_id,
                  razorpay_signature: response.razorpay_signature,
                 // email: razorpay_data.emailid,
                 
                 token:token,
                  amount: courseamount,
                  courseid: razorpay_data.courseid,
                  course_name: value.course_name
              })
              .then(function(response) {
                  console.log(response);
                  console.log(response.data.message);
                  if (response.data.message!="success") {
                      console.log ("payment not verified");
                     // alert ("payment not verified");
                     setloading(true);
                     Swal.fire({  
                      title: 'Oops...',  
                      text: 'Something went wrong please try again',
                      icon: 'error',
                     showCancelButton:true,
                      confirmButtonText:'Tryagain'
                    })
                  }
                  else {
                      console.log ("payment verified");
                      // setModalShow(true)
                      setloading(false);

                      Swal.fire({  
                        title: 'Success!',  
                        text: 'You have Successfully Enrolled to the Course',
                        icon: 'success',
                       showCancelButton:true,
                        confirmButtonText:'Dashboard'
                      }).then((show)=>{
                        if(show.isConfirmed){
                         window.location.replace('/dashboard')
                        }
          
                      }); 
                  }
              })
              .catch((error)=>{
                  console.log(error);
                  Swal.fire({  
                    title: 'Oops...',  
                    text: 'Something went wrong please try again',
                    icon: 'error',
                   showCancelButton:true,
                    confirmButtonText:'Tryagain'
                  })
                   
              })
              .then(()=>{
                  // always executed
              });
          }
      };
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
     // return;


     // window.location.replace(data.payment_url);
};

  const payment = async() => {
    console.log(data)
    const value=data;
    if (token == null || token == "" || token == undefined) {
      console.log("not");

    //   window.location.replace("/login?redirect_url="+window.location.pathname+`?cid=`+value.course_id);
    console.log(value)
      setloading(true);
      console.log("payment clicked");
      console.log(JSON.stringify(localStorage));
      // const course_name =
      // return;
      const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
      if (!res) {
          alert ("Razor SDK failed to load");
          setloading(true);

          

          return;
      }
      console.log(`razorpay script loaded:: ${res}`);

     // return;
      // const localStorageData = localStorage.getItem("email");
     // const email_id = localStorage.getItem("user-email");
        // console.log(data)
      const course_id = value.course_id
      const course_name = value.course_name
      const razorpay_test_key = process.env.REACT_APP_RAZORPAY_TEST_KEY;
      const razorpay_data = {
        token:token,
        //  emailid: email_id,
          courseid: course_id,
          coursename: course_name,
          razorpaytestkey: razorpay_test_key
      };
      console.log(razorpay_data);

      // return;
      const data = await fetch(`${process.env.REACT_APP_BASE_URL}/unauth/init-razorpay`, {
          headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
          method: 'POST',
          body: JSON.stringify(razorpay_data)
      })
      .then((razorpay_return) => razorpay_return.json());
      console.log(data);
      const options = {
          key: razorpay_data.razorpaytestkey,
          amount: data.amount.toString(),
          currency: data.currency,
          name: data.coursename,
          description: data.coursename,
          image: "https://askmeidentity.com/",
          order_id: data.id,
          handler: async response=> {
            Swal.fire({  
              title: 'Thanks!',  
              text: 'We have taken your payment request we will send you email after payment verify',
              icon: 'success',
             showCancelButton:true,
              
            })
            //   // alert (response.razorpay_payment_id);
            //   // alert (response.razorpay_order_id);
            //   // alert (response.razorpay_signature);
            //  // return;

            //   console.log(`client:: ${JSON.stringify(response)}`);
            //   // let courseamount = 0;
            //   // if (razorpay_data.courseid==="1") {
            //   //     courseamount = 35000;
            //   // }
            //   // else if (razorpay_data.courseid==="2") {
            //   //     courseamount = 700;
            //   // }

            //  let courseamount=(value.course_fee.INR).split(' ')[1]
            //   const test_point = await Axios.post(`${process.env.REACT_APP_BASE_URL}/unauth/verify-payment`, {
            //       razorpay_payment_id: response.razorpay_payment_id,
            //       razorpay_order_id: response.razorpay_order_id,
            //       razorpay_signature: response.razorpay_signature,
            //      // email: razorpay_data.emailid,
                 
            //      token:token,
            //       amount: courseamount,
            //       courseid: razorpay_data.courseid,
            //       course_name: value.course_name
            //   })
            //   .then(function(response) {
            //       console.log(response);
            //       console.log(response.data.message);
            //       if (response.data.message!="success") {
            //           console.log ("payment not verified");
            //          // alert ("payment not verified");
            //          setloading(true);
            //          Swal.fire({  
            //           title: 'Oops...',  
            //           text: 'Something went wrong please try again',
            //           icon: 'error',
            //          showCancelButton:true,
            //           confirmButtonText:'Tryagain'
            //         })
            //       }
            //       else {
            //           console.log ("payment verified");
            //           // setModalShow(true)
            //           setloading(false);

            //           Swal.fire({  
            //             title: 'Success!',  
            //             text: 'You have Successfully Enrolled to the Course',
            //             icon: 'success',
            //            showCancelButton:true,
            //             confirmButtonText:'Dashboard'
            //           }).then((show)=>{
            //             if(show.isConfirmed){
            //              window.location.replace('/dashboard')
            //             }
          
            //           }); 
            //       }
            //   })
            //   .catch((error)=>{
            //       console.log(error);
            //       Swal.fire({  
            //         title: 'Oops...',  
            //         text: 'Something went wrong please try again',
            //         icon: 'error',
            //        showCancelButton:true,
            //         confirmButtonText:'Tryagain'
            //       })
                   
            //   })
            //   .then(()=>{
            //       // always executed
            //   });
          }
      };
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
      return false;
    } else {
      paymentFunction(value);
    }
  };

  const wishlist = () => {
    if (token == null || token == "" || token == undefined) {
      console.log("not");

      window.location.replace("/login?redirect_url="+window.location.pathname+`?cid=`+data.course_id);      return false;
    } else {
      console.log(token);
      setwloading(true)

      Axios.post(`${process.env.REACT_APP_BASE_URL}/addwishlist`, {
        course: data.course_name,
        token: token,
      })
        .then((response) => {
          console.log(response.data);
          setwishlisttr("stop");
          setwloading(false)

        })
        .catch((err) => {
          console.log(err);
        });

      //      if(token.localId==null||token.localId==undefined||token.localId==''){

      //       console.log(token.uid);
      //       const db=firebase.firestore();
      //       db.collection("user").doc(token.uid).
      //       update({user_id:token.uid,

      //         array: firebase.firestore.FieldValue.arrayUnion(data.course_name)});
      //       setwishlisttr('stop');
      //      }

      //      else{
      //      console.log(token.localId);
      //      const db=firebase.firestore();
      //      db.collection("user").doc(token.localId).update({

      //       user_id:token.localId,
      //       array: firebase.firestore.FieldValue.arrayUnion(data.course_name)

      //     });

      // setwishlisttr('stop');
      //      }
    }
  };
  return (
    <>
      <Nav />
      {/* <div
        style={{
          height: "200px",
          display: "flex",
          backgroundImage: `url(${Back})`,
        }}
      >
        <p
          style={{
            marginLeft: "20%",
            marginTop: "80px",
            fontSize: "40px",
            fontWeight: "700",
            color: "#3C3C3B",
            fontFamily: "Inter",
          }}
        >
          Training Details
        </p>
      </div> */}
      <div className="maincomp">
        <Training
          content={data.course_content}
          title={data.course_name}
          section={data.Section}
          discription={data.course_description}
          img={data.image_url}
        />

        <div className="traininginfo">
          <div className="about-training">
            <p
              style={{
                fontSize: "25px",
                fontWeight: "bold",
                fontFamily: "Inter",
              }}
            >
              {" "}
              <img src={Bar2} alt="" /> Get the Training
            </p>

            <p
              style={{
                fontSize: "20px",
                marginTop: "25px",
                fontFamily: "Inter",
              }}
            >
              {" "}
              <img src={home} alt="" /> Instructor :
              <span style={{ opacity: "0.5", fontSize: "10",fontFamily:"Inter" }}>
                {data.course_instructor}
              </span>{" "}
            </p>
            <p
              style={{
                fontSize: "20px",
                marginTop: "25px",
                fontFamily: "Inter",
              }}
            >
              {" "}
              <img src={file} alt="" /> Source :{" "}
              <span style={{ opacity: "0.5", fontSize: "10",fontFamily:"Inter" }}>
                {data.course_source}
              </span>{" "}
            </p>
            <p
              style={{
                fontSize: "20px",
                marginTop: "25px",
                fontFamily: "Inter",
              }}
            >
              {" "}
              <img src={Calender} alt="" /> Duration :{" "}
              <span style={{ opacity: "0.5", fontSize: "10",fontFamily:"Inter" }}>
                {data.course_duration}
              </span>
            </p>
            <p
              style={{
                fontSize: "20px",
                marginTop: "25px",
                fontFamily: "Inter",
              }}
            >
              {" "}
              <img src={hat} alt="" /> Enrolled:{" "}
              <span style={{ opacity: "0.5", fontSize: "10",fontFamily:"Inter" }}>
                {data.enrolled_customers}
              </span>
            </p>
            <p
              style={{
                fontSize: "20px",
                marginTop: "25px",
                fontFamily: "Inter",
              }}
            >
              {" "}
              <img src={globe} alt="" /> Language:{" "}
              <span style={{ opacity: "0.5", fontSize: "10",fontFamily:"Inter" }}>
                {" "}
                {data.course_language}
              </span>
            </p>
            <hr style={{ width: "250px", marginTop: "30px" }} />
            <p
              style={{
                fontSize: "40px",
                fontWeight: "bold",
                marginTop: "20px",
              }}
            >
              {data.course_fee
                ? Object.keys(data.course_fee).map(function (key) {
                    return (
                      <div
                        style={{
                          fontSize: "25px",
                          fontFamily: "Inter",
                          fontWeight: "600",
                          marginTop: "10px",
                        }}
                      >
                        {data.course_fee[key]}
                      </div>
                    );
                  })
                : ""}
            </p>

            {/* {
              (state.countryName=='India')?( <button
                className="contactbtn"
                style={{ borderRadius: "10px", fontFamily: "Inter" }}
                onClick={() => payment()}
              >
                 {
                    (loading)?(
                      <img style={{width:"30px",height:"30px",marginBottom:"30px",}} src={loadinggif} alt="" />
                    ):('Start Learninng Now')
                  }
               
              </button>):(
              <button onClick={() => paymentStripe()}
              className="contactbtn"
                style={{ borderRadius: "10px", fontFamily: "Inter" }}>
                  Start Learninng Now
                
              </button>
             

            )
            } */}
            {/* <form action="http://localhost:3002/create-checkout-session" method="post"> <button >
                checkout
              </button></form> */}
           {/* <button onClick={() => paymentStripe()}>
                checkout
              </button> */}
              {/* <button onClick={() => paymentStripe()}
              className="contactbtn"
                style={{ borderRadius: "10px", style: "Inter" }}>
                Start Learninng Now
              </button> */}
             {/* <button onClick={() => paymentStripe()}
              className="contactbtn"
                style={{ borderRadius: "10px", fontFamily: "Inter" }}>
                Start Learninng Now
              </button> */}
              <NavLink to={{
               pathname:`/payment?cid=${resp}`,
                 state:{title:"hey"},
  
 }}  className="contactbtn"
 style={{ borderRadius: "10px", fontFamily: "Inter",width:"187px",display:"block",textAlign:"center",paddingTop:"18px"}}>Pay Now</NavLink>
 <br />

            {wishlisttr ? (
              <button
                className="contactbtn"
                style={{ borderRadius: "10px", fontFamily: "Inter" }}
              >
                Added{" "}
              </button>
            ) : (
              <button
                className="contactbtn"
                style={{ borderRadius: "10px", fontFamily: "Inter" }}
                onClick={() => wishlist()}
              >
                {
                  (wloading)?( <img style={{width:"30px",height:"30px",marginBottom:"30px",}} src={loadinggif} alt="" />):('Add to Wishlist')
                }
                
              </button>
            )}
          </div>
          <div className="about-training" style={{ marginBottom: "50px" }}>
            <p
              style={{
                fontSize: "25px",
                fontWeight: "bold",
                fontFamily: "Inter",
              }}
            >
              {" "}
              <img src={Bar2} alt="" /> Related Training
            </p>

            {ref_data.map((course) => (
              <NavLink to="" onClick={() => fetchcourse(course.course_id)}>
                <p className="training_names">
                  <img
                    className="imgt"
                    style={{ height: "80px",marginTop:"20px" }}
                    src={course.image_url}
                    alt=""
                  />{" "}
                  <span
                    style={{
                      margin: "10px 20px",
                      fontSize: "18px",
                      color: "black",
                      fontFamily: "Inter",
                    }}
                  >
                    {" "}
                    {course.course_name}
                    <span
                      style={{
                        fontSize: "25px",
                        fontWeight: "bold",
                        color: "black",
                        fontFamily: "Inter",
                      }}
                    >
                      {" "}
                      <br />{(course.course_fee)?
      (  Object.keys(course.course_fee).map(function(key) {
       return <div style={{fontSize:"20px",display:"flex",marginLeft:'0px',marginTop:"0px",fontFamily:"Inter",fontWeight:"650"}}>{course.course_fee[key]}</div>
      
  })):('')
    }
                    </span>{" "}
                  </span>
                </p>
              </NavLink>
            ))}
          </div>
        </div>
      </div>

      {/* <button variant="primary" onClick={() => setModalShow(true)}>
        Launch vertically 
      </button> */}

      {/* <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      /> */}
      <Footer />
    </>
  );
}

export default Pingfederate;




// function MyVerticallyCenteredModal(props) {
//   return (
//     <Modal
//       {...props}
//       size="sm"
//     //  width="100px"
//       aria-labelledby="contained-modal-title-vcenter"
//       centered
//     >

      
       
//           <div style={{justifyContent:"center",textAlign:"center"}}>
//             <p style={{color:"#4BB543",fontSize:"25px",fontFamily:"Inter"}}>Success!</p>

//           </div>
          
      
//       <Modal.Body>
//         <div style={{justifyContent:"center",textAlign:"center"}}>
//           <p style={{fontSize:"22px",fontFamily:"Inter",marginTop:"10px"}}>You have Successfully Erolled to the Course</p>
//            <img src={correct} style={{width:"100px",height:"100px",marginTop:"40px"}} alt="" /></div>
       
//       </Modal.Body>
      

//         <NavLink className='dashboard_button' to="/dashboard">Dashboard</NavLink>
//         {/* <button onClick={props.onHide}>Close</button> */}
     
//     </Modal>
//   );
// }