import React, { useState, useEffect } from 'react';
import axios from "axios";

const Dashboard = () => {
    const nodeURL = process.env.REACT_APP_BASE_URL;

    const [courses, setCourses] = useState([]);
    const [blogs, setBlogs] = useState([]);

    const getAllCoursesData = () => {
        axios.get(`${nodeURL}/api/courses`).then((response) => {
            console.log(response);
            setCourses(response.data);
        }).catch((error) => {
            console.log(error);
        }).finally(() => { });
    };

    const getAllBlogsData = () => {
        axios.get(`${nodeURL}/api/blogs`).then((response) => {
            console.log(response);
            setBlogs(response.data);
        }).catch((error) => {
            console.log(error);
        }).finally(() => { });
    };

    useEffect(() => {
        getAllBlogsData();
        getAllCoursesData();
    },[]);

    const options = {
        chart: {
            type: 'column',
            inverted: false,
        },
        title: {
            text: "Admin Chart"
        },
        series: [{
            name: "courses",
            data: [123, 23, 32]
        },{
            name: "blogs",
            data: [34, 234, 136]
        }]
    }
    return (
        <div className="dashboardContainer">
            <h1 style={{fontFamily:"25px",fontFamily:"Inter"}}>Dashboard</h1>
        </div>
    )
}

export default Dashboard;

