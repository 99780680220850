import React, { useState, useEffect } from "react";
import Logo from "../Images/Logo.png";
import { NavLink } from "react-router-dom";
import "./dash.css";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import Maindash from "./Maindash";
import Consultan from "./Consult";
import TrainerLecture from './TrainerLecture'

import icon7 from "../Images/icon7.png";
import icon8 from "../Images/icon8.png";
import icon9 from "../Images/icon9.png";
import Book from "../Images/Book.png";
import home from "../Images/home.png";
import icon10 from "../Images/icon10.png";
import icon11 from "../Images/icon11.png";
import icon12 from "../Images/icon12.png";
import Profile from "./Profile";
import Lecture from "./Lecture";
import Enrolled from "./Enrolled";
import Purchase from "./Purchase";
import Reviews from "./Reviews";
import Wislist from "./Wislist";
import Icon6 from "../Images/icon16.png";
import { signOut, onAuthStateChanged } from "firebase/auth";
import { auth } from "../../firebase";

function Dash() {
  const [Active, setActive] = useState("Maindash");
  const [data, setdata] = useState([]);
  const [user, setuser] = useState({});
  let navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem("data"));
  // const token=localStorage.getItem('data');

  const FetchData = async () => {
    if (token == null || token == "" || token == undefined) {
      console.log("not");
      window.location.replace("/login");
      return false;
    } else {
      console.log(token);
      console.log(token.email);
    }

    await Axios.post(`${process.env.REACT_APP_BASE_URL}/getname`, {
      token: token,
    })
      .then((response) => {
        console.log(response.data);

        setdata(response.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(FetchData, []);

  // onAuthStateChanged(auth,(currentUser)=>{
  //   setuser(currentUser)
  // })

  const logout = async (e) => {
    e.preventDefault();
    Axios.post(`${process.env.REACT_APP_BASE_URL}/logout`, {})
      .then((response) => {
        // localStorage.setItem('data', JSON.stringify(response.data))
        localStorage.removeItem("data");
        navigate("/login");
        // console.log(localStorage.getItem('data'))
        // navigate('/dashboard');

        // localStorage.setItem('data', JSON.stringify(user._tokenResponse))
      })
      .catch((err) => {
        console.error(err);
        // setfailed('Invalid email or password')
      });

    // await signOut(auth)
  };

  return (
    <>
      <div className="Dash_maincomp">
        <div className="dash_lcomp" style={{ diaplay: "" }}>
          <NavLink to="/home">
            <img
              onClick={() => setActive("Maindash")}
              style={{ marginLeft: "30px" }}
              src={Logo}
              alt=""
            />
          </NavLink>
          <div className="dash_nav">
            {data.email == "sumit@askmeidentity2.com" ||
            data.email == "training@askmeidentity.com" ||
            data.email == "testing@mail.com" ? (
              <NavLink className="dash_navlink" to="/admin">
                {" "}
                <img
                  className="dash_icons"
                  style={{ color: "black" }}
                  src={home}
                  alt=""
                />{" "}
                Admin Dashboard
              </NavLink>
            ) : (
              ""
            )}
            {data.email == "sumit@askmeidentity2.com" ||
            data.email == "training@askmeidentity.com" ||
            data.email == "testing@mail.com" ? (
              <NavLink className="dash_navlink" to="/admin-panel">
                {" "}
                <img
                  className="dash_icons"
                  style={{ color: "black" }}
                  src={home}
                  alt=""
                />{" "}
                Admin Panel
              </NavLink>
            ) : (
              ""
            )}

{
              (data.user=='trainer')?(<NavLink  className="dash_navlink" onClick={() => setActive("Trainer")} to=''> <img className='dash_icons' style={{color:"black"}} src={home} alt="" />My Lecture</NavLink>
              ):(<>
              

            <NavLink  className="dash_navlink" onClick={() => setActive("Maindash")} to=''> <img className='dash_icons' style={{color:"black"}} src={home} alt="" />  My Home</NavLink>
            {/* <NavLink className="dash_navlink"  to='/admin1' > <img  className='dash_icons' style={{color:"black"}}  src={icon9} alt="" />  Admin </NavLink> */}

            <NavLink  className="dash_navlink" onClick={() => setActive("Profile")} to=''> <img className='dash_icons' style={{color:"black"}} src={icon8} alt="" />  My Profile</NavLink>
            <NavLink className="dash_navlink" onClick={() => setActive("Consultant")} to=''> <img  className='dash_icons' style={{color:"black"}}  src={icon9} alt="" /> My Consultant</NavLink>
            <NavLink className="dash_navlink" onClick={() => setActive("Enrolled")} to=''> <img  className='dash_icons' style={{color:"black"}}  src={icon9} alt="" /> Enrolled Training</NavLink>
      
           
            <NavLink className="dash_navlink" onClick={() => setActive("Wislist")} to=''> <img  className='dash_icons' style={{color:"black"}}  src={icon10} alt="" /> Wishlist</NavLink>
     <br />
            {/* <NavLink className="dash_navlink" onClick={() => setActive("Reviews")}  to=''> <img  className='dash_icons' style={{color:"black"}}  src={icon7} alt="" /> Reviews</NavLink> */}
            <NavLink className="dash_navlink"  to='' onClick={(e) => setActive("Lecture")} > <img  className='dash_icons' style={{color:"black",width:"20px",height:"20px"}}  src={Book} alt="" />Lecture</NavLink>

            <NavLink className="dash_navlink" style={{marginLeft:"5px"}} onClick={() => setActive("Purchase")} to=''> <img  className='dash_icons' style={{color:"black"}}  src={icon11} alt="" /> Purchase History</NavLink>
          

              </>)
            }

            {/* <NavLink
              className="dash_navlink"
              onClick={() => setActive("Maindash")}
              to=""
            >
              {" "}
              <img
                className="dash_icons"
                style={{ color: "black" }}
                src={home}
                alt=""
              />{" "}
              My Home
            </NavLink> */}

            {/* <NavLink
              className="dash_navlink"
              onClick={() => setActive("Profile")}
              to=""
            >
              {" "}
              <img
                className="dash_icons"
                style={{ color: "black" }}
                src={icon8}
                alt=""
              />{" "}
              My Profile
            </NavLink> */}

            {/* <NavLink
              className="dash_navlink"
              onClick={() => setActive("Enrolled")}
              to=""
            >
              {" "}
              <img
                className="dash_icons"
                style={{ color: "black" }}
                src={icon9}
                alt=""
              />{" "}
              Enrolled Training
            </NavLink> */}
            {/* <NavLink
              className="dash_navlink"
              onClick={() => setActive("Consultant")}
              to=""
            >
              {" "}
              <img
                className="dash_icons"
                style={{ color: "black" }}
                src={icon9}
                alt=""
              />{" "}
              My Consultant
            </NavLink> */}

            {/* <NavLink
              className="dash_navlink"
              onClick={() => setActive("Wislist")}
              to=""
            >
              {" "}
              <img
                className="dash_icons"
                style={{ color: "black" }}
                src={icon10}
                alt=""
              />{" "}
              Wishlist
            </NavLink> */}
            <br />
            {/* <NavLink className="dash_navlink" onClick={() => setActive("Reviews")}  to=''> <img  className='dash_icons' style={{color:"black"}}  src={icon7} alt="" /> Reviews</NavLink> */}
            {/* <NavLink
              className="dash_navlink"
              to=""
              onClick={(e) => setActive("Lecture")}
            >
              {" "}
              <img
                className="dash_icons"
                style={{ color: "black", width: "20px", height: "20px" }}
                src={Book}
                alt=""
              />
              Lecture
            </NavLink> */}

            {/* <NavLink
              className="dash_navlink"
              style={{ marginLeft: "5px" }}
              onClick={() => setActive("Purchase")}
              to=""
            >
              {" "}
              <img
                className="dash_icons"
                style={{ color: "black" }}
                src={icon11}
                alt=""
              />{" "}
              Purchase History
            </NavLink> */}

            <NavLink
              className="dash_navlink"
              to="/login"
              onClick={(e) => logout(e)}
            >
              {" "}
              <img
                className="dash_icons"
                style={{ color: "black" }}
                src={icon12}
                alt=""
              />{" "}
              Logout
            </NavLink>
          </div>
        </div>
        <div className="dash_rcomp">
          <div className="dash_usercomp">
            <img
              src={Icon6}
              style={{ height: "100px", width: "100px", margin: "40px 40px" }}
              alt=""
            />
            <div>
              <p
                style={{
                  fontSize: "21px",
                  color: "white",
                  fontFamily: "Inter",
                  marginTop: "50px",
                  fontWeight: "400",
                }}
              >
                Hello,
              </p>
              <br />
              <span
                style={{
                  fontSize: "30px",
                  color: "white",
                  fontFamily: "Inter",
                  fontWeight: "600",
                }}
              >
                {data.name}
              </span>
            </div>
          </div>
          <div className="dash_comp">
            {Active === "Maindash" && <Maindash />}
            {Active === "Profile" && <Profile />}
            {Active === "Reviews" && <Reviews />}
            {Active ==="Trainer" && <TrainerLecture name={data.name}  />}
            {Active === "Lecture" && <Lecture />}
            {Active === "Enrolled" && <Enrolled />}
            {Active ==="Consultant" && <Consultan  />}
            {Active === "Purchase" && <Purchase />}
            {Active === "Wislist" && <Wislist />}
          </div>
        </div>
      </div>
    </>
  );
}

export default Dash;
