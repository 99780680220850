import React,{useRef} from 'react';
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  getcourses,
} from "../../action/courses";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Course_cart from './course_cart';
import left from '../../Assets/Images/left.png'
import right from '../../Assets/Images/right.png'
import course from '../../reducer/course';

const Carousel = (props) => {
    const sliderRef = useRef();
    const courses = useSelector((state) => state.courses);

    console.log(courses);
     const settings = {
    dots: true,
    button:false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true, // Enables autoplay
    autoplaySpeed: 2000, // Sets autoplay interval to 3 seconds
    responsive: [
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
const slll=[1,3,,4,5,6]
  const slides =[
    {
      text1:'SharpITS had all the answers we were looking for in order to transform and modernize our identity access management platforms. From helping us pick the vendors, defining our target state architectures and roadmaps, saving a significant amount of time and cost, to supporting us with our journey in transforming our IAM platforms - SharpITS was there all along.',
      head1:'Enterprise Architect',
      place1:'Canadian Financial Institution',
    },{
      text1:'I highly recommend SharpITS for any IAM related work. I’ve worked with SharpITS consultants on a highly complex eHR implementation. SharpITS consultants are professional and calm under pressure and have demonstrated excellent problem solving skills and leadership during stressful situations. They are dedicated to finding solutions that exceed customer expectations.',
      head1:"Sr. Project Manager,",
      place1:"Canadian Telecom"
    },{
      text1:'We partnered with SharpITS to deliver Ping related implementations and stabilize the Ping IAM platform for a Canadian home health care client. SharpITS consultant was very knowledgeable and was able to quickly help our client resolve the outstanding issues. SharpITS has been very responsive, responsible and accountable.',
      head1:" Director of Services Delivery",
      place1:"Canadian Security Consulting Firm",
 }
    ,
    {
      text1:'I had the opportunity to work with SharpITS team through one of the critical projects for our provincial health care systems. I found SharpITS team to be extremely knowledgeable particularly in Identity Management (IDM). It is evident from our support calls and debugging sessions for the project that SharpITS team has very detailed technical knowledge about the product .',
      head1:' Sr. Project Manager',
      place1:' Canadian Provincial Healthcare',
    },{
        text1:'We started a partnership with SharpITS for a PAM project in Summer 2022 and soon SharpITS became our go-to partner for our PAM and IAM projects. SharpITS was able to provide high quality resources in a very short time, often on the same day or next day whenever requested, and always responsive, responsible and accountable. ',
      head1:" Head of Professional Services",
      place1:" IAM Consulting Firm",
    },{
      text1:'Jim from SharpITS consistently demonstrated excellent technical knowledge and a strong work ethic to align his contribution to the strategic business goals of a complex organization. I found Jim a ready partner to work towards the best solution. He is always ready to offer an informed opinion and support it persuasively. ',
      head1:"Enterprise IAM Architect",
      place1:" Canadian Government",
    }
    ,
    {  
      text1:'SharpITS has been our go-to partner for our digital identity professional services. SharpITS was able to provide high quality resources in a timely manner whenever requested, and always responsive, responsible and accountable.SharpITS has helped us deliver multiple IAM projects. The quality, dedication and professionalism is second to none.',
      head1:'Partner',
      place1:'Global Consulting Firm',
    },{
        text1:'SharpITS had all the answers we were looking for in order to transform and modernize our identity access management platforms. From helping us pick the vendors, defining our target state architectures and roadmaps, saving a significant amount of time and cost, to supporting us with our journey in transforming our IAM platforms - SharpITS was there all along.',
      head1:"Enterprise Architect",
      place1:"Canadian Financial Institution",
    },{
      text1:'I highly recommend SharpITS for any IAM related work. I’ve worked with SharpITS consultants on a highly complex eHR implementation. SharpITS consultants are professional and calm under pressure and have demonstrated excellent problem solving skills and leadership during stressful situations. They are dedicated to finding solutions that exceed customer expectations.',
      head1:" Sr. Project Manager,",
      place1:"Canadian Telecom",
  }
]
console.log(slides);
  return (

    <>
    
<div className='test_coresal_home' style={{position:"relative"}}>

<button className='btn_testimonial_l' ><img  onClick={() => sliderRef.current.slickPrev()} style={{backgroundColor:"#1A53DD",width:"30px",borderRadius:"50%"}} src={left} alt="" /></button>
<button className='btn_testimonial_r' onClick={() => sliderRef.current.slickNext()} ><img style={{backgroundColor:"#1A53DD",width:"30px",borderRadius:"50%"}} src={right} alt="" /></button>

    
    {
      (courses=='undefined'||courses=='')?(''):(<Slider {...settings} ref={sliderRef}>
        {
        
          courses.map((course, index) => (
        
            (course.course_id=='4521')?(''):(  <Course_cart name={course.course_name} instructor={course.course_instructor} fee={course.course_fee} img={course.image_url} cid={course.course_id} url={course.url}/>
            )           
          
        )
          )}
    
  </Slider>)
    }
  

    </div>
    </> 
    
  );
};

export default Carousel;