import React,{useState,useEffect} from 'react'
import "./dash.css"
import Axios from 'axios'
function Purchase() {
  const[data,setdata]=useState([])
  const token=JSON.parse(localStorage.getItem('data'));
  const fetchinfo = async() => {
console.log('dchcvvv')
    await  Axios.post(`${process.env.REACT_APP_BASE_URL}/purchaseHistory`,{
      token:token,
         }
         ).then((response)=>{
          console.log(response.data)
         
          setdata(response.data)
        
        }).catch(err => {
            console.error(err);
        
        })

  }

  useEffect(
    fetchinfo    
   ,[]
  
  );

console.log(data)
  return (
   <>
       <p className='Dash_heading'>Purchase History</p>

       <div className="table">
      <table className='tablee'>
        <tr className='tr'>
          <th className='th'>Date/Time</th>
          <th className='th'>Amount</th>
          <th className='th'>Training Name</th>
          <th className='th'>Status</th>
        </tr>
      
        {(data)?(

(
  data.map(name => 
      
    <tr  className='tr' >
     
      <td className='td'>{name.date_time}</td>
      <td className='td'> <img src='' alt="" /> {name.amount}</td>
      <td className='td'> <img src='' alt="" /> {name.course_name}</td>
      <td className='td'> <img src='' alt="" /> {name.status}</td>
      {/* <td className='td'> <NavLink style={{color:"rgba(37,214,225,1)"}} to=''>View </NavLink> </td> */}
    </tr>
  ))
):(
    ''
  )
        }
 {/* {data.enrolled.map((val, key) => {
          return (
            <tr className='tr' key={key}>
              <td className='td'>{val.course1}</td>
              <td className='td'> <img src={icon13} alt="" /> {val.rate}</td>
              <td className='td'> <NavLink style={{color:"rgba(37,214,225,1)"}} to={val.go}>View </NavLink> </td>
            </tr>
          )
        })} */}
     
      </table>
    </div>
   </>
  )
}

export default Purchase