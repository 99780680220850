import React,{useState} from 'react'
import { FaEnvelope, FaLock, FaGoogle, FaFacebook } from "react-icons/fa";
import NavBar from '../Navbar/Navbar'
import Footer from '../Fotter/Fotter'
function Frgt_pswd() {
    const [email, setEmail] = useState("");

    const handleEmailChange = (e) => {

        setEmail(e.target.value);
      };
      const Raise_mail=(e)=>{
   e.preventDefault();
   console.log(email);
      }
    
  return (

<>
<NavBar/>
<div style={{display:"flex",justifyContent:"center",height:"80vh",alignItems:"center"}}>
    <div >
    <div className="input-group" style={{width:"350px"}} >
          <div className="icon">
            <FaEnvelope />
          </div>
          <input
            type="email"
            placeholder="Email"
            style={{heigth:'20px',fontSize:"18px",padding:"0px"}}
            value={email}
            onChange={handleEmailChange}
            required
          />
         
          
        </div>
        <div>
          <button
          onClick={(e)=>Raise_mail(e)}
          style={{ backgroundColor: "#1A53DD", color: "white",fontSize:"17px" ,height:"40px",fontFamily:"Inter",border:"1px solid #1A53DD",borderRadius:"10px",width:"100px"}}
        >
          send mail
        </button>
          </div>
    </div>

       
</div>

<Footer/>
</>  
  )
}

export default Frgt_pswd