import React, { useState } from 'react';
import axios from "axios";

import "./styles-newBlog.scss";

const NewBlog = (props) => {
    const nodeURL = process.env.REACT_APP_BASE_URL;

    const [about, setAbout] = useState("");
    const [categoryList, setCategoryList] = useState([{ "category": "" },]);
    const [contentList, setContentList] = useState([{ "content": "" },]);
    const [dateTime, setDateTime] = useState(new Date().toString());
    const [headingList, setHeadingList] = useState([{ "heading": "" },]);
    const [blogId, setBlogId] = useState("");
    const [imageURL, setImageURL] = useState("");
    const [name, setName] = useState("");
    const [organization, setOrganization] = useState("");

    const [submitDisabled, setSubmitDisabled] = useState(false);

    const addCategory = () => {
        setCategoryList([...categoryList, { "category": "" }]);
    };

    const removeCategory = (index) => {
        const list = [...categoryList];
        list.splice(index, 1);
        setCategoryList(list);
    };

    const handleCategoryChange = (event, index) => {
        const { name, value } = event.target;
        const list = [...categoryList];
        list[index][name] = value;
        setCategoryList(list);
        // console.log(categoryList);
    };

    const addContent = () => {
        setContentList([...contentList, { "content": "" }]);
    };

    const removeContent = (index) => {
        const list = [...contentList];
        list.splice(index, 1);
        setContentList(list);
    };

    const handleContentChange = (event, index) => {
        const { name, value } = event.target;
        const list = [...contentList];
        list[index][name] = value;
        setContentList(list);
        // console.log(contentList);
    };

    const addHeading = () => {
        setHeadingList([...headingList, { "heading": "" }]);
    };

    const removeHeading = (index) => {
        const list = [...headingList];
        list.splice(index, 1);
        setHeadingList(list);
    };

    const handleHeadingChange = (event, index) => {
        const { name, value } = event.target;
        const list = [...headingList];
        list[index][name] = value;
        setHeadingList(list);
        // console.log(headingList);
    };

    // insert data
    const tokenn = JSON.parse(localStorage.getItem("data"));

    const addNewBlog = async (event) => {
        event.preventDefault();

        // console.log(categoryList);
        const category = [];
        categoryList.map((item)=>{ category.push(item.category); });
        // console.log(contentList);
        let content = [];
        contentList.map((item)=>{ content.push(item.content) });
        const heading = [];
        headingList.map((item)=>{ heading.push(item.heading); });
        const newData = {
            "about_blog": about,
            "blog_category": category,
            "blog_content": content,
            "blog_date": dateTime,
            "blog_heading": heading,
            "blog_id": blogId,
            "image_url": imageURL,
            "name": name,
            "organization": organization
        };
        console.log(newData);

        setSubmitDisabled(true);
        await axios.post(`${nodeURL}/api/blog-create`, {newData,token:tokenn}).then((response)=>{
            // console.log(response);
            alert("added blog "+response);
            props.reloadDataFunc();
            document.querySelector("#newBlogForm").reset();
        }).catch((err)=>{
            console.log(err);
            alert(err);
        }).finally(()=>{
            setSubmitDisabled(false);
        });
    };

    return (
        <div className="newBlogContent">
            <form id="newBlogForm" onSubmit={addNewBlog}>
                <div className="blogInputContainer aboutBlogContainer">
                    <label htmlFor="about_blog" className='New_blog_Label'>About blog:</label>
                    <textarea name="about_blog" id="about_blog" cols="30" rows="10" required value={about}
                    onChange={(event)=>setAbout(event.target.value)}></textarea>
                </div>
                <div className="blogInputContainer blogCategoryContainer">
                    <label htmlFor="blog_category" className='New_blog_Label'>Blog Category:</label>
                    {categoryList.map((singleCategory, index) => (
                        <div key={index} className="categories">
                            <div className="first-division">
                                <input type="text" name="category" id="category" required
                                value={singleCategory.category}
                                onChange={(event)=>handleCategoryChange(event,index)} />
                                {/* {categoryList.length - 1 === index && categoryList.length < 4 && ( */}
                                {categoryList.length - 1 === index && (
                                    <button type="button" className="btn btn-add" onClick={addCategory}>
                                        <span>add a category</span>
                                    </button>
                                )}
                            </div>
                            <div className="second-division">
                                {categoryList.length > 1 && (
                                    <button type="button" className="btn btn-remove" onClick={() => removeCategory(index)}>
                                        <span>Remove</span>
                                    </button>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
                <div className="blogInputContainer blogContentContainer">
                    <label htmlFor="blog_content" className='New_blog_Label'>Blog Content:</label>
                    {/* <input type="text" className="blog_content" name="blog_content" placeholder="blog content" required /> */}
                    {contentList.map((singleContent, index) => (
                        <div key={index} className="contents">
                            <div className="first-division">
                                <textarea type="text" name="content" id="content" required
                                value={singleContent.content}
                                onChange={(event)=>handleContentChange(event,index)}></textarea>
                                {contentList.length - 1 === index && (
                                    <button type="button" className="btn btn-add" onClick={addContent}>
                                        <span>add a content</span>
                                    </button>
                                )}
                            </div>
                            <div className="second-division">
                                {contentList.length > 1 && (
                                    <button type="button" className="btn btn-remove" onClick={() => removeContent(index)}>
                                        <span>Remove</span>
                                    </button>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
                <div className="blogInputContainer blogHeadingsContainer">
                    <label htmlFor="blog_headings" className='New_blog_Label'>Blog Headings:</label>
                    {/* <input type="text" className="blog_headings" name="blog_headings" placeholder="blog headings" required /> */}
                    {headingList.map((singleHeading, index) => (
                        <div key={index} className="headings">
                            <div className="first-division">
                                <input type="text" name="heading" id="heading" required
                                value={singleHeading.heading}
                                onChange={(event)=>handleHeadingChange(event,index)} />
                                {headingList.length - 1 === index && (
                                    <button type="button" className="btn btn-add" onClick={addHeading}>
                                        <span>add a heading</span>
                                    </button>
                                )}
                            </div>
                            <div className="second-division">
                                {headingList.length > 1 && (
                                    <button type="button" className="btn btn-remove" onClick={() => removeHeading(index)}>
                                        <span>Remove</span>
                                    </button>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
                <div className="blogInputContainer blogIdContainer">
                    <label htmlFor="blog_id" className='New_blog_Label'>Blog id:</label>
                    <input type="text" className="blog_id" name="blog_id" placeholder="blog id" required 
                    onChange={(event)=>setBlogId(event.target.value)}/>
                </div>
                <div className="blogInputContainer imageURLContainer">
                    <label htmlFor="image_url" className='New_blog_Label'>Image URL:</label>
                    <input type="text" className="image_url" name="image_url" placeholder="image url" required  
                    onChange={(event)=>setImageURL(event.target.value)}/>
                </div>
                <div className="blogInputContainer nameContainer">
                    <label htmlFor="name" className='New_blog_Label'>Name:</label>
                    <input type="text" className="name" name="name" placeholder="name" required  
                    onChange={(event)=>setName(event.target.value)}/>
                </div>
                <div className="blogInputContainer organizationContainer">
                    <label htmlFor="organization" className='New_blog_Label'>Organization:</label>
                    <input type="text" className="organization" name="organization" placeholder="organization" required  
                    onChange={(event)=>setOrganization(event.target.value)}/>
                </div>
                <div className="submitButtonContainer">
                    <input type="submit" className="newBlogSubmit" value="Add Blog" disabled={submitDisabled} />
                </div>
            </form>
        </div>
    )
}

export default NewBlog
