import logo from './logo.svg';
import './App.css';
import MainNavbar from './Component/Navbar/Navbar';
import Home from './Pages/Home/Home';
import { Routes, Route, NavLink } from "react-router-dom";
import Training_detail from './Pages/Home/Training_detail';
import Fotter from './Component/Fotter/Fotter';
import Login from './Component/User/Login';
import Register from './Pages/Auth/Register';
import Overview from './Component/Dashboard/Overview';
import './Font/stylesheet.css'
import Payment from './Component/Training/Payment';

import All_training from './Pages/Training/All_training';
function App() {


  return (
    <div className="">
      <MainNavbar/>

       <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/home" element={<Home />} />
                    <Route path='/training' element={<Training_detail/>}/>
                    <Route path='/askmeidentity-training/login'element={<Login/>}/>
                    <Route path='/register' element={<Register/>}/>
                    <Route path='/payment' element={<Payment/>}/>
                    <Route path='/user-overview' element={<Overview/>}/>
                    <Route path='/all-training' element={<All_training/>}/>
                    </Routes>

                    <Fotter/>

    </div>
  );
}

export default App;
