import React,{useState} from 'react'
import firebase from 'firebase/compat/app'
import {useNavigate} from "react-router-dom"
function Editinfo() {
  const[name,setname]=useState('');
  const[username,setusername]=useState('');
  const[email,setemail]=useState('');
  const[skill,setskill]=useState('');
  const[number,setnumber]=useState('');
  const[bio,setbio]=useState('');
  const[date,setdate]=useState('');
  let navigate = useNavigate();
  const token=JSON.parse(localStorage.getItem('data'));
  
  const addinfo = async(e)=>{
    e.preventDefault()
    if(token==null || token==''  || token==undefined){ 
      console.log('not');
      alert("please login")
      window.location.replace("/login")
      return false;
   }
   else{
     if(token.localId==null||token.localId==undefined||token.localId==''){

      console.log(token.uid);
      const db=firebase.firestore();
      db.collection("user").doc(token.uid).
      update({name:name,username:username,email:email,skill:skill,number:number,bio:bio,date:date,user_id:token.uid});
     
     }
     else{
     console.log(token.localId);
     const db=firebase.firestore();
     db.collection("user").doc(token.localId).
     update({name:name,username:username,email:email,skill:skill,number:number,bio:bio,date:date,user_id:token.localId});
 
// var r=db.collection('user')
// r.where('user_ID', '==',token.localId).add({course:data.course_name});
     }

  }
  navigate('/dashboard');
}

  
  return (
<>
<div style={{textAlign:"center",justifyContent:"center",justifyItems:"center"}}>
<table style={{marginLeft:"auto",marginRight:"auto"}} >
<div className='profile_tabe'>
  <tbody>
    <tr className='profiletext' >
      <td >Registation Date</td>
      <td className='profilespace' ><input type="text" style={{padding: "6px 15px",fontFamily:"Inter",fontSize:'18px',borderRadius:"5px",margin:"30px"}}  onChange={(e)=>setdate(e.target.value)} /></td>
    </tr>
    <tr className='profiletext'>
      <td >Full Name</td>
      <td className='profilespace'><input type="text" style={{padding: "6px 15px",fontFamily:"Inter",fontSize:'18px',borderRadius:"5px",margin:"30px"}}  onChange={(e)=>setname(e.target.value)}/></td>
    </tr>
    <tr className='profiletext'>
      <td >UserName</td>
      <td className='profilespace'><input type="text" style={{padding: "6px 15px",fontFamily:"Inter",fontSize:'18px',borderRadius:"5px",margin:"30px"}}  onChange={(e)=>setusername(e.target.value)}/></td>
    </tr>
    <tr className= 'profiletext'>
      <td >Email</td>
      <td className='profilespace'><input type="email" style={{padding: "6px 15px",fontFamily:"Inter",fontSize:'18px',borderRadius:"5px",margin:"30px"}}  onChange={(e)=>setemail(e.target.value)} /></td>
    </tr>

    <tr className='profiletext'>
      <td >Phone Number</td>
      <td className='profilespace'><input type="number" style={{padding: "6px 15px",fontFamily:"Inter",fontSize:'18px',borderRadius:"5px",margin:"30px"}}  onChange={(e)=>setnumber(e.target.value)} /></td>
    </tr>
    <tr className='profiletext'>
      <td >Skill</td>
      <td className='profilespace'><input type="text" style={{padding: "6px 15px",fontFamily:"Inter",fontSize:'18px',borderRadius:"5px",margin:"30px"}}  onChange={(e)=>setskill(e.target.value)} /></td>
    </tr>
    <tr className='profiletext'>
      <td >Bio</td>
      <td className='profilespace'><input type="text" style={{padding: "6px 15px",fontFamily:"Inter",fontSize:'18px',borderRadius:"5px",margin:"30px"}} onChange={(e)=>setbio(e.target.value)} /></td>
    </tr>
  </tbody>
  </div>
</table>
<button className='contactbtn' onClick={(e) => addinfo(e)}>Submit</button>
</div>
</>
  )
}

export default Editinfo