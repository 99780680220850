import React, { useState, useEffect } from 'react';
import axios from "axios";

import "./styles-viewEditCourse.scss"

const ViewEditCourse = (props) => {
    const nodeURL = process.env.REACT_APP_BASE_URL;
    const tokenn = JSON.parse(localStorage.getItem("data"));

    const [course, setCourse] = useState([]);
    const [submitDisabled, setSubmitDisabled] = useState(false);

    const [certificationsList, setCertificationsList] = useState([{ "certification": "" }]);
    const [consultantINRFee, setConsultantINRFee] = useState("");
    const [consultantUSDFee, setConsultantUSDFee] = useState("");
    const [courseINRFee, setCourseINRFee] = useState("");
    const [courseUSDFee, setCourseUSDFee] = useState("");
    const [courseCategory, setCourseCategory] = useState("");
    const [courseContentList, setCourseContentList] = useState([{ "course_content": "" }]);
    const [courseDescription, setCourseDescription] = useState("");
    const [courseDuration, setCourseDuration] = useState("");
    const [courseId, setCourseId] = useState("");
    const [courseInstructor, setCourseInstructor] = useState("");
    const [courseLanguage, setCourseLanguage] = useState("");
    const [courseLevel, setCourseLevel] = useState("");
    const [courseName, setCourseName] = useState("");
    const [courseProducts, setCourseProducts] = useState("");
    const [courseSource, setCourseSource] = useState("");
    const [dislikes, setDislikes] = useState("");
    const [enrolledCustomers, setEnrolledCustomers] = useState("");
    const [imageBackground, setImageBackground] = useState("");
    const [imageUrl, setImageUrl] = useState("");
    const [likes, setLikes] = useState("");
    const [paymentUrl, setPaymentUrl] = useState("");
    const [resourceList, setResourceList] = useState([{ "resource": "" }]);
    const [reviewList, setReviewList] = useState([{ "review": "" }]);

    // compliant certification
    const addCertification = () => {
        setCertificationsList([...certificationsList, { "certification": "" }]);
    };

    const removeCertification = (index) => {
        const list = [...certificationsList];
        list.splice(index, 1);
        setCertificationsList(list);
    };

    const handleCertificationChange = (event, index) => {
        const { name, value } = event.target;
        const list = [...certificationsList];
        list[index][name] = value;
        setCertificationsList(list);
        // console.log(certificationsList);
    };

    // course content
    const addCourseContent = () => {
        setCourseContentList([...courseContentList, { "course_content": "" }]);
    };

    const removeCourseContent = (index) => {
        const list = [...courseContentList];
        list.splice(index, 1);
        setCourseContentList(list);
    };

    const handleCourseContentChange = (event, index) => {
        const { name, value } = event.target;
        const list = [...courseContentList];
        list[index][name] = value;
        setCourseContentList(list);
        // console.log(courseContentList);
    };

    // resource
    const addResource = () => {
        setResourceList([...resourceList, { "resource": "" }]);
    };

    const removeResource = (index) => {
        const list = [...resourceList];
        list.splice(index, 1);
        setResourceList(list);
    };

    const handleResourceChange = (event, index) => {
        const { name, value } = event.target;
        const list = [...resourceList];
        list[index][name] = value;
        setResourceList(list);
        // console.log(courseContentList);
    };

    // review
    const addReview = () => {
        setReviewList([...reviewList, { "review": "" }]);
    };

    const removeReview = (index) => {
        const list = [...reviewList];
        list.splice(index, 1);
        setReviewList(list);
    };

    const handleReviewChange = (event, index) => {
        const { name, value } = event.target;
        const list = [...reviewList];
        list[index][name] = value;
        setReviewList(list);
        // console.log(courseContentList);
    };

    useEffect(() => {
        if (props.course === course) {
            return;
        }
        console.log(props.course);
        setCourse(props.course);

        const certifications = [];
        props.course.compliant_certifications.map((item, index) => {
            certifications.push({
                "certification": item
            });
        });
        setCertificationsList(certifications);

        setConsultantINRFee(props.course.consultant_fee.INR);
        setConsultantUSDFee(props.course.consultant_fee.USD);
        setCourseINRFee(props.course.course_fee.INR);
        setCourseUSDFee(props.course.course_fee.USD);

        setCourseCategory(props.course.course_category);
        const contents = [];
        Object.values(props.course.course_content).map((item, index) => {
            contents.push({
                "content": item
            });
        });
        setCourseContentList(contents);
        setCourseDescription(props.course.course_description);
        setCourseDuration(props.course.course_duration);
        setCourseId(props.course.course_id);
        setCourseInstructor(props.course.course_instructor);
        setCourseLanguage(props.course.course_language);
        setCourseLevel(props.course.course_level);
        setCourseName(props.course.course_name);
        setCourseProducts(props.course.course_products);
        setCourseSource(props.course.course_source);
        setDislikes(props.course.dislikes);
        setEnrolledCustomers(props.course.enrolled_customers);
        setImageBackground(props.course.image_background);
        setImageUrl(props.course.image_url);
        setLikes(props.course.likes);
        setPaymentUrl(props.course.payment_url);
        const resources = [];
        Object.values(props.course.resources).map((item, index) => {
            resources.push({
                "resource": item
            });
        });
        setResourceList(resources);
        if (props.course.reviews) {
            const reviews = [];
            Object.values(props.course.reviews).map((item, index) => {
                reviews.push({
                    "review": item
                });
            });
            setReviewList(reviews);
        }
    });

    const updateCourse = async (event) => {
        event.preventDefault();

        const updatedData = {};

        const certifications = [];
        Object.values(certificationsList).map((item, index) => {
            certifications.push(item.certification);
        });
        updatedData.complaint_certification = certifications;

        const consultant_fee = { "INR": consultantINRFee, "USD": consultantUSDFee };
        updatedData.consultant_fee = consultant_fee;
        const course_fee = { "INR": courseINRFee, "USD": courseUSDFee };
        updatedData.course_fee = course_fee;
        updatedData.course_category = courseCategory;

        const contents = [];
        Object.values(courseContentList).map((item, index) => {
            contents.push(item.content);
        });
        updatedData.course_content = contents;

        updatedData.course_description = courseDescription;
        updatedData.course_duration = courseDuration;
        updatedData.course_id = courseId;
        updatedData.course_instructor = courseInstructor;
        updatedData.course_language = courseLanguage;
        updatedData.course_level = courseLevel;
        updatedData.course_name = courseName;
        updatedData.course_products = courseProducts;
        updatedData.course_source = courseSource;
        updatedData.dislikes = dislikes;
        updatedData.enrolled_customers = enrolledCustomers;
        updatedData.image_background = imageBackground;
        updatedData.image_url = imageUrl;
        updatedData.likes = likes;
        updatedData.payment_url = paymentUrl;

        const resources = [];
        Object.values(resourceList).map((item, index) => {
            resources.push(item.resource);
        });
        updatedData.resource = resources;

        const reviews = [];
        Object.values(reviewList).map((item, index) => {
            reviews.push(item.review);
        });
        updatedData.review = reviews;

        console.log(updatedData);

        setSubmitDisabled(true);
        await axios.post(`${nodeURL}/api/course-set/${props.course.docId}`, {updatedData,token:tokenn}).then((response)=>{
            alert(props.course.docId+" updated");
        }).catch((err)=>{
            alert(err);
        }).finally(()=>{
            setSubmitDisabled(false);
        });
    };

    return (
        <div className="viewEditContainer">
            <form id="viewEditCourseForm" onSubmit={updateCourse}>
                <div className="courseInputContainer certificationsContainer">
                    <label htmlFor="certifications" className='New_blog_Label'>Complaint Certifications:</label>
                    {certificationsList.map((item, index) => (
                        <div key={index} className="certifications">
                            <div className="first-division">
                                <input type="text" name="certification" id="certification" required style={{height:"40px",fontFamily:"Inter",fontSize:"20px",borderRadius:"10px"}}
                                    value={item.certification} placeholder="complaint certification"
                                    onChange={(event) => handleCertificationChange(event, index)} />
                                {certificationsList.length - 1 === index && (
                                    <button type="button" className="btn btn-add" onClick={addCertification}>
                                        <span>add a complaint certification</span>
                                    </button>
                                )}
                            </div>
                            <div className="second-division">
                                {certificationsList.length > 1 && (
                                    <button type="button" className="btn btn-remove" onClick={() => removeCertification(index)}>
                                        <span>Remove</span>
                                    </button>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
                <div className="courseInputContainer feeContainer">
                    <label htmlFor="fee" className='New_blog_Label'>Consultant Fee:</label><br />
                    <label style={{ display: 'inline-block', width: "50px", marginBottom: "5px" }} className='New_blog_Label'>INR:</label>
                    <input type="text" name="inrFee" id="inrFee" placeholder="Indian National Rupee" required
                        style={{ display: 'inline-block', marginLeft: "15px" }}
                        value={consultantINRFee} onChange={(event) => setConsultantINRFee(event.target.value)} /><br />
                    <label style={{ display: 'inline-block', width: "50px" }} className='New_blog_Label'>USD:</label>
                    <input type="text" name="usdFee" id="usdFee" placeholder="United States Dollar" required
                        style={{ display: 'inline-block', marginLeft: "15px" }}
                        value={consultantUSDFee} onChange={(event) => setConsultantUSDFee(event.target.value)} />
                </div>
                <div className="courseInputContainer courseFeeContainer">
                    <label htmlFor="courseFee" className='New_blog_Label'>Course Fee:</label><br />
                    <label style={{ display: 'inline-block', width: "50px", marginBottom: "5px" }} className='New_blog_Label'>INR:</label>
                    <input type="text" name="inrCourseFee" id="inrCourseFee" placeholder="Indian National Rupee" required
                        style={{ display: 'inline-block', marginLeft: "15px" }}
                        value={courseINRFee} onChange={(event) => setCourseINRFee(event.target.value)} /><br />
                    <label style={{ display: 'inline-block', width: "50px" }} className='New_blog_Label'>USD:</label>
                    <input type="text" name="usdCourseFee" id="usdCourseFee" placeholder="United States Dollar" required
                        style={{ display: 'inline-block', marginLeft: "15px" }}
                        value={courseUSDFee} onChange={(event) => setCourseUSDFee(event.target.value)} />
                </div>
                <div className="courseInputContainer courseCategoryContainer">
                    <label htmlFor="courseCategory" className='New_blog_Label'>Course Category:</label><br />
                    <input type="text" name="courseCategory" id="courseCategory" placeholder="Course Category" required  style={{height:"40px",fontFamily:"Inter",fontSize:"20px",borderRadius:"10px"}}
                        value={courseCategory} onChange={(event) => setCourseCategory(event.target.value)} />
                </div>
                <div className="courseInputContainer courseContentContainer">
                    <label htmlFor="course_content" className='New_blog_Label'>Course Content:</label>
                    {courseContentList.map((item, index) => (
                        <div key={index} className="courseContents">
                            <div className="first-division">
                                {/* <input type="text" name="content" id="content" required
                                    value={item.content} placeholder="course content"
                                    onChange={(event) => handleCourseContentChange(event, index)} /> */}
                                    <textarea type="text" name="content" id="content" required
                                    value={item.content} style={{height:"40px",fontFamily:"Inter",fontSize:"20px",borderRadius:"10px"}}
                                    onChange={(event)=>handleCourseContentChange(event,index)}></textarea>
                                {courseContentList.length - 1 === index && (
                                    <button type="button" className="btn btn-add" onClick={addCourseContent}>
                                        <span>add a course content</span>
                                    </button>
                                )}
                            </div>
                            <div className="second-division">
                                {courseContentList.length > 1 && (
                                    <button type="button" className="btn btn-remove" onClick={() => removeCourseContent(index)}>
                                        <span>Remove</span>
                                    </button>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
                <div className="courseInputContainer courseDescriptionContainer">
                    <label htmlFor="courseDescription" className='New_blog_Label'>Course Description:</label><br />
                    {/* <input type="text" name="courseDescription" id="courseDescription" placeholder="Course Description" required
                        value={courseDescription} onChange={(event) => setCourseDescription(event.target.value)} /> */}
                    <textarea type="text" name="courseDescription" id="courseDescription" placeholder="Course Description" required style={{height:"40px",fontFamily:"Inter",fontSize:"20px",borderRadius:"10px"}}
                    value={courseDescription} onChange={(event) => setCourseDescription(event.target.value)}></textarea>
                </div>
                <div className="courseInputContainer courseDurationContainer">
                    <label htmlFor="courseDuration" className='New_blog_Label'>Course Duration:</label><br />
                    <input type="text" name="courseDuration" id="courseDuration" placeholder="Course Duration" required style={{height:"40px",fontFamily:"Inter",fontSize:"20px",borderRadius:"10px"}}
                        value={courseDuration} onChange={(event) => setCourseDuration(event.target.value)} />
                </div>
                <div className="courseInputContainer courseIdContainer">
                    <label htmlFor="courseId" className='New_blog_Label'>Course Id:</label><br />
                    <input type="text" name="courseId" id="courseId" placeholder="Course ID" required style={{height:"40px",fontFamily:"Inter",fontSize:"20px",borderRadius:"10px"}}
                        value={courseId} onChange={(event) => setCourseId(event.target.value)} />
                </div>
                <div className="courseInputContainer courseInstructorContainer">
                    <label htmlFor="courseInstructor" className='New_blog_Label'>Course Instructor:</label><br />
                    <input type="text" name="courseInstructor" id="courseInstructor" placeholder="Course Instructor" required style={{height:"40px",fontFamily:"Inter",fontSize:"20px",borderRadius:"10px"}}
                        value={courseInstructor} onChange={(event) => setCourseInstructor(event.target.value)} />
                </div>
                <div className="courseInputContainer courseLanguageContainer">
                    <label htmlFor="courseLanguage" className='New_blog_Label'>Course Language:</label><br />
                    <input type="text" name="courseLanguage" id="courseLanguage" placeholder="Course Language" required style={{height:"40px",fontFamily:"Inter",fontSize:"20px",borderRadius:"10px"}}
                        value={courseLanguage} onChange={(event) => setCourseLanguage(event.target.value)} />
                </div>
                <div className="courseInputContainer courseLevelContainer">
                    <label htmlFor="courseLevel" className='New_blog_Label'>Course Level:</label><br />
                    <input type="text" name="courseLevel" id="courseLevel" placeholder="Course Level" required style={{height:"40px",fontFamily:"Inter",fontSize:"20px",borderRadius:"10px"}}
                        value={courseLevel} onChange={(event) => setCourseLevel(event.target.value)} />
                </div>
                <div className="courseInputContainer courseNameContainer">
                    <label htmlFor="courseName" className='New_blog_Label'>Course Name:</label><br />
                    <input type="text" name="courseName" id="courseName" placeholder="Course Name" required style={{height:"40px",fontFamily:"Inter",fontSize:"20px",borderRadius:"10px"}}
                        value={courseName} onChange={(event) => setCourseName(event.target.value)} />
                </div>
                <div className="courseInputContainer courseProductsContainer">
                    <label htmlFor="courseProducts" className='New_blog_Label'>Course Products:</label><br />
                    <input type="text" name="courseProducts" id="courseProducts" placeholder="Course Products" required style={{height:"40px",fontFamily:"Inter",fontSize:"20px",borderRadius:"10px"}}
                        value={courseProducts} onChange={(event) => setCourseProducts(event.target.value)} />
                </div>
                <div className="courseInputContainer courseSourceContainer">
                    <label htmlFor="courseSource" className='New_blog_Label'>Course Source:</label><br />
                    <input type="text" name="courseSource" id="courseSource" placeholder="Course Source" required style={{height:"40px",fontFamily:"Inter",fontSize:"20px",borderRadius:"10px"}}
                        value={courseSource} onChange={(event) => setCourseSource(event.target.value)} />
                </div>
                <div className="courseInputContainer dislikesContainer">
                    <label htmlFor="dislikes" className='New_blog_Label'>Dislikes:</label><br />
                    <input type="text" name="dislikes" id="dislikes" placeholder="Dislikes" required  style={{height:"40px",fontFamily:"Inter",fontSize:"20px",borderRadius:"10px"}}
                        value={dislikes} onChange={(event) => setDislikes(event.target.value)} />
                </div>
                <div className="courseInputContainer enrolledCustomersContainer">
                    <label htmlFor="enrolledCustomers" className='New_blog_Label'>Enrolled Customers:</label><br />
                    <input type="text" name="enrolledCustomers" id="enrolledCustomers" placeholder="Enrolled Customers" required style={{height:"40px",fontFamily:"Inter",fontSize:"20px",borderRadius:"10px"}}
                        value={enrolledCustomers} onChange={(event) => setEnrolledCustomers(event.target.value)} />
                </div>
                <div className="courseInputContainer imageBackgroundContainer">
                    <label htmlFor="imageBackground" className='New_blog_Label'>Image Background:</label><br />
                    <input type="text" name="imageBackground" id="imageBackground" placeholder="Image Background" required  style={{height:"40px",fontFamily:"Inter",fontSize:"20px",borderRadius:"10px"}}
                        value={imageBackground} onChange={(event) => setImageBackground(event.target.value)} />
                </div>
                <div className="courseInputContainer imageUrlContainer">
                    <label htmlFor="imageUrl" className='New_blog_Label'>Image URL:</label><br />
                    <input type="text" name="imageUrl" style={{height:"40px",fontFamily:"Inter",fontSize:"20px",borderRadius:"10px"}} id="imageUrl" placeholder="Image URL" required
                        value={imageUrl} onChange={(event) => setImageUrl(event.target.value)} />
                </div>
                <div className="courseInputContainer likesContainer">
                    <label htmlFor="likes" className='New_blog_Label'>Likes:</label><br />
                    <input type="text" name="likes" id="likes" placeholder="Likes" required  style={{height:"40px",fontFamily:"Inter",fontSize:"20px",borderRadius:"10px"}}
                        value={likes} onChange={(event) => setLikes(event.target.value)} />
                </div>
                <div className="courseInputContainer paymentUrlContainer">
                    <label htmlFor="paymentUrl" className='New_blog_Label'>Payment URL:</label><br />
                    <input type="text" name="paymentUrl" id="paymentUrl" placeholder="Payment URL" required  style={{height:"40px",fontFamily:"Inter",fontSize:"20px",borderRadius:"10px"}}
                        value={paymentUrl} onChange={(event) => setPaymentUrl(event.target.value)} />
                </div>
                <div className="courseInputContainer resourceContainer">
                    <label htmlFor="resource" className='New_blog_Label'>Resource:</label>
                    {resourceList.map((item, index) => (
                        <div key={index} className="resources">
                            <div className="first-division">
                                <input type="text" name="resource" id="resource" required
                                    value={item.resource} placeholder="resource"  style={{height:"40px",fontFamily:"Inter",fontSize:"20px",borderRadius:"10px"}}
                                    onChange={(event) => handleResourceChange(event, index)} />
                                {resourceList.length - 1 === index && (
                                    <button type="button" className="btn btn-add" onClick={addResource}>
                                        <span>add a resource</span>
                                    </button>
                                )}
                            </div>
                            <div className="second-division">
                                {resourceList.length > 1 && (
                                    <button type="button" className="btn btn-remove" onClick={() => removeResource(index)}>
                                        <span>Remove</span>
                                    </button>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
                <div className="courseInputContainer reviewContainer">
                    <label htmlFor="review" className='New_blog_Label' >Review:</label>
                    {reviewList.map((item, index) => (
                        <div key={index} className="reviews">
                            <div className="first-division">
                                {/* <input type="text" name="review" id="review" required
                                    value={item.review} placeholder="review"
                                    onChange={(event) => handleReviewChange(event, index)} /> */}
                                <textarea type="text" name="review" id="review" required  style={{height:"40px",fontFamily:"Inter",fontSize:"20px",borderRadius:"10px"}}
                                    value={item.review} placeholder="review"
                                    onChange={(event) => handleReviewChange(event, index)}></textarea>
                                {reviewList.length - 1 === index && (
                                    <button type="button" className="btn btn-add" onClick={addReview}>
                                        <span>add a review</span>
                                    </button>
                                )}
                            </div>
                            <div className="second-division">
                                {reviewList.length > 1 && (
                                    <button type="button" className="btn btn-remove" onClick={() => removeReview(index)}>
                                        <span>Remove</span>
                                    </button>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
                <div className="submitButtonContainer">
                    <input type="submit" className="updateCourseSubmit" value="Update Course" disabled={submitDisabled} />
                </div>
            </form>
        </div>
    )
}

export default ViewEditCourse;
