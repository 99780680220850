import React, { useState } from "react";
import "./event.css";
import Navbar from "../../Components/header/Header";
import Fotter from "../../src/Component/Fotter/Fotter";
import axios from "axios";
import SweetAlert2 from "react-sweetalert2";

function Events() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    companyName: "",
  });
  const [swalProps, setSwalProps] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add logic to handle form submission (e.g., send data to a server)
    console.log("runs");
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/addEventUser`, {
        formData,
      })
      .then(() => {
        console.log("runs2");
        setSwalProps({
          show: true,
          title: "Success!",
          text: "We have successfully captured your details.We will sending you meeting invite on your mail.",
          icon: "success",
        });

        // Returning a promise to make sure the next then waits for SweetAlert to close
        return new Promise((resolve) => setTimeout(resolve, 5000)); // Adjust the delay as needed
      })
      .then(() => {
        // This will be executed after the SweetAlert is closed
        setSwalProps({});
      })
      .catch((err) => {
        console.log(err);
      });

    //console.log(response);
  };
  return (
    <>
      <Navbar />
      <SweetAlert2 {...swalProps} />

      <div className="event_section1">
        {" "}
        <br />
        <h1 className="event_heading" style={{ color: "#1A53DD" }}>
          Welcome to the{" "}
          <span style={{ color: "#56b320" }}>Askmeidentity IAM Event</span>{" "}
        </h1>
        <p className="event_text">
          Join us for an exciting event focused on Identity and Access
          Management (IAM).
        </p>
      </div>
      <div className="Backgorund_sade">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "20px",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h2
            className="event_heading"
            style={{
              paddingTop: "100px",
              maxWidth: "800px",
              textAlign: "center",
            }}
          >
            Okta Workflow Webinar
          </h2>
          <p
            className="event_text"
            style={{ maxWidth: "800px", textAlign: "center" }}
          >
            Enhance your website's security and user experience with Okta Workflow. Our solution simplifies authentication and access control, providing a seamless and secure online environment for your visitors.
          </p>
        </div>
        <br />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "50px",
          }}
        >
          <div className="EventMobileImage">
            <section className="event-details">
              <img
                style={{ width: "300px", borderRadius: "10px" }}
                src="https://firebasestorage.googleapis.com/v0/b/askmeidentity-website.appspot.com/o/website_data%2Fpexels-andrea-piacquadio-3760089.jpg?alt=media&token=2f36bad6-15ce-4477-b2b2-c1b1482ef97d&_gl=1*c16m5f*_ga*ODk0MDU3ODI4LjE2NTQ2MDc2NTg.*_ga_CW55HF8NVT*MTY5NzU1NTIyOS40ODUuMS4xNjk3NTU1MzUzLjU1LjAuMA.."
                alt=""
              />
            </section>
          </div>
          <div>
            <form
              onSubmit={handleSubmit}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <label>
                <input
                  type="text"
                  className="event_input"
                  placeholder="first name"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                />
              </label>

              <label>
                <input
                  type="text"
                  className="event_input"
                  placeholder="last name"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                />
              </label>

              <label>
                <input
                  type="email"
                  className="event_input"
                  placeholder="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </label>

              <button className="event_btn" type="submit">
                Register
              </button>
            </form>
          </div>
        </div>
      </div>
      <Fotter />
    </>
  );
}

export default Events;
