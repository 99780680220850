import React, { useState, useEffect } from "react";
import loading from "../Images/loading.gif";
import "./dash.css";
import Logo from "../Images/Logo.png";
import Admin from "../Images/Admin.png";
function Admindashboard() {
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [admin, setAdmin] = useState(window.sessionStorage.getItem("admin"));
  const [loginStatus, setLoginStatus] = useState(
    JSON.parse(window.sessionStorage.getItem("login-status"))
  );
  const [loginButtonDisabled, setLoginButtonDisabled] = useState(false);
  const [displa, setDispla] = useState("60%");
  const login = (event) => {
    event.preventDefault();
    setLoginButtonDisabled(true);
    console.log(loginButtonDisabled);
    setDispla("0");
    console.log(displa);

    const data = {
      email: email,
      password: password,
    };
    // console.log(data);
    // return;
    const url = `${process.env.REACT_APP_BASE_URL}/api/signin`;
    fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })

    
      .then((res) => res.json())
      .then((data) => {
         console.log(data);
        setLoginStatus(data.status);
        setAdmin(data.name);
        setLoginButtonDisabled(false);
      });
  };
  useEffect(() => {
    window.sessionStorage.setItem("login-status", loginStatus);
    window.sessionStorage.setItem("admin", admin);
  }, [loginStatus]);
  const logout = () => {
    setLoginStatus(false);
    setAdmin(false);
    window.localStorage.setItem("login-status", false);
    window.sessionStorage.setItem("admin", null);
    setLoginButtonDisabled(false);
  };
  const [enrolledCourses, setEnrolledCourses] = useState("");
  useEffect(() => {
    setEnrolledCourses("");
    // console.log(loginStatus);
    if (loginStatus) {
      // console.log("fetched courses");
      const url = `${process.env.REACT_APP_BASE_URL}/api/enrolled-courses`;
      fetch(url, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          setEnrolledCourses(data);
        });
    }
  }, [loginStatus]);
  const [courseDetails, setCourseDetails] = useState("");
  const [newId, setNewId] = useState("");
  const [newComments, setNewComments] = useState("");
  const [newCourseName, setNewCourseName] = useState("");
  const [newDurationPerDay, setNewDurationPerDay] = useState("");
  const [newEmail, setNewEmail] = useState();
  const [newMeetingLink, setNewMeetingLink] = useState("");
  const [newRecordingLink, setNewReordingLink] = useState("");
  const [newTotalHours, setNewTotalHours] = useState("");
  const [newTrainer, setNewTrainer] = useState("");
  const [newTrainingStartDate, setNewTrainingStartDate] = useState("");
  const productDetails = (event) => {
    event.preventDefault();
    const id = event.currentTarget.id;
    setNewId("");
    setNewComments("");
    setNewCourseName("");
    setNewDurationPerDay("");
    setNewEmail("");
    setNewMeetingLink("");
    setNewReordingLink("");
    setNewTotalHours("");
    setNewTrainer("");
    setNewTrainingStartDate("");
    const url = `${process.env.REACT_APP_BASE_URL}/api/course-details`;
    fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id: id }),
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        setCourseDetails(data);
        setNewId(data.id);
        setNewComments(data.data.comments);
        setNewCourseName(data.data.course_name);
        setNewDurationPerDay(data.data.duration_per_day);
        setNewEmail(data.data.email);
        setNewMeetingLink(data.data.meeting_link);
        setNewReordingLink(data.data.recording_link);
        setNewTotalHours(data.data.total_hours);
        setNewTrainer(data.data.trainer);
        setNewTrainingStartDate(data.data.training_start_date);
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  const [disableUpdateButton, setDisableUpdateButton] = useState(false);
  const [updateStatus, setUpdateStatus] = useState("");
  const updateForm = (event) => {
    event.preventDefault();
    setDisableUpdateButton(true);
    setUpdateStatus(1);
    // console.log("update form");
    const update_data = {
      id: newId,
      data: {
        trainer: newTrainer,
        training_start_date: newTrainingStartDate,
        recording_link: newRecordingLink,
        total_hours: newTotalHours,
        comments: newComments,
        email: newEmail,
        meeting_link: newMeetingLink,
        course_name: newCourseName,
        duration_per_day: newDurationPerDay,
      },
    };
    const url = `${process.env.REACT_APP_BASE_URL}/api/update-form`;
    fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(update_data),
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        setDisableUpdateButton(false);
        setUpdateStatus("");
      })
      .catch((error) => {
        // console.log(JSON.stringify(error));
        setDisableUpdateButton(false);
        setUpdateStatus("");
      });
  };
  useEffect(() => {
    setDisableUpdateButton(true);
  }, []);
  useEffect(() => {
    if (newId) {
      setDisableUpdateButton(false);
    }
  }, [newId]);
  return (
    <div className="Admin_main">
      {!loginStatus ? (
        <div className="Admin_left">
          <div style={{ marginTop: "36%" }}>
            <img src={Admin} alt="" />

            <h1
              style={{
                fontSize: "40px",
                fontFamily: "Inter",
                fontWeight: "600",
                color: "#3C3C3B",
                opacity: "0.7",
              }}
            >
              Hello Admin{" "}
            </h1>
            <p
              style={{
                fontSize: "25px",
                fontFamily: "Inter",
                fontWeight: "600",
                color: "#3C3C3B",
                opacity: "0.7",
              }}
            >
              Just Couple of Clicks More
            </p>
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="Admin_right">
        <div style={{ marginRight: "70%" }}></div>
        <div className="Admin_login" style={{ marginLeft: { displa } }}>
          <div>
            {!loginStatus && (
              <>
                <img src={Logo} style={{ marginRight: "70%" }} alt="" />
                <div style={{ marginLeft: "60%" }}>
                  <h1
                    style={{
                      fontSize: "35px",
                      fontFamily: "Inter",
                      opacity: "0.8",
                      marginTop: "90%",
                      marginLeft: "60%",
                    }}
                  >
                    {" "}
                    WELCOME
                  </h1>
                  <div className="signin">
                    <form onSubmit={login}>
                      {/* admin1 = name: "Ravi", email: "ravi@mail.com", password: "password1"<br />
                        admin2 = name: "Sumit", email: "sumit@mail.com", password: "password2"<br /> */}
                      <label htmlFor=""></label>
                      <br />
                      <input
                        type="email"
                        placeholder="Email"
                        className="Admin_in"
                        required
                        onChange={(event) => setEmail(event.target.value)}
                      />
                      <br />
                      <br />
                      <label htmlFor=""></label>
                      <br />
                      <input
                        type="password"
                        placeholder="Password"
                        className="Admin_in"
                        required
                        onChange={(event) => setPassword(event.target.value)}
                      />
                      <br />
                      <br />
                      <button
                        type="submit"
                        className="loginButton"
                        disabled={loginButtonDisabled}
                      >
                        Login
                      </button>
                    </form>
                  </div>
                </div>
              </>
            )}
            {loginStatus && (
              <div className="loggedIn">
                <div
                  style={{
                    borderRadius: "3px",
                    height: "100vh",
                    oveow: "hidden",
                    width: "1500px",
                  }}
                >
                  <div
                    className="enrolledCoursesContainer"
                    style={{
                      width: "320px",
                      backgroundColor: "rgba(37,214,225,1)",
                      height: "100%",
                      overflow: "scroll",
                      display: "inline-block",
                    }}
                  >
                    {!enrolledCourses ? (
                      <div>
                        <img
                          src={loading}
                          alt="loading"
                          style={{ width: "100%" }}
                        />
                      </div>
                    ) : (
                      <div>
                        <h1
                          style={{
                            backgroundColor: "rgba(37,214,225,1)",
                            color: "black",
                            opacity: "0.6",
                            fontSize: "22px",
                            fontFamily: "Inter",
                            padding: "5px",
                            margin: "2px",
                          }}
                        >
                          Enrolled Users
                        </h1>
                        {enrolledCourses.map((x, i) => (
                          <>
                            <div
                              key={i}
                              id={x}
                              style={{
                                backgroundColor: "rgba(37,214,225,1)",
                                color: "#fff",
                                fontSize: "22px",
                                fontFamily: "Inter",
                                padding: "5px",
                                cursor: "pointer",
                                margin: "2px",
                              }}
                              onClick={productDetails}
                            >
                              {x}
                            </div>
                            <hr
                              style={{
                                marginLeft: "10px",
                                marginRight: "10px",
                              }}
                            />
                          </>
                        ))}
                      </div>
                    )}
                  </div>
                  <div
                    className="enrolledCoursesEditArea"
                    style={{
                      width: "calc(100% - 320px)",
                      height: "100%",
                      overfw: "scroll",
                      display: "inline-block",
                      position: "relative",
                    }}
                  >
                    {
                      <div>
                        <h1
                          style={{
                            color: "black",
                            fontFamily: "Inter",
                            fontSize: "25px",
                            padding: "5px",
                            paddingLeft: "20px",
                          }}
                        >
                          Enrolled user Email::{" "}
                          <b
                            style={{
                              fontSize: "22px",
                              fontFamily: "Inter",
                              opacity: "0.9",
                            }}
                          >
                            {courseDetails.id}
                          </b>
                        </h1>
                        <form className="updateForm" onSubmit={updateForm}>
                          <label
                            htmlFor=""
                            style={{ fontSize: "22px", fontFamily: "Inter" }}
                          >
                            Course name::{" "}
                            <b
                              style={{
                                fontSize: "22px",
                                fontFamily: "Inter",
                                opacity: "0.7",
                              }}
                            >
                              {newCourseName}
                            </b>
                          </label>
                          <br />
                          <br />
                          <label
                            style={{ fontSize: "22px", fontFamily: "Inter" }}
                          >
                            Comment:
                          </label>
                          <input
                            type="text"
                            className="Admin_in"
                            value={newComments}
                            onChange={(event) =>
                              setNewComments(event.target.value)
                            }
                            required
                          />
                          <br />
                          <label
                            style={{ fontSize: "22px", fontFamily: "Inter" }}
                          >
                            Duration Per Day:
                          </label>
                          <input
                            type="text"
                            className="Admin_in"
                            value={newDurationPerDay}
                            onChange={(event) =>
                              setNewDurationPerDay(event.target.value)
                            }
                            required
                          />
                          <br />
                          <label
                            style={{ fontSize: "22px", fontFamily: "Inter" }}
                          >
                            Email:
                          </label>
                          <input
                            type="text"
                            className="Admin_in"
                            value={newEmail}
                            onChange={(event) =>
                              setNewEmail(event.target.value)
                            }
                            required
                          />
                          <br />
                          <label
                            style={{ fontSize: "22px", fontFamily: "Inter" }}
                          >
                            Meeting Link:
                          </label>
                          <input
                            type="text"
                            className="Admin_in"
                            value={newMeetingLink}
                            onChange={(event) =>
                              setNewMeetingLink(event.target.value)
                            }
                            required
                          />
                          <br />
                          <label
                            style={{ fontSize: "22px", fontFamily: "Inter" }}
                          >
                            Recording Link:
                          </label>
                          <input
                            type="text"
                            className="Admin_in"
                            value={newRecordingLink}
                            onChange={(event) =>
                              setNewReordingLink(event.target.value)
                            }
                            required
                          />
                          <br />
                          <label
                            style={{ fontSize: "22px", fontFamily: "Inter" }}
                          >
                            Total hours:
                          </label>
                          <input
                            type="text"
                            className="Admin_in"
                            value={newTotalHours}
                            onChange={(event) =>
                              setNewTotalHours(event.target.value)
                            }
                            required
                          />
                          <br />
                          <label
                            style={{ fontSize: "22px", fontFamily: "Inter" }}
                          >
                            Trainer:
                          </label>
                          <input
                            type="text"
                            className="Admin_in"
                            value={newTrainer}
                            onChange={(event) =>
                              setNewTrainer(event.target.value)
                            }
                            required
                          />
                          <br />
                          <label
                            style={{ fontSize: "22px", fontFamily: "Inter" }}
                          >
                            Training start date:
                          </label>
                          <input
                            type="text"
                            className="Admin_in"
                            value={newTrainingStartDate}
                            onChange={(event) =>
                              setNewTrainingStartDate(event.target.value)
                            }
                            required
                          />
                          <br />
                          <br />
                          <button
                            type="submit"
                            style={{ marginTop: "50px" }}
                            className="Admin_btnn"
                            disabled={disableUpdateButton}
                          >
                            Update
                          </button>
                          <div>
                            <button
                              type="button"
                              className="Admin_btnn"
                              onClick={logout}
                              style={{ float: "right", marginRight: "50px" }}
                            >
                              Logout
                            </button>
                          </div>

                          {updateStatus == 1 ? (
                            <div>
                              <img
                                src={loading}
                                alt="loading"
                                style={{ width: "100%", height: "100%" }}
                              />
                            </div>
                          ) : (
                            <>{updateStatus}</>
                          )}
                        </form>
                      </div>
                    }
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default Admindashboard;
