import React,{useState,useEffect} from 'react'
import {NavLink} from "react-router-dom"
import "./dash.css"
import Axios from 'axios'
function Consult() {
    const [data,setdata]=useState([])
    const token=JSON.parse(localStorage.getItem('data'));
    const fetchinfo = async() => {
  console.log('dchcvvv')
      await  Axios.post(`${process.env.REACT_APP_BASE_URL}/getconsultant`,{
        token:token,
           }
           ).then((response)=>{
            console.log(response.data)
           
            setdata(response.data)
          
          }).catch(err => {
              console.error(err);
          
          })
  
    }
  
    useEffect(
      fetchinfo    
     ,[]
    
    );


    return (
        <>

        <p className='Dash_heading'>
          My Consultant
        </p>
{/* {(data.Enrolled_courses)?(
  
  
  
  
  <div style={{display:"flex"}}>
        <div style={{background:""}} className='Enrolled_trainingbox2'>
      <img  src={Ping} alt=""  className='Enrolled_trainingimage'/>
      <div style={{border:"",borderRadius:"20px",height:"130px",background:"white"}}>
          <h1 style={{fontSize:'24px',marginLeft:'10px',marginTop:"15px",fontFamily:"Inter"}}> Pingfederate Administrative</h1>
          <h2 style={{display:"flex", fontSize:'20px',marginLeft:"10px",marginTop:'30px',fontFamily:"Inter"}}><img src={Clock} alt="" />25hr  <h2 style={{display:"flex",float:"right",marginLeft:"160px", fontSize:'20px',marginTop:'0px',fontFamily:"Inter"}}><img src={icon13} alt="" />4.9(1k)</h2></h2>
        
      </div>
      </div>
      </div>):(
        <p style={{margin:"20px 50px",fontSize:"25px",fontFamily:"Inter",fontWeight:"500"}}>
          Curently you don't have any enrolled trining
        </p>
      )


}  */}

{/* {(data.enrolled)?
      (  Object.keys(data.enrolled).map(function(key) {
       return(
        <div style={{display:"flex"}}>
        <div style={{boxShadow: "0px 10px 100px 0px rgba(0, 0, 0, 0.05)"}} className='Enrolled_trainingbox2'>
     
      <div style={{border:"",borderRadius:"20px",height:"130px",background:"white"}}>
          <h1 style={{fontSize:'24px',marginTop:"15px",fontFamily:"Inter",textAlign:"center"}}>{data.enrolled[key]}</h1>
          <h2 style={{display:"flex", fontSize:'20px',marginLeft:"10px",marginTop:'30px',fontFamily:"Inter"}}><img src={Clock} alt="" /> <h2 style={{display:"flex",float:"right",marginLeft:"200px", fontSize:'20px',marginTop:'0px',fontFamily:"Inter",color:"rgba(37,214,225,1)"}}>View</h2></h2>
        
      </div>
      </div>
      </div>
       )
      
  })):(
    <p style={{margin:"20px 50px",fontSize:"25px",fontFamily:"Inter",fontWeight:"500"}}>
          Curently you don't have any enrolled training
        </p>
  )
    }  */}

{
  (data.length==0)?(      <div>
     <p className='Dash_heading' style={{opacity:'0.6'}}>
     You have not booked any Consultant  <NavLink to='/all-consultant' style={{ color: "rgba(37,214,225,1)",fontSize:"30px" ,fontFamily:"Inter"}}>Book Now</NavLink>
        </p>
     </div>
  ):('')
}

<div style={{display:""}}>
    {(data)?(
          data.map((course)=>(
            
            <div className='dash_usercom' >
            <h1 className='lecture_head'>{course.course_name}</h1>
            <div style={{marginLeft:"30px"}}>
            {/* <p className='Lecture_content'>Course:Pingfedrate Administrative</p> */}
            {/* <p className='Lecture_content'>Topic:Basic Certificate Management</p> */}
            {/* <p className='Lecture_content'>Date:25 July 2022</p> */}
            <p className='Lecture_content'>Scheduled Time: {course.training_start_date}</p>
            <p className='Lecture_content'>Trainer: {course.trainer}</p>
            <p className='Lecture_content'>Duration: {course.total_hours}</p>

        
            {/* <a className='Lecture_link' href="https://zoom.us/signin">LINK</a> */}
            
            
            
            </div>
            <div style={{textAlign:"center",justifyContent:"center",alignItems:"center"}}>
            <a href={course.meeting_link}>
                
                <div className='Lecture_link' >
                    <p style={{fontFamily:"Inter"}}>Start Training</p>
                </div>
            </a>
            <a href={course.recording_link}>
                <div className='Lecture_link1' >
                    <p style={{fontFamily:"Inter"}}>View Recording</p>
                </div>
            </a>
            </div>
           </div>     
      

   
      
//  <div style={{background:course.Background}}  className='trainingbox1'>
//   <img src={course.Image} alt=""  className='trainingimage'/>
//  <div style={{border:"1px solid lightgray",borderRadius:"20px",height:"200px",background:"white"}}>
//      <h1 style={{fontSize:'25px',textAlign:"center",marginTop:"15px",fontFamily:"Inter"}}>{course.course_name}
// </h1>

  



// <div style={{marginLeft:"30px"}}>
      
//        <p className='Lecture_content'>Scheduled Time: </p>
//        <p className='Lecture_content'>Trainer: Sumit</p>
   
       
       
       
//        </div>
//        <div style={{textAlign:"center",justifyContent:"center",alignItems:"center"}}>
//        <a href=''>
           
//            <div className='Lecture_linkk' >
//                <p style={{fontFamily:"Inter"}}>Start Training</p>
//            </div>
//        </a>
//        <a href=''>
//            <div className='Lecture_link1k' >
//                <p style={{fontFamily:"Inter"}}>View Recording</p>
//            </div>
//        </a>
//        </div>
//  </div>
//        </div>


     ))):('')
      } 




</div>
       
          
    </>
    )
}
export default Consult