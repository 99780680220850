import React, { useEffect, useState } from "react";
import axios from "axios";
import "./other.css";
import ReactPlayer from "react-player";
import {NavLink} from 'react-router-dom';
function Self_learning() {
  const [data, setdata] = useState([]);
  const token = JSON.parse(localStorage.getItem("profile"));
  const [url, seturl] = useState("");
  const getLectures = async () => {
    await axios
      .post(`${process.env.REACT_APP_BASE_URL}/get-recorded-lecture`, {
        token: token.access_token,
      })
      .then((response) => {
        console.log(response.data[0]);

        setdata(response.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  useEffect(() => {
    getLectures();
  }, []);
 // console.log(url);
  const UpdateVideo=(e,url)=>{
e.preventDefault();
seturl(url)
  //console.log(url);
  }
  console.log(data);
  const handleContextMenu = (e) => {
    e.preventDefault(); // Prevent the default context menu from appearing
  };
  return (
    <>
      <div>
        <h2 className="Progress_heading">Self Learning</h2>
      </div>
      <br />
      {
  (data.length==0)?(      <div>
     <p className='Dash_heading' style={{opacity:'0.6'}}>
     You have not Enrolled into Training  <NavLink to='/askmeidentity-training' style={{ color: "rgba(37,214,225,1)",fontSize:"30px" ,fontFamily:"Inter",textDecoration:"none"}}>Enroll Now</NavLink>
        </p>
     </div>
  ):(<div style={{ display: "flex", flexWrap: "wrap" ,gap:"50px",justifyContent:"center"}}>
  <div onContextMenu={handleContextMenu}>
    <ReactPlayer
      url={url}
      controls
      width="550px"
      playing={true}
      height="400px"
      config={{
        file: {
          attributes: {
            controlsList: 'nodownload', // Prevents the download option
          },
        },
      }}
    />
  </div>
  <div>
    {data?.map((element) => (
      <>
        <div  style={{ maxWidth: "400px" }}>
          <h2 className="Progress_heading" style={{fontSize:"20px"}}>
            {element.course_name}
          </h2>
          <div className="link_container" style={{ display: "flex", flexWrap: "wrap" }}>
            {element.Lecture_url.map((ele) => (
              <>
                <div >
                  <div
                    style={{
                      width: "400px",
                      textDecoration: "none",
                      display: "flex",
                      margin: "4px",
                    }}
                  >
                    <img
                      style={{ width: "70px", height: "30px" }}
                      src={element.image}
                      alt=""
                    />

                    <h5
                      className="Course_cart_head"
                      style={{ fontSize: "17px" ,width:"220px" ,paddingTop:"5px",paddingLeft:"5px"}}
                    >
                      Topic: {ele.name}
                    </h5>
                    <div className="course_link_btn"  onClick={(e) => UpdateVideo(e,ele.url)}>
                      Start
                    </div>
                  </div>

                  
                                      
                </div>
              </>
            ))}
          </div>
        </div>
      </>
    ))}
  </div>
</div>)
}
   
    </>
  );
}

export default Self_learning;
