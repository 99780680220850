import React,{useRef} from 'react';
import icoq from './Img/icoq.png'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import left from '../../src/Assets/Images/left.png'
import right from '../../src/Assets/Images/right.png'
import Casei from './Img/CaseI.png';
import ico2 from './Img/ico2.png';
import User from './Img/icons8-user-80.png'

const Case_courasel = (props) => {
    const sliderRef = useRef();

     const settings = {
    dots: true,
    button:false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Enables autoplay
    autoplaySpeed: 2000, // Sets autoplay interval to 3 seconds
    responsive: [
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
const slll=[1,3,,4,5,6]
  const slides =[
    {
      text1:"Before we used the services provided by AMI, we've always had problems in setting the network ourselves and keeping them stable even for just a month. Now, after we cooperated with AMI those problems seem so far away",
      name:'Kawaljeett Singh Hora',
      position:"Director, Panzer Technologies",
      img:Casei
    },{
      text1:"The increasing use of digital service pushed us to look for trusted network vendors that can provide reliable services with friendly prices. After using the services of Askmeldentity, we were able to impliment our IAM solutions on time.",
      name:"Amit Walia",
      position:"CEO of Informatica",
      img:ico2
    },{
      text1:"Successfully delivered Workday HR integration with Okta. Automated User onboarding process starting right at source. Advanced Lifecycle management for both way user synchronisation and provisioning.",
      name:"Mark Robinson",
      position:"CEO, TRUE LOGIC CONSULTAING",
      img:"https://jsinfos.com/wp-content/uploads/2023/10/Ellipse-4.png"
    },{
      text1:"Created business automation using Okta Workflows. Helped customers in their CIAM solution in registration, authentication and progressive profiling. Orchestrated Okta events to various 3rd party systems and APIs.",
      name:"Sukhwinder",
      position:"CEO IDENTITY HUB SOLUTIONS",
      img:User
    }
]
console.log(slides);
  return (

    <>
    
<div className='test_coresal_home' style={{position:"relative"}}>

<button className='btn_testimonial_l' ><img  onClick={() => sliderRef.current.slickPrev()} style={{backgroundColor:"#1A53DD",width:"30px",borderRadius:"50%"}} src={left} alt="" /></button>
<button className='btn_testimonial_r' onClick={() => sliderRef.current.slickNext()} ><img style={{backgroundColor:"#1A53DD",width:"30px",borderRadius:"50%"}} src={right} alt="" /></button>

    
    {
      (slides=='undefined'||slides=='')?(''):(<Slider {...settings} ref={sliderRef}>
        {
        
        slides.map((course, index) => (
        
            (course.course_id=='4521')?(''):( <>
            <div style={{width:"100%",display:"flex",justifyContent:"center",alignItems:"center",minHeight:"400px",}}>
            <div className='case_cart' style={{width:"90%",height:"100%",background:"white"}}>
                <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                <img style={{width:"150px",borderRadius:"50%"}} src={course.img} alt="" />
                </div>
                <div style={{maxWidth:"600px"}}>
                    <img style={{width:"30px"}} src={icoq} alt="" srcset="" />
                    <br />
                <p className="n_container2_text" style={{fontSize:"16px"}}><b style={{fontFamily:"Inter"}}>{course.text1}</b></p>
                <br />
                <b> <h4 className="n_container2_text"> <b  style={{color:"#1A53DD",fontFamily:"Inter"}}>{course.name}</b></h4></b>
                <h5 className="n_container2_text" style={{color:"#1A53DD"}}> <b style={{color:"#1A53DD",fontFamily:"Inter"}}>{course.position} (USA)</b> </h5>
                </div>
           
            </div>
            </div>
           
          </>
            )           
          
        )
          )}
    
  </Slider>)
    }
  

    </div>
    </> 
    
  );
};

export default Case_courasel;