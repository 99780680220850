import React, { useEffect ,useState} from 'react'
import Ping from  '../../Assets/Images/Ping.png';
import {NavLink} from 'react-router-dom'
import axios from 'axios'
function Course_cart(props) {
  const [count,setcount]=useState();


  return (
    <>
    <NavLink to={`/training?cid=${props.url}`} style={{textDecoration:'none'}}>
    <div style={{width:"230px",marginLeft:"15px",marginRight:"15px",textDecoration:"none"}}>
        <img style={{width:"200px",height:"100px",boxShadow:" rgba(149, 157, 165, 0.2) 0px 8px 24px"}} src={props.img} alt="" />
        <div style={{padding:"10px"}}>
        <h5 className='Course_cart_head'>{props.name}</h5>
        <p className='Course_cart_text'>{props.instructor}</p>
        <p style={{color:"#E59819"}}>4 ⭐⭐⭐⭐</p>
        
        </div>
        
    </div>
    </NavLink>
    </>
  )
}

export default Course_cart