import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import NewBlog from "./NewBlog";
import ViewEditBlog from "./ViewEditBlog";

import loading from "../../Images/admin-loading.gif";
import "./styles.scss";

const Blogs = () => {
  const nodeURL = process.env.REACT_APP_BASE_URL;

  const [buttonStatus, setButtonStatus] = useState(false);
  const [blogs, setBlogs] = useState([]);
  const tokenn = JSON.parse(localStorage.getItem("data"));
  const fetchAllBlogs = async () => {
    await axios
      .get(`${nodeURL}/api/blogs`)
      .then((response) => {
        console.log(response);
        setBlogs(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {});
  };

  useEffect(() => {
    fetchAllBlogs();
  }, []);

  const [newBlog, setNewBlog] = useState(false);
  const showNewBlogForm = async (event) => {
    event.preventDefault();
    console.log("add data");
    setNewBlog((current) => !current);
  };

  const [viewEditBlogId, setViewEditBlogId] = useState("");
  const viewEditBlog = async (event) => {
    const docId = event.currentTarget.className;
    let obj = blogs.find((o, i) => o.docId === docId);
    // console.log(obj, docId);
    setViewEditBlogId(obj);
    return;
    const url = `${nodeURL}/api/blog/${docId}`;
    setButtonStatus(true);
    await axios
      .get(url)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setButtonStatus(false);
      });
  };

  const deleteBlog = async (event) => {
    const docId = event.currentTarget.className;
    if (!window.confirm("This will delete blog with docId: " + docId)) {
      return;
    }
    console.log(docId);
    const url = `${nodeURL}/api/blog-delete/${docId}`;
    setButtonStatus(true);
    await axios
      .post(url,{
        token:tokenn,
      })
      .then((response) => {
        alert("blog deleted");
        fetchAllBlogs();
      })
      .catch((error) => {
        console.log("delete error");
        console.error(error);
      })
      .finally(() => {
        setButtonStatus(false);
      });
  };

  return (
    <div className="blogsContainer">
      <h3 style={{fontSize:"25px",fontFamily:"600",fontFamily:"Inter",marginBottom:"30px",marginTop:"20px"}}>Total {blogs.length} blogs</h3>
      <div className="allBlogsContainer">
        {blogs.length === 0 ? (
          <img
            src={loading}
            alt="loading"
            style={{
              marginLeft: "calc(50% - 90px)",
              width: "180px",
              height: "180px",
            }}
          />
        ) : (
          <>
            <table className="blogsList" border="0">
              <thead>
                <tr>
                  <th className="Admin_blog_Index">Index</th>
                  <th className="Admin_blog_Index">doc id</th>
                  <th className="Admin_blog_Index">blog id</th>
                  <th className="Admin_blog_Index">name</th>
                  <th className="Admin_blog_Index">View / Edit</th>
                  <th className="Admin_blog_Index">Delete</th>
                </tr>
              </thead>
              <tbody>
                {blogs.map((item, index) => (
                  <tr key={index}>
                    <td className="Admin_blog_Present">{index + 1}.</td>
                    <td className="Admin_blog_Present">{item.docId}</td>
                    <td className="Admin_blog_Present">{item.blog_id}</td>
                    <td className="Admin_blog_Present">{item.name}</td>
                    <td className="Admin_blog_Present">
                      <input
                        type="button"
                        className={item.docId}
                        value="View / Edit"
                        onClick={viewEditBlog}
                        style={{fontFamily:"Inter",fontSize:"18px"}}
                        disabled={buttonStatus}
                      />
                    </td>
                    <td className="Admin_blog_Present">
                      <input
                        type="button"
                        style={{fontFamily:"Inter",fontSize:"18px"}}
                        className={item.docId}
                        value="Delete"
                        onClick={deleteBlog}
                        disabled={buttonStatus}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </div>
      <hr />
      <div
        className="viewEditBlogContainer"
        style={{ backgroundColor: "#eee", padding: "15px", marginTop: "15px" }}
      >
        {viewEditBlogId && (
          <>
            <h3 style={{ marginTop: "20px" }}>
              Edit blog with document id: {JSON.stringify(viewEditBlogId.docId)}
            </h3>
            <ViewEditBlog blog={viewEditBlogId} />
          </>
        )}
      </div>
      <div
        style={{ backgroundColor: "#eee", padding: "20px", marginTop: "15px" }}
      >
        <input
          type="button"
          className="addNewBlog"
          style={{fontFamily:"Inter",fontSize:"18px"}}
          value="Add New Blog [Reset]"
          onClick={showNewBlogForm}
        />
        <div className="newBlogContainer">
          {newBlog && (
            <>
              <NewBlog reloadDataFunc={fetchAllBlogs} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Blogs;
