import React from 'react'
import {NavLink} from "react-router-dom"
function Home_Service(props) {
  return (
    <>
     <div className='b1'>
  <img className='b1image' src={props.gif} alt="" />
  <div className='servicecont'>
  <NavLink to={{
   pathname:`/idam?sid=${props.sid}`,
   state:{title:"hey"},
 }} >
  <h1 className='Hservice' style={{fontFamily:"Inter",color:"black",fontFamily:"Inter",fontWeight:"600"}}>{props.name}</h1>
  </NavLink>
  <p className='Home_Service_Points' style={{}}> 1.{props.p1}
</p>
<p className='Home_Service_Points' style={{}}>2. {props.p2}</p>
<p className='Home_Service_Points' style={{}}>3.{props.p3}
</p>
  </div>

  </div> 
    </>
  )
}

export default Home_Service