import React, { useState, useEffect } from "react";
import "./overview.css";
import icon1 from "./Images/Group1.svg";
import icon2 from "./Images/Group2.svg";
import Course_progress from "./Course_progress";
import Live_learning from "./Live_learning";
import Self_learning from "./Self_learning";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Navbar from '../Navbar/Navbar'
import Fotter from "../Fotter/Fotter";

function Overview() {
  const [Active, setActive] = useState("Main");
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem("profile"));
  const [data, setdata] = useState([]);
  const logout = (e) => {
    e.preventDefault();
    console.log("Make Logout");
    localStorage.clear();
    window.location.replace("/");
  };
  useEffect( () => {
    if (token == null || token == "" || token == undefined) {
      console.log("not");
      window.location.replace("/login");
      return false;
    } else {
      
       axios
        .post(`${process.env.REACT_APP_BASE_URL}/getname`, {
          token: token.access_token,
        })
        .then((response) => {
         // console.log(response.data);

          setdata(response.data);
        })
        .catch((err) => {
          console.error(err.response.data.err.message);
          if(err.response.data.err.message=="Jwt is expired"){
            localStorage.clear();
            window.location.replace("/");
          }
        });
    }
  }, []);
  console.log(data);
  const updateActive = (newCounterValue) => {
    setActive(newCounterValue);
  };
  return (
    <>
    <Navbar/>
      <div className="overview-main-container">
        <div className="overview-container">
          <div className="Side-bar">
            <div className="Overview-user">
              <div className="Short_name">
                <p style={{ color: "white", fontSize: "20px",fontFamily:"Inter" }}>{(data.name)?(<>{data.name.charAt(0)}{data.last_name.charAt(0) }</>):('Hello')}</p>
              </div>
              <p className="user_name">{data.name} {data.last_name}</p>
              <p
                style={{
                  color: "#A4A4A4",
                  fontSize: "14px",
                  fontFamily: "Inter",

                }}
              >
               {data.email}
              </p>
            </div>

            <div className="Dashboard_link_container">
              <div
                style={{ display: "flex", marginTop: "20px", height: "50px" }}
              >
                <img src={icon1} alt="" style={{ height: "25px" }} />{" "}
                <p onClick={() => setActive("Main")}  className="user_Nav_Link">
                  Dashboard
                </p>
              </div>
              <div
                style={{ display: "flex", marginTop: "20px", height: "50px" }}
              >
                <img src={icon1} alt="" style={{ height: "25px" }} />{" "}
                <p onClick={() => setActive("Live")} className="user_Nav_Link">
                  Live Courses
                </p>
              </div>
              <div
                style={{ display: "flex", marginTop: "20px", height: "50px" }}
              >
                <img src={icon1} alt="" style={{ height: "25px" }} />{" "}
                <p onClick={() => setActive("Self")} className="user_Nav_Link">
                  Self Learning
                </p>
              </div>
              <div
                style={{ display: "flex", marginTop: "20px", height: "50px" }}
              >
                <img src={icon2} alt="" style={{ height: "25px" }} />{" "}
                <p onClick={(e) => logout(e)} className="user_Nav_Link">
                  Logout
                </p>
              </div>
            </div>
          </div>
          <div className="Left_container">
            {Active === "Main" && (
              <Course_progress updateActive={updateActive} />
            )}
            {Active === "Live" && <Live_learning />}
            {Active === "Self" && <Self_learning />}

            {/* <Course_progress/>
        <Live_learning/>
        <Self_learning/> */}
          </div>
        </div>
      </div>
      <Fotter/>
    </>
  );
}

export default Overview;
