import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  getcourses,
} from "../../action/courses";
import Slider from '../../Component/Home/Slider';
import Course_Section from '../../Component/Home/Course_Section';
import Testimonials from '../../Component/Home/Testimonials';
import Second_course from '../../Component/Home/Second_course';
import { Helmet } from "react-helmet";
import Category from '../../Component/Home/Category';
import axios from 'axios'
function Home() {
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    dispatch(getcourses());
  }, [dispatch]);

  const token = JSON.parse(localStorage.getItem("profile"));

  useEffect(() => {
    if (token == null || token == "" || token == undefined) {
    return;
    }else{
      axios
      .post(`${process.env.REACT_APP_BASE_URL}/getname`, {
        token: token.access_token,
      })
      .then((response) => {
       // console.log(response.data);

      //  setdata(response.data);
      })
      .catch((err) => {
        console.error(err.response.data.err.message);
        if(err.response.data.err.message=="Jwt is expired"){
          localStorage.clear();
          window.location.replace("/");
        }
      });
    }
   
  }, [])
  

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const sectionName = urlParams.get("name");
    scrollToSection(sectionName);
  }, []);

  const scrollToSection = (sectionName) => {
    const sectionElement = document.getElementById(sectionName);
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: "smooth" });
    }
  };
  const keywords = ["Leading IAM Training Providers", "OKTA Training", "Best IAM Training", "Pingfederate Training", "Cyberark Training","SailPoint training","DevOps for Okta"]
  
  const keywordString = keywords.join(', ');
  return (
    <>
       <Helmet>
            <title>Identity and access management (IAM) Training programs| Training
      </title>
            <meta name="description" content="Elevate your expertise through our IAM training programs. Master the art of identity and access management and advance your career with our specialized courses." />
            <meta name="keywords" content={keywordString} />
          </Helmet>
<div style={{paddingLeft:"10px",paddingRight:"10px"}}>
<Slider/>
 <div  id="course">
 <Course_Section />

 </div>
 <Testimonials/>
 <Second_course/>
 <Category/>
</div>

 </>
  )
}

export default Home