import React from 'react'
import Calender from "../Images/Calendar.png";
import {NavLink} from "react-router-dom"
function Home_Blogs(props) {
  return (
    <>
          <div className='Hblog' style={{justifyContent:"center",width:"270px"}}>
  <div style={{margin:"0px 20px"}}>
      <img style={{width:"75%",borderRadius:"20px",height:"150px"}} className='Home_blog_img' src={props.img} alt="" />
      <p style={{ fontSize: "14px", marginTop: "10px",fontFamily:"Inter" }}>
                  {" "}
                  <img src={Calender} alt="" /> {props.date}{" "}
                </p>
                <p style={{fontSize:"18px",fontWeight:"600",fontFamily:"Inter"}} >{props.name}</p>

                <div style={{display:"flex"}}>
                <NavLink  to={{
   pathname:`/blog?bid=${props.bid}`,
   state:{title:"hey"},
 }} style={{fontSize:'18px',marginTop:'12px',marginRight:"10px",margin:"10px 6px",color:"grey",fontFamily:"Inter",fontWeight:"600"}}> Read More </NavLink>
<span style={{marginTop:"15px"}}><img src={props.img1} alt="" /></span>
</div>
  </div>
 
  
  </div>
    </>
  )
}

export default Home_Blogs