import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import Modal from "react-modal";
import { MdDashboard } from "react-icons/md";
import { BiFoodMenu } from "react-icons/bi";
import { ImProfile } from "react-icons/im";
import { FiSettings } from "react-icons/fi";
import { FiLogOut } from "react-icons/fi";
import { AiOutlineSearch } from "react-icons/ai";
import { FaUsers } from "react-icons/fa";
import { GiNotebook } from "react-icons/gi";
import { FaBloggerB } from "react-icons/fa";
import Main from './trainer/Main'
import Dashboard from "./dashboard";
import Courses from "./courses";
import Blogs from "./blogs";
import Logo from "../Images/Logo.png";
import Admin from "../Images/Admin.png";
import "./styles.scss";

Modal.setAppElement('#root');
const AdminPanel = () => {
    const nodeURL = process.env.REACT_APP_BASE_URL;

    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }

    // window.sessionStorage.setItem("adminLoggedIn", "invalid");
    const [adminLoggedIn, setAdminLoggedIn] = useState(window.sessionStorage.getItem("adminLoggedIn"));
    const [loginButtonDisabled, setLoginButtonDisabled] = useState(false);
    const verifyAdmin = async (event) => {
        event.preventDefault();

        const adminCreds = {
            username: document.querySelector("#adminUsername").value,
            password: document.querySelector("#adminPassword").value,
        };

        setLoginButtonDisabled(true);
        await axios.post(`${nodeURL}/api/admin-verify`, adminCreds).then((response) => {
            const data = response.data;
            console.log(data);
            if (data.message == "valid") {
                window.sessionStorage.setItem("adminLoggedIn", "valid");
                setAdminLoggedIn("valid");
                console.log(window.sessionStorage.getItem("adminLoggedIn"));
            } else {
                window.sessionStorage.setItem("adminLoggedIn", "invalid");
                setAdminLoggedIn("invalid");
                console.log(window.sessionStorage.getItem("adminLoggedIn"));
                alert("invalid admin creds");
            }
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            setLoginButtonDisabled(false);
        });
    };

    const adminLogout = (req, res) => {
        openModal();
    };

    const logoutConfirm = (req, res) => {
        setAdminLoggedIn("invalid");
        window.sessionStorage.setItem("adminLoggedIn", "invalid");
        closeModal();
    };

    const [listitem, setListItem] = useState(window.sessionStorage.getItem("clicked-listitem") || "dashboard");

    const dashboardClicked = (req, res) => {
        setListItem("dashboard");
        window.sessionStorage.setItem("clicked-listitem","dashboard");
    };
    const trainerClicked = (req, res) => {
        setListItem("trainer");
        window.sessionStorage.setItem("clicked-listitem","trainer");
    };

    const coursesClicked = (req, res) => {
        setListItem("courses");
        window.sessionStorage.setItem("clicked-listitem","courses");
    };

    const blogsClicked = (req, res) => {
        setListItem("blogs");
        window.sessionStorage.setItem("clicked-listitem","blogs");
    };

    const [dateTime, setDateTime] = useState(new Date().toString());
    useEffect(() => {
        const interval = setInterval(() => {
            setDateTime(new Date().toString());
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    const customStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "50%",
        },
    };


    return (
        <div className="adminPanelContainer">
            {
                adminLoggedIn == "invalid" ? (
                    <div className="Admin_panel">
                        <div className="AdminPanel_Left">
                            <img className="Admin_img2" src={Admin} alt="" />
                            <h1 style={{fontFamily:"Inter",color:"gray",fontSize:"30px",fontWeight:"600"}}>Hello Admin</h1>
                            <h1 style={{fontFamily:"Inter",color:"gray",fontSize:"30px",fontWeight:"600"}}>Just couple of clicks more</h1>

                        </div>
                    <div className="adminLoginArea">
                        
                        <NavLink to="/home"><h3 style={{ marginBottom: "20px" }}><img style={{width:"150px"}} src={Logo} alt="" srcset="" /></h3></NavLink>
                        <div className="Admin_panel_Right">
                           
                        <h1 style={{fontFamily:"Inter",fontSize:"30px",fontWeight:"600",marginBottom:"50px"}}>Welcome</h1>
                        <input type="text" className="Admin_in" style={{marginBottom:"5px"}} placeholder="Admin Email" id="adminUsername" />
                        <br />
                        <input type="password" className="Admin_in" style={{marginBottom:"25px"}}  placeholder="Password" id="adminPassword" />
                        <br />
                        <input type="submit"  className="loginButton"value="Login" onClick={verifyAdmin} disabled={loginButtonDisabled} />
                    
                        </div>
                    </div>
                    </div>
                ) : (
                    <div className="contentContainer">
                        <div className="sideBar">
                            <div className="logo">
                                <NavLink to="/" className="navHome"><img style={{width:"150px"}} src={Logo} alt="" srcset="" /></NavLink>
                            </div>
                            <div className="nav">
                                <div className="main">
                                    Main
                                    <ul>
                                        <li className="Admin_List" onClick={dashboardClicked}><MdDashboard className="icons" />Dashboard</li>
                                        <li className="Admin_List"><BiFoodMenu className="icons" />Logs</li>
                                        <li className="Admin_List"><ImProfile className="icons" />Profile</li>
                                        <li className="Admin_List"><FiSettings className="icons" />Settings</li>
                                        <li className="Admin_List" onClick={adminLogout}><FiLogOut className="icons" />Logout</li>
                                    </ul>
                                </div>
                                <div className="list">
                                    List
                                    <ul>
                                        <li className="Admin_List"><FaUsers className="icons" />Users</li>
                                        <li className="Admin_List" onClick={coursesClicked}><GiNotebook className="icons" />Trainings</li>
                                        <li className="Admin_List" onClick={blogsClicked}><FaBloggerB className="icons" />Blogs</li>
                                        <li className="Admin_List" onClick={trainerClicked}><FaUsers className="icons" />Trainer</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="contentSection">
                            <div className="header">
                                <div className="searchContainer">
                                    <input type="text" placeholder="Search" className="searchInput" />
                                    <AiOutlineSearch className="searchIcon" />
                                </div>
                                <div className="dateTimeContainer">{dateTime}</div>
                            </div>
                            <div className="mainContent">
                                {listitem === "dashboard" && <Dashboard />}
                                {listitem === "courses" && <Courses />}
                                {listitem === "blogs" && <Blogs />}
                                {listitem === "trainer" && <Main />}

                            </div>
                        </div>
                    </div>
                )
            }

            {/* modal logout */}
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Logout Modal"
            >
                {/* <button onClick={closeModal} style={{ float: "right", border: "none", backgroundColor: "inherit", width: "50px", height: "20px", cursor: "pointer", border: "1px solid #000", borderRadius: "3px", }}>X</button> */}
                <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Confirm logout ?</h2>
                <p style={{ marginTop: "20px" }}>
                    <input type="button" value="Logout" style={{ width: "100px" }} onClick={logoutConfirm} />
                    <input type="button" value="Cancel" style={{ width: "100px", marginLeft: "50px" }} onClick={closeModal} />
                </p>
            </Modal>
        </div>
    )
}

export default AdminPanel

