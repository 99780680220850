import React,{useEffect,useState} from 'react'
import "./Webinar.css";
import Navbar from '../Navbar/Navbar';
import Flippy, { FrontSide, BackSide } from 'react-flippy';
import { NavLink } from 'react-router-dom';
import Img from './images/Okta wprkflow.png';
import AOS from 'aos';
import Axios from 'axios';
import banner from './images/baneer.png'
import Footer from '../Fotter/Fotter';
function MainWebinar() {
    const [data, setdata] = useState([]);

    const FetchData = async () => {
      await Axios.get(
        `http://localhost:8080/webinar`
      )
        .then((response) => {
          console.log(response.data, "hjgyj");
          setdata(response.data);
        })
        .catch((err) => {
          console.error(err);
        });
    };

    useEffect(()=>{
     FetchData();
    },[])
    useEffect(()=>{
        AOS.init({duration:2000})
      },[]);
  return (
    <>
    <Navbar/>
    <div className='webinar_headerMain'>
        <div className='webinar_headContainer'>
    <h1 className='webinar_head_text'>Webinar and Events</h1>
    <p style={{color:"white",fontFamily:"Inter",fontSize:"16px"}}>Navigating the Future: Join AskMeIdentity's Webinar on Identity and Access Management for a Safer and Smarter Digital Landscape</p>
        </div>
    </div>
    <div style={{marginTop:"50px"}}>
        <h1 style={{fontFamily:"Inter",fontSize:"35px",textAlign:"center",fontWeight:"600"}}>Webinar</h1>
        <p style={{fontFamily:"Inter",fontSize:"16px",textAlign:"center",color:"rgb(84, 180, 38)"}}>AskMeIdentity's IAM Webinar - Empowering Secure Access in the Age of Information.</p>
    </div>
    <br /><br />
<div style={{display:"flex",justifyContent:"center",width:"100%"}}>
    <div style={{display:"flex",gap:"20px",flexWrap:"wrap",width:"80%",justifyContent:"center"}}>
        {
            data?.map((element)=>(
                <Flippy data-aos="fade-right"  flipOnHover={true} flipDirection="horizontal" className='flip_card' style={{}} >
                <FrontSide style={{borderRadius:"20px",position:"relative"}}>
                
                                <img style={{ height: "20px" ,float:"right"}} src='https://assets-global.website-files.com/62cd14d1378c5d4fb293537e/62ea58573733b4ad59cc1481_turn-icon.svg' alt="" />
                                <img className="flip_img" style={{width:"150px",marginTop:"20px",marginLeft:"20px",height:"130px"}} src={element.img} alt="" />
                                <h2
                                  className="n_container2_text"
                                  style={{
                                    position:"absolute",
                                    bottom:"15px",
                                    left:"10px",
                                    fontWeight: "600",
                                   
                                    fontSize: "17px",
                                    textAlign:"center"
                                  }}
                                >
                                  {element.name}
                                </h2>
                    </FrontSide>
                    <BackSide  className="Back_flip">
                    <div className="Back_flip">
                      <div className="Back_flip_container" style={{position:"relative",height:"95%"}}>
                    <p style={{ fontFamily: "Inter", fontSize: "17px" ,position:"absolute",bottom:"30px"}}>
                              date: <b style={{fontFamily:"Inter"}}>{element.date}</b>
                            </p>
                            <p style={{ fontFamily: "Inter", fontSize: "17px" ,position:"absolute",bottom:"50px"}}>
                              Time: <b style={{fontFamily:"Inter"}}>{element.time}</b>
                            </p>
                            <p style={{ fontFamily: "Inter", fontSize: "15px" ,position:"absolute",bottom:"90px"}}>
                                {element.info}
    
                                </p>
                            <a style={{fontFamily:"Inter",fontSize:"18px",fontWeight:"600",textDecoration:"none",color:"white",position:"absolute",bottom:"0",right:"10px",cursor:"pointer"}} href={element.url}>View </a>
                            </div>
                      </div>
                    </BackSide>
                </Flippy>
            ))
        }
   
    </div>
    </div>
    <br />
    <div style={{width:"100%",display:"flex",justifyContent:"center",marginTop:"70px"}}>
        <div style={{width:"75%"}}>
<img src={banner} className='vert-move' style={{width:"100%",height:"75%",borderRadius:"20px"}} alt="" />
        </div>
    </div>
    <Footer/>
    </>
  )
}

export default MainWebinar