import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Course_cart from "./course_cart";
import left from "../../Assets/Images/left.png";
import right from "../../Assets/Images/right.png";
import msg from "../../Assets/Images/comma.svg";
function Testimonials() {
  const sliderRef = useRef();

  const settings = {
    dots: false,
    button: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true, // Enables autoplay
    autoplaySpeed: 2000, // Sets autoplay interval to 3 seconds
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };     

  const slides = [
    {
      text1:
        "Mr.Ravi is outstanding trainer, thanks so much for your very valuable training.i really enjoyed it and apprecited that you made it Fun i feel much better prepared to deal with uncomfortable issues. ",
      head1: "AT",
      place1: "Abhijit Tejaswi kotra,",
    },
    {
      text1:
        "Getting trained by ravi and sumit was amazing they have helped us to get into the nitty grittes of access management and have helped in developing critical thinking skills while working on these applications",
      head1: "MV",
      place1: "M Venkatesh",
    },
    {
      text1:
        "I don't have any about to describe the trainers because the fabulous especially Ravi thank you Ravi for being our trainer and thank you abhi ,Anisha Khulood and sumit for your support 🖤",
      head1: "VP",
      place1: "Valli parabathni",
    },
    {
      text1:
        "Actually i started from zero with help trainer RAVI now i can handle both OKTA and Ping Federate equally Thank you for your wisdom, commitment, experience and personable presentation skills.",
      head1: "BR",
      place1: "Pradeep Nagadasari",
    },
    {
      text1:
        "Trainers are good .they explained with quantity of subject information is very well. Thank you toall trainers. I am satisfied throughout this training classes.I learnt good knowledge in this training. ",
      head1: "J",
      place1: "Jayanth",
    },
    {
      text1:
        "Training is productive, but slightly slower pacing would enhance comprehension. Trainers excel in expertise and explanations, making the experience enjoyable.",
      head1: "AP",
      place1: "Abhishek Pandey",
    },
  ];
  return (
    <>
      <div
        style={{
          backgroundColor: "#F7F9FA",
          paddingBottom: "30px",
          paddingTop: "30px",
          marginTop: "50px",
        }}
      >
        <div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              style={{
                maxWidth: "1250px",
                width: "100%",
                paddingLeft: "20px",
                paddingRight: "20px",
              }}
            >
              <h style={{fontSize:"30px",fontWeight:"600",fontFamily:"Inter"}}>How learners like you are achieving their goals</h>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              className="testimonial_coresal_home"
              style={{ position: "relative" }}
            >
              <button className="btn_testimonial_l">
                <img
                  onClick={() => sliderRef.current.slickPrev()}
                  style={{
                    backgroundColor: "#1A53DD",
                    width: "30px",
                    borderRadius: "50%",
                  }}
                  src={left}
                  alt=""
                />
              </button>
              <button
                className="btn_testimonial_r"
                onClick={() => sliderRef.current.slickNext()}
              >
                <img
                  style={{
                    backgroundColor: "#1A53DD",
                    width: "30px",
                    borderRadius: "50%",
                  }}
                  src={right}
                  alt=""
                />
              </button>

              <Slider {...settings} ref={sliderRef}>
                {slides.map((ell) => (
                  <>
                    <div
                      className="testimonials_carts"
                      style={{ height: "310px" }}
                    >
                      <img style={{ width: "30px"}} src={msg} alt="" />
                      <p style={{fontSize:"16px",fontFamily:"Inter"}}>{ell.text1}</p>
                      <div style={{ display: "flex" }}>
                        <div
                          style={{
                            width: "30px",
                            height: "30px",
                            backgroundColor: "black",
                            display: "flex",
                            justifyContent: "center",
                            borderRadius: "50%",
                          }}
                        >
                          <h4 style={{ color: "white", paddingTop: "5px",fontSize:"16px",fontFamily:"Inter" }}>
                            {ell.head1}
                          </h4>
                        </div>
                        <h5 style={{ paddingTop: "5px",fontSize:"16px", paddingLeft: "10px",fontFamily:"Inter" }}>
                          {ell.place1}
                        </h5>
                      </div>
                      <hr />
                      <h2 style={{ color: "#1A53DD",fontFamily:"Inter",fontSize:"16px" }}>
                        Become a IAM Expert | Learn the Skills & Get the
                        Job
                      </h2>
                    </div>
                  </>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Testimonials;
