import React,{useState,useEffect} from "react";
import Bar2 from "../Images/bar2.png";
import db from "../../firebase";
import { useSearchParams } from "react-router-dom";
import Calender from "../Images/Calendar.png";
import Navigation from "../Images/navigation.png";
import Bar from "../Images/Bar.png";
import Axios from 'axios'
import Arrow from "../Images/Arrow.png";

import { NavLink } from "react-router-dom";
import Nav from "../../Components/Nav/Navbar";
import Footer from "../../src/Component/Fotter/Fotter";
import Back from "../Images/Back.png";
import "./mainblog.css";
import Navbar from "../header/Header";
function Blog1() {

  const [searchParams, setSearchParams] = useSearchParams();
  const [data,setdata] =useState([]);
  const[ref_data,setref_data]=useState([])
  const bid= searchParams.get("bid");
  console.log(bid)

  const fetchblog = async() => {
    await  Axios.get(`${process.env.REACT_APP_BASE_URL}/blog_detail?id=${bid}`,{
 
    }
    ).then((response)=>{
   

     setdata(response.data)
   
   }).catch(err => {
       console.error(err);
   
   })

await  Axios.get(`${process.env.REACT_APP_BASE_URL}/Related_blogs`).then((response)=>{
  
console.log(response.data);
  setref_data(response.data)

}).catch(err => {
    console.error(err)
  }) 
}

console.log(data)

useEffect(
  fetchblog
 ,[]);
useEffect(() => {
  window.scrollTo(0, 0);
}, []);
const fetchrelblog = async(get) => {
await  Axios.get(`${process.env.REACT_APP_BASE_URL}/blog_detail?id=${get}`,{
 }
  ).then((response)=>{
   setdata(response.data)
 }).catch(err => {
     console.error(err);
 })

}


  return (
    <>
      <Navbar />
      <div
        style={{
          height: "200px",
          display: "flex",marginTop:"80px",
          backgroundImage: `url(${Back})`,
        }}
      >
        <p
          style={{
            marginLeft: "20%",
            marginTop: "80px",
            fontSize: "40px",
            fontWeight: "700",
            color: "#3C3C3B",
            fontFamily:'Inter'
          }}
        >
          Blog
        </p>
      </div>
      <div className="maincomp">
        <div className="training">
          <div className="blogcontt">
            <img className="blogimgg" src={data.image_url} alt="" />
            <p
              style={{
                fontSize: "15px",
                marginTop: "30px",
                marginLeft: "20px",
              }}
            >
              {" "}
              <img src={Calender} alt="" /> {data.blog_date}{" "}
            </p>
            <div className="blogstory">
              <h1
                style={{
                  fontSize: "27px",
                  marginLeft: "20px",
                  fontWeight:"500",
                  marginTop: "30px",
                  marginRight: "20px",
                  fontFamily:"Inter"
                }}
              >
                {data.blog_category}
              </h1>

              <p
                style={{
                  fontSize: "20px",
                  marginTop: "10px",
                  marginBottom: "50px",
                  opacity: "0.8",
                  fontFamily:"Inter",
                  marginLeft: "20px",
                  marginRight: "20px",
                }}
              > {data.blog_heading}</p>

              {(data.blog_content)?
      (  Object.keys(data.blog_content).map(function(key) {
       return  <p
                style={{
                  fontSize: "20px",
                  marginTop: "10px",
                  marginBottom: "50px",
                  opacity: "0.8",
                  fontFamily:"Inter",
                  marginLeft: "20px",
                  marginRight: "20px",
                }}
              >{data.blog_content[key]} </p>
      
  })):('')
    }

<div>
      <div style={{fontFamily:"Inter"}} dangerouslySetInnerHTML={{ __html: data.content }}></div>
    </div>



              <p
                style={{
                  fontSize: "27px",
                  marginLeft: "20px",
                  fontWeight:"500",
                  marginTop: "30px",
                  marginRight: "20px",
                  fontFamily:"Inter"
                }}
              >
               {data.about_blog}
              </p>
              <h1
                style={{
                  fontSize: "25px",
                  marginLeft: "20px",
                  marginTop: "15px",
                  color: "rgba(37,214,225,1)",
                  fontFamily:"Inter"
                }}

              >
                {" "}
                <img src={Bar} alt="" /> {data.name}
              </h1>
            </div>
            <hr className="hrsize" style={{ color: "gray", marginTop: "20px" }} />
            <div style={{ display: "flex" }}>
              <p
                style={{
                  fontSize: "20px",
                  color: "black",
                  opacity: "0.9",
                  margin: "50px 30px",
                }}
              >
                Tags :
              </p>
              <button
                style={{
                  fontSize: "18px",
                  color: "black",
                  margin: "40px 20px",
                  background: "white",
                  padding: "10px 18px",
                  border: "1px solid black",
                  borderRadius: "9px",
                }}
              >
                Bussines
              </button>
              <button
                style={{
                  fontSize: "18px",
                  color: "black",
                  margin: "40px 10px",
                  background: "white",
                  padding: "10px 18px",
                  border: "1px solid black",
                  borderRadius: "9px",
                }}
              >
                Insights
              </button>
              <button
                style={{
                  fontSize: "18px",
                  color: "black",
                  margin: "40px 10px",
                  background: "white",
                  padding: "10px 18px",
                  border: "1px solid black",
                  borderRadius: "9px",
                }}
              >
                Case Study
              </button>
            </div>
          </div>
        </div>

        <div className="traininginfoo">
          {/* <div style={{ display: "flex" }}>
            <input
              type="text"
              className="inp"
              name=""
              placeholder="Search..."
              id=""
            />
            <img src={Search} alt="" />
            <button
              style={{
                marginTop: "100px",
                width: "60px",
                background: "rgba(37,214,225,1)",
                border: "1px solid white",
                borderRadius: "7px",
              }}
            >
              <img style={{ marginTop: "4px" }} src={Search} alt="" />
            </button>
          </div> */}
          <div className="about-blog">
            <p style={{ fontSize: "25px", fontWeight: "bold" }}>
              {" "}
              <img src={Bar2} alt="" /> Categories
            </p>

            <p style={{ fontSize: "20px", marginTop: "20px" }}>
              {" "}
              <img src={Arrow} alt="" />{" "}
              <span style={{ opacity: "0.6" }}> Security(11)</span>
            </p>
            
          </div>
         
        </div>
      </div>
      <div className="Related">
        <h1 style={{ color: "black", opacity: "0.8" }}>Our Posts</h1>
        <h1
          style={{
            fontSize: "50px",
            color: "rgba(37,214,225,1)",
            marginTop: "20px",
          }}
        >
          Related Blogs
        </h1>
       
        <div className="Related_Blogs">
       

          {
          ref_data.map((blog)=>(       
          //  <Blog_  img={blog.image_url} date={blog.blog_date} head={blog.blog_category} subhead={blog.blog_heading} bid={blog.blog_id} />

          <NavLink to='' onClick={() => fetchrelblog(blog.blog_id)}>

          <p style={{ marginTop: "30px",marginBottom:'50px', display: "flex", marginLeft: "40px",color:"black",fontFamily:"Inter" }}>
            <img className="imgtt" src={blog.image_url} alt="" />
            <span
              style={{
                marginLeft: "15px",
                fontSize: "17px",
                fontWeight: "bold",
                color:"black",
                fontFamily:"Inter"
              }}
            >
             
              {blog.blog_category}
              <p style={{ fontSize: "15px", marginTop: "10px" }}>
                
                <img src={Calender} alt="" /> {blog.blog_date}
              </p>
            </span>
          </p>
          </NavLink>
     ))
      }

        </div>
      </div>
      {/* <div className="comment">
        <div className="com_section">
          <h1 style={{ fontSize: "25px" }}>Comments</h1>
          <div style={{ display: "inline" }}>
            <p style={{ display: "flex", marginLeft: "10px" }}>
              <img className="imgtt" src={Rect2} alt="" />{" "}
              <span
                style={{
                  marginLeft: "15px",
                  fontSize: "18px",
                  fontWeight: "600",
                }}
              >
                {" "}
                Sheri Marvin{" "}
                <p
                  style={{
                    fontSize: "15px",
                    marginTop: "10px",
                    opacity: "0.8",
                    fontWeight: "400",
                  }}
                >
                  {" "}
                  Lorem ipsum dolor sit amet.{" "}
                </p>
              </span>
            </p>
            <button
              style={{
                float: "right",
                background: "white",
                fontSize: "20px",
                fontWeight: "400",
                marginRight: "200px",
                border: "1px solid white",
              }}
            >
              {" "}
              <img src={Navigation} alt="" /> Reply{" "}
            </button>

            <hr
            className="hrsize"
           
            />
            <p style={{ display: "flex", marginLeft: "10px" }}>
              <img className="imgtt" src={Rect2} alt="" />{" "}
              <span
                style={{
                  marginLeft: "15px",
                  fontSize: "18px",
                  fontWeight: "600",
                }}
              >
                {" "}
                Sheri Marvin{" "}
                <p
                  style={{
                    fontSize: "15px",
                    marginTop: "10px",
                    opacity: "0.8",
                    fontWeight: "400",
                  }}
                >
                  {" "}
                  Lorem ipsum dolor sit amet.{" "}
                </p>
              </span>
            </p>
            <button
              style={{
                float: "right",
                background: "white",
                fontSize: "20px",
                fontWeight: "400",
                marginRight: "200px",
                border: "1px solid white",
              }}
            >
              {" "}
              <img src={Navigation} alt="" /> Reply{" "}
            </button>

            <hr
             className="hrsize"
            />
            <p style={{ display: "flex", marginLeft: "10px" }}>
              <img className="imgtt" src={Rect2} alt="" />{" "}
              <span
                style={{
                  marginLeft: "15px",
                  fontSize: "18px",
                  fontWeight: "600",
                }}
              >
                {" "}
                Sheri Marvin{" "}
                <p
                  style={{
                    fontSize: "15px",
                    marginTop: "10px",
                    opacity: "0.8",
                    fontWeight: "400",
                  }}
                >
                  {" "}
                  Lorem ipsum dolor sit amet.{" "}
                </p>
              </span>
            </p>
            <button
              style={{
                float: "right",
                background: "white",
                fontSize: "20px",
                fontWeight: "400",
                marginRight: "200px",
                border: "1px solid white",
              }}
            >
              {" "}
              <img src={Navigation} alt="" /> Reply{" "}
            </button>

            <hr
               className="hrsize"
            />
          </div>
        </div>
        <div className="comment_Form">
          <h1 style={{ fontSize: "18px", opacity: "0.8" }}>Write Comments</h1>
          <h1 style={{ fontSize: "50px", color: "rgba(37,214,225,1)" }}>
            Leave A Reply
          </h1>
          <input className="inpt" type="text" placeholder="Name" />
          <input className="inpt" type="text" placeholder="Phone Number" />
          <input className="inpt" type="Email" placeholder="Email" />
          <input
            style={{ height: "100px", marginBottom: "20px" }}
            className="inpt"
            type="text"
            placeholder="Message"
          />
          <br />
          <button
            className="contactbtn"
            style={{
              margin: "50px 0px",
              height: "50px",
              fontSize: "18px",
              borderRadius: "10px",
              boxShadow: "0px 10px 100px 0px rgba(0, 0, 0, 0.05)",
            }}
          >
            Post Now
          </button>
        </div>
      </div> */}
      <Footer />
    </>
  );
}

export default Blog1;
