import React from "react";
import { useState,useEffect } from "react";
import axios from "axios";
import './trainer.css'

function TrainerLecture(props) {
    const nodeURL = process.env.REACT_APP_BASE_URL;
    const token=JSON.parse(localStorage.getItem('data'));
console.log(token);
const [t_name,setT_name]=useState('')
    const fetchAllTrainer = async () => {
        await axios.get(`${nodeURL}/api/get_trainer`).then((response) => {
            console.log(response);
            setT_name(response.data);
            console.log(props.name);
        }).catch((error) => {
            console.log(error);
        }).finally(() => { });
    };
    
    useEffect(() => {
        calltrainer();
    }, []);
    const[T_detail,setT_detail]=useState('')

    const calltrainer=async(namee,idd)=>{


        await axios.post(`${nodeURL}/api/check_trainer_auth1`,{token:token}).then((res)=>{
             axios.get(`${nodeURL}/api/get_trainer_detail?id=${res.data}`).then((response) => {
                setT_detail(response.data);
          }).catch((err)=>{
              console.log(err);
          })
        }).catch((err)=>{
            console.log(err);
        })
       


        
    
    }
    console.log(T_detail);

    return(
        <>
     <p className='Dash_heading'> My Lecture</p>
     <div className="Trainer_Main_comp">
         {/* <div className="Trainer_name_comp">
            <h1 className="Trainer_name_head">Select trainer</h1>
        <ul>
            {
                (t_name=='')?(''):(
                   <div>
                    {
                        t_name.map((detail) => (
                            <>
                            <li className="Trainer_name_select" onClick={(e)=>calltrainer(detail.name,detail.doc_id)}>{detail.name}</li>
                            
                            </>

                        ))
                    }
                   </div>
                )
            }
            
            
        </ul>

        </div>  */}
        <div className="Trainer_detail_comp">
        {
            (T_detail=='')?(''):(

            <div className="Trainer_detail_cad">
                <h1 className="Trainer_detail_cad_head">{T_detail.name} Lecture</h1>
                <p className="Trainer_detail_cad_detail">Course: <span className="Trainer_detail_cad__name" >{T_detail.course}</span></p>
                <p className="Trainer_detail_cad_detail">Date: <span className="Trainer_detail_cad__name">{T_detail.date}</span></p>
                <p className="Trainer_detail_cad_detail">Time: <span className="Trainer_detail_cad__name">{T_detail.time}</span></p>
                <p className="Trainer_detail_cad_detail">Student Name: <span className="Trainer_detail_cad__name">{T_detail.student_name}</span></p>
                <a className="Trainer_detail_cad__url" href={T_detail.meeting_url}>Start Lecture</a>
            </div>
            
            )
        }
                </div>

        
     </div>

        </>
    )
}
export default TrainerLecture