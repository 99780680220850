import React, { useEffect, useState } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
function Live_learning() {
    const[data,setdata]=useState([])
    const token=JSON.parse(localStorage.getItem('profile'));
    const fetchinfo = async() => {
 
        await  axios.post(`${process.env.REACT_APP_BASE_URL}/getLink`,{
          token:token.access_token,
             }
             ).then((response)=>{
              console.log(response.data)
             
              setdata(response.data)
            
            }).catch(err => {
                console.error(err);
            
            })
    
      }
    
   
      useEffect(() => {
        fetchinfo();
      }, [])
   
   

  return (
    <>
      <div>
        <h2 className="Progress_heading">Live Learning</h2>
      </div>
      <br />
      {
  (data.length==0)?(      <div>
     <p className='Dash_heading' style={{opacity:'0.6'}}>
     You have not Enrolled into Training  <NavLink to='/askmeidentity-training' style={{ color: "rgba(37,214,225,1)",fontSize:"30px" ,fontFamily:"Inter",textDecoration:"none"}}>Enroll Now</NavLink>
        </p>
     </div>
  ):('')
}

<div style={{display:"flex",flexWrap:"wrap"}}>
{
          data?.map((course)=>(  
            <div className='dash_usercom' style={{backgroundColor:"#E8F4FF",maxWidth:"100%"}}>
            <h1 className='lecture_head'>{course.course_name}</h1>
            <div style={{marginLeft:"30px"}}>
          
            <p className='Lecture_content'>Scheduled Time: {course.time}</p>
            <p className='Lecture_content'>Trainer: {course.trainer}</p>
        
            
            
            
            </div>
            <div style={{textAlign:"center",justifyContent:"center",alignItems:"center"}}>
            <a href={course.meeting_link}>
                
                <div className='Lecture_link' >
                    <p style={{fontFamily:"Inter"}}>Start Training</p>
                </div>
            </a>
            <a href={course.recording_link}>
                <div className='Lecture_link1' >
                    <p style={{fontFamily:"Inter"}}>View Recording</p>
                </div>
            </a>
            </div>
           </div>     
      
     ))
      }

  
</div>
 
    </>
  );
}

export default Live_learning;
