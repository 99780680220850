// For Firebase JS SDK v7.20.0 and later, measurementId is optional
import firebase from 'firebase/compat/app';
import  'firebase/compat/auth';
import 'firebase/compat/analytics'
import 'firebase/compat/firestore';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAVSk62lnyPHsSEWdWLs8BnGwmWEGaOZGk",
    authDomain: "askmeidentity-website.firebaseapp.com",
    projectId: "askmeidentity-website",
    storageBucket: "askmeidentity-website.appspot.com",
    messagingSenderId: "987511814910",
    appId: "1:987511814910:web:d4794b0c6debd91a0203af",
    measurementId: "G-8L7R5R0VXJ"
  };

   
const firebaseApp=firebase.initializeApp(firebaseConfig);
 const db=firebase.firestore();
 export const auth =firebaseApp.auth();


export default db;

