import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function MyVerticallyCenteredModal(props) {
  const extractFirstLetters=(inputString)=>{
    const words = inputString.split(' ');

    // Extract the first letter of the first two words
    const firstLetter1 = (words[0] && words[0].length > 0) ? words[0][0] : '';
    const firstLetter2 = (words[1] && words[1].length > 0) ? words[1][0] : '';

return firstLetter1+""+firstLetter2;
  }
  function getRandomColor() {
    const colorCodes = [
        "#FF5733", "#33FF57", "#5733FF", "#FF3366", "#33FFFF",
        "#FFD700", "#8A2BE2", "#7CFC00", "#FF1493", "#00BFFF"
    ];

    // Generate a random index to pick a color
    const randomIndex = Math.floor(Math.random() * colorCodes.length);

    // Return the randomly selected color code
    return colorCodes[randomIndex];
}
  
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         <h1 style={{fontFamily:"Inter",fontWeight:"600"}}>Candidate Rating</h1> 
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
       <div style={{height:"60vh",overflow:"scroll",scrollbarWidth:"thin",overflowY:"auto",overflowX:"none"}}>
        {
props?.data?.map((element)=>(
  <div style={{display:"flex",gap:"15px",marginTop:"20px"}}>
  <div style={{width:"50px",height:"50px",borderRadius:"50%",backgroundColor:getRandomColor(),display:"flex",justifyContent:"center",alignItems:"center"}}>
  <h1 style={{fontFamily:"Inter",color:"white"}}>{extractFirstLetters(element.name)}</h1>  
  </div>
  <div>
     <h2  style={{fontFamily:"Inter",paddingTop:"5px"}}>{element.name}</h2>
     <h3>{element.rating}</h3>
  </div>

</div>
))
        }
       
       </div>

      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
export default MyVerticallyCenteredModal;
