import React, { useState, useEffect } from "react";
import "./trainer.css";
import Swal from "sweetalert2";
import axios from "axios";
const Main = () => {
  const [course, setCourse] = useState("");
  const [name, setname] = useState("");

  const [time, setTime] = useState("");

  const [date, setDate] = useState("");
  const [t_name, setT_name] = useState("");
  const [comment, setComment] = useState("");
  const [url, setUrl] = useState("");
  const [r_url, setR_url] = useState("");
  const [s_name, setS_name] = useState("");
  const [doc_id, setDoc_id] = useState("");
  const nodeURL = process.env.REACT_APP_BASE_URL;
  const tokenn = JSON.parse(localStorage.getItem("data"));

  const fetchAllTrainer = async () => {
    await axios
      .get(`${nodeURL}/api/get_trainer`)
      .then((response) => {
        console.log(response);
        setT_name(response.data);
        // console.log(t_name);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {});
  };

  useEffect(() => {
    fetchAllTrainer();
  }, []);
  const [trainerName,settrainerName]=useState('')
  const[trainerEmail,settrainerEmail]=useState('')
const makeTrainer= async (e)=>{
  console.log(trainerName        ,trainerEmail)
  e.preventDefault();
  await axios.post(`${nodeURL}/api/make_tainer`,{name:trainerName,email:trainerEmail}).then((res)=>{
    console.log('done'  )
    console.log(res.data);
    if(res.data =='error'){
      Swal.fire({
        title: "Oops..",
        text: "Please enter correct email",
        icon: "error",
        showCancelButton: true,
      })
      
    }else{
      Swal.fire({
        title: "Success",
        text: "Trainer added Successfully",
        icon: "success",
        showCancelButton: true,
      });
    }
  }).then(()=>{
    settrainerName('')
    settrainerName('')
    

  }).catch((err)=>{
    console.log(err);
    Swal.fire({
      title: "Error",
      text: "Please enter correct email",
      icon: "error",
      showCancelButton: true,
    });
  })
}
  const setTrainer = async (e) => {
    e.preventDefault();
    const trainer_info = {
      course: course,
      name: name,
      time: time,
      doc_id: doc_id,
      date: date,
      comment: comment,
      student_name: s_name,
      meeting_url: url,
      recorded_url: r_url,
    };
    //  console.log(trainer_info)
    await axios
      .post(`${nodeURL}/api/add_trainer`, { trainer_info, token: tokenn })
      .then((response) => {
        Swal.fire({
          title: "Success",
          text: "Trainer is set for the Student",
          icon: "success",
          showCancelButton: true,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const calltrainer = async (namee, idd) => {
    setname(namee);
    //console.log(namee,  idd)
    setDoc_id(idd);
    // setCourse('')
    setComment("");
    setCourse("");
    setS_name("");
    setDate("");
    setR_url("");
    setUrl("");
    setTime("");
  };
  return (
    <>
      <h1 style={{ fontFamily: "Inter" }}>Hey Admin</h1>
      <div className="Admin_Trainer">
        <div className="Admin_Trainer_left">
          <h1 className="Trainer_name_head" style={{ marginTop: "20px" }}>
            Trainers
          </h1>
          <ul>
            {t_name == "" ? (
              ""
            ) : (
              <div>
                {t_name.map((detail) => (
                  <li
                    className="Trainer_name_select"
                    onClick={(e) => calltrainer(detail.name, detail.doc_id)}
                  >
                    {detail.name}
                  </li>
                ))}
              </div>
            )}
          </ul>
        </div>
        <div className="Admin_Trainer_right">
          <h1 className="Trainer_name_head">Trainer:{name}</h1>
          <div>
            <div>
              <label htmlFor="course_name" className="Admin_trainer_label">
                Course:
              </label>
              <input
                type="text"
                placeholder="course name"
                className="Admin_trainer_input"
                onChange={(e) => setCourse(e.target.value)}
                value={course}
              />
            </div>
            <div>
              <label htmlFor="course_name" className="Admin_trainer_label">
                Time:
              </label>
              <input
                type="text"
                placeholder="time"
                className="Admin_trainer_input"
                onChange={(e) => setTime(e.target.value)}
                value={time}
              />
            </div>
            <div>
              <label htmlFor="course_name" className="Admin_trainer_label">
                Date:
              </label>
              <input
                type="text"
                placeholder="date"
                className="Admin_trainer_input"
                onChange={(e) => setDate(e.target.value)}
                value={date}
              />
            </div>
            <div>
              <label htmlFor="course_name" className="Admin_trainer_label">
                Comment:
              </label>
              <input
                type="text"
                placeholder="comment"
                className="Admin_trainer_input"
                onChange={(e) => setComment(e.target.value)}
                value={comment}
              />
            </div>
            <div>
              <label htmlFor="course_name" className="Admin_trainer_label">
                Student Name:
              </label>
              <input
                type="text"
                placeholder="mention student name"
                className="Admin_trainer_input"
                onChange={(e) => setS_name(e.target.value)}
                value={s_name}
              />
            </div>
            <div>
              <label htmlFor="course_name" className="Admin_trainer_label">
                Meeting link:
              </label>
              <input
                type="text"
                placeholder="meeting url"
                className="Admin_trainer_input"
                onChange={(e) => setUrl(e.target.value)}
                value={url}
              />
            </div>
            <div>
              <label htmlFor="course_name" className="Admin_trainer_label">
                Recoreded link:
              </label>
              <input
                type="text"
                placeholder="recorded url"
                className="Admin_trainer_input"
                onChange={(e) => setR_url(e.target.value)}
                value={r_url}
              />
            </div>
          </div>
          <div>
            <button className="Admin_submit_btn" onClick={(e) => setTrainer(e)}>
              Submit
            </button>
          </div>
        </div>
      </div>
      <div className="MAke_trainer">
        <h1 className="Trainer_name_head">Add trainer</h1>
        <input
                type="text"
                placeholder="Name"
                className="Admin_trainer_input"
                onChange={(e) => settrainerName(e.target.value)}
               
              />
              <br />
               <input
                type="email"
                placeholder="Email"
                className="Admin_trainer_input"
                onChange={(e) => settrainerEmail(e.target.value)}
               
              />

            <button className="Trainer_submit_btn" onClick={(e) => makeTrainer(e)}>
              Add
            </button>
      </div>
    </>
  );
};
export default Main;
