import React, { useState } from 'react';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import './Navbar.css';
import Dropdown from './Dropdown';
import Logo from "../../Components/Images/Logo.png"
import Dropservice from './Dropservice';
import Navsocial from './Navsocial';

function Navbar() {
  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [dropdownn, setDropdownn] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const onMouseEnterr = () => {
    if (window.innerWidth < 960) {
      setDropdownn(false);
    } else {
      setDropdownn(true);
    }
  };

  const onMouseLeavee = () => {
    if (window.innerWidth < 960) {
      setDropdownn(false);
    } else {
      setDropdownn(false);
    }
  };

  const onMouseEnter = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
  };

  const onMouseLeave = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(false);
    }
  };


  const token=JSON.parse(localStorage.getItem('data'));

  

  return (
    <>
    <Navsocial/>
    <hr style={{color:"gray",opacity:"0.8",margin:"0px 0px"}} />
      <nav className='navbar' >
        <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
          <img src={Logo} alt="" style={{height:"70px"}} />
          <i class='fab fa-firstdraft' />
        </Link>
        <div className='menu-icon' onClick={handleClick}>
          <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
        </div>
        <ul className={click ? 'nav-menu active' : 'nav-menu'}>
          <li className='nav-item'>
            <Link to='/home' className='nav-links' onClick={closeMobileMenu}>
              HOME
            </Link>
          </li>
          {/* <li
            className='nav-item'
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            <Link
              to={{
   pathname:`/idam?sid=${'1'}`,
   state:{title:"hey"},
 }}             
              className='nav-links'
              onClick={closeMobileMenu}
            >
              SERVICES <i className='fas fa-caret-down' />
            </Link>
            {dropdown && <Dropservice />}
          </li>
          
          <li
            className='nav-item'
            onMouseEnter={onMouseEnterr}
            onMouseLeave={onMouseLeavee}
          >
            <Link
              to='/maintraining'
              className='nav-links'
              onClick={closeMobileMenu}
            >
              TRAINING <i className='fas fa-caret-down' />
            </Link>
            {dropdownn && <Dropdown />}
          </li> */}
          {/* <li className='nav-item'>
            <Link
              to='/'
              className='nav-links'
              onClick={closeMobileMenu}
            >
              TRAINING
            </Link>
          </li> */}

          <li className='nav-item'>
            <Link
              to={{
   pathname:`/idam?sid=${'1'}`,
   state:{title:"hey"},
 }}    
              className='nav-links'
              onClick={closeMobileMenu}
            >
             SERVICES
            </Link>
          </li>

          <li className='nav-item'>
            <Link
              to="/askmeidentity-training"
              className='nav-links'
              onClick={closeMobileMenu}
            >
             TRAININGS
            </Link>
          </li>
          <li className='nav-item'>
            <Link
              to='/all-consultant'
              className='nav-links'
              onClick={closeMobileMenu}
            >
             CONSULTANT
            </Link>
          </li>

         


          <li className='nav-item'>
            <Link
              to='/mainblog'
              className='nav-links'
              onClick={closeMobileMenu}
            >
              BLOG
            </Link>
          </li>
          <li className='nav-item'>
            <Link
              to='/about'
              className='nav-links'
              onClick={closeMobileMenu}
            >
              ABOUT
            </Link>
          </li>
          
          <li className='nav-item'>
            <Link
              to='/contact'
              className='nav-links'
              onClick={closeMobileMenu}
            >
              CONTACT US
            </Link>
          </li>
          
          <li>
            <Link
              to='/login'
              className='nav-links-mobile'
              onClick={closeMobileMenu}
            >
               {/* {
          (token==null || token==''  || token==undefined)?
          (
      <Link to='/login'>
      Login
    </Link>
          ):(
    <Link to='/dashboard'>
    Dashboard
    </Link>
          )

        } */}
            </Link>
          </li>

          
        </ul>
        {/* {
          (token==null || token==''  || token==undefined)?
          (
      <Link to='/login'>
      <button className='btnn'>Login</button>
    </Link>
          ):(
    <Link to='/dashboard'>
      <button className='btnn'>Dashboard</button>
    </Link>
          )

        } */}

       
      </nav>
      <hr style={{color:"gray",opacity:"0.6",margin:"0px 0px"}} />
    </>
  );
}

export default Navbar;
