import React, { useState,useEffect } from 'react'
import icon13 from "../Images/icon13.png"
import Ping from "../Images/Ping.png";
import Clock from "../Images/Clock.png"
import Home_Training from '../Homee/Home_Training';
import book1 from "../Images/books1.gif"
import db from '../../firebase'
import { NavLink } from 'react-router-dom';
import book2 from "../Images/award.gif"
import Axios from 'axios'
function Enrolled() {

  const[data,setdata]=useState('')
  
  const daata = [
      // { name: "Okta Administrator", rate: 5, go: "" },
      // { name: "Pingfederate Administrator", rate: 4.5, go: "" },
      // { name: "Forgerock Administrator", rate: 5, go: ""},
    ]
    const token=JSON.parse(localStorage.getItem('data'));
    const FetchData = async() => {
      var r;


      await  Axios.post(`${process.env.REACT_APP_BASE_URL}/enrolled`,{
        token:token,
           }
           ).then((response)=>{
            console.log(response.data)
           
            setdata(response.data)
          
          }).catch(err => {
              console.error(err);
          
          })
          


      // const Rel = db.collection('user');
      // (token.localId==null||token.localId==undefined||token.localId=='')?(r=token.uid):(r=token.localId)
      // Rel.where('user_id', '==',r).get().then((snapshot) => {
      //   if (snapshot.empty) {
      //     console.log('No matching documents.');
      //     return;
      //   }  
      //   snapshot.forEach(element => {
      //     var course = element.data();
       
     
      //     console.log(course.enrolled_course)
    
      //     for(var i=0;i<=course.enrolled_course.length;i++){
      //       const Rel = db.collection('courses');
        
      //       Rel.where('course_id', '==',course.enrolled_course[i]).get().then((snapshot) => {
      //         if (snapshot.empty) {
      //           console.log('No matching documents.');
      //           return;
      //         }  
      //         snapshot.forEach(element => {
      //           var course = element.data();
               
      //         //  setref_data(course)
      //            setdata(arr => [...arr, course]);
      //           console.log(course)
                
      //         })
      //       })
      //     } 

          
      //   })
      // })
    
    }
  
  
  console.log(data)
  
    useEffect(
      FetchData, []
    );

  return (
    <>



        <p className='Dash_heading'>
          My  Enrolled Training
        </p>
{/* {(data.Enrolled_courses)?(
  
  
  
  
  <div style={{display:"flex"}}>
        <div style={{background:""}} className='Enrolled_trainingbox2'>
      <img  src={Ping} alt=""  className='Enrolled_trainingimage'/>
      <div style={{border:"",borderRadius:"20px",height:"130px",background:"white"}}>
          <h1 style={{fontSize:'24px',marginLeft:'10px',marginTop:"15px",fontFamily:"Inter"}}> Pingfederate Administrative</h1>
          <h2 style={{display:"flex", fontSize:'20px',marginLeft:"10px",marginTop:'30px',fontFamily:"Inter"}}><img src={Clock} alt="" />25hr  <h2 style={{display:"flex",float:"right",marginLeft:"160px", fontSize:'20px',marginTop:'0px',fontFamily:"Inter"}}><img src={icon13} alt="" />4.9(1k)</h2></h2>
        
      </div>
      </div>
      </div>):(
        <p style={{margin:"20px 50px",fontSize:"25px",fontFamily:"Inter",fontWeight:"500"}}>
          Curently you don't have any enrolled trining
        </p>
      )


}  */}

{/* {(data.enrolled)?
      (  Object.keys(data.enrolled).map(function(key) {
       return(
        <div style={{display:"flex"}}>
        <div style={{boxShadow: "0px 10px 100px 0px rgba(0, 0, 0, 0.05)"}} className='Enrolled_trainingbox2'>
     
      <div style={{border:"",borderRadius:"20px",height:"130px",background:"white"}}>
          <h1 style={{fontSize:'24px',marginTop:"15px",fontFamily:"Inter",textAlign:"center"}}>{data.enrolled[key]}</h1>
          <h2 style={{display:"flex", fontSize:'20px',marginLeft:"10px",marginTop:'30px',fontFamily:"Inter"}}><img src={Clock} alt="" /> <h2 style={{display:"flex",float:"right",marginLeft:"200px", fontSize:'20px',marginTop:'0px',fontFamily:"Inter",color:"rgba(37,214,225,1)"}}>View</h2></h2>
        
      </div>
      </div>
      </div>
       )
      
  })):(
    <p style={{margin:"20px 50px",fontSize:"25px",fontFamily:"Inter",fontWeight:"500"}}>
          Curently you don't have any enrolled training
        </p>
  )
    }  */}


    {(data)?(
          data.map((course)=>(       

            
            <div style={{background:course.image_background}}  className='trainingbox1'>
      <img src={course.image_url} alt=""  className='trainingimage'/>
      <div style={{border:"1px solid lightgray",borderRadius:"20px",height:"200px",background:"white"}}>
          <h1 style={{fontSize:'25px',textAlign:"center",marginTop:"15px",fontFamily:"Inter"}}>{course.course_name}</h1>
    
       

    <div style={{display:"flex",float:"right"}}>
          <NavLink to={{
   pathname:`/pingfederate?cid=${course.course_id}`,
   state:{title:"hey"},
  
 }} 
  style={{fontSize:'20px',marginTop:'30px', float:"right",marginRight:"10px",margin:"30px 10px",color:"black",fontFamily:"Inter",fontWeight:"600" ,color:"rgba(37,214,225,1)"}}> View  </NavLink>
 
  </div>
      </div>
            </div>

     ))):('')
      }


       
          {/* <div style={{display:"flex"}}>
        <div style={{background:""}} className='Enrolled_trainingbox2'>
      <img  src={Ping} alt=""  className='Enrolled_trainingimage'/>
      <div style={{border:"",borderRadius:"20px",height:"130px",background:"white"}}>
          <h1 style={{fontSize:'24px',marginLeft:'10px',marginTop:"15px",fontFamily:"Inter"}}> Pingfederate Administrative</h1>
          <h2 style={{display:"flex", fontSize:'20px',marginLeft:"10px",marginTop:'30px',fontFamily:"Inter"}}><img src={Clock} alt="" />25hr  <h2 style={{display:"flex",float:"right",marginLeft:"160px", fontSize:'20px',marginTop:'0px',fontFamily:"Inter"}}><img src={icon13} alt="" />4.9(1k)</h2></h2>
        
      </div>
      </div>
      </div> */}
    </>
  )
}

export default Enrolled