import React from 'react'
import SwiperCore, { Navigation } from 'swiper/core';
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import 'swiper/swiper.min.css'
import "swiper/css/pagination";
import left from '../../Assets/Images/left.png'
import right from '../../Assets/Images/right.png'

import Himg1 from '../../Assets/Images/slider4.webp';
import Himg2 from '../../Assets/Images/slider3.webp';
import { NavLink } from 'react-router-dom';
import { Autoplay, Pagination } from "swiper";
import './Home.css'
import 'swiper/swiper.min.css';
// Import any additional Swiper modules you might need
SwiperCore.use([Navigation]);
function Slider() {
    const swiperRef = React.useRef(null);

    const goNext = () => {
      if (swiperRef.current && swiperRef.current.swiper) {
        swiperRef.current.swiper.slideNext();
      }
    };
  
    const goPrev = () => {
      if (swiperRef.current && swiperRef.current.swiper) {
        swiperRef.current.swiper.slidePrev();
      }
    };
  return (
     <>
     <div style={{width:"100%",display:"flex",justifyContent:"center"}}>

     
     <div style={{position:"relative",width:"90%"}}>
    <Swiper
          pagination={{clickable: true}} modules={[Autoplay, Pagination]}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          navigation
          ref={swiperRef}
          >
          <SwiperSlide >
            <div className="align-about-hero" style={{
              backgroundImage: `url(${Himg1})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}>
              <div  className="mySwiper">
              <div className='myswiper_cart'>
<h2 className='myswiper_cart_head'>Build ready-for anything teams</h2>
<p className='myswiper_cart_text'>see why leading organizations choose to learn with Askmeidentity Training </p>
<NavLink to='/register'>
<button className='Btn' >Sign up</button>
</NavLink>
              </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="align-about-hero" style={{
              backgroundImage: `url(${Himg2})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}>
              <div  className="mySwiper">
              <div className='myswiper_cart'>
<h2 className='myswiper_cart_head'>Build ready-for anything teams</h2>
<p className='myswiper_cart_text'>see why leading organizations choose to learn with Askmeidentity Training </p>
<button className='Btn'>Sign up</button>

              </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
        <button className='left_btn' style={{backgroundColor:"#1A53DD",width:"50px",height:"50px",borderRadius:"50%",border:"1px solid #1A53DD",zIndex: '9999'}}  onClick={goPrev}><img style={{backgroundColor:"#1A53DD",width:"30px",borderRadius:"50%"}} src={left} alt="" /></button>
      <button  className='right_btn' onClick={goNext}  style={{backgroundColor:"#1A53DD",width:"50px",height:"50px",borderRadius:"50%",border:"1px solid #1A53DD",zIndex: '9999'}} ><img style={{backgroundColor:"#1A53DD",width:"30px",borderRadius:"50%"}} src={right} alt="" /></button>
        </div> 
        </div>
     </>  
  )
}

export default Slider