import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import "./home.css";
import Clock from "./Images/Clock.png";
import Calender from "./Images/Calendar.png";
import Header from "../Components/Images/Header.png";
import Computer from "../Components/Images/Computer.gif";
import Database from "../Components/Images/Database.gif";
import Idea from "../Components/Images/Idea.gif";
import Mortarboard from "../Components/Images/Mortarboard.gif";
import Group from "../Components/Images/Group.png";
import Okta from "../Components/Images/Okta.png";
import Group7 from "./Images/Group7.png";
import { auth } from "../firebase";
import Forgerock1 from "../Components/Images/Forgerock1.png";
import Onelogin from "../Components/Images/Onelogin.png";
import Ping from "../Components/Images/Ping.png";
import db from "../firebase";
import Auth0 from "../Components/Images/Auth0.png";
import Group1 from "../Components/Images/Group1.png";
import Group2 from "../Components/Images/Group2.png";
import Group3 from "../Components/Images/Group3.png";
import Cloud from "../Components/Images/Cloud.gif";
import Frame from "./Images/Frame.png";
import Logo from "../Components/Images/Logo.png";
import Nav from "../Components/Nav/Navbar";
import Footer from "./Footer";
import Home_Service from "./Homee/Home_Service";
import Headerback from "./Images/Headerback.png";
import Home_Training from "./Homee/Home_Training";
import Onlogin from "./Trainingcomp/Onlogin";
import Home_Blogs from "./Homee/Home_Blogs";
import Navbar from "../Components/header/index";
import slider from "./Homee/Slider";
import Slider from "./Homee/Slider";
import Axios from "axios";
import Fotter from "../../src/src/Component/Fotter/Fotter";
import New_home from "./Homee/New_home";
import { Helmet } from "react-helmet";

function Home() {
  const [courses, setcourses] = useState([]);
  const [Count, setCount] = useState(0);
  const [state, setState] = useState({
    ip: "",
    countryName: "",
    countryCode: "",
    city: "",
    timezone: "",
  });

  useEffect(() => {
    Axios.get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        setState({
          ...state,
          ip: data.ip,
          countryName: data.country_name,
          countryCode: data.country_calling_code,
          city: data.city,
          timezone: data.timezone,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const FetchData = async () => {
    await Axios.get(`${process.env.REACT_APP_BASE_URL}/Home_course`)
      .then((response) => {
        console.log(response.data);

        setcourses(response.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(FetchData, []);

  const header = {
    width: "100%",
    height: "90rem",
    display: " grid",
    gridTemplateColumns: "20rem 1fr 1fr",
    // backgroundImage: " linear-gradient(to right, #d8fdff, #e1fdff, #eafdff, #f2fdff, #fafdff, #fafdff, #fafcff, #fafcff, #f4fbff, #edfaff, #e5f9fd, #def8f9)",
    backgroundImage: `url(${Headerback})`,
    backgroundRepeat: "no-repeat",
    // backgroundImage:"linear-gradient(to right , rgba(37,214,225,1),white,white,white,white,white, rgba(37,214,225,1))"
  };
  const keywords = [
    "Safeguard your Digital Identity",
    "Leading Providers of Identity and Access Management Solutions",
    "Best IAM Training",
    "IAM Services",
    "IAM Services",
  ];

  const keywordString = keywords.join(", ");
  return (
    <>
      <Helmet>
        <title>Leading IAM Service Providers |Askmeidentity</title>
        <meta
          name="description"
          content="Enhance your business's growth with Askmeidentity's cutting-edge IAM Services. Elevate security and optimize access effortlessly."
        />
        <meta name="keywords" content={keywordString} />
      </Helmet>
      <Navbar />
      {/* <Slider /> */}
      <New_home />

      <Fotter />
    </>
  );
}

export default Home;
