import React,{useState} from 'react'
import { FaEnvelope, FaLock, FaGoogle, FaFacebook } from 'react-icons/fa';
import './user.css'
import Google from '../../Assets/Images/Google.png'
import facebook from '../../Assets/Images/facebook.png'
import { signin, signup } from '../../action/auth';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { useSearchParams } from 'react-router-dom';
import axios from 'axios'
function Register() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name,setName]=useState('');
    const [last_name,setLastname]=useState('')
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showerror,setShowerror]=useState('');
    const [searchParams, setSearchParams] = useSearchParams();
    const redirect_Url = searchParams.get("redirect_url");
console.log(redirect_Url);
    const handleEmailChange = (e) => {
      setEmail(e.target.value);
    };
    const handlenameChange = (e) => {
        setName(e.target.value);
      };
      
      const handlelastChange = (e) => {
        setLastname(e.target.value);
      };
    const handlePasswordChange = (e) => {
      setPassword(e.target.value);
    };
  
    const handleSubmit = async(e) => {
      e.preventDefault();
      //login logic
     

      setShowerror('')
      
      if(name==''){
        setShowerror('Please enter first name')
        return ;
      }
      
      if(last_name==''){
        setShowerror('Please enter last name')
        return ;
      }
      
      if(!isEmail(email)){
         setShowerror('Please enter valid email');
         return;
      }
      
      if(validatePassword(password)=="Password is valid."){

        try{
          var response= await  axios.post(`https://us-central1-askmeidentity-website.cloudfunctions.net/as/register`, {
            email,password,name,last_name});
           // console.log(response.data);
           localStorage.setItem("profile", JSON.stringify(response.data));
           if(redirect_Url==''||redirect_Url==null||redirect_Url==undefined){
             window.location.replace('/user-overview')
           }else{
             window.location.replace(`/${redirect_Url}`)
   
           }
        }catch(error){
          setShowerror('User with this email already exist')
          console.log(error);
        }
       
      }else{
        setShowerror(validatePassword(password))
      }
   //   dispatch(signup(email,password,name,navigate));
  
    };
    const isEmail = (email) => {
      const regEx =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (email.match(regEx)) return true;
      else return false;
    };
    function validatePassword(password) {
      // Password must be at least 8 characters long.
      if (password.length < 8) {
        return "Password must be at least 8 characters long.";
      }
    
      // Password must contain at least one uppercase letter, one lowercase letter, and one number.
      const uppercaseRegex = /[A-Z]/;
      const lowercaseRegex = /[a-z]/;
      const numberRegex = /[0-9]/;
    
      if (!uppercaseRegex.test(password)) {
        return "Password must contain at least one uppercase letter.";
      }
    
      if (!lowercaseRegex.test(password)) {
        return "Password must contain at least one lowercase letter.";
      }
    
      if (!numberRegex.test(password)) {
        return "Password must contain at least one number.";
      }
    
      return "Password is valid.";
    }
    return (
      <div className="login-form">
        <h2 style={{marginTop:"25%",marginBottom:"20px",fontFamily:"Inter"}}>Sign up</h2>
        <form onSubmit={handleSubmit}>
        <div className="input-group">
            
            <input
              type="text"
              placeholder="first name"
              value={name}
              onChange={handlenameChange}
              required
              style={{heigth:'20px',fontSize:"18px",padding:"0px",fontFamily:"Inter"}}

            />
          </div>
          <div className="input-group">
            
            <input
              type="text"
              placeholder="last name"
              value={last_name}
              onChange={handlelastChange}
              required
              style={{heigth:'20px',fontSize:"18px",padding:"0px",fontFamily:"Inter"}}

            />
          </div>
          <div className="input-group">
            
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={handleEmailChange}
              required
              style={{heigth:'20px',fontSize:"18px",padding:"0px",fontFamily:"Inter"}}

            />
          </div>
  
          <div className="input-group">
           
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={handlePasswordChange}
              required
              style={{heigth:'20px',fontSize:"18px",padding:"0px",fontFamily:"Inter"}}

            />
          </div>
          <p style={{color:"red",marginLeft:"10px",fontWeight:"500",textAlign:"center"}} className="forgot-password">{showerror}</p>

          <button type="submit" style={{backgroundColor:'#1A53DD',color:"white",fontSize:"17px" ,height:"40px",fontFamily:"Inter"}}>Signup</button>
        </form>
  <br />
        <p style={{marginLeft:"30px",fontSize:"16px",fontFamily:"Inter"}}>Already have an account? <a href="/login">Log in</a></p>
      </div>
    );
}

export default Register