import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  getcoursesDetail
} from "../../action/courses";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom"; 
import axios from 'axios';
import "./training.css";
import globe from "../../Assets/Images/globe.png";
import Alert from "../../Assets/Images/alert.png";
import Ping from "../../Assets/Images/Ping.png";
import check from '../../Assets/Images/check.png'
import user from '../../Assets/Images/user.png';
import MyVerticallyCenteredModal from './MyVerticallyCenteredModal'
import clock from '../../Assets/Images/clock.png';
import globe1 from '../../Assets/Images/globe1.png';
import file from '../../Assets/Images/file.png'
import Second_course from "../Home/Second_course";
import { useSearchParams } from "react-router-dom";
import { NavLink } from "react-router-dom";
function Training() {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate =useNavigate();
  const [loading, setloading] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);

  const ID = searchParams.get("cid");
  useEffect(() => {
    dispatch(getcoursesDetail(ID));
  }, [dispatch]);
  const courses_detail = useSelector((state) => state.course_detail);
  const [count,setcount]=useState(true);

//API to Fetch the User IP

  useEffect(()=>{
  
    axios.get("https://ipapi.co/json/")
            .then((response) => {
              let dataa = response.data;
            console.log(dataa.country_name);
            if (dataa.country_name === "India") {
              setcount(true);
  
            } else {
              setcount(false);

            }
  })
  },[] )
  const token = JSON.parse(localStorage.getItem("profile"));


//Payment for India

const loadScript = (src) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
      setTimeout(() => {
        setloading(false);
      }, 2000);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};


const paymentIndia = async () => {
 var Amount=courses_detail.course_fee.INR.split(" ")[1];

  if (token == null || token == "" || token == undefined) {
    console.log("not");
    navigate(`/login?redirect_url=training?cid=${ID}`)
    return;
  }else{
    
    setloading(true);
    console.log("payment clicked");
    console.log(JSON.stringify(localStorage));
    // const course_name =
    // return;

 //   console.log(Amount);
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      alert("Razor SDK failed to load");
      setloading(true);
      return;
    }
    console.log(`razorpay script loaded:: ${res}`);

  
    const course_id = courses_detail.course_id;
    const course_name = courses_detail.course_name;
    const razorpay_test_key = process.env.REACT_APP_RAZORPAY_TEST_KEY;
    const razorpay_data = {
      token: token.access_token,
      Amount: Amount,
      //  emailid: email_id,
      courseid: course_id,
      coursename: course_name,
      razorpaytestkey: razorpay_test_key,
    };
    console.log(razorpay_data);

    // return;
    const data = await fetch(
      `${process.env.REACT_APP_BASE_URL}/init-razorpay`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(razorpay_data),
      }
    ).then((razorpay_return) => razorpay_return.json());
    console.log(data);
    const options = {
      key: razorpay_data.razorpaytestkey,
      amount: data.amount.toString(),
      currency: data.currency,
      name: data.coursename,
      description: data.coursename,
      image: "https://askmeidentity.com/",
      order_id: data.id,
      handler: async (response) => {
       
        // return;

        console.log(`client:: ${JSON.stringify(response)}`);
       

        // let courseamount=(value.course_fee.INR).split(' ')[1]
        let courseamount = Amount;
        const test_point = await axios.post(`${process.env.REACT_APP_BASE_URL}/verify-payment`, {
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_order_id: response.razorpay_order_id,
            razorpay_signature: response.razorpay_signature,
           // email: razorpay_data.emailid,

           token:token.access_token,
            amount: courseamount,
            courseid: razorpay_data.courseid,
            course_name: courses_detail.course_name
        })
        .then(function(response) {
            console.log(response);
            console.log(response.data.message);
            if (response.data=="Success") {
                console.log (response.data);
               // alert ("payment not verified");
               Swal.fire({
                title: 'Success!',
                text: 'You have Successfully Enrolled to the Course',
                icon: 'success',
               showCancelButton:true,
                confirmButtonText:'Dashboard'
              }).then((show)=>{
                // if(show.isConfirmed){
                //  window.location.replace('/dashboard')
                // }
              });
            }
            else {
                console.log ("payment verified");
                // setModalShow(true)
                setloading(false);
                setloading(true);
                Swal.fire({
                 title: 'Oops...',
                 text: 'Something went wrong please try again',
                 icon: 'error',
                showCancelButton:true,
                 confirmButtonText:'Tryagain'
               })
            }
        })
        .catch((error)=>{
            console.log(error);
            Swal.fire({
              title: 'Oops...',
              text: 'Something went wrong please try again',
              icon: 'error',
             showCancelButton:true,
              confirmButtonText:'Tryagain'
            })
        })
        .then(()=>{
            // always executed
        });
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }
}


//Payment with Stripe
const paymentStripe = async () => {
var Stripe_Amount=courses_detail.course_fee.USD.split(" ")[1]
   if (token == null || token == "" || token == undefined) {
    console.log("not");

navigate(`/login?redirect_url=training?cid=${ID}`)
    return;

  } else {
  
    await fetch(`${process.env.REACT_APP_BASE_URL}/create-checkout-session`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ token: token.access_token, items: courses_detail.course_id, Amount: Stripe_Amount }),
    })
      .then((res) => {
        if (res.ok) return res.json();
        return res.json().then((json) => Promise.reject(json));
      })
      .then(({ url }) => {
        window.location = url;
      })
      .then(
        console.log(window.location.pathname)
       
      )
      .catch((e) => {
        console.error(e.error);
      });
  }
};
const [name,setname]=useState('')
const [email,setemail]=useState('')
const [number,setnumber]=useState('')
const trainingRequest =async(e)=>{
e.preventDefault();
console.log(name,email,number);
await axios.post(`${process.env.REACT_APP_BASE_URL}/trainingrequest`, {
  name: name,
  email: email,
  number:number
}).then((res)=>{
  Swal.fire({
    title: 'Success!',
    text: 'you have successfully raised training request',
    icon: 'success',
   showCancelButton:true,
  })
  setemail('')
  setname('')
  setnumber('')
}).catch((err)=>{
  Swal.fire({
    title: 'Oops...',
    text: 'Something went wrong please try again',
    icon: 'error',
   showCancelButton:true,
    confirmButtonText:'Tryagain'
  })
})
}

console.log(courses_detail);
  return (
    <>
    

      <MyVerticallyCenteredModal
        show={modalShow}
        data={courses_detail.student}
        onHide={() => setModalShow(false)}
      />
      <section>
        <div className="header_container">
          <div className="header_content">
            <h3 className="header_heading">
             {courses_detail.course_name}
            </h3>
            <div className="Mobile_change">
            <p className="header_text">
            Askmeidentity trainers offer specialized IAM (Identity and Access Management) training, providing comprehensive courses on products like Ping and Okta to enhance individuals' skills in managing identity and access control systems.
            </p>
            <br />

            <div style={{ display: "flex" }}>
              <div
                style={{
                  width: "100px",
                  height: "25px",
                  backgroundColor: "#54B426",
                  borderRadius: "15px",
                }}
              >
                <p
                  style={{
                    color: "#00000099",
                    alignItems: "center",
                    fontSize:"15px",
                    textAlign: "center",
                  }}
                >
                  Bestseller
                </p>

              </div>
              <div>
                <p style={{ color: "#E59819",marginLeft:"10px" ,fontSize:"16px" }}>  </p>
              </div>
              <div className="header_text" onClick={() => setModalShow(true)} style={{marginLeft:"20px",cursor:"pointer",color:"#1A53DD"}}>Students Rating</div>
            </div>
            <br />

            <p className="header_text">Created by {courses_detail.course_instructor}</p><br />
            <div style={{ display: "flex" }}>
              <p className="header_text">
                <img style={{ paddingRight: "10px" }} src={Alert} alt="" />
                Last updated 6/2023
              </p>{" "}
              <p className="header_text">
                <img style={{ padding: "0px 10px" }} src={globe} alt="" />
                English
              </p>
            </div>
            </div>
          </div>

          <div className="training_cart">
            <img style={{ marginBottom: "10px",width:"230px",height:"160px" }} src={courses_detail.image_url} alt="" />
           { 
            (count===true)?( <button className="pay_btn"  onClick={() => paymentIndia()} >Buy this course</button>):( <button className="pay_btn"   onClick={() => paymentStripe()}>Buy this course</button>)
           }
            <p
              style={{
                fontSize: "14px",
                fontFamily: "Whyte",
                color: "#646464",
                alignItems:"center",
                textAlign:"center"
              }}
            >
              <br /> Full Lifetime Access
            </p>
            <div style={{display:"flex"}}>
              <p className="cart_share">Share</p>{" "}
              <p className="cart_share" style={{marginLeft:"50px"}}>Apply Coupon</p>
            </div>
          </div>
        </div>
      </section>
      <div className="training_detail">
        <div className="training_section1">
          <h2 className="training_section1_heading">About this Training</h2>
          <p className="training_section1_text">{courses_detail.course_description}</p>
        </div>
       
      </div>
      <div className="training_detail" >
       
      <div className="training_section2" style={{position:"relative"}}>
        
      <h2 className="training_section1_heading" style={{position:"absolute",top:"20px",left:"50px"}}>What will I Learn ?</h2>
    {  (courses_detail.course_content)?(
       
        Object.keys(courses_detail.course_content).map((key) => {
          return (
<div className="training_text_container" key={key}>
          
          <p className="training_section1_text"><img src={check} alt="" /> {courses_detail.course_content[key]}</p>
        </div>
        )})
      
    ):('')}
     
     

        </div>
      </div>
       <div className="training_detail" style={{marginTop:"50px"}}>
        <div className="training_instructure" >
          <div style={{display:"flex",width:"250px",margin:"10px"}}>
            <img style={{height:"27px",marginRight:"10px"}} src={user} alt="" /><p className="training_section1_text"><span style={{fontWeight:"700"}}>Instructor</span> : {courses_detail.course_instructor}</p>
          </div>
        </div>
        <div className="training_instructure">
          <div style={{display:"flex",width:"240px",margin:"10px"}}>
            <img style={{height:"27px",marginRight:"10px"}} src={file} alt="" /><p className="training_section1_text"><span style={{fontWeight:"700"}}>Source</span> : {courses_detail.course_source}</p>
          </div>
        </div>
        <div className="training_instructure">
          <div style={{display:"flex",width:"220px",margin:"10px"}}>
            <img style={{height:"27px",marginRight:"10px"}} src={clock} alt="" /><p className="training_section1_text"><span style={{fontWeight:"700"}}>Duration</span> : {courses_detail.course_duration}</p>
          </div>
        </div>
        <div className="training_instructure">
          <div style={{display:"flex",width:"220px",margin:"10px"}}>
            <img style={{height:"27px",marginRight:"10px"}} src={globe1} alt="" /><p className="training_section1_text"><span style={{fontWeight:"700"}}>Language</span> : English</p>
          </div>
        </div>
       </div>
       <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",textAlign:"center",backgroundColor:"#1A53DD",margin:"50px 0px"}}>
        {/* <div className="conic" style={{width:"250px",height:"100px",background:"white",}}><h2  className="fee_text" style={{fontSize:"25px",marginTop:"20px",color:"black",color:"#1A53DD"}}>Fees: {(count===true)?(<>{courses_detail?.course_fee?.INR}</>):(<>{courses_detail?.course_fee?.USD}</>)}</h2>
        </div> */}
        <h2 className="fee_text" style={{marginTop:"30px"}}>Block your seat today!</h2>
        <h2 className="fee_text" style={{paddingTop:"30px"}}>Request POC</h2>

        <br />
        <input type="name" value={name} placeholder="name" onChange={(e)=>setname(e.target.value)} className="training_input"/>

        <input type="email" value={email}  placeholder="email" onChange={(e)=>setemail(e.target.value)} className="training_input"/>
        <input type="number" placeholder="number" value={number} onChange={(e)=>setnumber(e.target.value)} className="training_input" />
        <button className="L_pay_btn" onClick={(e) => trainingRequest(e)}>Submit</button>
       
        <br />
        <p style={{color:"white",fontFamily:"Inter",fontSize:"16px"}}>Leading companies uses our courses to keep their <br /> talents equipped with new skills.

</p><br />
       </div>
       <Second_course/>
    </>
  );
}

export default Training;
