import React from 'react'
import Ping from "../Images/Ping.png";
import Clock from "../Images/Clock.png"
import {NavLink} from "react-router-dom"
function Reviews() {
  return (
      <>
   <p className='Dash_heading'>
       Reviews
   </p>

   <div style={{display:"flex"}}>
        <div style={{background:""}} className='Enrolled_trainingbox2'>
      <img  src={Ping} alt=""  className='Enrolled_trainingimage'/>
      <div style={{border:"",borderRadius:"20px",height:"130px",background:"white"}}>
          <h1 style={{fontSize:'24px',marginLeft:'10px',marginTop:"15px",fontFamily:"Inter"}}> Pingfederate Administrative</h1>
          <h2 style={{display:"flex", fontSize:'20px',marginLeft:"10px",marginTop:'30px',fontFamily:"Inter"}}><img src={Clock} alt="" />25hr  <NavLink to="" style={{display:"flex",float:"right",marginLeft:"160px", fontSize:'20px',marginTop:'0px',fontFamily:"Inter",color:"rgba(37,214,225,1)"}}>Reviews</NavLink></h2>
        
      </div>
      </div>
      </div>
      
   </>
  )
}

export default Reviews