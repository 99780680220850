import React, { useRef, useEffect } from "react";
import Slider from "react-slick";
import left from "../../Assets/Images/left.png";
import right from "../../Assets/Images/right.png";
import msg from "../../Assets/Images/comma.svg";
import Course_cart from "../../Component/Home/course_cart";
import { NavLink } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import users from "../../Assets/Images/users.png";
import { getcourses } from "../../action/courses";
import "./training.css";
import Navbar from "../../Component/Navbar/Navbar";
import Footer from "../../../Components/Footer";
import Fotter from "../../Component/Fotter/Fotter";
function All_training() {
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    dispatch(getcourses());
  }, [dispatch]);
  const [searchParams, setSearchParams] = useSearchParams();
  const bid = searchParams.get("name");
  console.log(bid);

  const sliderRef = useRef();
  const courses = useSelector((state) => state.courses);

  const settings = {
    dots: false,
    button: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true, // Enables autoplay
    autoplaySpeed: 2000, // Sets autoplay interval to 3 seconds
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settings1 = {
    dots: false,
    button: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Enables autoplay
    autoplaySpeed: 2000, // Sets autoplay interval to 3 seconds
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <Navbar />
      <div className="All_training_container">
        <div className="Main_container">
          {bid == "IAM" ? (
            <>
              <div>
                <h2 className="Alltraining_head">IAM Course</h2>
                <p className="Alltraining_text" style={{ fontFamily: "Inter" }}>
                  IAM relates to{" "}
                  <span style={{ fontFamily: "Inter" }} className="All_span">
                    User Access Control and Security
                  </span>{" "}
                </p>
                <div style={{ display: "flex", fontSize: "16px" }}>
                  <img
                    style={{ Width: "35px", height: "35px" }}
                    src={users}
                    alt=""
                  />{" "}
                  <p className="user_collection"> 1000+ learners</p>
                </div>
              </div>
              <br />
              <div>
                <h3 className="Alltraining_head" style={{ fontSize: "22px" }}>
                  {" "}
                  Askmeidentity's Expert IAM Training Solutions
                </h3>
                <div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div
                      className="testimonial_coresal_home"
                      style={{ position: "relative" }}
                    >
                      <button className="btn_testimonial_l">
                        <img
                          onClick={() => sliderRef.current.slickPrev()}
                          style={{
                            backgroundColor: "black",
                            width: "30px",
                            borderRadius: "50%",
                          }}
                          src={left}
                          alt=""
                        />
                      </button>
                      <button
                        className="btn_testimonial_r"
                        onClick={() => sliderRef.current.slickNext()}
                      >
                        <img
                          style={{
                            backgroundColor: "black",
                            width: "30px",
                            borderRadius: "50%",
                          }}
                          src={right}
                          alt=""
                        />
                      </button>

                      {courses === "undefined" || courses === "" ? (
                        ""
                      ) : (
                        <>
                          <Slider {...settings} ref={sliderRef}>
                            {courses.map((course, index) =>
                              course.course_id == "4521" ? (
                                ""
                              ) : (
                                <Course_cart
                                  name={course.course_name}
                                  instructor={course.course_instructor}
                                  fee={course.course_fee}
                                  img={course.image_url}
                                  cid={course.course_id}
                                  url={course.url}
                                />
                              )
                            )}
                          </Slider>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div>
                <h2 className="Alltraining_head">Web Development Course</h2>
                <p className="Alltraining_text" style={{ fontFamily: "Inter" }}>
                  Begin an exploration into the world of Web Development{" "}
                  <span style={{ fontFamily: "Inter" }} className="All_span">
                    {" "}
                    discovering its endless possibilities.
                  </span>{" "}
                </p>
                <div style={{ display: "flex", fontSize: "16px" }}>
                  <img
                    style={{ Width: "35px", height: "35px" }}
                    src={users}
                    alt=""
                  />{" "}
                  <p className="user_collection"> 1000+ learners</p>
                </div>
              </div>
              <br />
              <div>
                <h3 className="Alltraining_head" style={{ fontSize: "22px" }}>
                  Perfect for beginners and aspiring developers
                </h3>
                <div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div
                      className="testimonial_coresal_home"
                      style={{ position: "relative" }}
                    >
                      <button className="btn_testimonial_l">
                        <img
                          onClick={() => sliderRef.current.slickPrev()}
                          style={{
                            backgroundColor: "black",
                            width: "30px",
                            borderRadius: "50%",
                          }}
                          src={left}
                          alt=""
                        />
                      </button>
                      <button
                        className="btn_testimonial_r"
                        onClick={() => sliderRef.current.slickNext()}
                      >
                        <img
                          style={{
                            backgroundColor: "black",
                            width: "30px",
                            borderRadius: "50%",
                          }}
                          src={right}
                          alt=""
                        />
                      </button>

                      {courses === "undefined" || courses === "" ? (
                        ""
                      ) : (
                        <>
                          <Slider {...settings1} ref={sliderRef}>
                            <div style={{ textDecoration: "none" }}>
                              <NavLink
                                to={`/training?cid=node.js`}
                                style={{ textDecoration: "none" }}
                              >
                                <div
                                  style={{
                                    width: "230px",
                                    marginLeft: "15px",
                                    marginRight: "15px",
                                    textDecoration: "none",
                                  }}
                                >
                                  <img
                                    style={{ width: "200px", height: "100px" }}
                                    src="https://firebasestorage.googleapis.com/v0/b/askmeidentity-website-ppe.appspot.com/o/Images-training%2Fnodeimg.png?alt=media&token=2b4883a3-fc9e-4400-84a0-96462c31e967"
                                    alt=""
                                  />
                                  <div style={{ padding: "10px" }}>
                                    <h5 className="Course_cart_head">Nodejs</h5>
                                    <p className="Course_cart_text">
                                      Sumit k Tiwari
                                    </p>
                                    <p style={{ color: "#E59819" }}>
                                      4.5 ⭐⭐⭐
                                    </p>
                                    <p
                                      style={{
                                        fontWeight: "600",
                                        color: "black",
                                        fontFamily: "Inter",
                                      }}
                                    >
                                      ₹ 25000
                                    </p>
                                  </div>
                                </div>
                              </NavLink>
                            </div>
                          </Slider>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <Fotter />
    </>
  );
}

export default All_training;
