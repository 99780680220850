import React, { useState, useEffect } from "react";
import "./Consultant.css";
import Img from "./Images/consultant.jpg";
import Navbar from "../header/Header";
import Contact_banner from "../Servicecomp.js/Services/Contact_baner";
import Footer from "../../src/Component/Fotter/Fotter";
import Result from "./Images/result.png";
import axios from "axios";
import { Helmet } from "react-helmet";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function Consultant() {
  const notify = (data) => toast(data);

  const [name, setName] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [phone, setphone] = useState("");
  const [date, setDate] = useState("");
  const [message, setMessage] = useState("");
  const book_consultant = (e) => {
    e.preventDefault();
    console.log(name, lastname, email, subject, phone, date, message);
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/book_consultant`, {
        email: email,
        name: name,
        lastname: lastname,
        subject: subject,
        phone: phone,
        date: date,
        message: message,
      })
      .then((response) => {
        //console.log(response);
        if (response.data == "Request Sent") {
          notify(response.data);
          setEmail("");
          setDate("");
          setLastname("");
          setMessage("");
          setSubject("");
          setphone("");
          setName("");
        } else {
          notify("Something Went wrong Please try again");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const keywords = ["Secure you business", "Proven Industry Pioneers", "Trust Built on Results", "streamline access management", "Safeguard Your Digital identity","training"]
  
  const keywordString = keywords.join(', ');
  return (
    <>
       <Helmet>
            <title> Explore Askmeidentity Consultants, Your Trusted IAM Solutions Partner
      </title>
            <meta name="description" content="Discover top-notch IAM consulting at Askmeidentity. We specialize in IAM solutions for unmatched digital security. Partner with us for a fortified business strategy." />
            <meta name="keywords" content={keywordString} />
          </Helmet>
      <Navbar />

      <div className="Sevice_header" style={{ height: "700px" }}>
        <div style={{ width: "100%", height: "700px" }}>
          <img
            className="s_Header_img"
            style={{ width: "100%", height: "700px" }}
            src={Img}
            alt=""
          />
        </div>
        <div
          style={{ opacity: "0.5", height: "700px" }}
          className="Overlay"
        ></div>
        <div
          className="Service_heading"
          style={{
            left: "5%",
            transform: "none",
            textAlign: "start",
            top: "30%",
          }}
        >
          <h2
            className="S_heading_text"
            style={{ fontSize: "45px", fontWeight: "500" }}
          >
            We help to Secure <br />
            your business
          </h2>
          <br />
          <p className="S_heading" style={{ fontSize: "20px" }}>
            Your Trusted Partner for IAM Excellence - Providing Expert <br />{" "}
            Training and Innovative Solutions to Safeguard Your Digital <br />{" "}
            Identity and Bolster Security in the Digital Age.
          </p>
        </div>
      </div>

      <div className="Consultant_section1">
        <div className="consultant_inner1">
          <div className="consultant_inner_l">
            <div>
              <h1 className="Consutant_head">
                Trusted Consulting Company With 10+ Years Experience.
              </h1>
              <br />
              <br />
              <div
                className="remove_gap"
                style={{ display: "flex", gap: "20px" }}
              >
                <img
                  className="small_img"
                  src="https://demo.rstheme.com/wordpress/bizdesk/wp-content/uploads/2023/08/investor.png"
                  alt=""
                />
                <div>
                  <h2 className="consultant_subhead">
                    Proven Industry Pioneers
                  </h2>
                  <p className="consultant_text">
                    With more than a decade of experience, Askmeidentity has
                    established itself as a trailblazer in the IAM field. We've
                    been at the forefront of evolving security challenges,
                    helping organizations navigate complex identity and access
                    management landscapes.
                  </p>
                </div>
              </div>
              <br />
              <div
                className="remove_gap"
                style={{ display: "flex", gap: "20px" }}
              >
                <div>
                  <img
                    className="small_img"
                    style={{ width: "130px" }}
                    src={Result}
                    alt=""
                  />
                </div>
                <div>
                  <h2 className="consultant_subhead">Trust Built on Results</h2>
                  <p className="consultant_text">
                    A decade-plus of IAM excellence isn't just about the years
                    but the tangible results we've achieved. We've partnered
                    with diverse organizations across various industries,
                    helping them secure their digital identities, streamline
                    access management, and achieve compliance goals.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="consultant_inner_r"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <img
              style={{ width: "80%", borderRadius: "20px" }}
              src="https://demo.rstheme.com/wordpress/bizdesk/wp-content/uploads/2023/08/about-image-h2.jpg"
              alt=""
            />
          </div>
        </div>
      </div>
      <ToastContainer
        style={{ fontSize: "17px", fontFamily: "Inter !important" }}
      />

      <h2
        className="Consutant_head"
        style={{
          textAlign: "center",
          fontSize: "35px",
          fontWeight: "600",
          margin: "70px 10px 50px 10px",
        }}
      >
        Choosing Your IAM Path
      </h2>

      <div className="Consultant_section2">
        <div className="Consultant_Inner2">
          <div className="consutant_Container">
            <div style={{ display: "flex", alignItems: "center" }}>
              <h2 className="consultant_subhead" style={{ color: "#1A53DD" }}>
                Okta - The Identity Cloud
              </h2>
            </div>
            <p className="consultant_text">
              Okta is a cloud-based identity and access management platform
              trusted by thousands of organizations worldwide.
            </p>
          </div>
          <div className="consutant_Container">
            <div style={{ display: "flex", alignItems: "center" }}>
              <h2 className="consultant_subhead" style={{ color: "#1A53DD" }}>
                Microsoft Azure
              </h2>
            </div>
            <p className="consultant_text">
              As part of the Azure ecosystem, Microsoft Azure Active Directory
              provides robust identity and access management services.
            </p>
          </div>
          <div className="consutant_Container">
            <div style={{ display: "flex", alignItems: "center" }}>
              <h2 className="consultant_subhead" style={{ color: "#1A53DD" }}>
                Ping Identity
              </h2>
            </div>
            <p className="consultant_text">
              Ping Identity offers a comprehensive IAM platform known for its
              API security and advanced authentication solutions.
            </p>
          </div>
          <div className="consutant_Container">
            <div style={{ display: "flex", alignItems: "center" }}>
              <h2 className="consultant_subhead" style={{ color: "#1A53DD" }}>
                OneLogin
              </h2>
            </div>
            <p className="consultant_text">
              OneLogin is a user-friendly IAM solution with single sign-on, MFA,
              and adaptive authentication features.
            </p>
          </div>
          <div className="consutant_Container">
            <div style={{ display: "flex", alignItems: "center" }}>
              <h2 className="consultant_subhead" style={{ color: "#1A53DD" }}>
                ForgeRock Identity Platform
              </h2>
            </div>
            <p className="consultant_text">
              ForgeRock offers a full-featured identity platform with
              capabilities like identity management, access management, and
              user-managed access.
            </p>
          </div>
          <div className="consutant_Container">
            <div style={{ display: "flex", alignItems: "center" }}>
              <h2 className="consultant_subhead" style={{ color: "#1A53DD" }}>
                Devops
              </h2>
            </div>
            <p className="consultant_text">
              DevOps teams require robust identity and access management (IAM)
              solutions to streamline workflows, enhance security, and ensure
              compliance.
            </p>
          </div>
        </div>
      </div>
      <div className="consultant_Section3">
        <div>
          <img
            style={{
              height: "100vh",
              float: "right",
              borderRadius: "0px 0px 0px 100px",
            }}
            src="https://demo.rstheme.com/wordpress/bizdesk/wp-content/uploads/2023/08/contact-bg-hm2.jpg"
            alt=""
          />
        </div>
        <div className="Consultant_form_container">
          <div>
            <h2
              className="Consutant_head"
              style={{
                textAlign: "center",
                fontWeight: "600",
                margin: "20px 0px",
              }}
            >
              Book an Appointment
            </h2>
          </div>
          <div className="small_input">
            <div>
              <input
                className="consultant_input"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="First Name"
                type="text"
                name=""
                id=""
              />
            </div>
            <div>
              <input
                className="consultant_input"
                value={lastname}
                onChange={(e) => setLastname(e.target.value)}
                placeholder="Last Name"
                type="text"
                name=""
                id=""
              />
            </div>
            <div>
              <input
                className="consultant_input"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                type="text"
                name=""
                id=""
              />
            </div>
            <div>
              <input
                className="consultant_input"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                placeholder="Subject"
                type="text"
                name=""
                id=""
              />
            </div>
            <div>
              <input
                className="consultant_input"
                value={phone}
                onChange={(e) => setphone(e.target.value)}
                placeholder="Phone"
                type="text"
                name=""
                id=""
              />
            </div>
            <div>
              <input
                className="consultant_input"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                placeholder="Available Date"
                type="text"
                name=""
                id=""
              />
            </div>
          </div>
          <div>
            <input
              type="text"
              className="consultant_input"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Message"
              style={{ width: "460px", height: "100px", marginTop: "20px" }}
              name=""
              id=""
            />
          </div>
          <div>
            <button
              className="consultant_book_btn"
              onClick={(e) => book_consultant(e)}
            >
              Book Now
            </button>
          </div>
        </div>
      </div>
      <Contact_banner />

      <Footer />
    </>
  );
}

export default Consultant;
