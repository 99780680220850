import React, { useState, useEffect } from "react";
import Axios from "axios";
import Logo from "../../Assets/Images/Logo.png";
import Swal from "sweetalert2";
import loadinggif from "./Images/Images/Spinner.gif";
import { useSearchParams } from "react-router-dom";
import Calender from "./Images/Images/Calendar.png";
import home from "./Images/Images/home.png";
import { NavLink } from "react-router-dom";
import './payment.css';
import { useNavigate } from "react-router-dom";
import file from "./Images/Images/file.png";
function Payment() {
  const [data, setdata] = useState([]);
  const[range_failed,setrange_failed]=useState('')
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setloading] = useState(false);
  const [wloading, setwloading] = useState(false);
  const [Amount, setAmount] = useState("");
  const [state, setState] = useState({
    ip: "",
    countryName: "",
    countryCode: "",
    city: "",
    timezone: "",
  });
  const [maxValue, setmaxValue] = useState(0);
  const [minValue, setminValue] = useState(0);
  const resp = searchParams.get("cid");
  const navigate = useNavigate();

  const fetchSingleCourse = async () => {
    var cnamee;
    var rel;
    let course_Am
    await Axios.get(`${process.env.REACT_APP_BASE_URL}/training?id=${resp}`, {})
      .then((response) => {
        console.log(response.data.course_category);
        //  rel = response.data.course_category;

        setdata(response.data);
         course_Am=response.data;
      })
      .then(() => {
        Axios.get("https://ipapi.co/json/")
          .then((response) => {
            let dataa = response.data;
            setState({
              ...state,
              ip: dataa.ip,
              countryName: dataa.country_name,
              countryCode: dataa.country_calling_code,
              city: dataa.city,
              timezone: dataa.timezone,
            });
           // console.log(state);
            console.log(dataa);
            console.log(course_Am);
          
          if (dataa.country_name == "India") {
            //  setmaxValue((response.data.course_fee.INR).split(' ')[1])
            setAmount(course_Am.course_fee.INR.split(" ")[1]);
            // setminValue(5000)
          } else {
            setAmount((course_Am.course_fee.USD).split(' ')[1]);
            setmaxValue(course_Am.course_fee.USD.split(" ")[1]);
            //  setminValue(     30)
          }
        })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((err) => {
        console.error(err);
      });

    // console.log(searchParams.get("status"))
  };
  const [full, setfull] = useState(true);
  const Amount_Select = (e) => {
    console.log(e.target.value);
    if (state.countryName == "India") {
      setmaxValue(data.course_fee.INR.split(" ")[1]);
      // setAmount((response.data.course_fee.INR).split(' ')[1]);
      setminValue(5000);
    } else {
      setmaxValue(data.course_fee.USD.split(" ")[1]);
      // setAmount((response.data.course_fee.INR).split(' ')[1]);
      setminValue(30);
    }

    if (e.target.value == "full") {
      setfull(true);
      //setAmount(data.course_fee.INR)
    } else {
      setfull(false);
    }
  };

  //useEffect(fetchSingleCourse, []);
  useEffect(() => {
    fetchSingleCourse();
  }, []);
  
  console.log(data);
  // check IP
  // useEffect(() => {
  //   Axios
  //   .get("https://ipapi.co/json/")
  //   .then((response) => {
  //       let data = response.data;
  //       setState({
  //           ...state,
  //           ip: data.ip,
  //           countryName: data.country_name,
  //           countryCode: data.country_calling_code,
  //           city: data.city,
  //           timezone: data.timezone
  //       });
  //   })
  //   .catch((error) => {
  //       console.log(error);
  //   });
  // }, []);

  // Payment Section
  const token = JSON.parse(localStorage.getItem("profile"));
console.log(token);
  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
        setTimeout(() => {
          setloading(false);
        }, 2000);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };
  const payment = async () => {
    console.log(Amount);
    console.log(data.course_fee.INR.split(" ")[1]);
    if (Amount < 5000 || Amount > data.course_fee.INR.split(" ")[1]) {
    //  alert("Your Amount is not in the range or invalid");
    setrange_failed(`Amount must be in range 5000 to ${data.course_fee.INR.split(" ")[1]}`)
      return;
    }
    console.log(data);
    const value = data;
    if (full) {
      setAmount(data.course_fee.INR.split(" ")[1]);
    }
    // console.log(Amount);
// return;
  //  console.log(Amount);

    if (token == null || token == "" || token == undefined) {
      console.log("not");
      navigate(`/login?redirect_url=payment?cid=${resp}`)
      return;

      setloading(true);
      console.log("payment clicked");
      console.log(JSON.stringify(localStorage));
      // const course_name =
      // return;
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );
      if (!res) {
        alert("Razor SDK failed to load");
        setloading(true);

        return;
      }
      console.log(`razorpay script loaded:: ${res}`);

      // return;
      // const localStorageData = localStorage.getItem("email");
      // const email_id = localStorage.getItem("user-email");
      // console.log(data)
      const course_id = value.course_id;
      const course_name = value.course_name;
      const razorpay_test_key = process.env.REACT_APP_RAZORPAY_TEST_KEY;
      const razorpay_data = {
        token: token,
        Amount: Amount,
        //  emailid: email_id,
        courseid: course_id,
        coursename: course_name,
        razorpaytestkey: razorpay_test_key,
      };
      console.log(razorpay_data);

      // return;
      const data = await fetch(
        `${process.env.REACT_APP_BASE_URL}/unauth/init-razorpay`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify(razorpay_data),
        }
      ).then((razorpay_return) => razorpay_return.json());
      console.log(data);
      const options = {
        key: razorpay_data.razorpaytestkey,
        amount: data.amount.toString(),
        currency: data.currency,
        name: data.coursename,
        description: data.coursename,
        image: "https://askmeidentity.com/",
        order_id: data.id,
        handler: async (response) => {
          Swal.fire({
            title: "Thanks!",
            text: "We have taken your payment request we will send you email after payment verify",
            icon: "success",
            showCancelButton: true,
          });
        },
      };
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
      return false;
    } else {
      paymentFunction(value);
    }
  };

  const paymentFunction = async (value) => {
    console.log(value);
    if (full) {
      setAmount(value.course_fee.INR.split(" ")[1]);
    } else {
    }
    setloading(true);
    console.log("payment clicked");
    console.log(JSON.stringify(localStorage));
    // const course_name =
    // return;

 //   console.log(Amount);
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      alert("Razor SDK failed to load");
      setloading(true);
      return;
    }
    console.log(`razorpay script loaded:: ${res}`);

    // return;
    // const localStorageData = localStorage.getItem("email");
    // const email_id = localStorage.getItem("user-email");
    // console.log(data)
    const course_id = value.course_id;
    const course_name = value.course_name;
    const razorpay_test_key = process.env.REACT_APP_RAZORPAY_TEST_KEY;
    const razorpay_data = {
      token: token.access_token,
      Amount: Amount,
      //  emailid: email_id,
      courseid: course_id,
      coursename: course_name,
      razorpaytestkey: razorpay_test_key,
    };
    console.log(razorpay_data);

    // return;
    const data = await fetch(
      `${process.env.REACT_APP_BASE_URL}/init-razorpay`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(razorpay_data),
      }
    ).then((razorpay_return) => razorpay_return.json());
    console.log(data);
    const options = {
      key: razorpay_data.razorpaytestkey,
      amount: data.amount.toString(),
      currency: data.currency,
      name: data.coursename,
      description: data.coursename,
      image: "https://askmeidentity.com/",
      order_id: data.id,
      handler: async (response) => {
       
        // return;

        console.log(`client:: ${JSON.stringify(response)}`);
       

        // let courseamount=(value.course_fee.INR).split(' ')[1]
        let courseamount = Amount;
        const test_point = await Axios.post(`${process.env.REACT_APP_BASE_URL}/verify-payment`, {
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_order_id: response.razorpay_order_id,
            razorpay_signature: response.razorpay_signature,
           // email: razorpay_data.emailid,

           token:token.access_token,
            amount: courseamount,
            courseid: razorpay_data.courseid,
            course_name: value.course_name
        })
        .then(function(response) {
            console.log(response);
            console.log(response.data.message);
            if (response.data=="Success") {
                console.log (response.data);
               // alert ("payment not verified");
               Swal.fire({
                title: 'Success!',
                text: 'You have Successfully Enrolled to the Course',
                icon: 'success',
               showCancelButton:true,
                confirmButtonText:'Dashboard'
              }).then((show)=>{
                if(show.isConfirmed){
                 window.location.replace('/dashboard')
                }
              });
            }
            else {
                console.log ("payment verified");
                // setModalShow(true)
                setloading(false);
                setloading(true);
                Swal.fire({
                 title: 'Oops...',
                 text: 'Something went wrong please try again',
                 icon: 'error',
                showCancelButton:true,
                 confirmButtonText:'Tryagain'
               })
               
            }
        })
        .catch((error)=>{
            console.log(error);
            Swal.fire({
              title: 'Oops...',
              text: 'Something went wrong please try again',
              icon: 'error',
             showCancelButton:true,
              confirmButtonText:'Tryagain'
            })

        })
        .then(()=>{
            // always executed
        });
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
    // return;

    // window.location.replace(data.payment_url);
  };

  // stripe payment
  const paymentStripe = async () => {
    console.log(Amount);
    console.log(data.course_fee.USD.split(" ")[1]);

    if (Amount < 50 || Amount >data.course_fee.USD.split(" ")[1] ) {
    //  alert("Your Amount is not in the range");
    setrange_failed(`Amount must be in range 50 to ${data.course_fee.USD.split(" ")[1]}`)
      return;
    }
    if (full) {
      setAmount(data.course_fee.INR.split(" ")[1]);
    }
    const value = data;
    console.log("cklikk");
    if (token == null || token == "" || token == undefined) {
      console.log("not");

  navigate(`/login?redirect_url=payment?cid=${resp}`)
      return;

      console.log(loading);
      
      await fetch(
        `${process.env.REACT_APP_BASE_URL}/unauth/create-checkout-session`,
        {
          method: "POST",
          headers: {
            "Content-type": "application/json",
          },
          body: JSON.stringify({ items: value.course_id, Amount: Amount }),
        }
      )
        .then((res) => {
          if (res.ok) return res.json();
          return res.json().then((json) => Promise.reject(json));
        })
        .then(({ url }) => {
          window.location = url;
        })
        .then(
          console.log(window.location.pathname)
          // Axios.get(`http://localhost:3002/verifystripe?id=${idd}`,{

          // }).then((res)=>{
          //   console.log(res)
          // })
        )
        .catch((e) => {
          console.error(e.error);
        });
    } else {
      console.log(loading);
      //  await Axios.post('http://localhost:3002/create-checkout-session',{
      //   token:token
      //  })
      let acc=token.access_token
      await fetch(`${process.env.REACT_APP_BASE_URL}/create-checkout-session`, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({ token: token.access_token, items: value.course_id, Amount: Amount }),
      })
        .then((res) => {
          if (res.ok) return res.json();
          return res.json().then((json) => Promise.reject(json));
        })
        .then(({ url }) => {
          window.location = url;
        })
        .then(
          console.log(window.location.pathname)
          // Axios.get(`http://localhost:3002/verifystripe?id=${idd}`,{

          // }).then((res)=>{
          //   console.log(res)
          // })
        )
        .catch((e) => {
          console.error(e.error);
        });
    }
  };

  return (
    <>
      <div>
      <NavLink to='/home'><img style={{ marginLeft: "50px",width:"150px" }} src={Logo} alt="" /></NavLink> 

      
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div className="Payment_page_discription">
            <h1 className="Modal_head_ans">{data.course_name}</h1>
            <div style={{ display: "flex", marginTop: "50px" }}>
              <div>
                <img
                  style={{ width: "250px", height: "150px" }}
                  src={data.image_url}
                  alt=""
                />
              </div>
              <div style={{ marginLeft: "20px" }}>
                <p
                  style={{
                    fontSize: "20px",
                    marginTop: "5px",
                    fontFamily: "Inter",
                  }}
                >
                  {" "}
                  <img src={home} alt="" /> Instructor :
                  <span
                    style={{
                      opacity: "0.5",
                      fontSize: "10",
                      fontFamily: "Inter",
                    }}
                  >
                    {data.course_instructor}
                  </span>{" "}
                </p>
                <p
                  style={{
                    fontSize: "20px",
                    marginTop: "25px",
                    fontFamily: "Inter",
                  }}
                >
                  {" "}
                  <img src={file} alt="" /> Source :{" "}
                  <span
                    style={{
                      opacity: "0.5",
                      fontSize: "10",
                      fontFamily: "Inter",
                    }}
                  >
                    {data.course_source}
                  </span>{" "}
                </p>
                <p
                  style={{
                    fontSize: "20px",
                    marginTop: "25px",
                    fontFamily: "Inter",
                  }}
                >
                  {" "}
                  <img src={Calender} alt="" /> Duration :{" "}
                  <span
                    style={{
                      opacity: "0.5",
                      fontSize: "10",
                      fontFamily: "Inter",
                    }}
                  >
                    {data.course_duration}
                  </span>
                </p>

                {data.course_fee ? (
                  state.countryName == "India" ? (
                    <p
                      style={{
                        fontSize: "20px",
                        marginTop: "25px",
                        fontFamily: "Inter",
                      }}
                    >
                      {" "}
                      Amount :{" "}
                      <span
                        style={{
                          opacity: "0.5",
                          fontSize: "10",
                          fontFamily: "Inter",
                        }}
                      >
                        {data.course_fee.INR}
                      </span>
                    </p>
                  ) : (
                    <p
                      style={{
                        fontSize: "20px",
                        marginTop: "25px",
                        fontFamily: "Inter",
                      }}
                    >
                      {" "}
                      Amount :{" "}
                      <span
                        style={{
                          opacity: "0.5",
                          fontSize: "10",
                          fontFamily: "Inter",
                        }}
                      >
                        {data.course_fee.USD}
                      </span>
                    </p>
                  )
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="payment_form_section">
            <div>
              <label htmlFor="" className="payment_label_type">
                Payment type
              </label>
              <select
                className="payment_input_type"
                name=""
                id=""
                onChange={Amount_Select}
              >
                <option value="full">Full Payment</option>
                <option value="partial">Partial Payment</option>
              </select>
            </div>
            <div>
              {full ? (
                ""
              ) : (
                <>
                  <div>
                    <label htmlFor="" className="payment_label_type">
                      Enter Amount
                    </label>
                    <input
                      className="payment_input_type"
                      placeholder="Amount"
                      min={minValue}
                      max={maxValue}
                      onChange={(e) => setAmount(e.target.value)}
                      type="number"
                    />
                  </div>
                </>
              )}
            </div>
            <div>
              <label htmlFor="" className="payment_label_type">
                Name
              </label>
              <input
                type="text"
                placeholder="Name"
                className="payment_input_type"
                name=""
                id=""
              />
            </div>
            <div>
              <label htmlFor="" className="payment_label_type">
                Email
              </label>
              <input
                type="email"
                placeholder="Email"
                className="payment_input_type"
              />
            </div>
            <div>
              <label htmlFor="" className="payment_label_type">
                Phone
              </label>
              <input
                type="number"
                placeholder="Phone"
                className="payment_input_type"
              />
            </div>

            {
              (range_failed=='')?(''):(
                
                <>
                <p style={{fontSize:"18px",marginTop:"10px",fontFamily:"Inter",float:"right",color:"red"}}>{range_failed}</p>
                </>
                )
            }
            <br />

            {state.countryName == "India" ? (
              <button
                className="contactbtn"
                style={{
                  borderRadius: "10px",
                  fontFamily: "Inter",
                  float: "right",
                }}
                onClick={() => payment()}
              >
                {loading ? (
                  <img
                    style={{
                      width: "30px",
                      height: "30px",
                      marginBottom: "30px",
                    }}
                    src={loadinggif}
                    alt=""
                  />
                ) : (
                  "Pay Now"
                )}
              </button>
            ) : (
              <button
                onClick={() => paymentStripe()}
                className="contactbtn"
                style={{
                  borderRadius: "10px",
                  fontFamily: "Inter",
                  float: "right",
                }}
              >
                Pay Now
              </button>
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            marginTop: "8vh",
            justifyContent: "center",
            marginBottom:"5vh"
          }}
        >
          <NavLink to="/term" className="Modal_Links">
            Terms
          </NavLink>
          <NavLink to="/privacy" className="Modal_Links">
            Privacy
          </NavLink>
          <NavLink to="/refund" className="Modal_Links">
            Refund
          </NavLink>
        </div>
      </div>
    </>
  );
}
export default Payment;
