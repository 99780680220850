import React from 'react'
import Ping from  '../../Assets/Images/Ping.png';
import {NavLink} from 'react-router-dom'
function Webd() {
  return (
    <NavLink to={`/training?cid=node.js`} style={{textDecoration:'none'}}>
    <div style={{width:"230px",marginLeft:"15px",marginRight:"15px",textDecoration:"none"}}>
        <img style={{width:"200px",height:"100px"}} src='https://firebasestorage.googleapis.com/v0/b/askmeidentity-website-ppe.appspot.com/o/Images-training%2Fnodeimg.png?alt=media&token=2b4883a3-fc9e-4400-84a0-96462c31e967' alt="" />
        <div style={{padding:"10px"}}>
        <h5 className='Course_cart_head'>Nodejs</h5>
        <p className='Course_cart_text'>Sumit k Tiwari</p>
        <p style={{color:"#E59819"}}>4.5 ⭐⭐⭐</p>
        </div>
    </div>
    </NavLink>
  )
}

export default Webd