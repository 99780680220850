import React, { useEffect } from "react";
import Navbar from "../../Components/header/Header";
import Footer from "../../src/Component/Fotter/Fotter";
import Img from "./Images/im.jpg";
import { Helmet } from "react-helmet";
import IDAM from "./Services/IDAM";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
function AllSevicesComp() {
  const [Active, setActive] = useState("IDAM");
  const [searchParams, setSearchParams] = useSearchParams();
  let id = searchParams.get("sid");
  const [helmet_data, sethelmet_data] = useState({
    title:
      "Best IDAM Service Provider| Customer Satisfaction|Elevate your security",
    keyword: [
      "Secure Digital Identity",
      "Certified Consultants",
      "Customer Satisfaction",
      "On Time Delivery",
      "cutting-edge Digital Workspace Solutions ",
      "simplifies passwords",
    ],
    description:
      "Unlock Seamless Security with Askmeidentity's IDAM Service. Elevate Your Digital Identity Management Effortlessly.",
  });
  let keywordString;
  useEffect(() => {
    if (id == "identity-access-management") {
      setActive("IDAM");
      sethelmet_data({
        title:
          "Best IDAM Service Provider| Customer Satisfaction|Elevate your security",
        keyword: [
          "Secure Digital Identity",
          "Certified Consultants",
          "Customer Satisfaction",
          "On Time Delivery",
          "cutting-edge Digital Workspace Solutions ",
          "simplifies passwords",
        ],
        description:
          "Unlock Seamless Security with Askmeidentity's IDAM Service. Elevate Your Digital Identity Management Effortlessly.",
      });
    } else if (id == "managed-it-solutions") {
      setActive("ITM");
      sethelmet_data({
        title:
          "Explore our managed IT Services| Elevate IT with Askmeidentity. Lead in the Digital Age",
        keyword: [
          "Cybersecurity Expertise",
          "Best Service Provider.",
          "Strategic IT Planning",
          "Proactive IT Management",
          "Cost-Effective Solutions",
        ],
        description:
          "Optimize Your Business with Askmeidentity's Managed IT Solutions - Seamless, Secure, and Reliable IT Management for Your Success.",
      });
    } else if (id == "application-development") {
      setActive("AD");
      sethelmet_data({
        title:
          "Unlocking Possibilities with Application Development|Identity-Driven App Development",
        keyword: [
          "Architects of digital transformation",
          "software applications",
          "Seamless User Experiences",
          "On Time Delivery",
          "Innovation at Its Core",
          "safeguard your applications",
        ],
        description:
          "Transform Your Vision into Reality with Askmeidentity's App Development Services. Elevate Your Business with Tailored Applications.",
      });
    } else if (id == 4) {
      setActive("staff-augmentation");
      sethelmet_data({
        title:
          "Empower Your Projects with Augmented Staff|Efficient Staff Augmentation Solutions",
        keyword: [
          "Agile staff augmentation",
          "Certified Consultants",
          "Expert IT augmentation",
          "Extended Team Solutions",
          "Flexible staff augmentation",
          "Extended Team Solutions",
        ],
        description:
          "Efficient and tailored IT augmentation to meet your project demands – trust our experienced team.",
      });
    }
    keywordString = helmet_data.keyword.join(", ");
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title> {helmet_data.title}</title>
        <meta name="description" content={helmet_data.description} />
        <meta name="keywords" content={keywordString} />
      </Helmet>
      <Navbar />
      <div className="Sevice_header">
        <div style={{ width: "100%", height: "500px" }}>
          <img
            className="s_Header_img"
            style={{ width: "100%" }}
            src={Img}
            alt=""
          />
        </div>
        <div className="Overlay"></div>
        <div style={{ padding: "10px" }} className="Service_heading">
          <h2 className="S_heading">Our Services</h2>
          <p className="S_heading_text" >
            Our comprehensive suite of IAM services, including Managed IT,
            Identity and Access Management,Cybersecurity Training, and Staff
            Augmentation, empowers organizations to fortify their security
            posture and excel in the digital age.
          </p>
        </div>
      </div>

      <div className="sevice_nav">
        <div className="Service_nac_inner">
          <p
            className={`Service_nav_item ${Active === "IDAM" ? "Active" : ""}`}
            onClick={() => setActive("IDAM")}
          >
            IDAM
          </p>
          <p
            className={`Service_nav_item ${Active === "ITM" ? "Active" : ""}`}
            onClick={() => setActive("ITM")}
          >
            Managed IT
          </p>
          <p
            className={`Service_nav_item ${Active === "AD" ? "Active" : ""}`}
            onClick={() => setActive("AD")}
          >
            Application Development
          </p>
          <p
            className={`Service_nav_item ${Active === "SA" ? "Active" : ""}`}
            onClick={() => setActive("SA")}
          >
            Staff Augmentation
          </p>
          <p
            className={`Service_nav_item ${Active === "T" ? "Active" : ""}`}
            onClick={() => setActive("T")}
          >
            Training
          </p>
        </div>
      </div>

      <div>
        {Active === "IDAM" && <IDAM id="5" />}
        {Active === "T" && <IDAM id="1" />}
        {Active === "AD" && <IDAM id="2" />}
        {Active === "SA" && <IDAM id="3" />}
        {Active === "ITM" && <IDAM id="4" />}
      </div>
      <Footer />
    </>
  );
}

export default AllSevicesComp;
