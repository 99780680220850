import React,{useState,useEffect} from 'react'
import Rectangle from "../Images/Rectangle.png";
import {NavLink} from 'react-router-dom'

function Contact_banner() {
    const [text, setText] = useState("");
    const [fullText, setFullText] = useState("We're here to help you !");
    const [index, setIndex] = useState(0);
      useEffect(() => {
    
    if (index < fullText.length) {
      if (index == fullText.length - 1) {
        setIndex(0);
        setText("");
      }
      setTimeout(() => {
        setText(text + fullText[index]); 
        setIndex(index + 1);
      }, 200);
    }
  }, [index]);

  return (
<div className='Service_main_Container'>
    <div className='Service_inner_Container'>
    <div
          className="SI_section1"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div className="contact_banner_container">
            <NavLink to="/contact">
              <img src={Rectangle} alt="" style={{ width: "100%" }} />
              <div className="Contact_banner_text">
                <h2
                  className="Service_contact_Heading"
                  style={{ color: "White" }}
                >
                  Talk to an <br /> Identity Expert
                </h2>
                <br />

                <p
                  className="Service_text"
                  style={{ color: "rgba(255, 255, 255, 0.60)" }}
                >
                  {text}
                </p>
              </div>
            </NavLink>
          </div>
        </div>
    </div>
  </div>   
   )
}

export default Contact_banner