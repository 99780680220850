import React from 'react'
import c1 from './../../Assets/Images/image1.png'
import c2 from './../../Assets/Images/image2.png'
import c3 from './../../Assets/Images/image3.png'
import CL1 from '../../Assets/Images/CL1.png'
function Category() {
  return (
    <>
    <div style={{display:"flex",justifyContent:"center",paddingBottom:"50px",paddingTop:"30px",background:"#F7F9FA"}}>
    <div style={{maxWidth:"1100px"}}>
      <h3  style={{fontSize:"30px"}}>Top categories</h3>
       <div style={{display:"flex",flexWrap:"wrap"}}>
         <div className='Category_container'>
            <img style={{height:"150px"}} src={c1} alt="" />
            <h5 className='Categry_text'>IAM</h5>
         </div>
         <div className='Category_container'>
            <img style={{height:"150px"}} src={c2} alt="" />
            <h5 className='Categry_text'>Security</h5>
         </div>
         <div className='Category_container'>
            <img style={{height:"150px"}} src={c3} alt="" />
            <h5 className='Categry_text'>Web  <br /> Development</h5>
         </div>

       </div>
    </div>
</div>

<div style={{display:"flex",justifyContent:"center",alignItems:'center',margin:"50px 0px"}}>
    <div style={{maxWidth:"1000px",width:"100%"}}>
        <h3 style={{textAlign:"center",marginBottom:"20px",fontSize:"xx-large",fontFamily:"Inter"}}>Trusted by over 1000+ students</h3>
        <p className='Course_cart_text' style={{textAlign:"center",marginBottom:"50px",fontSize:"16px"}}>Leading companies uses our courses to keep their talents equipped with new skills.</p>
         <div style={{display:"flex",flexWrap:"wrap",justifyContent:"center",marginBottom:"30px"}}>
            <img style={{width:"170px",padding:"30px"}} src='https://firebasestorage.googleapis.com/v0/b/askmeidentity-website.appspot.com/o/website_data%2Ftlc.png?alt=media&token=267740a3-c113-4eb2-b628-b4110daaafaf' alt="" />

            <img style={{width:"150px",margin:"30px",backgroundColor:'gray',height:"68px",borderRadius:"10px"}}  src='https://firebasestorage.googleapis.com/v0/b/askmeidentity-website.appspot.com/o/website_data%2Fmindmajix.svg?alt=media&token=6aa1eaa3-80c5-4a83-af53-462e475b1586' alt="" />

            <img style={{width:"180px",padding:"30px"}}  src='https://firebasestorage.googleapis.com/v0/b/askmeidentity-website.appspot.com/o/website_data%2FIdentityHub_Logo.png?alt=media&token=0bb678f0-1a36-4859-8211-d084b97901bc' alt="" />
            <img style={{width:"150px",padding:"30px"}}  src='https://firebasestorage.googleapis.com/v0/b/askmeidentity-website.appspot.com/o/website_data%2Fwetrainiam.jpg?alt=media&token=6c96d41b-3e20-4bdf-ab5d-8fc76310ee47' alt="" />


         </div>

    </div>
</div>
    </>
  )
}

export default Category