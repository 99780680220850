import React from 'react'
import UserLogin from '../../Component/User/Login';
import Navbar from '../../Component/Navbar/Navbar';
import Fotter from '../../Component/Fotter/Fotter';
function Login() {
  return (
    <>
    <Navbar/>
    <UserLogin/>
    <Fotter/>
    </>
  )
}

export default Login