import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Bar2 from "../Images/bar2.png";
import Clock from "../Images/Clock.png";
import db from "./../../firebase";
import Search from "../Images/search.png";
import Okta from "../Images/Okta.png";
import Forgerock1 from "../Images/Forgerock1.png";
import Onelogin from "../Images/Onelogin.png";
import Ping from "../Images/Ping.png";
import Auth0 from "../Images/Auth0.png";
import Back from "../Images/Back.png";
import Frame from "../Images/Frame.png";
import "./ping.css";
import Axios from "axios";
import Nav from "../header/Header";
import Footer from "../Footer";
import Home_Consultant from "../Homee/Home_Consultant";

function Allconsultant() {
    const [courses, setcourses] = useState([]);
    const [IAM, setIAM] = useState([]);
    const [cor, setcor] = useState("");
    const [iamcor, setiamcor] = useState("");
    const [searchText, setSearchText] = useState("");

    const FetchData = async () => {
        await Axios.get(`${process.env.REACT_APP_BASE_URL}/course`)
            .then((response) => {
                console.log(response.data);
                setcor(response.data.length);
                let count = 0;
                for (let i = 0; i < response.data.length; i++) {
                    if (
                        response.data[i].course_category ===
                        "Identity and Access Management"
                    ) {
                        count++;
                    }
                }
                console.log(count);
                setiamcor(count);
                //setcourses(arr => [...arr, response.data]);
                setcourses(response.data);
                // localStorage.setItem('data', JSON.stringify(user._tokenResponse))
            })
            .catch((err) => {
                console.log(`${process.env.REACT_APP_BASE_URL}/course`);
                console.error(err);
            });
    };

    useEffect(FetchData, []);

    const [all,setAll] = useState(true);
    const [IAMChecked,setIAMChecked] = useState(false);
    const [SecurityChecked,setSecurityChecked] = useState(false);
    const [webChecked,setwebChecked] = useState(false);

    const fetchIAM = async () => {
      setIAMChecked(!IAMChecked)
        // var rel = "Identity and Access Management";

        // await Axios.get(
        //     `${process.env.REACT_APP_BASE_URL}/Related_training?rel_course=${rel}`
        // )
        //     .then((response) => {
        //         console.log(response.data);
        //         setcourses(response.data);
        //     })
        //     .catch((err) => {
        //         console.error(err);
        //     });

       
    };
    const fetchAll = async (event) => {
        // await Axios.get(`${process.env.REACT_APP_BASE_URL}/course`)
        //     .then((response) => {
        //         console.log(response.data);

        //         //setcourses(arr => [...arr, response.data]);
        //         setcourses(response.data);
        //         // localStorage.setItem('data', JSON.stringify(user._tokenResponse))
        //     })
        //     .catch((err) => {
        //         console.error(err);
        //     });
        setAll(!all);
        event.target.checked = all;
    };

    const fetchsecurity = () => {
     setSecurityChecked(!SecurityChecked)
    };

    const fetchwebd = () => {
       setwebChecked(!webChecked)
    };
    // console.log(courses);

    // console.log();

    const [allChecked,setAllChecked] = useState(true);
    const [beginnerChecked,setBeginnerChecked] = useState(false);
    const [intermediateChecked,setIntermediateChecked] = useState(false);
    const [expertChecked,setExpertChecked] = useState(false);

    const allLevelChange = async (event) => {
        setAllChecked(!allChecked);
        event.target.checked = allChecked;
    };
    const beginnerLevelChange = async () => {
        setBeginnerChecked(!beginnerChecked);
    };
    const intermediateLevelChange = async () => {
        setIntermediateChecked(!intermediateChecked)
    };
    const expertLevelChange = async () => {
        setExpertChecked(!expertChecked);
    };
    const [state, setState] = useState({
        ip: "",
        countryName: "",
        countryCode: "",
        city: "",
        timezone: ""
    });
    
      useEffect(() => {
        Axios
        .get("https://ipapi.co/json/")
        .then((response) => {
            let data = response.data;
            setState({
                ...state,
                ip: data.ip,
                countryName: data.country_name,
                countryCode: data.country_calling_code,
                city: data.city,
                timezone: data.timezone
            });
        })
        .catch((error) => {
            console.log(error);
        });
      }, []);

    const [originalCourses,setOriginalCourses] = useState();
    useEffect(async ()=>{
        await Axios
        .get(`${process.env.REACT_APP_BASE_URL}/course`)
        .then((response) => {
            setOriginalCourses(response.data);
        })
        .catch((err) => {
            console.error(err);
        });
    },[]);

    const searchClick = () => {
        const new_courses = [];
        originalCourses.map((course) => {
            if (course.course_name.toLowerCase().includes(searchText.toLocaleLowerCase().trim())) {
                new_courses.push(course);
            } else {
            }
        })
        setcourses(new_courses);
    };
    return (
        <>
            <Nav />
            <div
                style={{
                    height: "175px",
                    marginTop:"100px",
                    display: "flex",
                    backgroundImage: `url(${Back})`,
                }}
            >
                <p
                    style={{
                        marginLeft: "20%",
                        marginTop: "80px",
                        fontSize: "40px",
                        fontWeight: "700",
                        color: "#3C3C3B",
                        fontFamily: "Inter",
                    }}
                >
                    Training
                </p>
            </div>
            <div className="maintrainingcomp">
                <div className="trainingcheck">
                    <div style={{ display: "flex" }}>
                    <input onChange={(event) => setSearchText(event.target.value)}
                            type="text" className="Search_inp" name="" placeholder="Search..." id="" />
                        <img src={Search} alt="" />
                        <button onClick = {searchClick}
                            style={{ marginTop: "100px", width: "60px", background: "rgba(37,214,225,1)", border: "1px solid white", borderRadius: "7px", }} >
                            <img style={{ marginTop: "4px" }} src={Search} alt="" />
                        </button>

                    </div>
                    <div className="about-blog">
                        <p
                            style={{
                                fontSize: "25px",
                                fontWeight: "bold",
                                fontFamily: "Inter",
                            }}
                        >
                            {" "}
                            <img src={Bar2} alt="" /> Categories
                        </p>
                        <p
                            style={{
                                fontSize: "20px",
                                marginTop: "15px",
                                fontFamily: "Inter",
                            }}
                        >
                            {" "}
                            <input
                                type="checkbox"
                                onChange={fetchAll}
                                checked={all}
                                style={{
                                    height: "15px",
                                    width: "15px",
                                    border: "1px solid #000000",
                                    borderRadius: "2px",
                                }}
                            />{" "}
                            <span style={{ opacity: "0.9", fontFamily: "Inter" }}>
                                {" "}
                                All ({cor})
                            </span>
                        </p>
                        <p
                            style={{
                                fontSize: "20px",
                                marginTop: "15px",
                                fontFamily: "Inter",
                            }}
                        >
                            {" "}
                            <input
                                type="checkbox"
                                onChange={fetchIAM}
                                style={{
                                    height: "15px",
                                    width: "15px",
                                    border: "1px solid #000000",
                                    borderRadius: "2px",
                                }}
                            />{" "}
                            <span style={{ opacity: "0.9", fontFamily: "Inter" }}>
                                {" "}
                                IAM ({iamcor})
                            </span>
                        </p>
                        <p
                            style={{
                                fontSize: "20px",
                                marginTop: "15px",
                                fontFamily: "Inter",
                            }}
                        >
                            {" "}
                            <input
                                type="checkbox"
                                onChange={fetchsecurity}
                                style={{
                                    height: "15px",
                                    width: "15px",
                                    border: "1px solid #000000",
                                    borderRadius: "2px",
                                }}
                            />{" "}
                            <span style={{ opacity: "0.9", fontFamily: "Inter" }}>
                                {" "}
                                Security (0)
                            </span>{" "}
                        </p>
                        <p
                            style={{
                                fontSize: "20px",
                                marginTop: "15px",
                                fontFamily: "Inter",
                            }}
                        >
                            {" "}
                            <input
                                type="checkbox"
                                onChange={fetchwebd}
                                style={{
                                    height: "15px",
                                    width: "15px",
                                    border: "1px solid #000000",
                                    borderRadius: "2px",
                                }}
                            />
                            <span style={{ opacity: "0.9", fontFamily: "Inter" }}>
                                Web Development (0)
                            </span>{" "}
                        </p>
                    </div>

                    <div className="about-blog" style={{ height: "300px" }}>
                        <p
                            style={{
                                fontSize: "25px",
                                fontWeight: "bold",
                                fontFamily: "Inter",
                            }}
                        >
                            {" "}
                            <img src={Bar2} alt="" /> Filter
                        </p>

                        <p
                            style={{
                                fontSize: "20px",
                                marginTop: "20px",
                                fontFamily: "Inter",
                            }}
                        >
                            {" "}
                            <input
                                type="checkbox"
                                style={{
                                    height: "15px",
                                    width: "15px",
                                    border: "1px solid #000000",
                                    borderRadius: "2px",
                                }}
                                checked={allChecked}
                                onChange={allLevelChange}
                            />{" "}
                            <span style={{ opacity: "0.9", fontFamily: "Inter" }}>
                                {" "}
                                All Level
                            </span>
                        </p>
                        <p
                            style={{
                                fontSize: "20px",
                                marginTop: "15px",
                                fontFamily: "Inter",
                            }}
                        >
                            {" "}
                            <input
                                type="checkbox"
                                style={{
                                    height: "15px",
                                    width: "15px",
                                    border: "1px solid #000000",
                                    borderRadius: "2px",
                                }}
                                onChange={beginnerLevelChange}
                            />{" "}
                            <span style={{ opacity: "0.9", fontFamily: "Inter" }}>
                                {" "}
                                Beginner
                            </span>{" "}
                        </p>
                        <p
                            style={{
                                fontSize: "20px",
                                marginTop: "15px",
                                fontFamily: "Inter",
                            }}
                        >
                            {" "}
                            <input
                                type="checkbox"
                                style={{
                                    height: "15px",
                                    width: "15px",
                                    border: "1px solid #000000",
                                    borderRadius: "2px",
                                }}
                                onChange={intermediateLevelChange}
                            />
                            <span style={{ opacity: "0.9", fontFamily: "Inter" }}>
                                {" "}
                                Intermediate
                            </span>{" "}
                        </p>
                        <p
                            style={{
                                fontSize: "20px",
                                marginTop: "15px",
                                fontFamily: "Inter",
                            }}
                        >
                            {" "}
                            <input
                                type="checkbox"
                                style={{
                                    height: "15px",
                                    width: "15px",
                                    border: "1px solid #000000",
                                    borderRadius: "2px",
                                }}
                                onChange={expertLevelChange}
                            />{" "}
                            <span style={{ opacity: "0.9", fontFamily: "Inter" }}>
                                Expert
                            </span>{" "}
                        </p>
                    </div>
                </div>

                <div className="Maintraining">
                    {courses.map((course) => (
                        (allChecked || all) && (
                            <Home_Consultant
                            img={course.image_url}
                            key={course.course_id}
                            name={course.course_name}
                            cid={course.course_id}
                            color={course.image_background}
                            fee={(state.countryName=='India')?(course.consultant_fee.INR):(course.consultant_fee.USD)}
                            Instructer={course.course_instructor}
                            time={course.course_duration}
                            level={course.course_level}
                        />
                        )
                    ))}
                     {courses.map((course) => (
                        allChecked===false && IAMChecked===true && course.course_category==="Identity and Access Management" ? (
                            <Home_Consultant
                            img={course.image_url}
                            key={course.course_id}
                            name={course.course_name}
                            cid={course.course_id}
                            color={course.image_background}
                            fee={(state.countryName=='India')?(course.consultant_fee.INR):(course.consultant_fee.USD)}
                            Instructer={course.course_instructor}
                            time={course.course_duration}
                            level={course.course_level}
                        />
                        ) : ("")
                    ))}

{courses.map((course) => (
                        allChecked===false && webChecked===true && course.course_category==="Web Development" ? (
                            <Home_Consultant
                            img={course.image_url}
                            key={course.course_id}
                            name={course.course_name}
                            cid={course.course_id}
                            color={course.image_background}
                            fee={(state.countryName=='India')?(course.consultant_fee.INR):(course.consultant_fee.USD)}
                            Instructer={course.course_instructor}
                            time={course.course_duration}
                            level={course.course_level}
                        />
                        ) : ("")
                    ))}
                    {courses.map((course) => (
                        allChecked===false && SecurityChecked===true && course.course_category==="Security" ? (
                            <Home_Consultant
                            img={course.image_url}
                            key={course.course_id}
                            name={course.course_name}
                            cid={course.course_id}
                            color={course.image_background}
                            fee={(state.countryName=='India')?(course.consultant_fee.INR):(course.consultant_fee.USD)}
                            Instructer={course.course_instructor}
                            time={course.course_duration}
                            level={course.course_level}
                        />
                        ) : ("")
                    ))}
                    {courses.map((course) => (
                        allChecked===false && beginnerChecked===true && course.course_level==="beginner" ? (
                            <Home_Consultant
                            img={course.image_url}
                            key={course.course_id}
                            name={course.course_name}
                            cid={course.course_id}
                            color={course.image_background}
                            fee={(state.countryName=='India')?(course.consultant_fee.INR):(course.consultant_fee.USD)}
                            Instructer={course.course_instructor}
                            time={course.course_duration}
                            level={course.course_level}
                        />
                        ) : ("")
                    ))}
                    {courses.map((course) => (
                        allChecked===false && intermediateChecked===true && course.course_level==="intermediate" ? (
                            <Home_Consultant
                            img={course.image_url}
                            key={course.course_id}
                            name={course.course_name}
                            cid={course.course_id}
                            color={course.image_background}
                            fee={(state.countryName=='India')?(course.consultant_fee.INR):(course.consultant_fee.USD)}
                            Instructer={course.course_instructor}
                            time={course.course_duration}
                            level={course.course_level}
                        />
                        ) : ("")
                    ))}
                    {courses.map((course) => (
                        allChecked===false && expertChecked===true && course.course_level==="expert" ? (
                            <Home_Consultant
                            img={course.image_url}
                            key={course.course_id}
                            name={course.course_name}
                            cid={course.course_id}
                            color={course.image_background}
                            fee={(state.countryName=='India')?(course.consultant_fee.INR):(course.consultant_fee.USD)}
                            Instructer={course.course_instructor}
                            time={course.course_duration}
                            level={course.course_level}
                        />
                        ) : ("")
                    ))}
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Allconsultant;