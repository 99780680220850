import React from 'react'
import User_register from '../../Component/User/Register';
import Navbar from '../../Component/Navbar/Navbar';
import Fotter from '../../Component/Fotter/Fotter';
function Register() {
  return (
<>
<Navbar/>
<User_register/>
<Fotter/>
</>  )
}

export default Register