import React from "react";
import icon from "../Images/icon.png";
import icon2 from "../Images/icon2.png";
import icon3 from "../Images/icon3.png";
import icon4 from "../Images/icon4.png";
import icon5 from "../Images/icon5.png";
import Rectangle9 from "../Images/Rectangle9.png";
import Rectangle10 from "../Images/Rectangle10.png";
function Servicesdescription(props) {
  return (
    <>
      <div className="Service_Content">
        <img className="Service_ContentImg" style={{borderRadius:"20px 20px 0px 0px"}} src={props.img} alt="" />
        <p
          style={{
            fontSize: "25px",
            fontFamily: "Inter",
            fontWeight: "600",
            marginTop: "30px",
            marginLeft: "20px",
          }}
        >
          {props.title}
        </p>
        <p
          style={{
            fontSize: "17px",
            fontFamily: "Inter",
            fontWeight: "400",
            marginTop: "20px",
            color: "grey",
            marginLeft: "20px",
            marginRight: "20px",
          }}
        >
          {props.Servicesdescription}
        </p>
        <div className="Service_Points">
          <div
            className="Service_Pointsinside"
            style={{
              background: "black",
              height: "150px",
              borderRadius: "7px",
            }}
          >
            <p style={{ marginTop: "3px", display: "flex" }}>
              <img className="Simgtt" src={icon2} alt="" />{" "}
              <span
                style={{
                  marginLeft: "85px",
                  fontSize: "20px",
                  fontWeight: "500",
                  color: "rgba(37,214,225,1)",
                  marginTop: "30px",
                  fontFamily: "Inter",
                }}
              >
                {" "}
                Certified Consultants{" "}
                <span
                  style={{ fontSize: "15px", fontWeight: "normal" }}
                ></span>{" "}
                <p
                  style={{
                    fontSize: "15px",
                    marginTop: "10px",
                    color: "white",
                    fontFamily: "Inter",
                    marginRight: "10px",
                  }}
                >
                  {" "}
                  Our team of certified consultants with years of industry
                  experience.
                </p>
              </span>
            </p>
          </div>
          <div
            className="Service_Pointsinside"
            style={{
              background: "black",
              height: "150px",
              borderRadius: "7px",
            }}
          >
            <p style={{ marginTop: "3px", display: "flex" }}>
              <img className="Simgtt" src={icon} alt="" />{" "}
              <span
                style={{
                  marginLeft: "85px",
                  fontSize: "18px",
                  fontWeight: "500",
                  color: "rgba(37,214,225,1)",
                  marginTop: "30px",
                  fontFamily: "Inter",
                }}
              >
                {" "}
                Customer Satisfaction{" "}
                <span
                  style={{ fontSize: "15px", fontWeight: "normal" }}
                ></span>{" "}
                <p
                  style={{
                    fontSize: "15px",
                    marginTop: "10px",
                    fontFamily: "Inter",
                    color: "white",
                    marginRight: "10px",
                  }}
                >
                  {" "}
                  On-Time SLA based delivery of solutions makes us the top
                  choice among our clients.
                </p>
              </span>
            </p>
          </div>
        </div>
        <div className="Service_about">
          <img style={{ marginTop: "50px" }} src={Rectangle10} alt="" />
          <div style={{ marginLeft: "40px", marginTop: "30px" }}>
            <p
              style={{
                fontSize: "25px",
                fontwight: "600",
                fontFamily: "Inter",
                marginTop: "20px",
              }}
            >
              Our Portfolio
            </p>
            <p
              style={{
                fontSize: "16px",
                fontwight: "400",
                fontFamily: "Inter",
                marginTop: "10px",
              }}
            >
              1. Digital workspace Solution.
            </p>
            <p
              style={{
                fontSize: "16px",
                fontwight: "400",
                fontFamily: "Inter",
                marginTop: "10px",
              }}
            >
              2.Cloud and Internet Security Solutions.
            </p>
            <p
              style={{
                fontSize: "16px",
                fontwight: "400",
                fontFamily: "Inter",
                marginTop: "10px",
              }}
            >
              3. System Integration.
            </p>
            <p
              style={{
                fontSize: "16px",
                fontwight: "400",
                fontFamily: "Inter",
                marginTop: "10px",
              }}
            >
              3. Consulting.
            </p>
          </div>
        </div>
        <div className="Sercice_info">
          <div style={{ display: "flex", width: "250px", marginTop: "30px" }}>
            <div
              style={{
                height: "75px",
                width: "70px",
                background: "black",
                textAlign: "center",
                borderRadius: "7px",
              }}
            >
              <img
                style={{
                  background: "black",
                  height: "40px",
                  weight: "40px",
                  marginTop: "15px",
                }}
                src={icon3}
                alt=""
              />
            </div>
            <p
              style={{
                fontSize: "20px",
                fontWeight: "600",
                fontFamily: "Inter",
                marginLeft: "10px",
                marginTop: "20px",
              }}
            >
              On Time <br /> Delivery.
            </p>
          </div>
          <div style={{ display: "flex", width: "250px", marginTop: "30px" }}>
            <div
              style={{
                height: "75px",
                width: "70px",
                background: "black",
                textAlign: "center",
                borderRadius: "7px",
              }}
            >
              <img
                style={{
                  background: "black",
                  height: "40px",
                  weight: "40px",
                  marginTop: "15px",
                }}
                src={icon4}
                alt=""
              />
            </div>
            <p
              style={{
                fontSize: "20px",
                fontWeight: "600",
                fontFamily: "Inter",
                marginLeft: "10px",
                marginTop: "20px",
              }}
            >
              Value for
              <br /> Money.
            </p>
          </div>

          <div style={{ display: "flex", width: "250px", marginTop: "30px" }}>
            <div
              style={{
                height: "75px",
                width: "70px",
                background: "black",
                textAlign: "center",
                borderRadius: "7px",
              }}
            >
              <img
                style={{
                  background: "black",
                  height: "40px",
                  weight: "40px",
                  marginTop: "15px",
                }}
                src={icon5}
                alt=""
              />
            </div>
            <p
              style={{
                fontSize: "20px",
                fontWeight: "600",
                fontFamily: "Inter",
                marginLeft: "10px",
                marginTop: "20px",
              }}
            >
              Best Service
              <br /> Provider.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Servicesdescription;
