import React from 'react'
import icon14 from "../Images/icon14.png"
import icon15 from "../Images/icon15.png"
import Fa from "../Images/Fa.png"
import In from "../Images/In.png"
import Twi from "../Images/Twi.png"
import Insta from "../Images/Insta.png"

function Navsocial() {
  return (
    <>
        <div className='Navsocial'>
            <div className='Nav_lcomp'><p style={{fontSize:"14px",fontFamily: "Inter",fontWeight:"500"}}><img src={icon14} alt="" />  askmeidentity.com  - <img src={icon15} alt="" />  +91 70037 52368 </p></div>
            <div className='Nav_Rcomp'>
            <a href="https://www.facebook.com/askmeidentity"  ><img style={{height:"20px",marginTop:"8px"}} src={Fa} alt="" /></a>
            <a href="https://www.linkedin.com/company/modern-identity-private-limited/" ><img style={{height:"20px",marginLeft:"10px",marginTop:"8px"}} src={In} alt="" /></a>
            
            </div>
        </div>
    </>
  )
}

export default Navsocial