import React ,{useState,useEffect} from 'react'
import {NavLink} from "react-router-dom"
import Axios from 'axios'
import Okta from "../../Components/Images/Okta.png"
import Clock from "../../Components/Images/Clock.png"
import Frame from "../../Components/Images/Frame.png"
import { uselocation } from "react-router-dom"

function Home_Training(props) {
  
   const [courrency,setCurrency]=useState('')
  // if(state.countryName=='India'){
  //   setCurrency(props.fee.INR);
  // }else{
  //   setCurrency(props.fee.USD);

  // }
// if(props.ip.countryName=='India'){
//   console.log(props.ip.countryName);
//   setCurrency(props.fee.INR)
//   console.log(courrency)
// }


  return (
   <>
   <div style={{margin:"20px 20px"}}>
         <div style={{background:props.color}}  className='trainingbox1'>
      <img src={props.img} alt=""  className='trainingimage'/>
      <div style={{border:"1px solid lightgray",borderRadius:"20px",height:"180px",background:"white"}}>
          <h1 style={{fontSize:'24px',textAlign:"center",marginTop:"15px",fontFamily:"Inter",fontWeight:"600"}}>{props.name}</h1>
          <h2 style={{display:"flex",float:"right", fontSize:'20px',marginRight:"10px",marginTop:'20px',fontFamily:"Inter"}}>  <img src={Clock} alt=""style={{marginRight:"5px"}} />   {props.time}</h2>
          <div style={{fontSize:"23px",color:"gray",display:"flex",marginLeft:'20px',marginTop:"0px",fontFamily:"Inter",fontWeight:"500"}}>{props.Instructer}</div>

          <div style={{fontSize:"33px",display:"flex",marginLeft:'20px',marginTop:"0px",fontFamily:"Inter",fontWeight:"650"}}>{props.fee}</div>
      

    <div style={{display:"flex",float:"right"}}>
          <NavLink to={{
   pathname:`/training-detail?cid=${props.cid}`,
   state:{title:"hey"},
  
 }} 
  style={{fontSize:'20px', float:"right",marginRight:"10px",margin:"0px 10px",color:"black",fontFamily:"Inter",fontWeight:"600"}}> Enroll Now  </NavLink>
  <span style={{marginTop:"5px",marginRight:"15px"}}><img src={Frame}  alt="" /></span>
  </div>
      </div>
            </div>
            </div>
   </>
  )
}

export default Home_Training


