import React, { useState, useEffect, useRef } from "react";
import "./newHome.css";
import Hero_img from "./Img/Hero.png";
import Case_courasel from "./Case_courasel";
import Service_img from "./Img/serv.png";
import cloud from "./Img/Cloud.gif";
import computer from "./Img/Computer.gif";
import Flippy, { FrontSide, BackSide } from 'react-flippy';
import train from "./Img/Mortarboard.gif";
import idea from "./Img/Idea.gif";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Mini from './Img/miniOrange.webp'
import TechImg from './Img/3139256.jpg'
import About from "./Img/about.png";
import Axios from "axios";
import Course_cart from "../../src/Component/Home/course_cart";
import Left from "./Img/left.png";
import Right from "./Img/right.png";
import Home_Blogs from "./Home_Blogs";
import Group1 from "../../Components/Images/Group1.png";
import Group2 from "../../Components/Images/Group2.png";
import Group3 from "../../Components/Images/Group3.png";
import Frame from "../Images/Frame.png";
import Contact_banner from "../Servicecomp.js/Services/Contact_baner";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import Id from './Img/padlock-unscreen.gif'
import Ap from './Img/payment-app-unscreen.gif'
import MIT from './Img/web-browser-unscreen.gif'
import T from './Img/book-unscreen.gif';
import AOS from 'aos';
import 'aos/dist/aos.css';
import SA from './Img/work-list-unscreen.gif';
import About_Animation from './Img/Animation.png'
function New_home() {
  const sliderRef = useRef();
  const [courses, setCourses] = useState([]);

  const FetchData = async () => {
    await Axios.get(
      `https://us-central1-askmeidentity-website.cloudfunctions.net/as/course`
    )
      .then((response) => {
        console.log(response, "hjgyj");
        setCourses(response.data);
      })
      .catch((err) => {
        //console.log(`https://us-central1-askmeidentity-website.cloudfunctions.net/as/course`);
        console.error(err);
      });
  };

  useEffect(FetchData, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // const sliderRef = useRef();
  // const courses = useSelector((state) => state.courses);

  console.log(courses);
  const settings = {
    dots: true,
    button: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true, // Enables autoplay
    autoplaySpeed: 2000, // Sets autoplay interval to 3 seconds
    responsive: [
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const slll = [1, 3, , 4, 5, 6];
 
    useEffect(()=>{
      AOS.init({duration:2000})
    },[]);
  const slides = [
    {
      text1:
        "SharpITS had all the answers we were looking for in order to transform and modernize our identity access management platforms. From helping us pick the vendors, defining our target state architectures and roadmaps, saving a significant amount of time and cost, to supporting us with our journey in transforming our IAM platforms - SharpITS was there all along.",
      head1: "Enterprise Architect",
      place1: "Canadian Financial Institution",
    },
    {
      text1:
        "I highly recommend SharpITS for any IAM related work. I’ve worked with SharpITS consultants on a highly complex eHR implementation. SharpITS consultants are professional and calm under pressure and have demonstrated excellent problem solving skills and leadership during stressful situations. They are dedicated to finding solutions that exceed customer expectations.",
      head1: "Sr. Project Manager,",
      place1: "Canadian Telecom",
    },
    {
      text1:
        "We partnered with SharpITS to deliver Ping related implementations and stabilize the Ping IAM platform for a Canadian home health care client. SharpITS consultant was very knowledgeable and was able to quickly help our client resolve the outstanding issues. SharpITS has been very responsive, responsible and accountable.",
      head1: " Director of Services Delivery",
      place1: "Canadian Security Consulting Firm",
    },
    {
      text1:
        "I had the opportunity to work with SharpITS team through one of the critical projects for our provincial health care systems. I found SharpITS team to be extremely knowledgeable particularly in Identity Management (IDM). It is evident from our support calls and debugging sessions for the project that SharpITS team has very detailed technical knowledge about the product .",
      head1: " Sr. Project Manager",
      place1: " Canadian Provincial Healthcare",
    },
    {
      text1:
        "We started a partnership with SharpITS for a PAM project in Summer 2022 and soon SharpITS became our go-to partner for our PAM and IAM projects. SharpITS was able to provide high quality resources in a very short time, often on the same day or next day whenever requested, and always responsive, responsible and accountable. ",
      head1: " Head of Professional Services",
      place1: " IAM Consulting Firm",
    },
    {
      text1:
        "Jim from SharpITS consistently demonstrated excellent technical knowledge and a strong work ethic to align his contribution to the strategic business goals of a complex organization. I found Jim a ready partner to work towards the best solution. He is always ready to offer an informed opinion and support it persuasively. ",
      head1: "Enterprise IAM Architect",
      place1: " Canadian Government",
    },
    {
      text1:
        "SharpITS has been our go-to partner for our digital identity professional services. SharpITS was able to provide high quality resources in a timely manner whenever requested, and always responsive, responsible and accountable.SharpITS has helped us deliver multiple IAM projects. The quality, dedication and professionalism is second to none.",
      head1: "Partner",
      place1: "Global Consulting Firm",
    },
    {
      text1:
        "SharpITS had all the answers we were looking for in order to transform and modernize our identity access management platforms. From helping us pick the vendors, defining our target state architectures and roadmaps, saving a significant amount of time and cost, to supporting us with our journey in transforming our IAM platforms - SharpITS was there all along.",
      head1: "Enterprise Architect",
      place1: "Canadian Financial Institution",
    },
    {
      text1:
        "I highly recommend SharpITS for any IAM related work. I’ve worked with SharpITS consultants on a highly complex eHR implementation. SharpITS consultants are professional and calm under pressure and have demonstrated excellent problem solving skills and leadership during stressful situations. They are dedicated to finding solutions that exceed customer expectations.",
      head1: " Sr. Project Manager,",
      place1: "Canadian Telecom",
    },
  ];
  const keywords = ["IAM Managed Services", "Managed Services", "Managed Services for IAM", "IAM Managed Service Providers", "Best IAM Managed Service Providers"]
    
      const keywordString = keywords.join(', ');
  console.log(slides);
  return (
    <>
  
      <div className="n_container1">
        <div className="n_container2">
          <div className="n_container2_l">
            <h2 data-aos="fade-up" className="n_container2_head">
              Master and{" "}
              <span style={{ color: "#56b320", fontFamily: "Inter" }}>
                Safeguard digital identity{" "}
              </span>{" "}
            </h2>
            <br />
            <p data-aos="fade-up" className="n_container2_text" style={{ fontSize: "18px" }}>
              We have established ourselves as a distinguished and widely
              recognized leader in the realm of identity and access management
              (IAM) training and consulting services. Our extensive array of
              courses covers a wide range of topics, including Ping Identity
              Consultant, Okta Workflows: Zero to Hero, Okta Professionals
              Primer, and Okta Specials: Complete HR As A Source
            </p>
          </div>
          <div className="n_container2_r">
            <img data-aos="fade-up" style={{ width: "100%" }} src={Hero_img} alt="" />
          </div>
        </div>
        <div className="n_container2">
          <div className="About_home_css" style={{ marginTop: "2%",width:"40%" }}>
          <p data-aos="fade-up" className="n_container2_text" style={{ color: "#56b320" }}>
              Services & Offering
            </p>
            <p data-aos="fade-up" className="n_container2_head" style={{ fontSize: "30px" }}>
              Redefining Modern Identity Solutions and IT Security
            </p>
            <br />
            <div 
            data-aos="fade-up"
              style={{
                fontFamily:"Inter",
                fontSize:"17px"
              }}
            >
             At the heart of our offerings are cutting-edge IDAM (Identity and Access Management) Solutions, the foundation for accelerating digital transformation while prioritizing user experience. Our Managed IT Solutions encompass Digital Workspace and Cloud Services, optimizing business operations.

             
            </div>
            <br />
            <br />
            <img
              style={{
                width: "90%",
                background: "white",
                borderRadius: "25px",
              }}
              src={Service_img}
              alt=""
            />
           
            
          </div>

         
          <div
            className="n_container2_r1"
            style={{ width: "65%", padding: "10px",display:"flex",flexWrap:"wrap",gap:"30px" }}
          >
            <Flippy data-aos="fade-up"  flipOnHover={true} flipDirection="horizontal" className='flip_card' >
    <FrontSide style={{borderRadius:"20px"}}>
    
                    <img style={{ height: "20px" ,float:"right"}} src='https://assets-global.website-files.com/62cd14d1378c5d4fb293537e/62ea58573733b4ad59cc1481_turn-icon.svg' alt="" />
                    <img className="flip_img" src={Id} alt="" />
                    <h2
                      className="n_container2_text"
                      style={{
                        fontWeight: "600",
                        paddingTop: "9px",
                        fontSize: "17px",
                      }}
                    >
                      IDAM Solutions
                    </h2>
        </FrontSide>
        <BackSide  className="Back_flip">
        <div className="Back_flip">
          <div className="Back_flip_container" style={{position:"relative",height:"95%"}}>
        <p style={{ fontFamily: "Inter", fontSize: "17px" ,position:"absolute",bottom:"30px"}}>
                  Accelerate Digital transformation, Focus on user experience,
                  Reliable solution
                </p>
                <NavLink style={{fontFamily:"Inter",fontSize:"18px",fontWeight:"600",textDecoration:"none",color:"white",position:"absolute",bottom:"0",right:"10px"}} to='/services?sid=identity-access-management'>Read More </NavLink>
                </div>
          </div>
        </BackSide>
    </Flippy>
    <Flippy data-aos="fade-up" flipOnHover={true} flipDirection="horizontal" className='flip_card' >
    <FrontSide style={{borderRadius:"20px"}}>
    
    <img style={{ height: "20px",float:"right" }} src='https://assets-global.website-files.com/62cd14d1378c5d4fb293537e/62ea58573733b4ad59cc1481_turn-icon.svg' alt="" /> 
    <img className="flip_img" src={MIT} alt="" />

                       <h2
                      className="n_container2_text"
                      style={{
                        fontWeight: "600",
                        paddingTop: "9px",
                        fontSize: "17px",
                      }}
                    >
Managed IT Services
                    </h2>
        </FrontSide>
        <BackSide  className="Back_flip">
        <div className="Back_flip">
          <div className="Back_flip_container" style={{position:"relative",height:"95%"}}>
        <p style={{ fontFamily: "Inter", fontSize: "17px" ,position:"absolute",bottom:"30px"}}>
        Digital workspace solutions, cloud and infrastructure services
                </p>
                <NavLink style={{fontFamily:"Inter",fontSize:"18px",fontWeight:"600",textDecoration:"none",color:"white",position:"absolute",bottom:"0",right:"10px"}} to='/services?sid=managed-it-solutions'>Read More </NavLink>
                </div>
          </div>
        </BackSide>
    </Flippy>
    <Flippy data-aos="fade-up" flipOnHover={true} flipDirection="horizontal" className='flip_card' >
    <FrontSide style={{borderRadius:"20px"}}>
    
    <img style={{ height: "20px",float:"right" }} src='https://assets-global.website-files.com/62cd14d1378c5d4fb293537e/62ea58573733b4ad59cc1481_turn-icon.svg' alt="" />  
    <img className="flip_img" src={Ap} alt="" />

                      <h2

                      className="n_container2_text"
                      style={{
                        fontWeight: "600",
                        paddingTop: "9px",
                        fontSize: "17px",
                      }}
                    >
Application Development
                    </h2>
        </FrontSide>
        <BackSide  className="Back_flip">
        <div className="Back_flip">
          <div className="Back_flip_container" style={{position:"relative",height:"95%"}}>
        <p style={{ fontFamily: "Inter", fontSize: "17px" ,position:"absolute",bottom:"30px"}}>
        Empowering clients with innovative solutions, driving digital innovation.
                </p>
                <NavLink style={{fontFamily:"Inter",fontSize:"18px",fontWeight:"600",textDecoration:"none",color:"white",position:"absolute",bottom:"0",right:"10px"}} to='/services?sid=application-development'>Read More </NavLink>
                </div>
          </div>
        </BackSide>
    </Flippy>
    <Flippy data-aos="fade-up" flipOnHover={true} flipDirection="horizontal" className='flip_card' >
    <FrontSide style={{borderRadius:"20px"}}>
    
    <img style={{ height: "20px" ,float:"right"}} src='https://assets-global.website-files.com/62cd14d1378c5d4fb293537e/62ea58573733b4ad59cc1481_turn-icon.svg' alt="" />  
    <img className="flip_img" src={SA} alt="" />

                      <h2
                      className="n_container2_text"
                      style={{
                        fontWeight: "600",
                        paddingTop: "9px",
                        fontSize: "17px",
                      }}
                    >
Staff Augmentation
                    </h2>
        </FrontSide>
        <BackSide  className="Back_flip">
        <div className="Back_flip">
          <div className="Back_flip_container" style={{position:"relative",height:"95%"}}>
        <p style={{ fontFamily: "Inter", fontSize: "17px" ,position:"absolute",bottom:"30px"}}>
        Your trusted partner for seamless staff augmentation solutions.
                </p>
                <NavLink style={{fontFamily:"Inter",fontSize:"18px",fontWeight:"600",textDecoration:"none",color:"white",position:"absolute",bottom:"0",right:"10px"}} to='/services?sid=staff-augmentation'>Read More </NavLink>
                </div>
          </div>
        </BackSide>
    </Flippy>
    <Flippy data-aos="fade-up" flipOnHover={true} flipDirection="horizontal" className='flip_card' >
    <FrontSide style={{borderRadius:"20px"}}>
    
    <img style={{ height: "20px" ,float:"right"}} src='https://assets-global.website-files.com/62cd14d1378c5d4fb293537e/62ea58573733b4ad59cc1481_turn-icon.svg' alt="" />  
    <img className="flip_img" src={T} alt="" />

                      <h2
                      className="n_container2_text"
                      style={{
                        fontWeight: "600",
                        paddingTop: "9px",
                        fontSize: "17px",
                      }}
                    >
Training                    </h2>
        </FrontSide>
        <BackSide  className="Back_flip">
          <div className="Back_flip">
          <div className="Back_flip_container" style={{position:"relative",height:"95%"}}>
        <p style={{ fontFamily: "Inter", fontSize: "17px" ,position:"absolute",bottom:"30px"}}>
        Askmeidentity trainers offer specialized IAM (Identity and Access Management) training, providing comprehensive courses 
                </p>
                <NavLink style={{fontFamily:"Inter",fontSize:"18px",fontWeight:"600",textDecoration:"none",color:"white",position:"absolute",bottom:"0",right:"10px"}} to='/services?sid=training'>Read More </NavLink>
                </div>
          </div>
         
        </BackSide>
    </Flippy>
          </div>
        </div>
      </div>
      <br />
<br />

<div data-aos="fade-up" style={{display:"flex",justifyContent:"center",marginTop:"100px"}}>
  <div className="teach_p_img" style={{position:"relative"}}>
  <img style={{width:"100%",height:"100%",borderRadius:"20px"}} src={TechImg} alt="" />
  <h2  className='TectImg_text' >Our Official Tech Partners</h2>
  <p className="TectImg_text1" >Empowering IAM with cutting-edge innovation, <br />
Our official tech partners redefine authentication precision</p>
  <div className="tech_logo">
    <img style={{width:"100px",height:"50px"}} src='https://assets-global.website-files.com/62cd14d1378c5d4fb293537e/62f1f63221554670c4d12fde_Okta_Partners_Logos-p-500.webp' alt="" />
    <img src="https://www.miniorange.com/images/footer/miniorange-white.webp" style={{width:"170px",height:"50px"}} alt="" />
    <img src="https://miracl.com/images/logos/miracl-white.png" style={{width:"200px",height:"50px"}} alt="" />
    <img src='https://monokee.com/wp-content/uploads/2023/10/Monokee.color-positive_w300_h138_negative.png' style={{width:"170px",height:"60px"}} alt="" />
  </div>
  </div>
  
</div>
      <div className="n_container3" style={{marginTop:"100px"}}>
        <div className="n_container3_inner">
          <div className="n_container3_l">
            <p data-aos="fade-up" className="n_container2_text" style={{ color: "#56b320" }}>
              About us
            </p>
            <p data-aos="fade-up" className="n_container2_head" style={{ fontSize: "30px" }}>
              Orchestrating IAM Excellence: Leading Providers of Identity and
              Access Management Solutions
            </p>
            <p data-aos="fade-up" className="n_container2_text" style={{ fontSize: "15px" }}>
              Askmeidentity is a leading provider of identity and access
              management (IAM) training and consulting services. We offer a wide
              range of courses, including Ping Identity Consultant, Okta
              Workflows: Zero to Hero, Okta Professionals Primer, and Okta
              Specials: Complete HR As A Source. Our courses are designed to
              help you learn the skills you need to become a certified IAM
              professional or to implement and manage IAM solutions in your
              organization. <br />
              <br />
              Our courses are taught by experienced IAM professionals who have a
              deep understanding of the latest IAM technologies and best
              practices. We also offer hands-on exercises and labs so that you
              can practice what you learn in our courses.
            </p>
          </div>
          <div className="n_container3_r" style={{display:"flex"}}>
            <img data-aos="fade-up" style={{ width: "100%",height:"auto",mixBlendMode:"darken" }} src={About_Animation} alt="" />
          </div>
        </div>
      </div>
      <br />
      <div className="Case_background" style={{display:"flex",justifyContent:"center"}}>
        <div style={{marginTop:"100px",display:"flex",flexDirection:"column",alignItems:"center"}}> 
        <h2 className="n_container2_head" style={{ textAlign:"center",color: "#56b320" }}>Unlocking Success with Askmedietity</h2>
        <p className="n_container2_text" style={{textAlign:"center",fontSize:"16px"}}>A Journey Through Our Exceptional Case Studies Showcasing Innovative Solutions and Transformative Impact</p>
        <br />
            <Case_courasel/>
        </div>

          </div>
      <div className="n_container3">
        <div style={{ textAlign: "center" }}>
          <p className="n_container2_text" style={{ color: "#56b320" }}>
            Training
          </p>
          <p className="n_container2_head" style={{ fontSize: "30px" }}>
            Providing training programs with insightful skills to <br /> build
            strategic IAM solutions
          </p>
        </div>
      </div>
      <div className="n_container3">
        <div className="test_coresal_home" style={{ position: "relative" }}>
          <button className="btn_testimonial_l">
            <img
              onClick={() => sliderRef.current.slickPrev()}
              style={{
                backgroundColor: "#1A53DD",
                width: "30px",
                borderRadius: "50%",
              }}
              src={Left}
              alt=""
            />
          </button>
          <button
            className="btn_testimonial_r"
            onClick={() => sliderRef.current.slickNext()}
          >
            <img
              style={{
                backgroundColor: "#1A53DD",
                width: "30px",
                borderRadius: "50%",
              }}
              src={Right}
              alt=""
            />
          </button>

          {courses == "undefined" || courses == "" ? (
            ""
          ) : (
            <Slider {...settings} ref={sliderRef}>
              {courses.map((course, index) =>
                course.course_id == "4521" ? (
                  ""
                ) : (
                  <Course_cart
                    name={course.course_name}
                    instructor={course.course_instructor}
                    fee={course.course_fee}
                    img={course.image_url}
                    cid={course.course_id}
                    url={course.url}
                  />
                )
              )}
            </Slider>
          )}
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "50px 0px",
        }}
      >
        <div style={{ maxWidth: "1000px", width: "100%" }}>
          <h3
            style={{
              textAlign: "center",
              marginBottom: "20px",
              fontSize: "xx-large",
              fontFamily: "Inter",
            }}
          >
            Trusted by over 1000+ students
          </h3>
          <p
            className="Course_cart_text"
            style={{
              textAlign: "center",
              marginBottom: "50px",
              fontSize: "16px",
              fontFamily: "Inter",
              color: "#56b320",
            }}
          >
            Leading companies uses our courses to keep their talents equipped
            with new skills.
          </p>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              marginBottom: "30px",
            }}
          >
            <img
              style={{ width: "170px", padding: "30px" }}
              src="https://firebasestorage.googleapis.com/v0/b/askmeidentity-website.appspot.com/o/website_data%2Ftlc.png?alt=media&token=267740a3-c113-4eb2-b628-b4110daaafaf"
              alt=""
            />

            <img
              style={{
                width: "150px",
                margin: "30px",
                backgroundColor: "gray",
                height: "68px",
                borderRadius: "10px",
              }}
              src="https://firebasestorage.googleapis.com/v0/b/askmeidentity-website.appspot.com/o/website_data%2Fmindmajix.svg?alt=media&token=6aa1eaa3-80c5-4a83-af53-462e475b1586"
              alt=""
            />

            <img
              style={{ width: "180px", padding: "30px" }}
              src="https://firebasestorage.googleapis.com/v0/b/askmeidentity-website.appspot.com/o/website_data%2FIdentityHub_Logo.png?alt=media&token=0bb678f0-1a36-4859-8211-d084b97901bc"
              alt=""
            />
            <img
              style={{ width: "150px", padding: "30px" }}
              src="https://firebasestorage.googleapis.com/v0/b/askmeidentity-website.appspot.com/o/website_data%2Fwetrainiam.jpg?alt=media&token=6c96d41b-3e20-4bdf-ab5d-8fc76310ee47"
              alt=""
            />
          </div>
        </div>
      </div>
      <div>
        <div className="Services">
          <div className="">
            <h1
              className="n_container2_text"
              style={{ color: "#56b320", textAlign: "center" }}
            >
              Latest Blogs & News
            </h1>
            <p
              className="n_container2_head"
              style={{ fontSize: "30px", textAlign: "center" }}
            >
              Learn More About How We Can Benefit Your Business Blogs. <br />{" "}
              Use Cases. Insightful Research
            </p>
          </div>
        </div>
        <div className="Hblog" style={{ justifyContent: "center" }}>
          <Home_Blogs
            img={Group1}
            img1={Frame}
            name={"Impact of Remote Work on IAM"}
            date={" 07 , Jan 2022"}
            bid={2}
          />
          <Home_Blogs
            img={Group2}
            img1={Frame}
            name={"Research: IAM Growth & Futures"}
            date={" 19 , Nov 2021"}
            bid={3}
          />
          <Home_Blogs
            img={Group3}
            img1={Frame}
            name={"Best JavaScript Frameworks"}
            date={"15 , Aug 2021"}
            bid={1}
          />
          <Home_Blogs
            img="https://firebasestorage.googleapis.com/v0/b/askmeidentity-website.appspot.com/o/website_data%2Fblog-03.jpg?alt=media&token=4b54cb11-29e8-4a44-9a16-30a82ed12141"
            img1={Frame}
            name={"The Rise of Multi-factor Authentication (MFA)"}
            date={"19 , Aug 2023"}
            bid={3223}
          />
        </div>
      </div>
      
      <Contact_banner/>
    </>
  );
}

export default New_home;
