import React, { useState, useEffect } from "react";
import book1 from "../Images/books1.gif";
import db from "../../firebase";
import book2 from "../Images/award.gif";
import icon13 from "../Images/icon13.png";
import { NavLink } from "react-router-dom";
import Axios from "axios";
import Lecture from './Lecture'
import firebase from "firebase/compat/app";
function Maindash() {
  const [data, setdata] = useState("");
  const [Active, setActive] = useState("Maindash");

  const daata = [
    // { name: "Okta Administrator", rate: 5, go: "" },
    // { name: "Pingfederate Administrator", rate: 4.5, go: "" },
    // { name: "Forgerock Administrator", rate: 5, go: ""},
  ];
  const token = JSON.parse(localStorage.getItem("data"));
  const FetchData = async () => {
    await Axios.post(`${process.env.REACT_APP_BASE_URL}/getinfo`, {
      token: token,
    })
      .then((response) => {
        console.log(response.data);

        setdata(response.data);
      })
      .catch((err) => {
        console.error(err.response.data.err.code);
        if (err.response.data.err.code === "auth/id-token-expired") {
          alert("your session is expired please login again");
          localStorage.removeItem("data");
          window.location.replace("/login");
        }
      });

    console.log(data);
    console.log(data.length);

    //   var r;
    //   const Rel = db.collection('user');
    //  // (token.localId==null||token.localId==undefined||token.localId=='')?(r=token.uid):(r=token.localId)
    //  r=token
    //   Rel.where('user_id', '==',r).get().then((snapshot) => {
    //     if (snapshot.empty) {
    //       console.log('No matching documents.');
    //       return;
    //     }
    //     snapshot.forEach(element => {
    //       var course = element.data();
    //      setdata(course)
    //     //  setref_data(course)
    //     //   setref_data(arr => [...arr, course]);
    //       console.log(course.course)

    //       // const Rel = db.collection('courses');
    //       // Rel.where('course_name', '==',course.course).get().then((snapshot) => {
    //       //   if (snapshot.empty) {
    //       //     console.log('No matching documents.');
    //       //     return;
    //       //   }
    //       //   snapshot.forEach(element => {
    //       //     var course = element.data();

    //       //   //  setref_data(course)
    //       //      setdata(arr => [...arr, course]);
    //       //     console.log(course)

    //       //   })
    //       // })

    //     })
    //   })
  };

  useEffect(FetchData, []);

  return (
    <>{
    (Active=='Maindash')?(
      <div className="maindash_view">
        <div>
          <p className="Dash_heading"> Dashboard</p>
          <div className="maindash_enrolled" style={{ display: "flex" }}>
            <img
              style={{ width: "80px", height: "80px", margin: "20px 30px" }}
              src={book1}
              alt=""
            />
            <div>
              <p
                style={{
                  fontSize: "45px",
                  fontFamily: "Inter",
                  marginTop: "25px",
                  fontWeight: "700",
                  marginLeft: "10px",
                  color: "rgba(37,214,225,1)",
                }}
              >
                {" "}
                {data.length} <br />
              </p>
              <p>
                {" "}
                <span
                  style={{
                    fontSize: "24px",
                    fontFamily: "Inter",
                    fontWeight: "600",
                    marginLeft: "10px",
                  }}
                >
                  {" "}
                  Enrolled Trainings
                </span>
              </p>
            </div>
          </div>
          <div className="maindash_enrolled" style={{ display: "flex" }}>
            <img
              style={{ width: "80px", height: "80px", margin: "20px 30px" }}
              src={book1}
              alt=""
            />
            <div>
              <p
                style={{
                  fontSize: "45px",
                  fontFamily: "Inter",
                  marginTop: "25px",
                  fontWeight: "700",
                  marginLeft: "10px",
                  color: "rgba(37,214,225,1)",
                }}
              >
                {" "}
                {data.length}{" "}
              </p>

              <p>
                {" "}
                <span
                  style={{
                    fontSize: "24px",
                    fontFamily: "Inter",
                    fontWeight: "600",
                    marginTop: "25px",
                    marginLeft: "10px",
                  }}
                >
                  Active Trainings
                </span>
              </p>
            </div>
          </div>
          <div className="maindash_enrolled" style={{ display: "flex" }}>
            <img
              style={{ width: "80px", height: "80px", margin: "20px 30px" }}
              src={book2}
              alt=""
            />
            <div>
              <p
                style={{
                  fontSize: "45px",
                  fontFamily: "Inter",
                  fontWeight: "700",
                  marginLeft: "10px",
                  color: "rgba(37,214,225,1)",
                }}
              >
                {" "}
                {data.complete_course} <br />
              </p>
              <p>
                {" "}
                <span
                  style={{
                    fontSize: "24px",
                    fontFamily: "Inter",
                    fontWeight: "600",
                    marginLeft: "10px",
                  }}
                >
                  {" "}
                  Completed Trainings
                </span>
              </p>
            </div>
          </div>
        </div>

        <div className="table_margin">
          <p className="Dash_heading">My Trainings</p>
         
          <div className="table">
           
            <table className="tablee">

              <tr className="tr">
                <th className="th">Training Name</th>
                <th className="th">Categories</th>
                <th className="th">Learn</th>
              </tr>
              

              {data.length==0?(''):(
                data.map((name) => (
                    <tr className="tr">
                      <td className="td">{name.course_name}</td>
                      <td className="td">
                        {" "}
                        {name.course_category}
                      </td>
                      <td className="td">
                        {" "}
                        <NavLink style={{ color: "rgba(37,214,225,1)" ,fontFamily:"Inter"}} to="" onClick={(e) => setActive("Lecture")}>
                          View{" "}
                        </NavLink>{" "}
                        
                      </td>
                    </tr>
                  )))
                 
}
              {/* {data.enrolled.map((val, key) => {
          return (
            <tr className='tr' key={key}>
              <td className='td'>{val.course1}</td>
              <td className='td'> <img src={icon13} alt="" /> {val.rate}</td>
              <td className='td'> <NavLink style={{color:"rgba(37,214,225,1)"}} to={val.go}>View </NavLink> </td>
            </tr>
          )
        })} */}
            </table>
          </div>
          
          {
              data.length==0?(  <p style={{marginTop:"20px"}}> 
              
              <p style={{ fontSize:"25px" ,fontFamily:"Inter"}}>You are not enrolled into trainings.</p>
              <NavLink style={{ color: "rgba(37,214,225,1)",fontSize:"30px" ,fontFamily:"Inter"}} to="/maintraining" >
              Enroll Now
                       </NavLink>
                       </p> ):('')

            
            } 
        </div>
        
      </div>):(<Lecture   />)
    }
     
    </>
  );
}

export default Maindash;
