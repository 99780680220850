import React,{useEffect} from 'react'
import Training from '../../Component/Training/Training';
import Navbar from '../../Component/Navbar/Navbar'
import Fotter from '../../Component/Fotter/Fotter';
import axios from 'axios'
function Training_detail() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

   
  const token = JSON.parse(localStorage.getItem("profile"));

  useEffect(() => {
    if (token == null || token == "" || token == undefined) {
    return;
    }else{
      axios
      .post(`${process.env.REACT_APP_BASE_URL}/getname`, {
        token: token.access_token,
      })
      .then((response) => {
       // console.log(response.data);

      //  setdata(response.data);
      })
      .catch((err) => {
        console.error(err.response.data.err.message);
        if(err.response.data.err.message=="Jwt is expired"){
          localStorage.clear();
          window.location.replace("/");
        }
      });
    }
   
  }, [])
  


  return (
<>
<Navbar/>
<Training/>
<Fotter/>
</> 
 )
}

export default Training_detail