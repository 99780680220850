import React,{useEffect,useState} from 'react'
import db from '../../firebase'
import Calender from "../Images/Calendar.png"
import Rect5 from "../Images/Rectangle5.png"
import Rect6 from "../Images/Rectangle6.png"
import Arrow from "../Images/Arrow.png"
import Bar2 from "../Images/bar2.png"
import Axios from 'axios'
import Rect1 from "../Images/Rectangle1.png"
import Rect2 from "../Images/Rectangle2.png"
import Rect3 from "../Images/Rectangle3.png"
import Rect4 from "../Images/Rectangle4.png"
import { NavLink } from 'react-router-dom'
import Nav from "../../Components/Nav/Navbar"
import Footer from '../../src/Component/Fotter/Fotter'
import Back from "../Images/Back.png"
import "./mainblog.css"
import Blog_ from './Blog_'
import { Helmet } from 'react-helmet'
import Navbar from '../header/Header';
import Home_Blogs from '../Homee/Home_Blogs'
import Frame from '../Images/Frame.png'
function Mainblog() {
const[blog,setblog]=useState([])
const[blogno,setblogno]=useState([])
  const FetchData = async() => {

    await  Axios.get(`${process.env.REACT_APP_BASE_URL}/All_blogs`)
    .then((response)=>{
    console.log(response.data)
   
    setblog(response.data)
  setblogno(response.data.length)
  }).catch(err => {
      console.error(err);
  
  })
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

    // db.collection('blogs').get().then((querySnapshot) => {
    //     querySnapshot.forEach(element => {
    //         var data = element.data();
         
    //         setblog(arr => [...arr, data]);
            

    //     })
    // });
  }

  useEffect(
    FetchData, []
  );
  console.log(blog)
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const keywords = ["IAM Growth & Futures", "Adoption of cloud-based IAM solutions", "Passwordless Authentication", "IAM Solution", "Digital Identity","training"]
  
  const keywordString = keywords.join(', ');
  return (
    <>
       <Helmet>
            <title> Cybersecurity Insights by Askmeidentity
      </title>
            <meta name="description" content="Stay Secure with Askmeidentity's IAM Insights. Explore Our Blog for Expert IAM Best Practices." />
            <meta name="keywords" content={keywordString} />
          </Helmet>
    <Navbar/>
    <div style={{height:"200px",display:"flex",backgroundImage: `url(${Back})`,marginTop:"80px"}}>
    <p style={{marginLeft:"20%",marginTop:"80px",fontSize:"40px",fontWeight:"700",color:"#3C3C3B",fontFamily:'Inter'}}>Blog</p>
    </div>
    <br /><br />

    <div style={{display:"flex",justifyContent:"center"}}>
    <div className='maincomp' style={{display:"flex",flexWrap:"wrap",width:"90%",justifyContent:"center"}}>
        <div className='training' style={{display:"flex",flexWrap:"wrap",justifyContent:"center",gap:"20px",width:"75%"}}>
        {
          blog.map((blog)=>(       
            <Home_Blogs
            img={blog.image_url}
            img1={Frame}
            name={blog.blog_category}
            date={blog.blog_date}
            bid={blog.blog_id}
          />
     ))
      }


      
        {/* <Blog_  img={Rect5}/>
        <Blog_  img={Rect6}/> */}
    {/* <div className='blogcont'>
          
            <img className='blogimgg' src={Rect1} alt="" />
            <p  style={{fontSize:"15px",marginTop:"10px",marginLeft:"50px"}}> <img src={Calender} alt="" /> 07 , jan 2022 </p>
            <h1  style={{fontSize:"25px",marginLeft:"50px"}}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus, dolore doloremque amet perferendis </h1>
            
            <p  style={{fontSize:"20px",marginTop:"10px",marginBottom:"50px",opacity:"0.8",marginLeft:"50px"}}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt sint reiciendis unde aut blanditiis. Expedita, vel. Laborum illo facere atque doloribus quod. Necessitatibus qui doloremque officia explicabo quas cum animi.</p>
            <NavLink className="blogbtn" to="/blog">Read More</NavLink>
            </div>
            <div className='blogcont'>
          
          <img className='blogimgg' src={Rect5} alt="" />
          <p  style={{fontSize:"15px",marginTop:"10px",marginLeft:"50px"}}> <img src={Calender} alt="" /> 07 , jan 2022 </p>
          <h1  style={{fontSize:"25px",marginLeft:"50px"}}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus, dolore doloremque amet perferendis </h1>
          
          <p  style={{fontSize:"20px",marginTop:"10px",marginBottom:"50px",opacity:"0.8",marginLeft:"50px"}}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt sint reiciendis unde aut blanditiis. Expedita, vel. Laborum illo facere atque doloribus quod. Necessitatibus qui doloremque officia explicabo quas cum animi.</p>
          <NavLink className="blogbtn" to="">Read More</NavLink>
          </div>
          <div className='blogcont'>
          
          <img className='blogimgg' src={Rect6} alt="" />
          <p  style={{fontSize:"15px",marginTop:"10px",marginLeft:"50px"}}> <img src={Calender} alt="" /> 07 , jan 2022 </p>
          <h1  style={{fontSize:"25px",marginLeft:"50px"}}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus, dolore doloremque amet perferendis </h1>
          
          <p  style={{fontSize:"20px",marginTop:"10px",marginBottom:"50px",opacity:"0.8",marginLeft:"50px"}}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt sint reiciendis unde aut blanditiis. Expedita, vel. Laborum illo facere atque doloribus quod. Necessitatibus qui doloremque officia explicabo quas cum animi.</p>
          <NavLink className="blogbtn" to="">Read More</NavLink>
          </div> */}
           
        </div>
        
        <div className='traininginfoo'>
        {/* <div >
            <input type="text" className='inp' name="" placeholder='Search...' id="" />
        </div> */}
            <div className='about-blog' style={{width:"250px"}}>
                <p style={{fontSize:"25px",fontWeight:"bold"}}> <img src={Bar2} alt="" /> Categories</p>
               
                <p  style={{fontSize:"20px",marginTop:"20px"}}> <img src={Arrow}  alt="" /> <span style={{opacity:"0.6"}}> Security ({blogno})</span></p>
                {/* <p  style={{fontSize:"20px",marginTop:"15px"}}> <img src={Arrow}  alt="" /><span style={{opacity:"0.6"}}>Case  (13)</span>   </p>
                <p  style={{fontSize:"20px",marginTop:"15px"}}> <img src={Arrow} alt="" /><span style={{opacity:"0.6"}}> Insights (9)</span> </p>
                <p  style={{fontSize:"20px",marginTop:"15px"}}> <img src={Arrow}  alt="" /><span style={{opacity:"0.6"}}>World (6)</span> </p>
                <p  style={{fontSize:"20px",marginTop:"15px"}}> <img src={Arrow} alt="" /> <span style={{opacity:"0.6"}}> Tax Solutions (4)</span></p>
                <p  style={{fontSize:"20px",marginTop:"15px"}}> <img src={Arrow} alt="" /><span style={{opacity:"0.6"}}>  Creative (8)</span></p> */}
       
            </div>
            {/* <div className='about-blog2' style={{marginBottom:"50px"}}>
                <p style={{fontSize:"25px",fontWeight:"bold"}}> <img src={Bar2} alt="" /> Categories</p>
               
                <p  style={{marginTop:"30px",display:"flex"}}><img className='imgtt' src={Rect1}alt="" /> <span style={{marginLeft:"15px",fontSize:"17px" ,fontWeight:"bold"}}> Lorem ipsum dolor  ipsa atque.  <span style={{fontSize:"15px",fontWeight:"normal"}}></span>       <p  style={{fontSize:"15px",marginTop:"10px"}}> <img src={Calender} alt="" /> 07 , jan 2022 </p></span></p>
                <p  style={{marginTop:"30px",display:"flex"}}><img className='imgtt' src={Rect2}alt="" /> <span style={{marginLeft:"15px",fontSize:"17px" ,fontWeight:"bold"}}> Lorem ipsum dolor  ipsa atque.  <span style={{fontSize:"15px",fontWeight:"normal"}}></span>       <p  style={{fontSize:"15px",marginTop:"10px"}}> <img src={Calender} alt="" /> 07 , jan 2022 </p></span></p>
                <p  style={{marginTop:"30px",display:"flex"}}><img className='imgtt' src={Rect3}alt="" /> <span style={{marginLeft:"15px",fontSize:"17px" ,fontWeight:"bold"}}> Lorem ipsum dolor  ipsa atque.  <span style={{fontSize:"15px",fontWeight:"normal"}}></span>       <p  style={{fontSize:"15px",marginTop:"10px"}}> <img src={Calender} alt="" /> 07 , jan 2022 </p></span></p>
                <p  style={{marginTop:"30px",display:"flex"}}><img className='imgtt' src={Rect4}alt="" /> <span style={{marginLeft:"15px",fontSize:"17px" ,fontWeight:"bold"}}> Lorem ipsum dolor  ipsa atque.  <span style={{fontSize:"15px",fontWeight:"normal"}}></span>       <p  style={{fontSize:"15px",marginTop:"10px"}}> <img src={Calender} alt="" /> 07 , jan 2022 </p></span></p>
                
               
            </div> */}
        </div>
    </div>
    </div>

    <Footer/>
    </>
  )
}

export default Mainblog