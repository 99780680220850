import React,{useEffect, useState} from 'react'
import db from './../../firebase'
import icon13 from "../Images/icon13.png"
import Ping from "../Images/Ping.png";
import Home_Training from '../Homee/Home_Training';
import Clock from "../Images/Clock.png"
import Axios from 'axios'



function Wislist() {
const[data,setdata]=useState([])

const [state, setState] = useState({
  ip: "",
  countryName: "",
  countryCode: "",
  city: "",
  timezone: ""
});

useEffect(() => {
  Axios
  .get("https://ipapi.co/json/")
  .then((response) => {
      let data = response.data;
      setState({
          ...state,
          ip: data.ip,
          countryName: data.country_name,
          countryCode: data.country_calling_code,
          city: data.city,
          timezone: data.timezone
      });
  })
  .catch((error) => {
      console.log(error);
  });
}, []);
   const token=JSON.parse(localStorage.getItem('data'));
   console.log(token)
// const fetch = () => {
// var r;
//   const Rel = db.collection('user');
//   (token.localId==null||token.localId==undefined||token.localId=='')?(r=token.uid):(r=token.localId)
//   Rel.where('user_id', '==',r).get().then((snapshot) => {
//     if (snapshot.empty) {
//       console.log('No matching documents.');
//       return;
//     }  
//     snapshot.forEach(element => {
//       var course = element.data();
     
   
//       console.log(course.array)

//       for(var i=0;i<=course.array.length;i++){
//       const Rel = db.collection('courses');
  
//       Rel.where('course_name', '==',course.array[i]).get().then((snapshot) => {
//         if (snapshot.empty) {
//           console.log('No matching documents.');
//           return;
//         }  
//         snapshot.forEach(element => {
//           var course = element.data();
         
        
//            setdata(arr => [...arr, course]);
//           console.log(course)
          
//         })
//       })
//     } 
//     })
//   })

// }

const fetch = async()=>{
  await  Axios.post(`${process.env.REACT_APP_BASE_URL}/wishlist`,{
 token:token
  }
  ).then((response)=>{
   console.log(response.data)
  
   setdata(response.data)
 
 }).catch(err => {
     console.error(err);
 
 })
 
}

useEffect(
  fetch
 ,[]

);

  return (
   <>
       <p className='Dash_heading'>
           Wishlist
       </p>
       <div style={{display:"flex"}}>
        {/* <div style={{background:""}} className='Enrolled_trainingbox2'>
      <img  src={Ping} alt=""  className='Enrolled_trainingimage'/>
      <div style={{border:"",borderRadius:"20px",height:"130px",background:"white"}}>
          <h1 style={{fontSize:'24px',marginLeft:'10px',marginTop:"15px",fontFamily:"Inter"}}> Pingfederate Administrative</h1>
          <h2 style={{display:"flex", fontSize:'20px',marginLeft:"10px",marginTop:'30px',fontFamily:"Inter"}}><img src={Clock} alt="" />25hr  <h2 style={{display:"flex",float:"right",marginLeft:"160px", fontSize:'20px',marginTop:'0px',fontFamily:"Inter"}}><img src={icon13} alt="" />4.9(1k)</h2></h2>
        
      </div>
      </div> */}

      {
          data.map((course)=>(       
       <Home_Training img={course.image_url } key={course.course_id} name={course.course_name} cid={course.course_id} color={course.image_background}  fee={(state.countryName=='India')?(course.course_fee.INR):(course.course_fee.USD)}
       Instructer={course.course_instructor} time={course.course_duration}/>
     ))
      }
      </div>
   </>
  )
}

export default Wislist