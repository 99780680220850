import React, { useState } from 'react';
import axios from "axios";

import "./styles-newCourse.scss";

const NewCourse = (props) => {
    const nodeURL = process.env.REACT_APP_BASE_URL;
    const tokenn = JSON.parse(localStorage.getItem("data"));

    const [certificationsList, setCertificationsList] = useState([{ "certification": "" }]);
    const [consultantINRFee, setConsultantINRFee] = useState("");
    const [consultantUSDFee, setConsultantUSDFee] = useState("");
    const [courseINRFee, setCourseINRFee] = useState("");
    const [courseUSDFee, setCourseUSDFee] = useState("");
    const [courseCategory, setCourseCategory] = useState("");
    const [courseContentList, setCourseContentList] = useState([{ "course_content": "" }]);
    const [courseDescription, setCourseDescription] = useState("");
    const [courseDuration, setCourseDuration] = useState("");
    const [courseId, setCourseId] = useState("");
    const [courseInstructor, setCourseInstructor] = useState("");
    const [courseLanguage, setCourseLanguage] = useState("");
    const [courseLevel, setCourseLevel] = useState("");
    const [courseName, setCourseName] = useState("");
    const [courseProducts, setCourseProducts] = useState("");
    const [courseSource, setCourseSource] = useState("");
    const [dislikes, setDislikes] = useState("");
    const [enrolledCustomers, setEnrolledCustomers] = useState("");
    const [imageBackground, setImageBackground] = useState("");
    const [imageUrl, setImageUrl] = useState("");
    const [likes, setLikes] = useState("");
    const [paymentUrl, setPaymentUrl] = useState("");
    const [resourceList, setResourceList] = useState([{ "resource": "" }]);
    const [reviewList, setReviewList] = useState([{ "review": "" }]);

    const [submitDisabled, setSubmitDisabled] = useState(false);

    // compliant certification
    const addCertification = () => {
        setCertificationsList([...certificationsList, { "certification": "" }]);
    };

    const removeCertification = (index) => {
        const list = [...certificationsList];
        list.splice(index, 1);
        setCertificationsList(list);
    };

    const handleCertificationChange = (event, index) => {
        const { name, value } = event.target;
        const list = [...certificationsList];
        list[index][name] = value;
        setCertificationsList(list);
        // console.log(certificationsList);
    };

    // course content
    const addCourseContent = () => {
        setCourseContentList([...courseContentList, { "course_content": "" }]);
    };

    const removeCourseContent = (index) => {
        const list = [...courseContentList];
        list.splice(index, 1);
        setCourseContentList(list);
    };

    const handleCourseContentChange = (event, index) => {
        const { name, value } = event.target;
        const list = [...courseContentList];
        list[index][name] = value;
        setCourseContentList(list);
        // console.log(courseContentList);
    };

    // resource
    const addResource = () => {
        setResourceList([...resourceList, { "resource": "" }]);
    };

    const removeResource = (index) => {
        const list = [...resourceList];
        list.splice(index, 1);
        setResourceList(list);
    };

    const handleResourceChange = (event, index) => {
        const { name, value } = event.target;
        const list = [...resourceList];
        list[index][name] = value;
        setResourceList(list);
        // console.log(courseContentList);
    };

    // review
    const addReview = () => {
        setReviewList([...reviewList, { "review": "" }]);
    };

    const removeReview = (index) => {
        const list = [...reviewList];
        list.splice(index, 1);
        setReviewList(list);
    };

    const handleReviewChange = (event, index) => {
        const { name, value } = event.target;
        const list = [...reviewList];
        list[index][name] = value;
        setReviewList(list);
        // console.log(courseContentList);
    };

    // insert data
    const addNewCourse = async (event) => {
        event.preventDefault();

        const certifications = [];
        certificationsList.map((item) => { certifications.push(item.certification); });
        const courseContent = [];
        courseContentList.map((item) => { courseContent.push(item.course_content); });
        const resources = [];
        resourceList.map((item) => { resources.push(item.resource); });
        const reviews = [];
        reviewList.map((item) => { reviews.push(item.review); });
        const newData = {
            "compliant_certifications": certifications,
            "consultant_fee": { "INR": consultantINRFee, "USD": consultantUSDFee },
            "course_fee": { "INR": + courseINRFee, "USD": courseUSDFee },
            "course_category": courseCategory,
            "course_content": courseContent,
            "course_description": courseDescription,
            "course_duration": courseDuration,
            "course_id": courseId,
            "course_instructor": courseInstructor,
            "course_language": courseLanguage,
            "course_level": courseLevel,
            "course_name": courseName,
            "course_products": courseProducts,
            "course_source": courseSource,
            "dislikes": dislikes,
            "enrolled_customers": enrolledCustomers,
            "image_background": imageBackground,
            "image_url": imageUrl,
            "likes": likes,
            "payment_url": paymentUrl,
            "resources": resources,
            "reviews": reviews,
        };
        console.log(newData);

        setSubmitDisabled(true);
        await axios.post(`${nodeURL}/api/course-create`, { newData, token: tokenn }).then((response) => {
            // console.log(response);
            alert("added course " + response);
            props.reloadDataFunc();
            document.querySelector("#newCourseForm").reset();
        }).catch((err) => {
            console.log(err);
            alert(err);
        }).finally(() => {
            setSubmitDisabled(false);
        });
    };

    return (
        <div className="newCourseContent">
            <form id="newCourseForm" onSubmit={addNewCourse}>
                <div className="courseInputContainer certificationsContainer">
                    <label htmlFor="certifications" className='New_blog_Label'>Compliant Certifications:</label>
                    {certificationsList.map((item, index) => (
                        <div key={index} className="certifications">
                            <div className="first-division">
                                <input type="text" name="certification" id="certification" required style={{height:"40px",fontFamily:"Inter",fontSize:"20px",borderRadius:"10px"}}
                                    value={item.certification} placeholder="compliant certification"
                                    onChange={(event) => handleCertificationChange(event, index)} />
                                {certificationsList.length - 1 === index && (
                                    <button type="button" className="btn btn-add" onClick={addCertification}>
                                        <span>add a compliant certification</span>
                                    </button>
                                )}
                            </div>
                            <div className="second-division">
                                {certificationsList.length > 1 && (
                                    <button type="button" className="btn btn-remove" onClick={() => removeCertification(index)}>
                                        <span>Remove</span>
                                    </button>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
                <div className="courseInputContainer feeContainer">
                    <label htmlFor="fee" className='New_blog_Label'>Consultant Fee:</label><br />
                    <label style={{ display: 'inline-block', width: "50px", marginBottom: "5px" }} className='New_blog_Label'>INR:</label>
                    <input type="text" name="inrFee" id="inrFee" placeholder="Indian National Rupee" required 
                        style={{ display: 'inline-block', marginLeft: "15px" ,height:"40px",fontFamily:"Inter",fontSize:"20px",borderRadius:"10px"}}
                        value={consultantINRFee} onChange={(event) => setConsultantINRFee(event.target.value)} /><br />
                    <label style={{ display: 'inline-block', width: "50px" }} className='New_blog_Label'>USD:</label>
                    <input type="text" name="usdFee" id="usdFee" placeholder="United States Dollar" required 
                        style={{ display: 'inline-block', marginLeft: "15px",height:"40px",fontFamily:"Inter",fontSize:"20px",borderRadius:"10px" }}
                        value={consultantUSDFee} onChange={(event) => setConsultantUSDFee(event.target.value)} />
                </div>
                <div className="courseInputContainer courseFeeContainer">
                    <label htmlFor="courseFee" className='New_blog_Label'>Course Fee:</label><br />
                    <label style={{ display: 'inline-block', width: "50px", marginBottom: "5px" }} className='New_blog_Label'>INR:</label>
                    <input type="text" name="inrCourseFee" id="inrCourseFee" placeholder="Indian National Rupee" 
                        style={{ display: 'inline-block', marginLeft: "15px",height:"40px",fontFamily:"Inter",fontSize:"20px",borderRadius:"10px" }}
                        value={courseINRFee} onChange={(event) => setCourseINRFee(event.target.value)} /><br />
                    <label style={{ display: 'inline-block', width: "50px" }} className='New_blog_Label'>USD:</label>
                    <input type="text" name="usdCourseFee" id="usdCourseFee" placeholder="United States Dollar" required 
                        style={{ display: 'inline-block', marginLeft: "15px",height:"40px",fontFamily:"Inter",fontSize:"20px",borderRadius:"10px" }}
                        value={courseUSDFee} onChange={(event) => setCourseUSDFee(event.target.value)} />
                </div>
                <div className="courseInputContainer courseCategoryContainer">
                    <label htmlFor="courseCategory" className='New_blog_Label'>Course Category:</label><br />
                    <input type="text" name="courseCategory" id="courseCategory" placeholder="Course Category" required style={{height:"40px",fontFamily:"Inter",fontSize:"20px",borderRadius:"10px"}}
                        value={courseCategory} onChange={(event) => setCourseCategory(event.target.value)} />
                </div>
                <div className="courseInputContainer courseContentContainer">
                    <label htmlFor="course_content" className='New_blog_Label'>Course Content:</label>
                    {courseContentList.map((item, index) => (
                        <div key={index} className="courseContents">
                            <div className="first-division">
                                {/* <input type="text" name="course_content" id="course_content" required
                                value={item.course_content} placeholder="course content"
                                onChange={(event)=>handleCourseContentChange(event,index)} /> */}
                                <textarea type="text" name="course_content" id="course_content" required style={{height:"40px",fontFamily:"Inter",fontSize:"20px",borderRadius:"10px"}}
                                    value={item.course_content}
                                    onChange={(event) => handleCourseContentChange(event, index)}></textarea>
                                {courseContentList.length - 1 === index && (
                                    <button type="button" className="btn btn-add" onClick={addCourseContent}>
                                        <span>add a course content</span>
                                    </button>
                                )}
                            </div>
                            <div className="second-division">
                                {courseContentList.length > 1 && (
                                    <button type="button" className="btn btn-remove" onClick={() => removeCourseContent(index)}>
                                        <span>Remove</span>
                                    </button>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
                <div className="courseInputContainer courseDescriptionContainer">
                    <label htmlFor="courseDescription" className='New_blog_Label'>Course Description:</label><br />
                    <input type="text" name="courseDescription" id="courseDescription" placeholder="Course Description" required style={{height:"40px",fontFamily:"Inter",fontSize:"20px",borderRadius:"10px"}}
                        value={courseDescription} onChange={(event) => setCourseDescription(event.target.value)} />
                </div>
                <div className="courseInputContainer courseDurationContainer">
                    <label htmlFor="courseDuration" className='New_blog_Label'>Course Duration:</label><br />
                    <input type="text" name="courseDuration" id="courseDuration" placeholder="Course Duration" required style={{height:"40px",fontFamily:"Inter",fontSize:"20px",borderRadius:"10px"}}
                        value={courseDuration} onChange={(event) => setCourseDuration(event.target.value)} />
                </div>
                <div className="courseInputContainer courseIdContainer">
                    <label htmlFor="courseId" className='New_blog_Label'>Course Id:</label><br />
                    <input type="text" name="courseId" id="courseId" placeholder="Course ID" required style={{height:"40px",fontFamily:"Inter",fontSize:"20px",borderRadius:"10px"}}
                        value={courseId} onChange={(event) => setCourseId(event.target.value)} />
                </div>
                <div className="courseInputContainer courseInstructorContainer">
                    <label htmlFor="courseInstructor" className='New_blog_Label'>Course Instructor:</label><br />
                    <input type="text" name="courseInstructor" id="courseInstructor" placeholder="Course Instructor" required style={{height:"40px",fontFamily:"Inter",fontSize:"20px",borderRadius:"10px"}}
                        value={courseInstructor} onChange={(event) => setCourseInstructor(event.target.value)} />
                </div>
                <div className="courseInputContainer courseLanguageContainer">
                    <label htmlFor="courseLanguage" className='New_blog_Label'>Course Language:</label><br />
                    <input type="text" name="courseLanguage" id="courseLanguage" placeholder="Course Language" required style={{height:"40px",fontFamily:"Inter",fontSize:"20px",borderRadius:"10px"}}
                        value={courseLanguage} onChange={(event) => setCourseLanguage(event.target.value)} />
                </div>
                <div className="courseInputContainer courseLevelContainer">
                    <label htmlFor="courseLevel" className='New_blog_Label'>Course Level:</label><br />
                    <input type="text" name="courseLevel" id="courseLevel" placeholder="Course Level" required style={{height:"40px",fontFamily:"Inter",fontSize:"20px",borderRadius:"10px"}}
                        value={courseLevel} onChange={(event) => setCourseLevel(event.target.value)} />
                </div>
                <div className="courseInputContainer courseNameContainer">
                    <label htmlFor="courseName" className='New_blog_Label'>Course Name:</label><br />
                    <input type="text" name="courseName" id="courseName" placeholder="Course Name" required style={{height:"40px",fontFamily:"Inter",fontSize:"20px",borderRadius:"10px"}}
                        value={courseName} onChange={(event) => setCourseName(event.target.value)} />
                </div>
                <div className="courseInputContainer courseProductsContainer">
                    <label htmlFor="courseProducts" className='New_blog_Label'>Course Products:</label><br />
                    <input type="text" name="courseProducts" id="courseProducts" placeholder="Course Products" required style={{height:"40px",fontFamily:"Inter",fontSize:"20px",borderRadius:"10px"}}
                        value={courseProducts} onChange={(event) => setCourseProducts(event.target.value)} />
                </div>
                <div className="courseInputContainer courseSourceContainer">
                    <label htmlFor="courseSource" className='New_blog_Label'>Course Source:</label><br />
                    <input type="text" name="courseSource" id="courseSource" placeholder="Course Source" required style={{height:"40px",fontFamily:"Inter",fontSize:"20px",borderRadius:"10px"}}
                        value={courseSource} onChange={(event) => setCourseSource(event.target.value)} />
                </div>
                <div className="courseInputContainer dislikesContainer">
                    <label htmlFor="dislikes" className='New_blog_Label'>Dislikes:</label><br />
                    <input type="text" name="dislikes" id="dislikes" placeholder="Dislikes" required style={{height:"40px",fontFamily:"Inter",fontSize:"20px",borderRadius:"10px"}}
                        value={dislikes} onChange={(event) => setDislikes(event.target.value)} />
                </div>
                <div className="courseInputContainer enrolledCustomersContainer">
                    <label htmlFor="enrolledCustomers" className='New_blog_Label'>Enrolled Customers:</label><br />
                    <input type="text" name="enrolledCustomers" id="enrolledCustomers" placeholder="Enrolled Customers" required style={{height:"40px",fontFamily:"Inter",fontSize:"20px",borderRadius:"10px"}}
                        value={enrolledCustomers} onChange={(event) => setEnrolledCustomers(event.target.value)} />
                </div>
                <div className="courseInputContainer imageBackgroundContainer">
                    <label htmlFor="imageBackground" className='New_blog_Label'>Image Background:</label><br />
                    <input type="text" name="imageBackground" id="imageBackground" placeholder="Image Background" required style={{height:"40px",fontFamily:"Inter",fontSize:"20px",borderRadius:"10px"}}
                        value={imageBackground} onChange={(event) => setImageBackground(event.target.value)} />
                </div>
                <div className="courseInputContainer imageUrlContainer">
                    <label htmlFor="imageUrl" className='New_blog_Label'>Image URL:</label><br />
                    <input type="text" name="imageUrl" id="imageUrl" placeholder="Image URL" required style={{height:"40px",fontFamily:"Inter",fontSize:"20px",borderRadius:"10px"}}
                        value={imageUrl} onChange={(event) => setImageUrl(event.target.value)} />
                </div>
                <div className="courseInputContainer likesContainer">
                    <label htmlFor="likes" className='New_blog_Label'>Likes:</label><br />
                    <input type="text" name="likes" id="likes" placeholder="Likes" required style={{height:"40px",fontFamily:"Inter",fontSize:"20px",borderRadius:"10px"}}
                        value={likes} onChange={(event) => setLikes(event.target.value)} />
                </div>
                <div className="courseInputContainer paymentUrlContainer">
                    <label htmlFor="paymentUrl" className='New_blog_Label'>Payment URL:</label><br />
                    <input type="text" name="paymentUrl" id="paymentUrl" placeholder="Payment URL" required style={{height:"40px",fontFamily:"Inter",fontSize:"20px",borderRadius:"10px"}}
                        value={paymentUrl} onChange={(event) => setPaymentUrl(event.target.value)} />
                </div>
                <div className="courseInputContainer resourceContainer">
                    <label htmlFor="resource" className='New_blog_Label'>Resource:</label>
                    {resourceList.map((item, index) => (
                        <div key={index} className="resources">
                            <div className="first-division">
                                <input type="text" name="resource" id="resource" required style={{height:"40px",fontFamily:"Inter",fontSize:"20px",borderRadius:"10px"}}
                                    value={item.resource} placeholder="resource"
                                    onChange={(event) => handleResourceChange(event, index)} />
                                {resourceList.length - 1 === index && (
                                    <button type="button" className="btn btn-add" onClick={addResource}>
                                        <span className='New_blog_Label'>add a resource</span>
                                    </button>
                                )}
                            </div>
                            <div className="second-division">
                                {resourceList.length > 1 && (
                                    <button type="button" className="btn btn-remove" onClick={() => removeResource(index)}>
                                        <span>Remove</span>
                                    </button>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
                <div className="courseInputContainer reviewContainer">
                    <label htmlFor="review" className='New_blog_Label'>Review:</label>
                    {reviewList.map((item, index) => (
                        <div key={index} className="reviews">
                            <div className="first-division">
                                <input type="text" name="review" id="review" required  style={{height:"40px",fontFamily:"Inter",fontSize:"20px",borderRadius:"10px"}}
                                    value={item.review} placeholder="review"
                                    onChange={(event) => handleReviewChange(event, index)} />
                                {reviewList.length - 1 === index && (
                                    <button type="button" className="btn btn-add" onClick={addReview}>
                                        <span>add a review</span>
                                    </button>
                                )}
                            </div>
                            <div className="second-division">
                                {reviewList.length > 1 && (
                                    <button type="button" className="btn btn-remove" onClick={() => removeReview(index)}>
                                        <span>Remove</span>
                                    </button>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
                <div className="submitButtonContainer">
                    <input type="submit" className="newCourseSubmit" value="Add Course" disabled={submitDisabled} />
                </div>
            </form>
        </div>
    )
}

export default NewCourse;
