import React,{useEffect, useState} from 'react'
import db from './../../firebase'
import {useRef} from  "react"
import { NavLink } from 'react-router-dom'
import firebase from 'firebase/compat/app'
import {useNavigate} from "react-router-dom"
import { FaWindows } from 'react-icons/fa'
import  Axios  from 'axios'
import  './dash.css'



function Profile() {
const[data,setdata]=useState([])
const[name,setname]=useState(data.name);
  const[username,setusername]=useState(data.username);
  const[email,setemail]=useState(data.email);
  const[skill,setskill]=useState(data.skill);
  const[number,setnumber]=useState(data.number);
  const[bio,setbio]=useState(data.bio);
  const[date,setdate]=useState('');
  let navigate = useNavigate();
  const token=JSON.parse(localStorage.getItem('data'));

var myRef=useRef();

const[form,setform]=useState(false)
  const fetchinfo = async() => {
    // var r;
    // (token.localId==null||token.localId==undefined||token.localId=='')?(r=token.uid):(r=token.localId)
console.log(token);

    await  Axios.post(`${process.env.REACT_APP_BASE_URL}/userdetail`,{
 token:token,
    }
    ).then((response)=>{
     console.log(response.data)
    
     setdata(response.data)
   
   }).catch(err => {
       console.error(err);
   
   })
   
    //  const Rel = db.collection('user');
     
    //   Rel.where('user_id', '==',r).get().then((snapshot) => {
    //     if (snapshot.empty) {
    //       console.log('No matching documents.');
    //       return;
    //     }  
    //     snapshot.forEach(element => {
    //       var course = element.data();
         
       
    //       console.log(course.name)
    
    //   setdata(course)
          
    //     })

    //   })

      

    }

    useEffect(
      fetchinfo    
     ,[]
    
    );


    const openform=()=>{


      
      if(name==''||name==null||name==undefined){
        setname(data.name);
      
      }
      if(username==''||username==null||username==undefined){
        setusername(data.username);
       
      }
      if(email==''|| email==null||email==undefined){
        setemail(data.email);
       
      }
      if(number==''|| number==null||number==undefined){
        setnumber(data.number);
       
      }
      if(skill==''|| skill==null||skill==undefined){
        setskill(data.skill);
       
      }
      if(bio==''|| bio==null||bio==undefined){
        setbio(data.bio);
       
      }
      
      console.log('dbjhdvev')
  setform(true)
    }

    const addinfo= async(e)=>{


      e.preventDefault()



      if(token==null || token==''  || token==undefined){ 
        console.log('not');
        alert("please login")
        window.location.replace("/login")
        return false;
     }
     else{

      await  Axios.post(`${process.env.REACT_APP_BASE_URL}/editdetail`,{
        token:token,
 name:name,
 username:username,
 email:email,
 skill:skill,
 number:number,
 bio:bio

    }
    ).then(()=>{
     console.log('res')
     fetchinfo();
    
   
   }).catch(err => {
       console.error(err);
   
   })
   







      //  if(token.localId==null||token.localId==undefined||token.localId==''){
  
      //   console.log(token.uid);
      //   const db=firebase.firestore();
      //   db.collection("user").doc(token.uid).
      //   update({name:name,username:username,email:email,skill:skill,number:number,bio:bio,date:date,user_id:token.uid});
        
      //  }
      //  else{
      //  console.log(token.localId);
      //  const db=firebase.firestore();
       
        
      //  db.collection("user").doc(token.localId).set(
      //   {name:name,username:username,email:email,skill:skill,number:number,bio:bio,ate:date,user_id:token.localId}
      // ,{merge:true})
      
      //  }
  
    }
    
  setform(false)
//window.location.replace("/dashboard")
    }

  return (
   <>
  
     <p className='Dash_heading'> My Profile</p>
    

 

{
  (form)?(

 <div style={{textAlign:"center",justifyContent:"center",justifyItems:"center"}}>
<table style={{marginLeft:"auto",marginRight:"auto"}} >
<div className='profile_tabe'>
  <tbody>
    {/* <tr className='profiletext' >
      <td >Registation Date</td>
      <td className='profilespace' ><input type="text" style={{padding: "6px 15px",fontFamily:"Inter",fontSize:'18px',borderRadius:"5px",margin:"30px"}}  onChange={(e)=>setdate(e.target.value)}  /></td>
    </tr> */}
    <tr className='profiletext'>
      <td style={{fontFamily:"Inter"}}>Full Name</td>

      <td className='profilespace'> <input type="text" style={{padding: "6px 15px",fontFamily:"Inter",fontSize:'18px',borderRadius:"5px",margin:"30px"}}   onChange={(e)=>setname(e.target.value)} value={name} /></td>
    </tr>
    <tr className='profiletext'>
      <td style={{fontFamily:"Inter"}}>UserName</td>
      <td className='profilespace'><input type="text" style={{padding: "6px 15px",fontFamily:"Inter",fontSize:'18px',borderRadius:"5px",margin:"30px"}}  onChange={(e)=>setusername(e.target.value)}  value={username} /></td>
    </tr>
    <tr className= 'profiletext'>
      <td style={{fontFamily:"Inter"}}>Email</td>
      <td className='profilespace'><input type="email" style={{padding: "6px 15px",fontFamily:"Inter",fontSize:'18px',borderRadius:"5px",margin:"30px"}}  onChange={(e)=>setemail(e.target.value)}   value={email}/></td>
    </tr>

    <tr className='profiletext'>
      <td style={{fontFamily:"Inter"}}>Phone Number</td>
      <td className='profilespace'><input type="number" style={{padding: "6px 15px",fontFamily:"Inter",fontSize:'18px',borderRadius:"5px",margin:"30px"}}  onChange={(e)=>setnumber(e.target.value)} value={number} /></td>
    </tr>
    <tr className='profiletext'>
      <td style={{fontFamily:"Inter"}}>Skill/Occupation</td>
      <td className='profilespace'><input type="text" style={{padding: "6px 15px",fontFamily:"Inter",fontSize:'18px',borderRadius:"5px",margin:"30px"}}  onChange={(e)=>setskill(e.target.value)} value={skill}/></td>
    </tr>
    <tr className='profiletext'>
      <td style={{fontFamily:"Inter"}}>Bio</td>
      <td className='profilespace'><input type="text" style={{padding: "6px 15px",fontFamily:"Inter",fontSize:'18px',borderRadius:"5px",margin:"30px"}} onChange={(e)=>setbio(e.target.value)} value={bio} /></td>
    </tr>
  </tbody>
  </div>
</table>
<button className='contactbtn' onClick={(e) => addinfo(e)}>Submit</button>
 {/* <button className='contactbtn' onClick={(e) => addinfo(e)}>Submit</button> */}
</div>):(
  
 <div>


 <div style={{float:"right"}}>
<div><NavLink className='contactbtn' onClick={() => openform()}  to=''>Edit profile</NavLink></div>
</div>

  <table >
<div className='profile_tabe' style={{marginTop:"70px"}}>
  <tbody>
    <tr className='profiletext'   >
      <td >Registation Date</td>
      <td className='profilespace' style={{fontFamily:"Inter"}}>{data.date}</td>
    </tr>
    <tr className='profiletext' >
      <td style={{fontFamily:"Inter"}}>Full Name</td>
      <td className='profilespace' style={{fontFamily:"Inter"}}>{data.name}</td>
    </tr>
    <tr className='profiletext'>
      <td style={{fontFamily:"Inter"}}>UserName</td>
      <td className='profilespace' style={{fontFamily:"Inter"}}>{data.username}</td>
    </tr>
    <tr className='profiletext'>
      <td style={{fontFamily:"Inter"}}>Email</td>
      <td className='profilespace' style={{fontFamily:"Inter"}}>{data.email}</td>
    </tr>

    <tr className='profiletext'>
      <td style={{fontFamily:"Inter"}}>Phone Number</td>
      <td className='profilespace' style={{fontFamily:"Inter"}}>{data.number}</td>
    </tr>
    <tr className='profiletext'>
      <td style={{fontFamily:"Inter"}}>Skill/Occupation</td>
      <td className='profilespace' style={{fontFamily:"Inter"}}>{data.skill}</td>
    </tr>
    <tr className='profiletext'>
      <td style={{fontFamily:"Inter"}}>Bio</td>
      <td className='profilespace' style={{fontFamily:"Inter"}}>{data.bio}</td>
    </tr>
  </tbody>
  </div>
</table>

</div>
)
}

   </>
  )
}

export default Profile