import React, { useState,useEffect } from 'react'
import {NavLink} from "react-router-dom"
import Axios from 'axios';
import Swal from 'sweetalert2'

function Lecture() {
    const[dataa,setdataa]=useState([])
    const token=JSON.parse(localStorage.getItem('data'));
    const [loading,setloading]=useState(false)

    const fetchinfo = async() => {
  console.log('dchcvvv')
      await  Axios.post(`${process.env.REACT_APP_BASE_URL}/getLink`,{
        token:token,
           }
           ).then((response)=>{
            console.log(response.data)
           
            setdataa(response.data)
          
          }).catch(err => {
              console.error(err);
          
          })
  
    }
  
    useEffect(
      fetchinfo    
     ,[]
    
    );
 //   console.log(data);


   // const token = JSON.parse(localStorage.getItem("data"));

    const loadScript = (src) => {
       
      return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
          resolve(true);
          setTimeout(() => {
            setloading(false);
    
          }, 2000);
          
    
        };
        script.onerror = () => {
          resolve(false);
        };
        document.body.appendChild(script);
      });
    };

    const [data,setdata]=useState([])
  //  const [Amount,setAmount]=useState('')
     const payment=async(C_name,Amount)=> {
//setAmount('500')
let value;
      // console.log(C_name,Money)
       await Axios.get(`${process.env.REACT_APP_BASE_URL}/training_name?name=${C_name}`, {})
       .then((response) => {
       //  console.log(response.data.course_category);
       //  rel = response.data.course_category;
 
         setdata(response.data);
value=response.data
         // setcname(response.data.course_name)
        // cnamee = response.data.course_name;
       }).then(async()=>{
        console.log(data);
      
      // value=data;
    //   if(full){
    //     setAmount((data.course_fee.INR).split(' ')[1]);
    //   }
    
      console.log(Amount)
    
      if (token == null || token == "" || token == undefined) {
        console.log("not");
    
      //   window.location.replace("/login?redirect_url="+window.location.pathname+`?cid=`+value.course_id);
      console.log(value)
        setloading(true);
        console.log("payment clicked");
        console.log(JSON.stringify(localStorage));
        // const course_name =
        // return;
        const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
        if (!res) {
            alert ("Razor SDK failed to load");
            setloading(true);
    
            
    
            return;
        }
        console.log(`razorpay script loaded:: ${res}`);
    
       // return;
        // const localStorageData = localStorage.getItem("email");
       // const email_id = localStorage.getItem("user-email");
          // console.log(data)
        const course_id = value.course_id
        const course_name = value.course_name
        const razorpay_test_key = process.env.REACT_APP_RAZORPAY_TEST_KEY;
        const razorpay_data = {
          token:token,
          //  emailid: email_id,
            courseid: course_id,
            coursename: course_name,
            razorpaytestkey: razorpay_test_key
        };
        console.log(razorpay_data);
    
        // return;
        const data = await fetch(`${process.env.REACT_APP_BASE_URL}/unauth/init-razorpay`, {
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            method: 'POST',
            body: JSON.stringify(razorpay_data)
        })
        .then((razorpay_return) => razorpay_return.json());
        console.log(data);
        const options = {
            key: razorpay_data.razorpaytestkey,
            amount: data.amount.toString(),
            currency: data.currency,
            name: data.coursename,
            description: data.coursename,
            image: "https://askmeidentity.com/",
            order_id: data.id,
            handler: async response=> {
              Swal.fire({  
                title: 'Thanks!',  
                text: 'We have taken your payment request we will send you email after payment verify',
                icon: 'success',
               showCancelButton:true,
                
              })
           
             
            }
        };
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
        return false;
      } else {
        paymentFunction(value,Amount);
      }
    }).catch((err) => {
  console.error(err);
})
};
    const paymentFunction = async (value,Amount) => {
    //  console.log(value)
    console.log('running');
    //   if(full){
    //     setAmount((value.course_fee.INR).split(' ')[1]);
    //   }else{
        
    //   }
        setloading(true);
        console.log("payment clicked");
        console.log(JSON.stringify(localStorage));
        // const course_name =
        // return;
        
    
      console.log(Amount)
        const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
        if (!res) {
            alert ("Razor SDK failed to load");
            setloading(true);
    
            
    
            return;
        }
        console.log(`razorpay script loaded:: ${res}`);
    
       // return;
        // const localStorageData = localStorage.getItem("email");
       // const email_id = localStorage.getItem("user-email");
          // console.log(data)
        const course_id = value.course_id
        const course_name = value.course_name
        const razorpay_test_key = process.env.REACT_APP_RAZORPAY_TEST_KEY;
        const razorpay_data = {
          token:token,
          Amount:Amount,
          //  emailid: email_id,
            courseid: course_id,
            coursename: course_name,
            razorpaytestkey: razorpay_test_key
        };
        console.log(razorpay_data);
    
        // return;
        const data = await fetch(`${process.env.REACT_APP_BASE_URL}/init-razorpay`, {
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            method: 'POST',
            body: JSON.stringify(razorpay_data)
        })
        .then((razorpay_return) => razorpay_return.json());
        console.log(data);
        const options = {
            key: razorpay_data.razorpaytestkey,
            amount: data.amount.toString(),
            currency: data.currency,
            name: data.coursename,
            description: data.coursename,
            image: "https://askmeidentity.com/",
            order_id: data.id,
            handler: async response=> {
                // alert (response.razorpay_payment_id);
                // alert (response.razorpay_order_id);
                // alert (response.razorpay_signature);
               // return;
    
                console.log(`client:: ${JSON.stringify(response)}`);
                // let courseamount = 0;
                // if (razorpay_data.courseid==="1") {
                //     courseamount = 35000;
                // }
                // else if (razorpay_data.courseid==="2") {
                //     courseamount = 700;
                // }
    
    
              // let courseamount=(value.course_fee.INR).split(' ')[1]
              let courseamount= Amount;
                // const test_point = await Axios.post(`${process.env.REACT_APP_BASE_URL}/partial/verify-payment`, {
                //     razorpay_payment_id: response.razorpay_payment_id,
                //     razorpay_order_id: response.razorpay_order_id,
                //     razorpay_signature: response.razorpay_signature,
                //    // email: razorpay_data.emailid,
                   
                //    token:token,
                //     amount: courseamount,
                //     courseid: razorpay_data.courseid,
                //     course_name: value.course_name
                // })
                // .then(function(response) {
                //     console.log(response);
                //     console.log(response.data.message);
                //     if (response.data.message!="success") {
                //         console.log ("payment not verified");
                //        // alert ("payment not verified");
                //        setloading(true);
                //        Swal.fire({  
                //         title: 'Oops...',  
                //         text: 'Something went wrong please try again',
                //         icon: 'error',
                //        showCancelButton:true,
                //         confirmButtonText:'Tryagain'
                //       })
                //     }
                //     else {
                //         console.log ("payment verified");
                //         // setModalShow(true)
                //         setloading(false);
    
                //         Swal.fire({  
                //           title: 'Success!',  
                //           text: 'You have Successfully Enrolled to the Course',
                //           icon: 'success',
                //          showCancelButton:true,
                //           confirmButtonText:'Dashboard'
                //         }).then((show)=>{
                //           if(show.isConfirmed){
                //            window.location.replace('/dashboard')
                //           }
            
                //         }); 
                //     }
                // })
                // .catch((error)=>{
                //     console.log(error);
                //     Swal.fire({  
                //       title: 'Oops...',  
                //       text: 'Something went wrong please try again',
                //       icon: 'error',
                //      showCancelButton:true,
                //       confirmButtonText:'Tryagain'
                //     })
                     
                // })
                // .then(()=>{
                //     // always executed
                // });
            }
        };
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    }
     

// stripe payment
const paymentStripe =async(C_name,Amount)=>{
  let value;
  // console.log(C_name,Money)
   await Axios.get(`${process.env.REACT_APP_BASE_URL}/training_name?name=${C_name}`, {})
   .then((response) => {
   //  console.log(response.data.course_category);
   //  rel = response.data.course_category;

     setdata(response.data);
value=response.data
     // setcname(response.data.course_name)
    // cnamee = response.data.course_name;
   }).then(async()=>{
    console.log(data);
  
  
 // const value=data;
   console.log('cklikk')
   if (token == null || token == "" || token == undefined) {
     console.log("not");

   //   window.location.replace("/login?redirect_url="+window.location.pathname+`?cid=`+value.course_id);
   //   return false;

   console.log(loading);
   //  await Axios.post('http://localhost:3002/create-checkout-session',{
   //   token:token
   //  })
  await fetch(`${process.env.REACT_APP_BASE_URL}/unauth/create-checkout-session` , {
 method: "POST",
 headers: {
   'Content-type': 'application/json',    
 },
 body:JSON.stringify({items: value.course_id,Amount:Amount}),
}).then(res => {

 if (res.ok) return res.json()
 return res.json().then(json => Promise.reject(json))
})
.then(({ url }) => {
 window.location = url
 
}).then(
 console.log(window.location.pathname)
// Axios.get(`http://localhost:3002/verifystripe?id=${idd}`,{

// }).then((res)=>{
//   console.log(res)
// })
)
.catch(e => {
 console.error(e.error)
})
   } else {
    
     console.log(loading);
  
  await fetch(`${process.env.REACT_APP_BASE_URL}/create-checkout-session` , {
 method: "POST",
 headers: {
   'Content-type': 'application/json',
   
 },
 body:JSON.stringify({token,
   items: value.course_id,
   Amount:Amount
 }),
}).then(res => {

 if (res.ok) return res.json()
 return res.json().then(json => Promise.reject(json))
})
.then(({ url }) => {
 window.location = url
 
}).then(
 console.log(window.location.pathname)
// Axios.get(`http://localhost:3002/verifystripe?id=${idd}`,{

// }).then((res)=>{
//   console.log(res)
// })
)
.catch(e => {
 console.error(e.error)
})
   } 
  }).catch((err) => {
    console.error(err);
  })
 }

    return (
   <>
   <p className='Dash_heading'>Scheduled Training</p>
   {
          dataa.map((course)=>(  
            <div className='dash_usercom' >
            <h1 className='lecture_head'>{course.course_name}</h1>
            <div style={{marginLeft:"30px"}}>
            {/* <p className='Lecture_content'>Course:Pingfedrate Administrative</p> */}
            {/* <p className='Lecture_content'>Topic:Basic Certificate Management</p> */}
            {/* <p className='Lecture_content'>Date:25 July 2022</p> */}
            <p className='Lecture_content'>Scheduled Time: {course.time}</p>
            <p className='Lecture_content'>Trainer: {course.trainer}</p>
        
            {/* <a className='Lecture_link' href="https://zoom.us/signin">LINK</a> */}
            
            
            
            </div>
            <div style={{textAlign:"center",justifyContent:"center",alignItems:"center"}}>
            <a href={course.meeting_link}>
                
                <div className='Lecture_link' >
                    <p style={{fontFamily:"Inter"}}>Start Training</p>
                </div>
            </a>
            <a href={course.recording_link}>
                <div className='Lecture_link1' >
                    <p style={{fontFamily:"Inter"}}>View Recording</p>
                </div>
            </a>
            <div>{

                (course.Pending_Amount)?(  <>
                    <p  style={{fontFamily:"Inter",fontSize:"25px",marginLeft:"20px",marginTop:"30px",marginBottom:"20px"}}>Pending Amount</p>
                    <div style={{display:"flex"}}>
                        <div><p style={{fontFamily:"Inter",fontSize:"25px",marginLeft:"20px",marginTop:"12px"}}>{course.Pending_Amount}</p></div>
<div><button className='contactbtn' style={{width:"130px",marginTop:"0px",marginLeft:"100px",fontFamily:"Inter",height:"40px",marginBottom:"0px",marginLeft:"120px",marginBottom:"10px"}}onClick={() => payment(course.course_name,course.Pending_Amount)}>Pay Now</button></div>
                    </div>
                    </>):(
                  ''
                )


                }
               
            </div>
            </div>
           </div>     
      
     ))
      }

  


   
   </>
    )

}
export default Lecture