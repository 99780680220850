import React, { useState} from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { IconContext } from 'react-icons';
import { FiPlus, FiMinus } from 'react-icons/fi';
import In from "../Images/In.png"
import  Insta from "../Images/Insta.png"
import Twi from "../Images/Twi.png"
import Fa from "../Images/Fa.png"  
import Bar2 from "../Images/bar2.png"
import Ping from "../Images/Ping.png"
import Calender from "../Images/Calendar.png"
import home from "../Images/home.png"
import file from "../Images/file.png"
import hat from "../Images/hat.png"
import Group8 from "../Images/Group8.png"
import Clock from "../Images/Clock.png"
import globe from "../Images/globe.png"
import Auth0 from "../Images/Auth0.png"
import Forgerock from '../Images/Forgerock1.png'
import Onelogin from '../Images/Onelogin.png'
import Okta from "../Images/Okta.png"
import "./ping.css"
import Back from "../Images/Back.png"
import Right from "../Images/ChRight.png"

import Arrow from '../Images/Arrow.png'

const AccordionSection = styled.div`
box-shadow:  0px 10px 100px 0px rgba(0, 0, 0, 0.05);; 
  display: flex;
  flex-direction: column;
width:80%;
  justify-content: center;
  position: relative;
  height: 60vh;
  overflow-y:scroll;
 // background: #fff;
  margin-bottom: 100px;
  
`;

const Container = styled.div`
  position:absolute;
  margin-left:50px;
  top: 10%;
 
  ${'' /* box-shadow: 0px 10px 100px 0px rgba(0, 0, 0, 0.05); */}
`;

const Wrap = styled.div`
  
  color: black;
  display: flex;

  width:100%;
  
  cursor: pointer;
  h1 {
    padding: 2rem;
    font-size: 2rem;
  }
  span {
    margin-right: 1.5rem;
  }
`;

const Dropdown = styled.div`

  color:  rgba(37,214,225,1);
  width: 100%;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  p {
    font-size: 2rem;
  }
`;
function Training(props) {
  
const [data,setdata]=useState([]);
    const [clicked, setClicked] = useState(false);

    const toggle = index => {
      if (clicked === index) {
        //if clicked question is already active, then close it
        return setClicked(null);
      }
    
      setClicked(index);
    };
    

   
  //   console.log(props.content)
  //   var tifOptions = Object.keys(props.content).map(function(key) {
  //     // return <option value={key}>{props.content[key]}</option>
  //     console.log(key)
  //     console.log(props.content[key])
  // });


  return (
   <>
      <div className='training'>
            <h1 className='training_heading' style={{fontFamily:"Inter"}}>{props.title}</h1>
        
            <img className='imggt' src={props.img} alt="" />
            <h1  style={{fontSize:"25px",marginTop:"50px",marginLeft:"50px",fontFamily:"Inter"}}>About this Training</h1>
            <p  className='trap' style={{marginTop:"20px",opacity:"",fontFamily:"Inter"}}>{props.discription}</p>
           
{
  (props.content)?(<div>
    <p  style={{fontSize:"25px",marginTop:"100px",marginBottom:"30px",fontFamily:"Inter",fontWeight:"600"}}>What will I Learn ?</p>
             
                <IconContext.Provider value={{ color: '#00FFB9', size: '25px' }}>
                
          <AccordionSection>
            <Container>
          
            {
              (props.content)?(
              
              Object.keys(props.content).map((key) => {
                return (
                  <>
                    <Wrap onClick={() => toggle(key)} key={key}>
                    <span style={{marginTop:"17px"}}>{clicked === key ? <FiMinus /> : <img src={Right} alt="" />}</span>
    
                      <h1 style={{fontFamily:"Inter",fontSize:'21px',fontWeight:"500",justifyItem:"center"}} >{props.content[key]}</h1>
                     
                    </Wrap>
                    {clicked === key ? (
                      <Dropdown>
                      
                      
                      </Dropdown>
                    ) : null}
                  </>
                );
              }
              )):('')
              }
               
                </Container>
          </AccordionSection>
        </IconContext.Provider>
        
    </div>):('')
}
{
  (props.para)?(<div className='About_Consultant_box'>
  <p  style={{fontSize:"25px",marginTop:"50px",fontFamily:"Inter",fontWeight:"600"}}>Why Consultant ? </p>
  <p className='Consultant_info'> <img src={Arrow} alt="" />{props.para.p1}</p>
  <p className='Consultant_info'> <img src={Arrow} alt="" />{props.para.p2}</p>
  <p className='Consultant_info'> <img src={Arrow} alt="" />{props.para.p3}</p>
  <p className='Consultant_info'> <img src={Arrow} alt="" />{props.para.p4}</p>

  </div>):('')
}


    <div>
            <p  style={{fontSize:"25px",marginTop:"50px",fontFamily:"Inter",fontWeight:"600"}}>Share Training </p>
            <div style={{display:"flex",marginTop:"30px"}}>
            <a href="https://www.facebook.com/askmeidentity" style={{height:"24px",width:"24px"}}><img src={Fa} alt="" /></a>
            <a href="https://www.linkedin.com/company/modern-identity-private-limited/" style={{marginLeft:"15px",height:"24px",width:"24px"}}><img src={In} alt="" /></a>
            
            
        


            </div>
                
            </div>

        </div> 
   </>
  )
}

export default Training


const Data = [
  {
    question: ' Lorem ipsum dolor sit amet.',
    answer: 'A labracadabrador',
    ans2:"abcd"
  },
  {
    question: ' Lorem ipsum dolor sit amet.',
    answer: 'Hill-arious.'
  },
  {
    question: ' Lorem ipsum dolor sit amet.',
    answer: 'I Apollo-gize.'
  },
  {
    question: ' Lorem ipsum dolor sit amet.',
    answer: 'Hill-arious.'
  },
  {
    question: ' Lorem ipsum dolor sit amet.',
    answer: 'I Apollo-gize.'
  }
];

// {Data.map((item, index) => {
//   return (
//     <>
//       <Wrap onClick={() => toggle(index)} key={index}>
//       <span>{clicked === index ? <FiMinus /> : <img src={Right} alt="" />}</span>

//         <h1>{item.question}</h1>
       
//       </Wrap>
//       {clicked === index ? (
//         <Dropdown>
//           <p>{item.answer}</p>
//           <p>{item.ans2}</p>


      
//         </Dropdown>
//       ) : null}
//     </>
//   );
// })}