import React from 'react'
import './progress.css'
function Course_progress({ updateActive }) {
  
  return (
    <>
    <div>
    <h2 className='Progress_heading'>Course Progress</h2>
    </div>
    <div className='progress_container'>
        <div className= 'progres_cart' onClick={() => updateActive("Self")}>
        <h4 className='cart_name'>Self Learning</h4>
        <p className='cart_type' style={{fontFamily:"Inter"}}>My learning</p>
        </div>
        <div className='progres_cart' onClick={() => updateActive("Live")}  style={{backgroundColor:"#F3F1FF"}}>
        <h4 className='cart_name'>Virtual Learning</h4>
        <p className='cart_type' style={{color:"#432FB3"}}>My learning</p>
        </div>
        {/* <div className='progres_cart' style={{backgroundColor:"#E8F4FF",color:"#1642A2"}}>
        <h4 className='cart_name'>Live Learning</h4>
        <p className='cart_type' style={{color:"#1642A2"}}>My learning</p>
        </div> */}
    </div>
    </>
  )
}

export default Course_progress