import React, { useState } from "react";
import Header from "../Images/Header.png";
import { FaArrowAltCircleRight, FaArrowAltCircleLeft } from "react-icons/fa";
import Headerback from "../Images/Headerback.png";
import Header1 from "../Images/Header1.png";
import Header2 from "../Images/Header2.png";
import { NavLink } from "react-router-dom";
import R from "../Images/R.png";
import L from "../Images/L.png";

function Slider() {
  const [current, setCurrent] = useState(0);
  const length = slides.length;

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  if (!Array.isArray(slides) || slides.length <= 0) {
    return null;
  }
  const header = {
    width: "100%",
    height: "90rem",
    display: " grid",
    gridTemplateColumns: "20rem 1fr 1fr 5rem",
  };
  return (
    <>
      <div
        style={{
          backgroundImage: `url(${Headerback})`,
          height: "92rem",
          marginBottom: "50px",
        }}
      >
        <section className="slider" style={{ position: "relative" }}>
          <button
            className="left-arrow1"
            onClick={prevSlide}
            style={{ border: "none", background: "none" }}
          >
            <img src={R} alt="" />
          </button>
          <button
            className="right-arrow1"
            style={{ border: "none", background: "none" }}
            onClick={nextSlide}
          >
            <img src={L} alt="" />
          </button>

          {slides.map((slide, index) => {
            return (
              <div
                className={index === current ? "slide active" : "slide"}
                key={index}
              >
                {index === current && (
                  <>
                    <div>
                      <div className="Header" style={header}>
                        <div className="left">
                          <h1
                            style={{ fontFamily: "Inter" }}
                            className="heading"
                          >
                            {slide.head}
                          </h1>
                          <h1
                            style={{ fontFamily: "Inter" }}
                            className="heading2"
                          >
                            {slide.head1}
                          </h1>
                          <p style={{ fontFamily: "Inter" }} className="ptext">
                            {slide.docs}
                          </p>
                          <div>
                            <NavLink to="/contact">
                              <button
                                className="contactbtn"
                                style={{ fontFamily: "Inter" }}
                              >
                                {" "}
                                CONTACT NOW
                              </button>
                            </NavLink>
                          </div>
                        </div>
                        <div className="right">
                          <img
                            className="Himg"
                            src={slide.image}
                            style={{ height: "85rem", marginTop: "30px" }}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            );
          })}
        </section>
      </div>
    </>
  );
}

export default Slider;

const slides = [
  {
    image: Header,
    head: "Managed",
    head1: "IT Services",
    docs: "We Provide Managed IT Services for our Global clients leveraging our industry experience in Managing IT Infrastructure and Access Management. We provide Consulting, Integration and Implementation services for smooth IT Operations.!",
  },
  {
    image: Header1,
    head: "IAM",
    head1: "Training",
    docs: "Harness our industry expertise to streamline IT operations. Comprehensive consulting, integration, and implementation programs for seamless IAM management. Empower your organization with efficient access and infrastructure solutions.",
  },
  {
    image: Header2,
    head: "Application",
    head1: "Development",
    docs: "Global Application Development Services: Empowering clients with innovative solutions, driving digital innovation worldwide.",
  },
];
